import { cpf } from 'cpf-cnpj-validator';
import * as emailValidator from 'email-validator';
import { vazio } from '../Validacao';

/**
 * Validar CPF 
 * cpf: cpf do paciente
 * paciente: id do paciente atual
 * pacientes: lista de todos os pacientes
 * setAlerta: função para setar o alerta
 */
export function verificaCpf(valor: any, paciente: any, pacientes: any[], setAlerta: Function): boolean {
  if(vazio(valor)) return true;
  if (pacientes.some((p: any) => p.cpf === valor && parseInt(p.paciente) !== parseInt(paciente) )) {
    setAlerta({ type: 'danger', message: 'CPF ja cadastrado em outro paciente' });
    return false;
  }

  if (!cpf.isValid(valor)) {
    setAlerta({ type: 'danger', message: 'CPF não é válido' });
    return false;
  }
  return true;
}

/**
 * Validar email
 * email: email do paciente
 * paciente: id do paciente atual
 * pacientes: lista de todos os pacientes
 * setAlerta: função para setar o alerta
 */
export function verificaEmail(email: any, paciente: any, pacientes: any[], setAlerta: Function): boolean {
  if (pacientes.some((p: any) => p.email === email && p.paciente !==paciente)) {
    setAlerta({ type: 'warning', message: 'Email ja cadastrado em outro paciente' });
    return false;
  }
  
  if (!emailValidator.validate(email)) {
    setAlerta({ type: 'warning', message: 'Email com formato incorreto' });
    return false;
  }
  return true;
}

/**
 * Validar telefone
 * telefone: telefone do paciente
 * paciente: id do paciente atual
 * pacientes: lista de todos os pacientes
 * setAlerta: função para setar o alerta
 */
export function verificaTelefone(telefone: any, paciente: any, pacientes: any[], setAlerta: Function): boolean {
  if (pacientes.some((p: any) => p.telefone === telefone && p.paciente !== paciente)) {
    setAlerta({ type: 'warning', message: 'Telefone ja cadastrado em outro paciente' });
    return false;
  }
  return true;
}


/**
 * Validar nome de paciente 
 * nome: Nome do paciente
 * paciente: id do paciente atual
 * pacientes: lista de todos os pacientes
 * setAlerta: função para setar o alerta
 */
export function verificaNome(nome: any, paciente: any, pacientes: any[], setAlerta: Function): boolean {
  if (pacientes.some((p: any) => p.nome === nome && p.paciente !== paciente)) {
    setAlerta({ type: 'warning', message: nome + ' ja esta cadastrado.' });
    return false;
  }
  return true;
}


const ANOS150 = 150 * 365.25 * 24 * 60 * 60 * 1000;
/**
 * Validar nome de paciente 
 * nome: Nome do paciente
 * paciente: id do paciente atual
 * pacientes: lista de todos os pacientes
 * setAlerta: função para setar o alerta
 */

export function verificaDataNascimento(data: Date, setAlerta: Function): boolean {
  if(data == null) return true;
  let timedif = new Date().getTime() - data.getTime();

  if(timedif > ANOS150){
    setAlerta({ type: 'warning', message: 'Data de nascimento muito antiga' });
    return false;
  }
  
  if(timedif < 0) {
    setAlerta({ type: 'warning', message: 'Data de nascimento esta no futuro' });
    return false; 
  }

  return true;
}