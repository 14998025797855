import Container from 'react-bootstrap/Container';
import { Col, Nav, Row } from 'react-bootstrap';
import MenuPrincipal from './prescrito/MenuPrincipal';
import MenuLogo from './prescrito/MenuLogo';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

function AppIndex() {
  const nav = useNavigate();
  useEffect(() => {
    nav("/home");
  },[])
  return (
    <Container>
      <MenuLogo />
      <Row className='mt-5'>
        <Col>
          <p>Seja bem vindo ao Prescrito!</p>
        </Col>
      </Row>
      <Row>
        <Col>
            <a href="/home">Acessar o sistema</a>
        </Col>
      </Row>
    </Container>
  );
}

export default AppIndex;
