import Container from 'react-bootstrap/Container';
import { ListGroup, Nav } from 'react-bootstrap';
import { useEffect } from 'react';
import { useSendGet } from './Server';
import { useNavigate } from 'react-router-dom';
import { useKeycloak } from 'keycloak-react-web';
import MenuLogo from './prescrito/MenuLogo';
import MenuPrincipal from './prescrito/MenuPrincipal';

function Home() {
  const get = useSendGet();
  const navigate = useNavigate();

  const keycloak = useKeycloak();
  const userData = keycloak.keycloak.tokenParsed;

  useEffect(() => {
    get("medico/validar-login", (response: any) => {
      if ("Ok" == response.data)
        return;

      navigate("/medico-inicial")
    });
    ;

  }, []);

  return (
    <Container>
      <MenuPrincipal />
      <p className="mt-5">Bem vindo {userData?.given_name}</p>


      <ListGroup className="mt-5">
        <ListGroup.Item><a href="/prescricao">Prescricao</a></ListGroup.Item>
        <ListGroup.Item><a href="/pacientes">Lista Pacientes</a></ListGroup.Item>
        <ListGroup.Item><a href="/paciente-editor">Novo Paciente</a></ListGroup.Item>
        <ListGroup.Item><a href="/prescricao-configuracao">Configuracao Prescricao</a></ListGroup.Item>
        <ListGroup.Item><a href="/dashboard">Dashboard</a></ListGroup.Item>
        <ListGroup.Item><a href="/secretaria-editor">Secretaria</a></ListGroup.Item>
        <ListGroup.Item><a href="/sinais-vitais">Sinais Vitais</a></ListGroup.Item>
        <ListGroup.Item><a href="/estabelecimento-lista">Estabelecimentos</a></ListGroup.Item>
        <ListGroup.Item><a href={keycloak.keycloak.createAccountUrl()}>Minha Conta</a></ListGroup.Item>
        <ListGroup.Item><a href="/medico-inicial">Medico Inicial</a></ListGroup.Item>
        <ListGroup.Item><a href="/logout">Logout</a></ListGroup.Item>
      </ListGroup>
    </Container>
  );
}

export default Home;
