import Form from 'react-bootstrap/Form';
import { Button, Col, Container, Row } from 'react-bootstrap';
import MenuPrincipal from './MenuPrincipal';
import { useState } from 'react';
import { useSendPostFile } from '../NoAuthServer';
import { useAlerta } from '../componentes/Alerta';
import { vazioProps } from '../Validacao';
import MenuLogo from './MenuLogo';

function PrescricaoAcesso() {
  const [dados, setDados] = useState<any>({});
  const sendPostFile = useSendPostFile();
  const [MensagemAlerta, setAlerta] = useAlerta();

  function downloadPrescricao() {
    let msg = null;

    let props = vazioProps(dados, ['token', 'codigoDownload'])
    if (msg == null && props.length > 0)
      msg = { type: 'danger', message: 'Informe os dados da prescricao:  ' + props.join(', ') };

    if (msg != null) {
      setAlerta(msg)
      return;
    }

    const d = { ...dados }
    sendPostFile("prescricao/acesso", d, (response: any) => {
      if (!response.data.size) {
        setAlerta({ type: 'danger', message: "Codigo invalido" });
        return;
      }

      const href = URL.createObjectURL(
        new Blob([response.data], { type: 'application/pdf' }));

      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'prescricao' + dados.token + '.pdf');
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  }
  return <>
    <MenuLogo />
    <Container>
      <Row className="mt-5">
        <Col>
          <h2>Acesso prescricão </h2>
          <p>Informe os dados de acesso </p>
        </Col>
      </Row>
      <MensagemAlerta />
      <Row className='mt-4'>
        <Col sm={{ span: 6 }}>
          <Form.Label>Token</Form.Label>
          <Form.Control id="token" type="input" placeholder="Digite o token"
            value={dados.token} onChange={e => setDados({ ...dados, token: e.target.value })} />
        </Col>
        <Col sm={{ span: 6 }}>
          <Form.Label>Codigo de Acesso</Form.Label>
          <Form.Control id="codigoDownload" type="input" placeholder="Digite o codigo de acesso"
            value={dados.codigoDownload} onChange={e => setDados({ ...dados, codigoDownload: e.target.value })} onKeyPress={e => e.key === 'Enter' && downloadPrescricao()}  />
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col sm={{ span: 6 }}>
          <Button type='button' variant='primary' onClick={() => downloadPrescricao()}>Fazer download</Button>
        </Col>
      </Row>
    </Container>
  </>
}
export default PrescricaoAcesso;
