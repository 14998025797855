
export function calcularIdade(data: Date): any {
  let today = new Date()

  let years = today.getFullYear() - data.getFullYear();
  let months = today.getMonth() - data.getMonth();
  let days = today.getDate() - data.getDate() - 1;

  if (data.getTime() > today.getTime()) {
    return { anos: 0, meses: 0, dias: 0, futuro: true };
  }

  while (months < 0 || days < 0) {
    if (months < 0) {
      years--;
      months = 12 + months;
    }

    if (days < 0) {
      months--;
      days = 30 + days;
    }
  }

  return { anos: years, meses: months, dias: days, futuro: data.getTime() > today.getTime() };
}

const descricaoMes = (meses: number) => {
  if (!meses) return "";
  return meses + " " + (meses < 2 ? "mês" : "meses");
}

const descricaoDias = (dias: number) => {
  if (!dias) return "";
  return dias + " " + (dias < 2 ? "dia" : "dias");
}

const descricaoAno = (ano: number) => {
  if (!ano) return "";
  return ano + " " + (ano === 1 ? "ano" : "anos");
}

export function descricaoIdade(idade: any) {
  if (idade.futuro) {
    return "";
  }
  if (idade.anos < 1) {
    return (idade.meses ? descricaoMes(idade.meses) : "") +
      (idade.meses && idade.dias ? " e " : "") +
      (idade.dias ? descricaoDias(idade.dias) : "");
  }
  else if (idade.anos < 2) {
    var cond =
      [
        [[0, 0, 0], ["", ""]],
        [[0, 0, 1], ["", ""]],
        [[0, 1, 0], ["", ""]],
        [[0, 1, 1], [" e ", ""]],
        [[1, 0, 0], ["", ""]],
        [[1, 0, 1], [" e ", ""]],
        [[1, 1, 0], [" e ", ""]],
        [[1, 1, 1], [", ", " e "]]
      ].filter(i =>
        i[0][0] === (idade.anos > 0 ? 1 : 0) &&
        i[0][1] === (idade.meses > 0 ? 1 : 0) &&
        i[0][2] === (idade.dias > 0 ? 1 : 0));

    let c = cond[0][1];
    return (idade.anos ? descricaoAno(idade.anos) : "") +
      c[0] +
      (idade.meses ? descricaoMes(idade.meses) : "") +
      c[1] +
      (idade.dias ? descricaoDias(idade.dias) : "");
  }
  else {
    return (idade.anos ? descricaoAno(idade.anos) : "") +
      (idade.meses ? " e " + descricaoMes(idade.meses) : "")
  }
}
