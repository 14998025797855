enum State {
	INICIAL,
	paciente_selecionado,
	incluindo_prescricao,
	incluso_prescricao,
	incluir_paciente,
	incluir_alergia,
	incluir_condicao,
	gerar_prescricao,
  incluir_texto_livre
}

export default State;