export const listaEstados  = [
	{id: 12, nome: 'Acre', sigla: ' AC'},
	{id: 27, nome: 'Alagoas', sigla: ' AL'},
	{id: 16, nome: 'Amapá', sigla: ' AP'},
	{id: 13, nome: 'Amazonas', sigla: ' AM'},
	{id: 29, nome: 'Bahia', sigla: ' BA'},
	{id: 23, nome: 'Ceará', sigla: ' CE'},
	{id: 53, nome: 'Distrito Federal', sigla: ' DF'},
	{id: 32, nome: 'Espírito Santo', sigla: ' ES'},
	{id: 52, nome: 'Goiás', sigla: ' GO'},
	{id: 21, nome: 'Maranhão', sigla: ' MA'},
	{id: 51, nome: 'Mato Grosso', sigla: ' MT'},
	{id: 50, nome: 'Mato Grosso do Sul', sigla: ' MS'},
	{id: 31, nome: 'Minas Gerais', sigla: ' MG'},
	{id: 15, nome: 'Pará', sigla: ' PA'},
	{id: 25, nome: 'Paraíba', sigla: ' PB'},
	{id: 41, nome: 'Paraná', sigla: ' PR'},
	{id: 26, nome: 'Pernambuco', sigla: ' PE'},
	{id: 22, nome: 'Piauí', sigla: ' PI'},
	{id: 33, nome: 'Rio de Janeiro', sigla: ' RJ'},
	{id: 24, nome: 'Rio Grande do Norte', sigla: ' RN'},
	{id: 43, nome: 'Rio Grande do Sul', sigla: ' RS'},
	{id: 11, nome: 'Rondônia', sigla: ' RO'},
	{id: 14, nome: 'Roraima', sigla: ' RR'},
	{id: 42, nome: 'Santa Catarina', sigla: ' SC'},
	{id: 35, nome: 'São Paulo', sigla: ' SP'},
	{id: 28, nome: 'Sergipe', sigla: ' SE'},
	{id: 17, nome: 'Tocantins', sigla: ' TO'},
  ];

export const listaSexo  = [
    { id: '1', nome: 'Feminino' },
    { id: '2', nome: 'Masculino' },
  ];

export const listaRaca  = [
    { id: '1', nome: 'Branco' },
    { id: '2', nome: 'Preto' },
    { id: '3', nome: 'Amarelo' },
    { id: '4', nome: 'Pardo' },
    { id: '5', nome: 'Indígena' },
    { id: '6', nome: 'Não declaradoo' },
  ];

export const listaCidades  = [
	{id: 1100015, nome: "Alta Floresta D'oeste", estado: 11},
	{id: 1100023, nome: "Ariquemes", estado: 11},
	{id: 1100031, nome: "Cabixi", estado: 11},
	{id: 1100049, nome: "Cacoal", estado: 11},
	{id: 1100056, nome: "Cerejeiras", estado: 11},
	{id: 1100064, nome: "Colorado do Oeste", estado: 11},
	{id: 1100072, nome: "Corumbiara", estado: 11},
	{id: 1100080, nome: "Costa Marques", estado: 11},
	{id: 1100098, nome: "Espigão D'oeste", estado: 11},
	{id: 1100106, nome: "Guajará-Mirim", estado: 11},
	{id: 1100114, nome: "Jaru", estado: 11},
	{id: 1100122, nome: "Ji-Paraná", estado: 11},
	{id: 1100130, nome: "Machadinho D'oeste", estado: 11},
	{id: 1100148, nome: "Nova Brasilândia D'oeste", estado: 11},
	{id: 1100155, nome: "Ouro Preto do Oeste", estado: 11},
	{id: 1100189, nome: "Pimenta Bueno", estado: 11},
	{id: 1100205, nome: "Porto Velho", estado: 11},
	{id: 1100254, nome: "Presidente Médici", estado: 11},
	{id: 1100262, nome: "Rio Crespo", estado: 11},
	{id: 1100288, nome: "Rolim de Moura", estado: 11},
	{id: 1100296, nome: "Santa Luzia D'oeste", estado: 11},
	{id: 1100304, nome: "Vilhena", estado: 11},
	{id: 1100320, nome: "São Miguel do Guaporé", estado: 11},
	{id: 1100338, nome: "Nova Mamoré", estado: 11},
	{id: 1100346, nome: "Alvorada D'oeste", estado: 11},
	{id: 1100379, nome: "Alto Alegre dos Parecis", estado: 11},
	{id: 1100403, nome: "Alto Paraíso", estado: 11},
	{id: 1100452, nome: "Buritis", estado: 11},
	{id: 1100502, nome: "Novo Horizonte do Oeste", estado: 11},
	{id: 1100601, nome: "Cacaulândia", estado: 11},
	{id: 1100700, nome: "Campo Novo de Rondônia", estado: 11},
	{id: 1100809, nome: "Candeias do Jamari", estado: 11},
	{id: 1100908, nome: "Castanheiras", estado: 11},
	{id: 1100924, nome: "Chupinguaia", estado: 11},
	{id: 1100940, nome: "Cujubim", estado: 11},
	{id: 1101005, nome: "Governador Jorge Teixeira", estado: 11},
	{id: 1101104, nome: "Itapuã do Oeste", estado: 11},
	{id: 1101203, nome: "Ministro Andreazza", estado: 11},
	{id: 1101302, nome: "Mirante da Serra", estado: 11},
	{id: 1101401, nome: "Monte Negro", estado: 11},
	{id: 1101435, nome: "Nova União", estado: 11},
	{id: 1101450, nome: "Parecis", estado: 11},
	{id: 1101468, nome: "Pimenteiras do Oeste", estado: 11},
	{id: 1101476, nome: "Primavera de Rondônia", estado: 11},
	{id: 1101484, nome: "São Felipe D'oeste", estado: 11},
	{id: 1101492, nome: "São Francisco do Guaporé", estado: 11},
	{id: 1101500, nome: "Seringueiras", estado: 11},
	{id: 1101559, nome: "Teixeirópolis", estado: 11},
	{id: 1101609, nome: "Theobroma", estado: 11},
	{id: 1101708, nome: "Urupá", estado: 11},
	{id: 1101757, nome: "Vale do Anari", estado: 11},
	{id: 1101807, nome: "Vale do Paraíso", estado: 11},
	{id: 1200013, nome: "Acrelândia", estado: 12},
	{id: 1200054, nome: "Assis Brasil", estado: 12},
	{id: 1200104, nome: "Brasiléia", estado: 12},
	{id: 1200138, nome: "Bujari", estado: 12},
	{id: 1200179, nome: "Capixaba", estado: 12},
	{id: 1200203, nome: "Cruzeiro do Sul", estado: 12},
	{id: 1200252, nome: "Epitaciolândia", estado: 12},
	{id: 1200302, nome: "Feijó", estado: 12},
	{id: 1200328, nome: "Jordão", estado: 12},
	{id: 1200336, nome: "Mâncio Lima", estado: 12},
	{id: 1200344, nome: "Manoel Urbano", estado: 12},
	{id: 1200351, nome: "Marechal Thaumaturgo", estado: 12},
	{id: 1200385, nome: "Plácido de Castro", estado: 12},
	{id: 1200393, nome: "Porto Walter", estado: 12},
	{id: 1200401, nome: "Rio Branco", estado: 12},
	{id: 1200427, nome: "Rodrigues Alves", estado: 12},
	{id: 1200435, nome: "Santa Rosa do Purus", estado: 12},
	{id: 1200450, nome: "Senador Guiomard", estado: 12},
	{id: 1200500, nome: "Sena Madureira", estado: 12},
	{id: 1200609, nome: "Tarauacá", estado: 12},
	{id: 1200708, nome: "Xapuri", estado: 12},
	{id: 1200807, nome: "Porto Acre", estado: 12},
	{id: 1300029, nome: "Alvarães", estado: 13},
	{id: 1300060, nome: "Amaturá", estado: 13},
	{id: 1300086, nome: "Anamã", estado: 13},
	{id: 1300102, nome: "Anori", estado: 13},
	{id: 1300144, nome: "Apuí", estado: 13},
	{id: 1300201, nome: "Atalaia do Norte", estado: 13},
	{id: 1300300, nome: "Autazes", estado: 13},
	{id: 1300409, nome: "Barcelos", estado: 13},
	{id: 1300508, nome: "Barreirinha", estado: 13},
	{id: 1300607, nome: "Benjamin Constant", estado: 13},
	{id: 1300631, nome: "Beruri", estado: 13},
	{id: 1300680, nome: "Boa Vista do Ramos", estado: 13},
	{id: 1300706, nome: "Boca do Acre", estado: 13},
	{id: 1300805, nome: "Borba", estado: 13},
	{id: 1300839, nome: "Caapiranga", estado: 13},
	{id: 1300904, nome: "Canutama", estado: 13},
	{id: 1301001, nome: "Carauari", estado: 13},
	{id: 1301100, nome: "Careiro", estado: 13},
	{id: 1301159, nome: "Careiro da Várzea", estado: 13},
	{id: 1301209, nome: "Coari", estado: 13},
	{id: 1301308, nome: "Codajás", estado: 13},
	{id: 1301407, nome: "Eirunepé", estado: 13},
	{id: 1301506, nome: "Envira", estado: 13},
	{id: 1301605, nome: "Fonte Boa", estado: 13},
	{id: 1301654, nome: "Guajará", estado: 13},
	{id: 1301704, nome: "Humaitá", estado: 13},
	{id: 1301803, nome: "Ipixuna", estado: 13},
	{id: 1301852, nome: "Iranduba", estado: 13},
	{id: 1301902, nome: "Itacoatiara", estado: 13},
	{id: 1301951, nome: "Itamarati", estado: 13},
	{id: 1302009, nome: "Itapiranga", estado: 13},
	{id: 1302108, nome: "Japurá", estado: 13},
	{id: 1302207, nome: "Juruá", estado: 13},
	{id: 1302306, nome: "Jutaí", estado: 13},
	{id: 1302405, nome: "Lábrea", estado: 13},
	{id: 1302504, nome: "Manacapuru", estado: 13},
	{id: 1302553, nome: "Manaquiri", estado: 13},
	{id: 1302603, nome: "Manaus", estado: 13},
	{id: 1302702, nome: "Manicoré", estado: 13},
	{id: 1302801, nome: "Maraã", estado: 13},
	{id: 1302900, nome: "Maués", estado: 13},
	{id: 1303007, nome: "Nhamundá", estado: 13},
	{id: 1303106, nome: "Nova Olinda do Norte", estado: 13},
	{id: 1303205, nome: "Novo Airão", estado: 13},
	{id: 1303304, nome: "Novo Aripuanã", estado: 13},
	{id: 1303403, nome: "Parintins", estado: 13},
	{id: 1303502, nome: "Pauini", estado: 13},
	{id: 1303536, nome: "Presidente Figueiredo", estado: 13},
	{id: 1303569, nome: "Rio Preto da Eva", estado: 13},
	{id: 1303601, nome: "Santa Isabel do Rio Negro", estado: 13},
	{id: 1303700, nome: "Santo Antônio do Içá", estado: 13},
	{id: 1303809, nome: "São Gabriel da Cachoeira", estado: 13},
	{id: 1303908, nome: "São Paulo de Olivença", estado: 13},
	{id: 1303957, nome: "São Sebastião do Uatumã", estado: 13},
	{id: 1304005, nome: "Silves", estado: 13},
	{id: 1304062, nome: "Tabatinga", estado: 13},
	{id: 1304104, nome: "Tapauá", estado: 13},
	{id: 1304203, nome: "Tefé", estado: 13},
	{id: 1304237, nome: "Tonantins", estado: 13},
	{id: 1304260, nome: "Uarini", estado: 13},
	{id: 1304302, nome: "Urucará", estado: 13},
	{id: 1304401, nome: "Urucurituba", estado: 13},
	{id: 1400027, nome: "Amajari", estado: 14},
	{id: 1400050, nome: "Alto Alegre", estado: 14},
	{id: 1400100, nome: "Boa Vista", estado: 14},
	{id: 1400159, nome: "Bonfim", estado: 14},
	{id: 1400175, nome: "Cantá", estado: 14},
	{id: 1400209, nome: "Caracaraí", estado: 14},
	{id: 1400233, nome: "Caroebe", estado: 14},
	{id: 1400282, nome: "Iracema", estado: 14},
	{id: 1400308, nome: "Mucajaí", estado: 14},
	{id: 1400407, nome: "Normandia", estado: 14},
	{id: 1400456, nome: "Pacaraima", estado: 14},
	{id: 1400472, nome: "Rorainópolis", estado: 14},
	{id: 1400506, nome: "São João da Baliza", estado: 14},
	{id: 1400605, nome: "São Luiz", estado: 14},
	{id: 1400704, nome: "Uiramutã", estado: 14},
	{id: 1500107, nome: "Abaetetuba", estado: 15},
	{id: 1500131, nome: "Abel Figueiredo", estado: 15},
	{id: 1500206, nome: "Acará", estado: 15},
	{id: 1500305, nome: "Afuá", estado: 15},
	{id: 1500347, nome: "Água Azul do Norte", estado: 15},
	{id: 1500404, nome: "Alenquer", estado: 15},
	{id: 1500503, nome: "Almeirim", estado: 15},
	{id: 1500602, nome: "Altamira", estado: 15},
	{id: 1500701, nome: "Anajás", estado: 15},
	{id: 1500800, nome: "Ananindeua", estado: 15},
	{id: 1500859, nome: "Anapu", estado: 15},
	{id: 1500909, nome: "Augusto Corrêa", estado: 15},
	{id: 1500958, nome: "Aurora do Pará", estado: 15},
	{id: 1501006, nome: "Aveiro", estado: 15},
	{id: 1501105, nome: "Bagre", estado: 15},
	{id: 1501204, nome: "Baião", estado: 15},
	{id: 1501253, nome: "Bannach", estado: 15},
	{id: 1501303, nome: "Barcarena", estado: 15},
	{id: 1501402, nome: "Belém", estado: 15},
	{id: 1501451, nome: "Belterra", estado: 15},
	{id: 1501501, nome: "Benevides", estado: 15},
	{id: 1501576, nome: "Bom Jesus do Tocantins", estado: 15},
	{id: 1501600, nome: "Bonito", estado: 15},
	{id: 1501709, nome: "Bragança", estado: 15},
	{id: 1501725, nome: "Brasil Novo", estado: 15},
	{id: 1501758, nome: "Brejo Grande do Araguaia", estado: 15},
	{id: 1501782, nome: "Breu Branco", estado: 15},
	{id: 1501808, nome: "Breves", estado: 15},
	{id: 1501907, nome: "Bujaru", estado: 15},
	{id: 1501956, nome: "Cachoeira do Piriá", estado: 15},
	{id: 1502004, nome: "Cachoeira do Arari", estado: 15},
	{id: 1502103, nome: "Cametá", estado: 15},
	{id: 1502152, nome: "Canaã dos Carajás", estado: 15},
	{id: 1502202, nome: "Capanema", estado: 15},
	{id: 1502301, nome: "Capitão Poço", estado: 15},
	{id: 1502400, nome: "Castanhal", estado: 15},
	{id: 1502509, nome: "Chaves", estado: 15},
	{id: 1502608, nome: "Colares", estado: 15},
	{id: 1502707, nome: "Conceição do Araguaia", estado: 15},
	{id: 1502756, nome: "Concórdia do Pará", estado: 15},
	{id: 1502764, nome: "Cumaru do Norte", estado: 15},
	{id: 1502772, nome: "Curionópolis", estado: 15},
	{id: 1502806, nome: "Curralinho", estado: 15},
	{id: 1502855, nome: "Curuá", estado: 15},
	{id: 1502905, nome: "Curuçá", estado: 15},
	{id: 1502939, nome: "dom Eliseu", estado: 15},
	{id: 1502954, nome: "Eldorado do Carajás", estado: 15},
	{id: 1503002, nome: "Faro", estado: 15},
	{id: 1503044, nome: "Floresta do Araguaia", estado: 15},
	{id: 1503077, nome: "Garrafão do Norte", estado: 15},
	{id: 1503093, nome: "Goianésia do Pará", estado: 15},
	{id: 1503101, nome: "Gurupá", estado: 15},
	{id: 1503200, nome: "Igarapé-Açu", estado: 15},
	{id: 1503309, nome: "Igarapé-Miri", estado: 15},
	{id: 1503408, nome: "Inhangapi", estado: 15},
	{id: 1503457, nome: "Ipixuna do Pará", estado: 15},
	{id: 1503507, nome: "Irituia", estado: 15},
	{id: 1503606, nome: "Itaituba", estado: 15},
	{id: 1503705, nome: "Itupiranga", estado: 15},
	{id: 1503754, nome: "Jacareacanga", estado: 15},
	{id: 1503804, nome: "Jacundá", estado: 15},
	{id: 1503903, nome: "Juruti", estado: 15},
	{id: 1504000, nome: "Limoeiro do Ajuru", estado: 15},
	{id: 1504059, nome: "Mãe do Rio", estado: 15},
	{id: 1504109, nome: "Magalhães Barata", estado: 15},
	{id: 1504208, nome: "Marabá", estado: 15},
	{id: 1504307, nome: "Maracanã", estado: 15},
	{id: 1504406, nome: "Marapanim", estado: 15},
	{id: 1504422, nome: "Marituba", estado: 15},
	{id: 1504455, nome: "Medicilândia", estado: 15},
	{id: 1504505, nome: "Melgaço", estado: 15},
	{id: 1504604, nome: "Mocajuba", estado: 15},
	{id: 1504703, nome: "Moju", estado: 15},
	{id: 1504752, nome: "Mojuí dos Campos", estado: 15},
	{id: 1504802, nome: "Monte Alegre", estado: 15},
	{id: 1504901, nome: "Muaná", estado: 15},
	{id: 1504950, nome: "Nova Esperança do Piriá", estado: 15},
	{id: 1504976, nome: "Nova Ipixuna", estado: 15},
	{id: 1505007, nome: "Nova Timboteua", estado: 15},
	{id: 1505031, nome: "Novo Progresso", estado: 15},
	{id: 1505064, nome: "Novo Repartimento", estado: 15},
	{id: 1505106, nome: "Óbidos", estado: 15},
	{id: 1505205, nome: "Oeiras do Pará", estado: 15},
	{id: 1505304, nome: "Oriximiná", estado: 15},
	{id: 1505403, nome: "Ourém", estado: 15},
	{id: 1505437, nome: "Ourilândia do Norte", estado: 15},
	{id: 1505486, nome: "Pacajá", estado: 15},
	{id: 1505494, nome: "Palestina do Pará", estado: 15},
	{id: 1505502, nome: "Paragominas", estado: 15},
	{id: 1505536, nome: "Parauapebas", estado: 15},
	{id: 1505551, nome: "Pau D'arco", estado: 15},
	{id: 1505601, nome: "Peixe-Boi", estado: 15},
	{id: 1505635, nome: "Piçarra", estado: 15},
	{id: 1505650, nome: "Placas", estado: 15},
	{id: 1505700, nome: "Ponta de Pedras", estado: 15},
	{id: 1505809, nome: "Portel", estado: 15},
	{id: 1505908, nome: "Porto de Moz", estado: 15},
	{id: 1506005, nome: "Prainha", estado: 15},
	{id: 1506104, nome: "Primavera", estado: 15},
	{id: 1506112, nome: "Quatipuru", estado: 15},
	{id: 1506138, nome: "Redenção", estado: 15},
	{id: 1506161, nome: "Rio Maria", estado: 15},
	{id: 1506187, nome: "Rondon do Pará", estado: 15},
	{id: 1506195, nome: "Rurópolis", estado: 15},
	{id: 1506203, nome: "Salinópolis", estado: 15},
	{id: 1506302, nome: "Salvaterra", estado: 15},
	{id: 1506351, nome: "Santa Bárbara do Pará", estado: 15},
	{id: 1506401, nome: "Santa Cruz do Arari", estado: 15},
	{id: 1506500, nome: "Santa Izabel do Pará", estado: 15},
	{id: 1506559, nome: "Santa Luzia do Pará", estado: 15},
	{id: 1506583, nome: "Santa Maria das Barreiras", estado: 15},
	{id: 1506609, nome: "Santa Maria do Pará", estado: 15},
	{id: 1506708, nome: "Santana do Araguaia", estado: 15},
	{id: 1506807, nome: "Santarém", estado: 15},
	{id: 1506906, nome: "Santarém Novo", estado: 15},
	{id: 1507003, nome: "Santo Antônio do Tauá", estado: 15},
	{id: 1507102, nome: "São Caetano de Odivelas", estado: 15},
	{id: 1507151, nome: "São domingos do Araguaia", estado: 15},
	{id: 1507201, nome: "São domingos do Capim", estado: 15},
	{id: 1507300, nome: "São Félix do Xingu", estado: 15},
	{id: 1507409, nome: "São Francisco do Pará", estado: 15},
	{id: 1507458, nome: "São Geraldo do Araguaia", estado: 15},
	{id: 1507466, nome: "São João da Ponta", estado: 15},
	{id: 1507474, nome: "São João de Pirabas", estado: 15},
	{id: 1507508, nome: "São João do Araguaia", estado: 15},
	{id: 1507607, nome: "São Miguel do Guamá", estado: 15},
	{id: 1507706, nome: "São Sebastião da Boa Vista", estado: 15},
	{id: 1507755, nome: "Sapucaia", estado: 15},
	{id: 1507805, nome: "Senador José Porfírio", estado: 15},
	{id: 1507904, nome: "Soure", estado: 15},
	{id: 1507953, nome: "Tailândia", estado: 15},
	{id: 1507961, nome: "Terra Alta", estado: 15},
	{id: 1507979, nome: "Terra Santa", estado: 15},
	{id: 1508001, nome: "Tomé-Açu", estado: 15},
	{id: 1508035, nome: "Tracuateua", estado: 15},
	{id: 1508050, nome: "Trairão", estado: 15},
	{id: 1508084, nome: "Tucumã", estado: 15},
	{id: 1508100, nome: "Tucuruí", estado: 15},
	{id: 1508126, nome: "Ulianópolis", estado: 15},
	{id: 1508159, nome: "Uruará", estado: 15},
	{id: 1508209, nome: "Vigia", estado: 15},
	{id: 1508308, nome: "Viseu", estado: 15},
	{id: 1508357, nome: "Vitória do Xingu", estado: 15},
	{id: 1508407, nome: "Xinguara", estado: 15},
	{id: 1600055, nome: "Serra do Navio", estado: 16},
	{id: 1600105, nome: "Amapá", estado: 16},
	{id: 1600154, nome: "Pedra Branca do Amapari", estado: 16},
	{id: 1600204, nome: "Calçoene", estado: 16},
	{id: 1600212, nome: "Cutias", estado: 16},
	{id: 1600238, nome: "Ferreira Gomes", estado: 16},
	{id: 1600253, nome: "Itaubal", estado: 16},
	{id: 1600279, nome: "Laranjal do Jari", estado: 16},
	{id: 1600303, nome: "Macapá", estado: 16},
	{id: 1600402, nome: "Mazagão", estado: 16},
	{id: 1600501, nome: "Oiapoque", estado: 16},
	{id: 1600535, nome: "Porto Grande", estado: 16},
	{id: 1600550, nome: "Pracuúba", estado: 16},
	{id: 1600600, nome: "Santana", estado: 16},
	{id: 1600709, nome: "Tartarugalzinho", estado: 16},
	{id: 1600808, nome: "Vitória do Jari", estado: 16},
	{id: 1700251, nome: "Abreulândia", estado: 17},
	{id: 1700301, nome: "Aguiarnópolis", estado: 17},
	{id: 1700350, nome: "Aliança do Tocantins", estado: 17},
	{id: 1700400, nome: "Almas", estado: 17},
	{id: 1700707, nome: "Alvorada", estado: 17},
	{id: 1701002, nome: "Ananás", estado: 17},
	{id: 1701051, nome: "Angico", estado: 17},
	{id: 1701101, nome: "Aparecida do Rio Negro", estado: 17},
	{id: 1701309, nome: "Aragominas", estado: 17},
	{id: 1701903, nome: "Araguacema", estado: 17},
	{id: 1702000, nome: "Araguaçu", estado: 17},
	{id: 1702109, nome: "Araguaína", estado: 17},
	{id: 1702158, nome: "Araguanã", estado: 17},
	{id: 1702208, nome: "Araguatins", estado: 17},
	{id: 1702307, nome: "Arapoema", estado: 17},
	{id: 1702406, nome: "Arraias", estado: 17},
	{id: 1702554, nome: "Augustinópolis", estado: 17},
	{id: 1702703, nome: "Aurora do Tocantins", estado: 17},
	{id: 1702901, nome: "Axixá do Tocantins", estado: 17},
	{id: 1703008, nome: "Babaçulândia", estado: 17},
	{id: 1703057, nome: "Bandeirantes do Tocantins", estado: 17},
	{id: 1703073, nome: "Barra do Ouro", estado: 17},
	{id: 1703107, nome: "Barrolândia", estado: 17},
	{id: 1703206, nome: "Bernardo Sayão", estado: 17},
	{id: 1703305, nome: "Bom Jesus do Tocantins", estado: 17},
	{id: 1703602, nome: "Brasilândia do Tocantins", estado: 17},
	{id: 1703701, nome: "Brejinho de Nazaré", estado: 17},
	{id: 1703800, nome: "Buriti do Tocantins", estado: 17},
	{id: 1703826, nome: "Cachoeirinha", estado: 17},
	{id: 1703842, nome: "Campos Lindos", estado: 17},
	{id: 1703867, nome: "Cariri do Tocantins", estado: 17},
	{id: 1703883, nome: "Carmolândia", estado: 17},
	{id: 1703891, nome: "Carrasco Bonito", estado: 17},
	{id: 1703909, nome: "Caseara", estado: 17},
	{id: 1704105, nome: "Centenário", estado: 17},
	{id: 1704600, nome: "Chapada de Areia", estado: 17},
	{id: 1705102, nome: "Chapada da Natividade", estado: 17},
	{id: 1705508, nome: "Colinas do Tocantins", estado: 17},
	{id: 1705557, nome: "Combinado", estado: 17},
	{id: 1705607, nome: "Conceição do Tocantins", estado: 17},
	{id: 1706001, nome: "Couto Magalhães", estado: 17},
	{id: 1706100, nome: "Cristalândia", estado: 17},
	{id: 1706258, nome: "Crixás do Tocantins", estado: 17},
	{id: 1706506, nome: "darcinópolis", estado: 17},
	{id: 1707009, nome: "Dianópolis", estado: 17},
	{id: 1707108, nome: "Divinópolis do Tocantins", estado: 17},
	{id: 1707207, nome: "dois Irmãos do Tocantins", estado: 17},
	{id: 1707306, nome: "Dueré", estado: 17},
	{id: 1707405, nome: "Esperantina", estado: 17},
	{id: 1707553, nome: "Fátima", estado: 17},
	{id: 1707652, nome: "Figueirópolis", estado: 17},
	{id: 1707702, nome: "Filadélfia", estado: 17},
	{id: 1708205, nome: "Formoso do Araguaia", estado: 17},
	{id: 1708254, nome: "Fortaleza do Tabocão", estado: 17},
	{id: 1708304, nome: "Goianorte", estado: 17},
	{id: 1709005, nome: "Goiatins", estado: 17},
	{id: 1709302, nome: "Guaraí", estado: 17},
	{id: 1709500, nome: "Gurupi", estado: 17},
	{id: 1709807, nome: "Ipueiras", estado: 17},
	{id: 1710508, nome: "Itacajá", estado: 17},
	{id: 1710706, nome: "Itaguatins", estado: 17},
	{id: 1710904, nome: "Itapiratins", estado: 17},
	{id: 1711100, nome: "Itaporã do Tocantins", estado: 17},
	{id: 1711506, nome: "Jaú do Tocantins", estado: 17},
	{id: 1711803, nome: "Juarina", estado: 17},
	{id: 1711902, nome: "Lagoa da Confusão", estado: 17},
	{id: 1711951, nome: "Lagoa do Tocantins", estado: 17},
	{id: 1712009, nome: "Lajeado", estado: 17},
	{id: 1712157, nome: "Lavandeira", estado: 17},
	{id: 1712405, nome: "Lizarda", estado: 17},
	{id: 1712454, nome: "Luzinópolis", estado: 17},
	{id: 1712504, nome: "Marianópolis do Tocantins", estado: 17},
	{id: 1712702, nome: "Mateiros", estado: 17},
	{id: 1712801, nome: "Maurilândia do Tocantins", estado: 17},
	{id: 1713205, nome: "Miracema do Tocantins", estado: 17},
	{id: 1713304, nome: "Miranorte", estado: 17},
	{id: 1713601, nome: "Monte do Carmo", estado: 17},
	{id: 1713700, nome: "Monte Santo do Tocantins", estado: 17},
	{id: 1713809, nome: "Palmeiras do Tocantins", estado: 17},
	{id: 1713957, nome: "Muricilândia", estado: 17},
	{id: 1714203, nome: "Natividade", estado: 17},
	{id: 1714302, nome: "Nazaré", estado: 17},
	{id: 1714880, nome: "Nova Olinda", estado: 17},
	{id: 1715002, nome: "Nova Rosalândia", estado: 17},
	{id: 1715101, nome: "Novo Acordo", estado: 17},
	{id: 1715150, nome: "Novo Alegre", estado: 17},
	{id: 1715259, nome: "Novo Jardim", estado: 17},
	{id: 1715507, nome: "Oliveira de Fátima", estado: 17},
	{id: 1715705, nome: "Palmeirante", estado: 17},
	{id: 1715754, nome: "Palmeirópolis", estado: 17},
	{id: 1716109, nome: "Paraíso do Tocantins", estado: 17},
	{id: 1716208, nome: "Paranã", estado: 17},
	{id: 1716307, nome: "Pau D'arco", estado: 17},
	{id: 1716505, nome: "Pedro Afonso", estado: 17},
	{id: 1716604, nome: "Peixe", estado: 17},
	{id: 1716653, nome: "Pequizeiro", estado: 17},
	{id: 1716703, nome: "Colméia", estado: 17},
	{id: 1717008, nome: "Pindorama do Tocantins", estado: 17},
	{id: 1717206, nome: "Piraquê", estado: 17},
	{id: 1717503, nome: "Pium", estado: 17},
	{id: 1717800, nome: "Ponte Alta do Bom Jesus", estado: 17},
	{id: 1717909, nome: "Ponte Alta do Tocantins", estado: 17},
	{id: 1718006, nome: "Porto Alegre do Tocantins", estado: 17},
	{id: 1718204, nome: "Porto Nacional", estado: 17},
	{id: 1718303, nome: "Praia Norte", estado: 17},
	{id: 1718402, nome: "Presidente Kennedy", estado: 17},
	{id: 1718451, nome: "Pugmil", estado: 17},
	{id: 1718501, nome: "Recursolândia", estado: 17},
	{id: 1718550, nome: "Riachinho", estado: 17},
	{id: 1718659, nome: "Rio da Conceição", estado: 17},
	{id: 1718709, nome: "Rio dos Bois", estado: 17},
	{id: 1718758, nome: "Rio Sono", estado: 17},
	{id: 1718808, nome: "Sampaio", estado: 17},
	{id: 1718840, nome: "Sandolândia", estado: 17},
	{id: 1718865, nome: "Santa Fé do Araguaia", estado: 17},
	{id: 1718881, nome: "Santa Maria do Tocantins", estado: 17},
	{id: 1718899, nome: "Santa Rita do Tocantins", estado: 17},
	{id: 1718907, nome: "Santa Rosa do Tocantins", estado: 17},
	{id: 1719004, nome: "Santa Tereza do Tocantins", estado: 17},
	{id: 1720002, nome: "Santa Terezinha do Tocantins", estado: 17},
	{id: 1720101, nome: "São Bento do Tocantins", estado: 17},
	{id: 1720150, nome: "São Félix do Tocantins", estado: 17},
	{id: 1720200, nome: "São Miguel do Tocantins", estado: 17},
	{id: 1720259, nome: "São Salvador do Tocantins", estado: 17},
	{id: 1720309, nome: "São Sebastião do Tocantins", estado: 17},
	{id: 1720499, nome: "São Valério", estado: 17},
	{id: 1720655, nome: "Silvanópolis", estado: 17},
	{id: 1720804, nome: "Sítio Novo do Tocantins", estado: 17},
	{id: 1720853, nome: "Sucupira", estado: 17},
	{id: 1720903, nome: "Taguatinga", estado: 17},
	{id: 1720937, nome: "Taipas do Tocantins", estado: 17},
	{id: 1720978, nome: "Talismã", estado: 17},
	{id: 1721000, nome: "Palmas", estado: 17},
	{id: 1721109, nome: "Tocantínia", estado: 17},
	{id: 1721208, nome: "Tocantinópolis", estado: 17},
	{id: 1721257, nome: "Tupirama", estado: 17},
	{id: 1721307, nome: "Tupiratins", estado: 17},
	{id: 1722081, nome: "Wanderlândia", estado: 17},
	{id: 1722107, nome: "Xambioá", estado: 17},
	{id: 2100055, nome: "Açailândia", estado: 21},
	{id: 2100105, nome: "Afonso Cunha", estado: 21},
	{id: 2100154, nome: "Água doce do Maranhão", estado: 21},
	{id: 2100204, nome: "Alcântara", estado: 21},
	{id: 2100303, nome: "Aldeias Altas", estado: 21},
	{id: 2100402, nome: "Altamira do Maranhão", estado: 21},
	{id: 2100436, nome: "Alto Alegre do Maranhão", estado: 21},
	{id: 2100477, nome: "Alto Alegre do Pindaré", estado: 21},
	{id: 2100501, nome: "Alto Parnaíba", estado: 21},
	{id: 2100550, nome: "Amapá do Maranhão", estado: 21},
	{id: 2100600, nome: "Amarante do Maranhão", estado: 21},
	{id: 2100709, nome: "Anajatuba", estado: 21},
	{id: 2100808, nome: "Anapurus", estado: 21},
	{id: 2100832, nome: "Apicum-Açu", estado: 21},
	{id: 2100873, nome: "Araguanã", estado: 21},
	{id: 2100907, nome: "Araioses", estado: 21},
	{id: 2100956, nome: "Arame", estado: 21},
	{id: 2101004, nome: "Arari", estado: 21},
	{id: 2101103, nome: "Axixá", estado: 21},
	{id: 2101202, nome: "Bacabal", estado: 21},
	{id: 2101251, nome: "Bacabeira", estado: 21},
	{id: 2101301, nome: "Bacuri", estado: 21},
	{id: 2101350, nome: "Bacurituba", estado: 21},
	{id: 2101400, nome: "Balsas", estado: 21},
	{id: 2101509, nome: "Barão de Grajaú", estado: 21},
	{id: 2101608, nome: "Barra do Corda", estado: 21},
	{id: 2101707, nome: "Barreirinhas", estado: 21},
	{id: 2101731, nome: "Belágua", estado: 21},
	{id: 2101772, nome: "Bela Vista do Maranhão", estado: 21},
	{id: 2101806, nome: "Benedito Leite", estado: 21},
	{id: 2101905, nome: "Bequimão", estado: 21},
	{id: 2101939, nome: "Bernardo do Mearim", estado: 21},
	{id: 2101970, nome: "Boa Vista do Gurupi", estado: 21},
	{id: 2102002, nome: "Bom Jardim", estado: 21},
	{id: 2102036, nome: "Bom Jesus das Selvas", estado: 21},
	{id: 2102077, nome: "Bom Lugar", estado: 21},
	{id: 2102101, nome: "Brejo", estado: 21},
	{id: 2102150, nome: "Brejo de Areia", estado: 21},
	{id: 2102200, nome: "Buriti", estado: 21},
	{id: 2102309, nome: "Buriti Bravo", estado: 21},
	{id: 2102325, nome: "Buriticupu", estado: 21},
	{id: 2102358, nome: "Buritirana", estado: 21},
	{id: 2102374, nome: "Cachoeira Grande", estado: 21},
	{id: 2102408, nome: "Cajapió", estado: 21},
	{id: 2102507, nome: "Cajari", estado: 21},
	{id: 2102556, nome: "Campestre do Maranhão", estado: 21},
	{id: 2102606, nome: "Cândido Mendes", estado: 21},
	{id: 2102705, nome: "Cantanhede", estado: 21},
	{id: 2102754, nome: "Capinzal do Norte", estado: 21},
	{id: 2102804, nome: "Carolina", estado: 21},
	{id: 2102903, nome: "Carutapera", estado: 21},
	{id: 2103000, nome: "Caxias", estado: 21},
	{id: 2103109, nome: "Cedral", estado: 21},
	{id: 2103125, nome: "Central do Maranhão", estado: 21},
	{id: 2103158, nome: "Centro do Guilherme", estado: 21},
	{id: 2103174, nome: "Centro Novo do Maranhão", estado: 21},
	{id: 2103208, nome: "Chapadinha", estado: 21},
	{id: 2103257, nome: "Cidelândia", estado: 21},
	{id: 2103307, nome: "Codó", estado: 21},
	{id: 2103406, nome: "Coelho Neto", estado: 21},
	{id: 2103505, nome: "Colinas", estado: 21},
	{id: 2103554, nome: "Conceição do Lago-Açu", estado: 21},
	{id: 2103604, nome: "Coroatá", estado: 21},
	{id: 2103703, nome: "Cururupu", estado: 21},
	{id: 2103752, nome: "davinópolis", estado: 21},
	{id: 2103802, nome: "dom Pedro", estado: 21},
	{id: 2103901, nome: "Duque Bacelar", estado: 21},
	{id: 2104008, nome: "Esperantinópolis", estado: 21},
	{id: 2104057, nome: "Estreito", estado: 21},
	{id: 2104073, nome: "Feira Nova do Maranhão", estado: 21},
	{id: 2104081, nome: "Fernando Falcão", estado: 21},
	{id: 2104099, nome: "Formosa da Serra Negra", estado: 21},
	{id: 2104107, nome: "Fortaleza dos Nogueiras", estado: 21},
	{id: 2104206, nome: "Fortuna", estado: 21},
	{id: 2104305, nome: "Godofredo Viana", estado: 21},
	{id: 2104404, nome: "Gonçalves Dias", estado: 21},
	{id: 2104503, nome: "Governador Archer", estado: 21},
	{id: 2104552, nome: "Governador Edison Lobão", estado: 21},
	{id: 2104602, nome: "Governador Eugênio Barros", estado: 21},
	{id: 2104628, nome: "Governador Luiz Rocha", estado: 21},
	{id: 2104651, nome: "Governador Newton Bello", estado: 21},
	{id: 2104677, nome: "Governador Nunes Freire", estado: 21},
	{id: 2104701, nome: "Graça Aranha", estado: 21},
	{id: 2104800, nome: "Grajaú", estado: 21},
	{id: 2104909, nome: "Guimarães", estado: 21},
	{id: 2105005, nome: "Humberto de Campos", estado: 21},
	{id: 2105104, nome: "Icatu", estado: 21},
	{id: 2105153, nome: "Igarapé do Meio", estado: 21},
	{id: 2105203, nome: "Igarapé Grande", estado: 21},
	{id: 2105302, nome: "Imperatriz", estado: 21},
	{id: 2105351, nome: "Itaipava do Grajaú", estado: 21},
	{id: 2105401, nome: "Itapecuru Mirim", estado: 21},
	{id: 2105427, nome: "Itinga do Maranhão", estado: 21},
	{id: 2105450, nome: "Jatobá", estado: 21},
	{id: 2105476, nome: "Jenipapo dos Vieiras", estado: 21},
	{id: 2105500, nome: "João Lisboa", estado: 21},
	{id: 2105609, nome: "Joselândia", estado: 21},
	{id: 2105658, nome: "Junco do Maranhão", estado: 21},
	{id: 2105708, nome: "Lago da Pedra", estado: 21},
	{id: 2105807, nome: "Lago do Junco", estado: 21},
	{id: 2105906, nome: "Lago Verde", estado: 21},
	{id: 2105922, nome: "Lagoa do Mato", estado: 21},
	{id: 2105948, nome: "Lago dos Rodrigues", estado: 21},
	{id: 2105963, nome: "Lagoa Grande do Maranhão", estado: 21},
	{id: 2105989, nome: "Lajeado Novo", estado: 21},
	{id: 2106003, nome: "Lima Campos", estado: 21},
	{id: 2106102, nome: "Loreto", estado: 21},
	{id: 2106201, nome: "Luís domingues", estado: 21},
	{id: 2106300, nome: "Magalhães de Almeida", estado: 21},
	{id: 2106326, nome: "Maracaçumé", estado: 21},
	{id: 2106359, nome: "Marajá do Sena", estado: 21},
	{id: 2106375, nome: "Maranhãozinho", estado: 21},
	{id: 2106409, nome: "Mata Roma", estado: 21},
	{id: 2106508, nome: "Matinha", estado: 21},
	{id: 2106607, nome: "Matões", estado: 21},
	{id: 2106631, nome: "Matões do Norte", estado: 21},
	{id: 2106672, nome: "Milagres do Maranhão", estado: 21},
	{id: 2106706, nome: "Mirador", estado: 21},
	{id: 2106755, nome: "Miranda do Norte", estado: 21},
	{id: 2106805, nome: "Mirinzal", estado: 21},
	{id: 2106904, nome: "Monção", estado: 21},
	{id: 2107001, nome: "Montes Altos", estado: 21},
	{id: 2107100, nome: "Morros", estado: 21},
	{id: 2107209, nome: "Nina Rodrigues", estado: 21},
	{id: 2107258, nome: "Nova Colinas", estado: 21},
	{id: 2107308, nome: "Nova Iorque", estado: 21},
	{id: 2107357, nome: "Nova Olinda do Maranhão", estado: 21},
	{id: 2107407, nome: "Olho D'água das Cunhãs", estado: 21},
	{id: 2107456, nome: "Olinda Nova do Maranhão", estado: 21},
	{id: 2107506, nome: "Paço do Lumiar", estado: 21},
	{id: 2107605, nome: "Palmeirândia", estado: 21},
	{id: 2107704, nome: "Paraibano", estado: 21},
	{id: 2107803, nome: "Parnarama", estado: 21},
	{id: 2107902, nome: "Passagem Franca", estado: 21},
	{id: 2108009, nome: "Pastos Bons", estado: 21},
	{id: 2108058, nome: "Paulino Neves", estado: 21},
	{id: 2108108, nome: "Paulo Ramos", estado: 21},
	{id: 2108207, nome: "Pedreiras", estado: 21},
	{id: 2108256, nome: "Pedro do Rosário", estado: 21},
	{id: 2108306, nome: "Penalva", estado: 21},
	{id: 2108405, nome: "Peri Mirim", estado: 21},
	{id: 2108454, nome: "Peritoró", estado: 21},
	{id: 2108504, nome: "Pindaré-Mirim", estado: 21},
	{id: 2108603, nome: "Pinheiro", estado: 21},
	{id: 2108702, nome: "Pio Xii", estado: 21},
	{id: 2108801, nome: "Pirapemas", estado: 21},
	{id: 2108900, nome: "Poção de Pedras", estado: 21},
	{id: 2109007, nome: "Porto Franco", estado: 21},
	{id: 2109056, nome: "Porto Rico do Maranhão", estado: 21},
	{id: 2109106, nome: "Presidente Dutra", estado: 21},
	{id: 2109205, nome: "Presidente Juscelino", estado: 21},
	{id: 2109239, nome: "Presidente Médici", estado: 21},
	{id: 2109270, nome: "Presidente Sarney", estado: 21},
	{id: 2109304, nome: "Presidente Vargas", estado: 21},
	{id: 2109403, nome: "Primeira Cruz", estado: 21},
	{id: 2109452, nome: "Raposa", estado: 21},
	{id: 2109502, nome: "Riachão", estado: 21},
	{id: 2109551, nome: "Ribamar Fiquene", estado: 21},
	{id: 2109601, nome: "Rosário", estado: 21},
	{id: 2109700, nome: "Sambaíba", estado: 21},
	{id: 2109759, nome: "Santa Filomena do Maranhão", estado: 21},
	{id: 2109809, nome: "Santa Helena", estado: 21},
	{id: 2109908, nome: "Santa Inês", estado: 21},
	{id: 2110005, nome: "Santa Luzia", estado: 21},
	{id: 2110039, nome: "Santa Luzia do Paruá", estado: 21},
	{id: 2110104, nome: "Santa Quitéria do Maranhão", estado: 21},
	{id: 2110203, nome: "Santa Rita", estado: 21},
	{id: 2110237, nome: "Santana do Maranhão", estado: 21},
	{id: 2110278, nome: "Santo Amaro do Maranhão", estado: 21},
	{id: 2110302, nome: "Santo Antônio dos Lopes", estado: 21},
	{id: 2110401, nome: "São Benedito do Rio Preto", estado: 21},
	{id: 2110500, nome: "São Bento", estado: 21},
	{id: 2110609, nome: "São Bernardo", estado: 21},
	{id: 2110658, nome: "São domingos do Azeitão", estado: 21},
	{id: 2110708, nome: "São domingos do Maranhão", estado: 21},
	{id: 2110807, nome: "São Félix de Balsas", estado: 21},
	{id: 2110856, nome: "São Francisco do Brejão", estado: 21},
	{id: 2110906, nome: "São Francisco do Maranhão", estado: 21},
	{id: 2111003, nome: "São João Batista", estado: 21},
	{id: 2111029, nome: "São João do Carú", estado: 21},
	{id: 2111052, nome: "São João do Paraíso", estado: 21},
	{id: 2111078, nome: "São João do Soter", estado: 21},
	{id: 2111102, nome: "São João dos Patos", estado: 21},
	{id: 2111201, nome: "São José de Ribamar", estado: 21},
	{id: 2111250, nome: "São José dos Basílios", estado: 21},
	{id: 2111300, nome: "São Luís", estado: 21},
	{id: 2111409, nome: "São Luís Gonzaga do Maranhão", estado: 21},
	{id: 2111508, nome: "São Mateus do Maranhão", estado: 21},
	{id: 2111532, nome: "São Pedro da Água Branca", estado: 21},
	{id: 2111573, nome: "São Pedro dos Crentes", estado: 21},
	{id: 2111607, nome: "São Raimundo das Mangabeiras", estado: 21},
	{id: 2111631, nome: "São Raimundo do doca Bezerra", estado: 21},
	{id: 2111672, nome: "São Roberto", estado: 21},
	{id: 2111706, nome: "São Vicente Ferrer", estado: 21},
	{id: 2111722, nome: "Satubinha", estado: 21},
	{id: 2111748, nome: "Senador Alexandre Costa", estado: 21},
	{id: 2111763, nome: "Senador La Rocque", estado: 21},
	{id: 2111789, nome: "Serrano do Maranhão", estado: 21},
	{id: 2111805, nome: "Sítio Novo", estado: 21},
	{id: 2111904, nome: "Sucupira do Norte", estado: 21},
	{id: 2111953, nome: "Sucupira do Riachão", estado: 21},
	{id: 2112001, nome: "Tasso Fragoso", estado: 21},
	{id: 2112100, nome: "Timbiras", estado: 21},
	{id: 2112209, nome: "Timon", estado: 21},
	{id: 2112233, nome: "Trizidela do Vale", estado: 21},
	{id: 2112274, nome: "Tufilândia", estado: 21},
	{id: 2112308, nome: "Tuntum", estado: 21},
	{id: 2112407, nome: "Turiaçu", estado: 21},
	{id: 2112456, nome: "Turilândia", estado: 21},
	{id: 2112506, nome: "Tutóia", estado: 21},
	{id: 2112605, nome: "Urbano Santos", estado: 21},
	{id: 2112704, nome: "Vargem Grande", estado: 21},
	{id: 2112803, nome: "Viana", estado: 21},
	{id: 2112852, nome: "Vila Nova dos Martírios", estado: 21},
	{id: 2112902, nome: "Vitória do Mearim", estado: 21},
	{id: 2113009, nome: "Vitorino Freire", estado: 21},
	{id: 2114007, nome: "Zé doca", estado: 21},
	{id: 2200053, nome: "Acauã", estado: 22},
	{id: 2200103, nome: "Agricolândia", estado: 22},
	{id: 2200202, nome: "Água Branca", estado: 22},
	{id: 2200251, nome: "Alagoinha do Piauí", estado: 22},
	{id: 2200277, nome: "Alegrete do Piauí", estado: 22},
	{id: 2200301, nome: "Alto Longá", estado: 22},
	{id: 2200400, nome: "Altos", estado: 22},
	{id: 2200459, nome: "Alvorada do Gurguéia", estado: 22},
	{id: 2200509, nome: "Amarante", estado: 22},
	{id: 2200608, nome: "Angical do Piauí", estado: 22},
	{id: 2200707, nome: "Anísio de Abreu", estado: 22},
	{id: 2200806, nome: "Antônio Almeida", estado: 22},
	{id: 2200905, nome: "Aroazes", estado: 22},
	{id: 2200954, nome: "Aroeiras do Itaim", estado: 22},
	{id: 2201002, nome: "Arraial", estado: 22},
	{id: 2201051, nome: "Assunção do Piauí", estado: 22},
	{id: 2201101, nome: "Avelino Lopes", estado: 22},
	{id: 2201150, nome: "Baixa Grande do Ribeiro", estado: 22},
	{id: 2201176, nome: "Barra D'alcântara", estado: 22},
	{id: 2201200, nome: "Barras", estado: 22},
	{id: 2201309, nome: "Barreiras do Piauí", estado: 22},
	{id: 2201408, nome: "Barro Duro", estado: 22},
	{id: 2201507, nome: "Batalha", estado: 22},
	{id: 2201556, nome: "Bela Vista do Piauí", estado: 22},
	{id: 2201572, nome: "Belém do Piauí", estado: 22},
	{id: 2201606, nome: "Beneditinos", estado: 22},
	{id: 2201705, nome: "Bertolínia", estado: 22},
	{id: 2201739, nome: "Betânia do Piauí", estado: 22},
	{id: 2201770, nome: "Boa Hora", estado: 22},
	{id: 2201804, nome: "Bocaina", estado: 22},
	{id: 2201903, nome: "Bom Jesus", estado: 22},
	{id: 2201919, nome: "Bom Princípio do Piauí", estado: 22},
	{id: 2201929, nome: "Bonfim do Piauí", estado: 22},
	{id: 2201945, nome: "Boqueirão do Piauí", estado: 22},
	{id: 2201960, nome: "Brasileira", estado: 22},
	{id: 2201988, nome: "Brejo do Piauí", estado: 22},
	{id: 2202000, nome: "Buriti dos Lopes", estado: 22},
	{id: 2202026, nome: "Buriti dos Montes", estado: 22},
	{id: 2202059, nome: "Cabeceiras do Piauí", estado: 22},
	{id: 2202075, nome: "Cajazeiras do Piauí", estado: 22},
	{id: 2202083, nome: "Cajueiro da Praia", estado: 22},
	{id: 2202091, nome: "Caldeirão Grande do Piauí", estado: 22},
	{id: 2202109, nome: "Campinas do Piauí", estado: 22},
	{id: 2202117, nome: "Campo Alegre do Fidalgo", estado: 22},
	{id: 2202133, nome: "Campo Grande do Piauí", estado: 22},
	{id: 2202174, nome: "Campo Largo do Piauí", estado: 22},
	{id: 2202208, nome: "Campo Maior", estado: 22},
	{id: 2202251, nome: "Canavieira", estado: 22},
	{id: 2202307, nome: "Canto do Buriti", estado: 22},
	{id: 2202406, nome: "Capitão de Campos", estado: 22},
	{id: 2202455, nome: "Capitão Gervásio Oliveira", estado: 22},
	{id: 2202505, nome: "Caracol", estado: 22},
	{id: 2202539, nome: "Caraúbas do Piauí", estado: 22},
	{id: 2202554, nome: "Caridade do Piauí", estado: 22},
	{id: 2202604, nome: "Castelo do Piauí", estado: 22},
	{id: 2202653, nome: "Caxingó", estado: 22},
	{id: 2202703, nome: "Cocal", estado: 22},
	{id: 2202711, nome: "Cocal de Telha", estado: 22},
	{id: 2202729, nome: "Cocal dos Alves", estado: 22},
	{id: 2202737, nome: "Coivaras", estado: 22},
	{id: 2202752, nome: "Colônia do Gurguéia", estado: 22},
	{id: 2202778, nome: "Colônia do Piauí", estado: 22},
	{id: 2202802, nome: "Conceição do Canindé", estado: 22},
	{id: 2202851, nome: "Coronel José Dias", estado: 22},
	{id: 2202901, nome: "Corrente", estado: 22},
	{id: 2203008, nome: "Cristalândia do Piauí", estado: 22},
	{id: 2203107, nome: "Cristino Castro", estado: 22},
	{id: 2203206, nome: "Curimatá", estado: 22},
	{id: 2203230, nome: "Currais", estado: 22},
	{id: 2203255, nome: "Curralinhos", estado: 22},
	{id: 2203271, nome: "Curral Novo do Piauí", estado: 22},
	{id: 2203305, nome: "demerval Lobão", estado: 22},
	{id: 2203354, nome: "Dirceu Arcoverde", estado: 22},
	{id: 2203404, nome: "dom Expedito Lopes", estado: 22},
	{id: 2203420, nome: "domingos Mourão", estado: 22},
	{id: 2203453, nome: "dom Inocêncio", estado: 22},
	{id: 2203503, nome: "Elesbão Veloso", estado: 22},
	{id: 2203602, nome: "Eliseu Martins", estado: 22},
	{id: 2203701, nome: "Esperantina", estado: 22},
	{id: 2203750, nome: "Fartura do Piauí", estado: 22},
	{id: 2203800, nome: "Flores do Piauí", estado: 22},
	{id: 2203859, nome: "Floresta do Piauí", estado: 22},
	{id: 2203909, nome: "Floriano", estado: 22},
	{id: 2204006, nome: "Francinópolis", estado: 22},
	{id: 2204105, nome: "Francisco Ayres", estado: 22},
	{id: 2204154, nome: "Francisco Macedo", estado: 22},
	{id: 2204204, nome: "Francisco Santos", estado: 22},
	{id: 2204303, nome: "Fronteiras", estado: 22},
	{id: 2204352, nome: "Geminiano", estado: 22},
	{id: 2204402, nome: "Gilbués", estado: 22},
	{id: 2204501, nome: "Guadalupe", estado: 22},
	{id: 2204550, nome: "Guaribas", estado: 22},
	{id: 2204600, nome: "Hugo Napoleão", estado: 22},
	{id: 2204659, nome: "Ilha Grande", estado: 22},
	{id: 2204709, nome: "Inhuma", estado: 22},
	{id: 2204808, nome: "Ipiranga do Piauí", estado: 22},
	{id: 2204907, nome: "Isaías Coelho", estado: 22},
	{id: 2205003, nome: "Itainópolis", estado: 22},
	{id: 2205102, nome: "Itaueira", estado: 22},
	{id: 2205151, nome: "Jacobina do Piauí", estado: 22},
	{id: 2205201, nome: "Jaicós", estado: 22},
	{id: 2205250, nome: "Jardim do Mulato", estado: 22},
	{id: 2205276, nome: "Jatobá do Piauí", estado: 22},
	{id: 2205300, nome: "Jerumenha", estado: 22},
	{id: 2205359, nome: "João Costa", estado: 22},
	{id: 2205409, nome: "Joaquim Pires", estado: 22},
	{id: 2205458, nome: "Joca Marques", estado: 22},
	{id: 2205508, nome: "José de Freitas", estado: 22},
	{id: 2205516, nome: "Juazeiro do Piauí", estado: 22},
	{id: 2205524, nome: "Júlio Borges", estado: 22},
	{id: 2205532, nome: "Jurema", estado: 22},
	{id: 2205540, nome: "Lagoinha do Piauí", estado: 22},
	{id: 2205557, nome: "Lagoa Alegre", estado: 22},
	{id: 2205565, nome: "Lagoa do Barro do Piauí", estado: 22},
	{id: 2205573, nome: "Lagoa de São Francisco", estado: 22},
	{id: 2205581, nome: "Lagoa do Piauí", estado: 22},
	{id: 2205599, nome: "Lagoa do Sítio", estado: 22},
	{id: 2205607, nome: "Landri Sales", estado: 22},
	{id: 2205706, nome: "Luís Correia", estado: 22},
	{id: 2205805, nome: "Luzilândia", estado: 22},
	{id: 2205854, nome: "Madeiro", estado: 22},
	{id: 2205904, nome: "Manoel Emídio", estado: 22},
	{id: 2205953, nome: "Marcolândia", estado: 22},
	{id: 2206001, nome: "Marcos Parente", estado: 22},
	{id: 2206050, nome: "Massapê do Piauí", estado: 22},
	{id: 2206100, nome: "Matias Olímpio", estado: 22},
	{id: 2206209, nome: "Miguel Alves", estado: 22},
	{id: 2206308, nome: "Miguel Leão", estado: 22},
	{id: 2206357, nome: "Milton Brandão", estado: 22},
	{id: 2206407, nome: "Monsenhor Gil", estado: 22},
	{id: 2206506, nome: "Monsenhor Hipólito", estado: 22},
	{id: 2206605, nome: "Monte Alegre do Piauí", estado: 22},
	{id: 2206654, nome: "Morro Cabeça No Tempo", estado: 22},
	{id: 2206670, nome: "Morro do Chapéu do Piauí", estado: 22},
	{id: 2206696, nome: "Murici dos Portelas", estado: 22},
	{id: 2206704, nome: "Nazaré do Piauí", estado: 22},
	{id: 2206720, nome: "Nazária", estado: 22},
	{id: 2206753, nome: "Nossa Senhora de Nazaré", estado: 22},
	{id: 2206803, nome: "Nossa Senhora dos Remédios", estado: 22},
	{id: 2206902, nome: "Novo Oriente do Piauí", estado: 22},
	{id: 2206951, nome: "Novo Santo Antônio", estado: 22},
	{id: 2207009, nome: "Oeiras", estado: 22},
	{id: 2207108, nome: "Olho D'água do Piauí", estado: 22},
	{id: 2207207, nome: "Padre Marcos", estado: 22},
	{id: 2207306, nome: "Paes Landim", estado: 22},
	{id: 2207355, nome: "Pajeú do Piauí", estado: 22},
	{id: 2207405, nome: "Palmeira do Piauí", estado: 22},
	{id: 2207504, nome: "Palmeirais", estado: 22},
	{id: 2207553, nome: "Paquetá", estado: 22},
	{id: 2207603, nome: "Parnaguá", estado: 22},
	{id: 2207702, nome: "Parnaíba", estado: 22},
	{id: 2207751, nome: "Passagem Franca do Piauí", estado: 22},
	{id: 2207777, nome: "Patos do Piauí", estado: 22},
	{id: 2207793, nome: "Pau D'arco do Piauí", estado: 22},
	{id: 2207801, nome: "Paulistana", estado: 22},
	{id: 2207850, nome: "Pavussu", estado: 22},
	{id: 2207900, nome: "Pedro Ii", estado: 22},
	{id: 2207934, nome: "Pedro Laurentino", estado: 22},
	{id: 2207959, nome: "Nova Santa Rita", estado: 22},
	{id: 2208007, nome: "Picos", estado: 22},
	{id: 2208106, nome: "Pimenteiras", estado: 22},
	{id: 2208205, nome: "Pio Ix", estado: 22},
	{id: 2208304, nome: "Piracuruca", estado: 22},
	{id: 2208403, nome: "Piripiri", estado: 22},
	{id: 2208502, nome: "Porto", estado: 22},
	{id: 2208551, nome: "Porto Alegre do Piauí", estado: 22},
	{id: 2208601, nome: "Prata do Piauí", estado: 22},
	{id: 2208650, nome: "Queimada Nova", estado: 22},
	{id: 2208700, nome: "Redenção do Gurguéia", estado: 22},
	{id: 2208809, nome: "Regeneração", estado: 22},
	{id: 2208858, nome: "Riacho Frio", estado: 22},
	{id: 2208874, nome: "Ribeira do Piauí", estado: 22},
	{id: 2208908, nome: "Ribeiro Gonçalves", estado: 22},
	{id: 2209005, nome: "Rio Grande do Piauí", estado: 22},
	{id: 2209104, nome: "Santa Cruz do Piauí", estado: 22},
	{id: 2209153, nome: "Santa Cruz dos Milagres", estado: 22},
	{id: 2209203, nome: "Santa Filomena", estado: 22},
	{id: 2209302, nome: "Santa Luz", estado: 22},
	{id: 2209351, nome: "Santana do Piauí", estado: 22},
	{id: 2209377, nome: "Santa Rosa do Piauí", estado: 22},
	{id: 2209401, nome: "Santo Antônio de Lisboa", estado: 22},
	{id: 2209450, nome: "Santo Antônio dos Milagres", estado: 22},
	{id: 2209500, nome: "Santo Inácio do Piauí", estado: 22},
	{id: 2209559, nome: "São Braz do Piauí", estado: 22},
	{id: 2209609, nome: "São Félix do Piauí", estado: 22},
	{id: 2209658, nome: "São Francisco de Assis do Piauí", estado: 22},
	{id: 2209708, nome: "São Francisco do Piauí", estado: 22},
	{id: 2209757, nome: "São Gonçalo do Gurguéia", estado: 22},
	{id: 2209807, nome: "São Gonçalo do Piauí", estado: 22},
	{id: 2209856, nome: "São João da Canabrava", estado: 22},
	{id: 2209872, nome: "São João da Fronteira", estado: 22},
	{id: 2209906, nome: "São João da Serra", estado: 22},
	{id: 2209955, nome: "São João da Varjota", estado: 22},
	{id: 2209971, nome: "São João do Arraial", estado: 22},
	{id: 2210003, nome: "São João do Piauí", estado: 22},
	{id: 2210052, nome: "São José do Divino", estado: 22},
	{id: 2210102, nome: "São José do Peixe", estado: 22},
	{id: 2210201, nome: "São José do Piauí", estado: 22},
	{id: 2210300, nome: "São Julião", estado: 22},
	{id: 2210359, nome: "São Lourenço do Piauí", estado: 22},
	{id: 2210375, nome: "São Luis do Piauí", estado: 22},
	{id: 2210383, nome: "São Miguel da Baixa Grande", estado: 22},
	{id: 2210391, nome: "São Miguel do Fidalgo", estado: 22},
	{id: 2210409, nome: "São Miguel do Tapuio", estado: 22},
	{id: 2210508, nome: "São Pedro do Piauí", estado: 22},
	{id: 2210607, nome: "São Raimundo Nonato", estado: 22},
	{id: 2210623, nome: "Sebastião Barros", estado: 22},
	{id: 2210631, nome: "Sebastião Leal", estado: 22},
	{id: 2210656, nome: "Sigefredo Pacheco", estado: 22},
	{id: 2210706, nome: "Simões", estado: 22},
	{id: 2210805, nome: "Simplício Mendes", estado: 22},
	{id: 2210904, nome: "Socorro do Piauí", estado: 22},
	{id: 2210938, nome: "Sussuapara", estado: 22},
	{id: 2210953, nome: "Tamboril do Piauí", estado: 22},
	{id: 2210979, nome: "Tanque do Piauí", estado: 22},
	{id: 2211001, nome: "Teresina", estado: 22},
	{id: 2211100, nome: "União", estado: 22},
	{id: 2211209, nome: "Uruçuí", estado: 22},
	{id: 2211308, nome: "Valença do Piauí", estado: 22},
	{id: 2211357, nome: "Várzea Branca", estado: 22},
	{id: 2211407, nome: "Várzea Grande", estado: 22},
	{id: 2211506, nome: "Vera Mendes", estado: 22},
	{id: 2211605, nome: "Vila Nova do Piauí", estado: 22},
	{id: 2211704, nome: "Wall Ferraz", estado: 22},
	{id: 2300101, nome: "Abaiara", estado: 23},
	{id: 2300150, nome: "Acarape", estado: 23},
	{id: 2300200, nome: "Acaraú", estado: 23},
	{id: 2300309, nome: "Acopiara", estado: 23},
	{id: 2300408, nome: "Aiuaba", estado: 23},
	{id: 2300507, nome: "Alcântaras", estado: 23},
	{id: 2300606, nome: "Altaneira", estado: 23},
	{id: 2300705, nome: "Alto Santo", estado: 23},
	{id: 2300754, nome: "Amontada", estado: 23},
	{id: 2300804, nome: "Antonina do Norte", estado: 23},
	{id: 2300903, nome: "Apuiarés", estado: 23},
	{id: 2301000, nome: "Aquiraz", estado: 23},
	{id: 2301109, nome: "Aracati", estado: 23},
	{id: 2301208, nome: "Aracoiaba", estado: 23},
	{id: 2301257, nome: "Ararendá", estado: 23},
	{id: 2301307, nome: "Araripe", estado: 23},
	{id: 2301406, nome: "Aratuba", estado: 23},
	{id: 2301505, nome: "Arneiroz", estado: 23},
	{id: 2301604, nome: "Assaré", estado: 23},
	{id: 2301703, nome: "Aurora", estado: 23},
	{id: 2301802, nome: "Baixio", estado: 23},
	{id: 2301851, nome: "Banabuiú", estado: 23},
	{id: 2301901, nome: "Barbalha", estado: 23},
	{id: 2301950, nome: "Barreira", estado: 23},
	{id: 2302008, nome: "Barro", estado: 23},
	{id: 2302057, nome: "Barroquinha", estado: 23},
	{id: 2302107, nome: "Baturité", estado: 23},
	{id: 2302206, nome: "Beberibe", estado: 23},
	{id: 2302305, nome: "Bela Cruz", estado: 23},
	{id: 2302404, nome: "Boa Viagem", estado: 23},
	{id: 2302503, nome: "Brejo Santo", estado: 23},
	{id: 2302602, nome: "Camocim", estado: 23},
	{id: 2302701, nome: "Campos Sales", estado: 23},
	{id: 2302800, nome: "Canindé", estado: 23},
	{id: 2302909, nome: "Capistrano", estado: 23},
	{id: 2303006, nome: "Caridade", estado: 23},
	{id: 2303105, nome: "Cariré", estado: 23},
	{id: 2303204, nome: "Caririaçu", estado: 23},
	{id: 2303303, nome: "Cariús", estado: 23},
	{id: 2303402, nome: "Carnaubal", estado: 23},
	{id: 2303501, nome: "Cascavel", estado: 23},
	{id: 2303600, nome: "Catarina", estado: 23},
	{id: 2303659, nome: "Catunda", estado: 23},
	{id: 2303709, nome: "Caucaia", estado: 23},
	{id: 2303808, nome: "Cedro", estado: 23},
	{id: 2303907, nome: "Chaval", estado: 23},
	{id: 2303931, nome: "Choró", estado: 23},
	{id: 2303956, nome: "Chorozinho", estado: 23},
	{id: 2304004, nome: "Coreaú", estado: 23},
	{id: 2304103, nome: "Crateús", estado: 23},
	{id: 2304202, nome: "Crato", estado: 23},
	{id: 2304236, nome: "Croatá", estado: 23},
	{id: 2304251, nome: "Cruz", estado: 23},
	{id: 2304269, nome: "deputado Irapuan Pinheiro", estado: 23},
	{id: 2304277, nome: "Ererê", estado: 23},
	{id: 2304285, nome: "Eusébio", estado: 23},
	{id: 2304301, nome: "Farias Brito", estado: 23},
	{id: 2304350, nome: "Forquilha", estado: 23},
	{id: 2304400, nome: "Fortaleza", estado: 23},
	{id: 2304459, nome: "Fortim", estado: 23},
	{id: 2304509, nome: "Frecheirinha", estado: 23},
	{id: 2304608, nome: "General Sampaio", estado: 23},
	{id: 2304657, nome: "Graça", estado: 23},
	{id: 2304707, nome: "Granja", estado: 23},
	{id: 2304806, nome: "Granjeiro", estado: 23},
	{id: 2304905, nome: "Groaíras", estado: 23},
	{id: 2304954, nome: "Guaiúba", estado: 23},
	{id: 2305001, nome: "Guaraciaba do Norte", estado: 23},
	{id: 2305100, nome: "Guaramiranga", estado: 23},
	{id: 2305209, nome: "Hidrolândia", estado: 23},
	{id: 2305233, nome: "Horizonte", estado: 23},
	{id: 2305266, nome: "Ibaretama", estado: 23},
	{id: 2305308, nome: "Ibiapina", estado: 23},
	{id: 2305332, nome: "Ibicuitinga", estado: 23},
	{id: 2305357, nome: "Icapuí", estado: 23},
	{id: 2305407, nome: "Icó", estado: 23},
	{id: 2305506, nome: "Iguatu", estado: 23},
	{id: 2305605, nome: "Independência", estado: 23},
	{id: 2305654, nome: "Ipaporanga", estado: 23},
	{id: 2305704, nome: "Ipaumirim", estado: 23},
	{id: 2305803, nome: "Ipu", estado: 23},
	{id: 2305902, nome: "Ipueiras", estado: 23},
	{id: 2306009, nome: "Iracema", estado: 23},
	{id: 2306108, nome: "Irauçuba", estado: 23},
	{id: 2306207, nome: "Itaiçaba", estado: 23},
	{id: 2306256, nome: "Itaitinga", estado: 23},
	{id: 2306306, nome: "Itapajé", estado: 23},
	{id: 2306405, nome: "Itapipoca", estado: 23},
	{id: 2306504, nome: "Itapiúna", estado: 23},
	{id: 2306553, nome: "Itarema", estado: 23},
	{id: 2306603, nome: "Itatira", estado: 23},
	{id: 2306702, nome: "Jaguaretama", estado: 23},
	{id: 2306801, nome: "Jaguaribara", estado: 23},
	{id: 2306900, nome: "Jaguaribe", estado: 23},
	{id: 2307007, nome: "Jaguaruana", estado: 23},
	{id: 2307106, nome: "Jardim", estado: 23},
	{id: 2307205, nome: "Jati", estado: 23},
	{id: 2307254, nome: "Jijoca de Jericoacoara", estado: 23},
	{id: 2307304, nome: "Juazeiro do Norte", estado: 23},
	{id: 2307403, nome: "Jucás", estado: 23},
	{id: 2307502, nome: "Lavras da Mangabeira", estado: 23},
	{id: 2307601, nome: "Limoeiro do Norte", estado: 23},
	{id: 2307635, nome: "Madalena", estado: 23},
	{id: 2307650, nome: "Maracanaú", estado: 23},
	{id: 2307700, nome: "Maranguape", estado: 23},
	{id: 2307809, nome: "Marco", estado: 23},
	{id: 2307908, nome: "Martinópole", estado: 23},
	{id: 2308005, nome: "Massapê", estado: 23},
	{id: 2308104, nome: "Mauriti", estado: 23},
	{id: 2308203, nome: "Meruoca", estado: 23},
	{id: 2308302, nome: "Milagres", estado: 23},
	{id: 2308351, nome: "Milhã", estado: 23},
	{id: 2308377, nome: "Miraíma", estado: 23},
	{id: 2308401, nome: "Missão Velha", estado: 23},
	{id: 2308500, nome: "Mombaça", estado: 23},
	{id: 2308609, nome: "Monsenhor Tabosa", estado: 23},
	{id: 2308708, nome: "Morada Nova", estado: 23},
	{id: 2308807, nome: "Moraújo", estado: 23},
	{id: 2308906, nome: "Morrinhos", estado: 23},
	{id: 2309003, nome: "Mucambo", estado: 23},
	{id: 2309102, nome: "Mulungu", estado: 23},
	{id: 2309201, nome: "Nova Olinda", estado: 23},
	{id: 2309300, nome: "Nova Russas", estado: 23},
	{id: 2309409, nome: "Novo Oriente", estado: 23},
	{id: 2309458, nome: "Ocara", estado: 23},
	{id: 2309508, nome: "Orós", estado: 23},
	{id: 2309607, nome: "Pacajus", estado: 23},
	{id: 2309706, nome: "Pacatuba", estado: 23},
	{id: 2309805, nome: "Pacoti", estado: 23},
	{id: 2309904, nome: "Pacujá", estado: 23},
	{id: 2310001, nome: "Palhano", estado: 23},
	{id: 2310100, nome: "Palmácia", estado: 23},
	{id: 2310209, nome: "Paracuru", estado: 23},
	{id: 2310258, nome: "Paraipaba", estado: 23},
	{id: 2310308, nome: "Parambu", estado: 23},
	{id: 2310407, nome: "Paramoti", estado: 23},
	{id: 2310506, nome: "Pedra Branca", estado: 23},
	{id: 2310605, nome: "Penaforte", estado: 23},
	{id: 2310704, nome: "Pentecoste", estado: 23},
	{id: 2310803, nome: "Pereiro", estado: 23},
	{id: 2310852, nome: "Pindoretama", estado: 23},
	{id: 2310902, nome: "Piquet Carneiro", estado: 23},
	{id: 2310951, nome: "Pires Ferreira", estado: 23},
	{id: 2311009, nome: "Poranga", estado: 23},
	{id: 2311108, nome: "Porteiras", estado: 23},
	{id: 2311207, nome: "Potengi", estado: 23},
	{id: 2311231, nome: "Potiretama", estado: 23},
	{id: 2311264, nome: "Quiterianópolis", estado: 23},
	{id: 2311306, nome: "Quixadá", estado: 23},
	{id: 2311355, nome: "Quixelô", estado: 23},
	{id: 2311405, nome: "Quixeramobim", estado: 23},
	{id: 2311504, nome: "Quixeré", estado: 23},
	{id: 2311603, nome: "Redenção", estado: 23},
	{id: 2311702, nome: "Reriutaba", estado: 23},
	{id: 2311801, nome: "Russas", estado: 23},
	{id: 2311900, nome: "Saboeiro", estado: 23},
	{id: 2311959, nome: "Salitre", estado: 23},
	{id: 2312007, nome: "Santana do Acaraú", estado: 23},
	{id: 2312106, nome: "Santana do Cariri", estado: 23},
	{id: 2312205, nome: "Santa Quitéria", estado: 23},
	{id: 2312304, nome: "São Benedito", estado: 23},
	{id: 2312403, nome: "São Gonçalo do Amarante", estado: 23},
	{id: 2312502, nome: "São João do Jaguaribe", estado: 23},
	{id: 2312601, nome: "São Luís do Curu", estado: 23},
	{id: 2312700, nome: "Senador Pompeu", estado: 23},
	{id: 2312809, nome: "Senador Sá", estado: 23},
	{id: 2312908, nome: "Sobral", estado: 23},
	{id: 2313005, nome: "Solonópole", estado: 23},
	{id: 2313104, nome: "Tabuleiro do Norte", estado: 23},
	{id: 2313203, nome: "Tamboril", estado: 23},
	{id: 2313252, nome: "Tarrafas", estado: 23},
	{id: 2313302, nome: "Tauá", estado: 23},
	{id: 2313351, nome: "Tejuçuoca", estado: 23},
	{id: 2313401, nome: "Tianguá", estado: 23},
	{id: 2313500, nome: "Trairi", estado: 23},
	{id: 2313559, nome: "Tururu", estado: 23},
	{id: 2313609, nome: "Ubajara", estado: 23},
	{id: 2313708, nome: "Umari", estado: 23},
	{id: 2313757, nome: "Umirim", estado: 23},
	{id: 2313807, nome: "Uruburetama", estado: 23},
	{id: 2313906, nome: "Uruoca", estado: 23},
	{id: 2313955, nome: "Varjota", estado: 23},
	{id: 2314003, nome: "Várzea Alegre", estado: 23},
	{id: 2314102, nome: "Viçosa do Ceará", estado: 23},
	{id: 2400109, nome: "Acari", estado: 24},
	{id: 2400208, nome: "Açu", estado: 24},
	{id: 2400307, nome: "Afonso Bezerra", estado: 24},
	{id: 2400406, nome: "Água Nova", estado: 24},
	{id: 2400505, nome: "Alexandria", estado: 24},
	{id: 2400604, nome: "Almino Afonso", estado: 24},
	{id: 2400703, nome: "Alto do Rodrigues", estado: 24},
	{id: 2400802, nome: "Angicos", estado: 24},
	{id: 2400901, nome: "Antônio Martins", estado: 24},
	{id: 2401008, nome: "Apodi", estado: 24},
	{id: 2401107, nome: "Areia Branca", estado: 24},
	{id: 2401206, nome: "Arês", estado: 24},
	{id: 2401305, nome: "Augusto Severo", estado: 24},
	{id: 2401404, nome: "Baía Formosa", estado: 24},
	{id: 2401453, nome: "Baraúna", estado: 24},
	{id: 2401503, nome: "Barcelona", estado: 24},
	{id: 2401602, nome: "Bento Fernandes", estado: 24},
	{id: 2401651, nome: "Bodó", estado: 24},
	{id: 2401701, nome: "Bom Jesus", estado: 24},
	{id: 2401800, nome: "Brejinho", estado: 24},
	{id: 2401859, nome: "Caiçara do Norte", estado: 24},
	{id: 2401909, nome: "Caiçara do Rio do Vento", estado: 24},
	{id: 2402006, nome: "Caicó", estado: 24},
	{id: 2402105, nome: "Campo Redondo", estado: 24},
	{id: 2402204, nome: "Canguaretama", estado: 24},
	{id: 2402303, nome: "Caraúbas", estado: 24},
	{id: 2402402, nome: "Carnaúba dos dantas", estado: 24},
	{id: 2402501, nome: "Carnaubais", estado: 24},
	{id: 2402600, nome: "Ceará-Mirim", estado: 24},
	{id: 2402709, nome: "Cerro Corá", estado: 24},
	{id: 2402808, nome: "Coronel Ezequiel", estado: 24},
	{id: 2402907, nome: "Coronel João Pessoa", estado: 24},
	{id: 2403004, nome: "Cruzeta", estado: 24},
	{id: 2403103, nome: "Currais Novos", estado: 24},
	{id: 2403202, nome: "doutor Severiano", estado: 24},
	{id: 2403251, nome: "Parnamirim", estado: 24},
	{id: 2403301, nome: "Encanto", estado: 24},
	{id: 2403400, nome: "Equador", estado: 24},
	{id: 2403509, nome: "Espírito Santo", estado: 24},
	{id: 2403608, nome: "Extremoz", estado: 24},
	{id: 2403707, nome: "Felipe Guerra", estado: 24},
	{id: 2403756, nome: "Fernando Pedroza", estado: 24},
	{id: 2403806, nome: "Florânia", estado: 24},
	{id: 2403905, nome: "Francisco dantas", estado: 24},
	{id: 2404002, nome: "Frutuoso Gomes", estado: 24},
	{id: 2404101, nome: "Galinhos", estado: 24},
	{id: 2404200, nome: "Goianinha", estado: 24},
	{id: 2404309, nome: "Governador Dix-Sept Rosado", estado: 24},
	{id: 2404408, nome: "Grossos", estado: 24},
	{id: 2404507, nome: "Guamaré", estado: 24},
	{id: 2404606, nome: "Ielmo Marinho", estado: 24},
	{id: 2404705, nome: "Ipanguaçu", estado: 24},
	{id: 2404804, nome: "Ipueira", estado: 24},
	{id: 2404853, nome: "Itajá", estado: 24},
	{id: 2404903, nome: "Itaú", estado: 24},
	{id: 2405009, nome: "Jaçanã", estado: 24},
	{id: 2405108, nome: "Jandaíra", estado: 24},
	{id: 2405207, nome: "Janduís", estado: 24},
	{id: 2405306, nome: "Januário Cicco", estado: 24},
	{id: 2405405, nome: "Japi", estado: 24},
	{id: 2405504, nome: "Jardim de Angicos", estado: 24},
	{id: 2405603, nome: "Jardim de Piranhas", estado: 24},
	{id: 2405702, nome: "Jardim do Seridó", estado: 24},
	{id: 2405801, nome: "João Câmara", estado: 24},
	{id: 2405900, nome: "João Dias", estado: 24},
	{id: 2406007, nome: "José da Penha", estado: 24},
	{id: 2406106, nome: "Jucurutu", estado: 24},
	{id: 2406155, nome: "Jundiá", estado: 24},
	{id: 2406205, nome: "Lagoa D'anta", estado: 24},
	{id: 2406304, nome: "Lagoa de Pedras", estado: 24},
	{id: 2406403, nome: "Lagoa de Velhos", estado: 24},
	{id: 2406502, nome: "Lagoa Nova", estado: 24},
	{id: 2406601, nome: "Lagoa Salgada", estado: 24},
	{id: 2406700, nome: "Lajes", estado: 24},
	{id: 2406809, nome: "Lajes Pintadas", estado: 24},
	{id: 2406908, nome: "Lucrécia", estado: 24},
	{id: 2407005, nome: "Luís Gomes", estado: 24},
	{id: 2407104, nome: "Macaíba", estado: 24},
	{id: 2407203, nome: "Macau", estado: 24},
	{id: 2407252, nome: "Major Sales", estado: 24},
	{id: 2407302, nome: "Marcelino Vieira", estado: 24},
	{id: 2407401, nome: "Martins", estado: 24},
	{id: 2407500, nome: "Maxaranguape", estado: 24},
	{id: 2407609, nome: "Messias Targino", estado: 24},
	{id: 2407708, nome: "Montanhas", estado: 24},
	{id: 2407807, nome: "Monte Alegre", estado: 24},
	{id: 2407906, nome: "Monte das Gameleiras", estado: 24},
	{id: 2408003, nome: "Mossoró", estado: 24},
	{id: 2408102, nome: "Natal", estado: 24},
	{id: 2408201, nome: "Nísia Floresta", estado: 24},
	{id: 2408300, nome: "Nova Cruz", estado: 24},
	{id: 2408409, nome: "Olho-D'água do Borges", estado: 24},
	{id: 2408508, nome: "Ouro Branco", estado: 24},
	{id: 2408607, nome: "Paraná", estado: 24},
	{id: 2408706, nome: "Paraú", estado: 24},
	{id: 2408805, nome: "Parazinho", estado: 24},
	{id: 2408904, nome: "Parelhas", estado: 24},
	{id: 2408953, nome: "Rio do Fogo", estado: 24},
	{id: 2409100, nome: "Passa E Fica", estado: 24},
	{id: 2409209, nome: "Passagem", estado: 24},
	{id: 2409308, nome: "Patu", estado: 24},
	{id: 2409332, nome: "Santa Maria", estado: 24},
	{id: 2409407, nome: "Pau dos Ferros", estado: 24},
	{id: 2409506, nome: "Pedra Grande", estado: 24},
	{id: 2409605, nome: "Pedra Preta", estado: 24},
	{id: 2409704, nome: "Pedro Avelino", estado: 24},
	{id: 2409803, nome: "Pedro Velho", estado: 24},
	{id: 2409902, nome: "Pendências", estado: 24},
	{id: 2410009, nome: "Pilões", estado: 24},
	{id: 2410108, nome: "Poço Branco", estado: 24},
	{id: 2410207, nome: "Portalegre", estado: 24},
	{id: 2410256, nome: "Porto do Mangue", estado: 24},
	{id: 2410306, nome: "Serra Caiada", estado: 24},
	{id: 2410405, nome: "Pureza", estado: 24},
	{id: 2410504, nome: "Rafael Fernandes", estado: 24},
	{id: 2410603, nome: "Rafael Godeiro", estado: 24},
	{id: 2410702, nome: "Riacho da Cruz", estado: 24},
	{id: 2410801, nome: "Riacho de Santana", estado: 24},
	{id: 2410900, nome: "Riachuelo", estado: 24},
	{id: 2411007, nome: "Rodolfo Fernandes", estado: 24},
	{id: 2411056, nome: "Tibau", estado: 24},
	{id: 2411106, nome: "Ruy Barbosa", estado: 24},
	{id: 2411205, nome: "Santa Cruz", estado: 24},
	{id: 2411403, nome: "Santana do Matos", estado: 24},
	{id: 2411429, nome: "Santana do Seridó", estado: 24},
	{id: 2411502, nome: "Santo Antônio", estado: 24},
	{id: 2411601, nome: "São Bento do Norte", estado: 24},
	{id: 2411700, nome: "São Bento do Trairí", estado: 24},
	{id: 2411809, nome: "São Fernando", estado: 24},
	{id: 2411908, nome: "São Francisco do Oeste", estado: 24},
	{id: 2412005, nome: "São Gonçalo do Amarante", estado: 24},
	{id: 2412104, nome: "São João do Sabugi", estado: 24},
	{id: 2412203, nome: "São José de Mipibu", estado: 24},
	{id: 2412302, nome: "São José do Campestre", estado: 24},
	{id: 2412401, nome: "São José do Seridó", estado: 24},
	{id: 2412500, nome: "São Miguel", estado: 24},
	{id: 2412559, nome: "São Miguel do Gostoso", estado: 24},
	{id: 2412609, nome: "São Paulo do Potengi", estado: 24},
	{id: 2412708, nome: "São Pedro", estado: 24},
	{id: 2412807, nome: "São Rafael", estado: 24},
	{id: 2412906, nome: "São Tomé", estado: 24},
	{id: 2413003, nome: "São Vicente", estado: 24},
	{id: 2413102, nome: "Senador Elói de Souza", estado: 24},
	{id: 2413201, nome: "Senador Georgino Avelino", estado: 24},
	{id: 2413300, nome: "Serra de São Bento", estado: 24},
	{id: 2413359, nome: "Serra do Mel", estado: 24},
	{id: 2413409, nome: "Serra Negra do Norte", estado: 24},
	{id: 2413508, nome: "Serrinha", estado: 24},
	{id: 2413557, nome: "Serrinha dos Pintos", estado: 24},
	{id: 2413607, nome: "Severiano Melo", estado: 24},
	{id: 2413706, nome: "Sítio Novo", estado: 24},
	{id: 2413805, nome: "Taboleiro Grande", estado: 24},
	{id: 2413904, nome: "Taipu", estado: 24},
	{id: 2414001, nome: "Tangará", estado: 24},
	{id: 2414100, nome: "Tenente Ananias", estado: 24},
	{id: 2414159, nome: "Tenente Laurentino Cruz", estado: 24},
	{id: 2414209, nome: "Tibau do Sul", estado: 24},
	{id: 2414308, nome: "Timbaúba dos Batistas", estado: 24},
	{id: 2414407, nome: "Touros", estado: 24},
	{id: 2414456, nome: "Triunfo Potiguar", estado: 24},
	{id: 2414506, nome: "Umarizal", estado: 24},
	{id: 2414605, nome: "Upanema", estado: 24},
	{id: 2414704, nome: "Várzea", estado: 24},
	{id: 2414753, nome: "Venha-Ver", estado: 24},
	{id: 2414803, nome: "Vera Cruz", estado: 24},
	{id: 2414902, nome: "Viçosa", estado: 24},
	{id: 2415008, nome: "Vila Flor", estado: 24},
	{id: 2500106, nome: "Água Branca", estado: 25},
	{id: 2500205, nome: "Aguiar", estado: 25},
	{id: 2500304, nome: "Alagoa Grande", estado: 25},
	{id: 2500403, nome: "Alagoa Nova", estado: 25},
	{id: 2500502, nome: "Alagoinha", estado: 25},
	{id: 2500536, nome: "Alcantil", estado: 25},
	{id: 2500577, nome: "Algodão de Jandaíra", estado: 25},
	{id: 2500601, nome: "Alhandra", estado: 25},
	{id: 2500700, nome: "São João do Rio do Peixe", estado: 25},
	{id: 2500734, nome: "Amparo", estado: 25},
	{id: 2500775, nome: "Aparecida", estado: 25},
	{id: 2500809, nome: "Araçagi", estado: 25},
	{id: 2500908, nome: "Arara", estado: 25},
	{id: 2501005, nome: "Araruna", estado: 25},
	{id: 2501104, nome: "Areia", estado: 25},
	{id: 2501153, nome: "Areia de Baraúnas", estado: 25},
	{id: 2501203, nome: "Areial", estado: 25},
	{id: 2501302, nome: "Aroeiras", estado: 25},
	{id: 2501351, nome: "Assunção", estado: 25},
	{id: 2501401, nome: "Baía da Traição", estado: 25},
	{id: 2501500, nome: "Bananeiras", estado: 25},
	{id: 2501534, nome: "Baraúna", estado: 25},
	{id: 2501575, nome: "Barra de Santana", estado: 25},
	{id: 2501609, nome: "Barra de Santa Rosa", estado: 25},
	{id: 2501708, nome: "Barra de São Miguel", estado: 25},
	{id: 2501807, nome: "Bayeux", estado: 25},
	{id: 2501906, nome: "Belém", estado: 25},
	{id: 2502003, nome: "Belém do Brejo do Cruz", estado: 25},
	{id: 2502052, nome: "Bernardino Batista", estado: 25},
	{id: 2502102, nome: "Boa Ventura", estado: 25},
	{id: 2502151, nome: "Boa Vista", estado: 25},
	{id: 2502201, nome: "Bom Jesus", estado: 25},
	{id: 2502300, nome: "Bom Sucesso", estado: 25},
	{id: 2502409, nome: "Bonito de Santa Fé", estado: 25},
	{id: 2502508, nome: "Boqueirão", estado: 25},
	{id: 2502607, nome: "Igaracy", estado: 25},
	{id: 2502706, nome: "Borborema", estado: 25},
	{id: 2502805, nome: "Brejo do Cruz", estado: 25},
	{id: 2502904, nome: "Brejo dos Santos", estado: 25},
	{id: 2503001, nome: "Caaporã", estado: 25},
	{id: 2503100, nome: "Cabaceiras", estado: 25},
	{id: 2503209, nome: "Cabedelo", estado: 25},
	{id: 2503308, nome: "Cachoeira dos Índios", estado: 25},
	{id: 2503407, nome: "Cacimba de Areia", estado: 25},
	{id: 2503506, nome: "Cacimba de dentro", estado: 25},
	{id: 2503555, nome: "Cacimbas", estado: 25},
	{id: 2503605, nome: "Caiçara", estado: 25},
	{id: 2503704, nome: "Cajazeiras", estado: 25},
	{id: 2503753, nome: "Cajazeirinhas", estado: 25},
	{id: 2503803, nome: "Caldas Brandão", estado: 25},
	{id: 2503902, nome: "Camalaú", estado: 25},
	{id: 2504009, nome: "Campina Grande", estado: 25},
	{id: 2504033, nome: "Capim", estado: 25},
	{id: 2504074, nome: "Caraúbas", estado: 25},
	{id: 2504108, nome: "Carrapateira", estado: 25},
	{id: 2504157, nome: "Casserengue", estado: 25},
	{id: 2504207, nome: "Catingueira", estado: 25},
	{id: 2504306, nome: "Catolé do Rocha", estado: 25},
	{id: 2504355, nome: "Caturité", estado: 25},
	{id: 2504405, nome: "Conceição", estado: 25},
	{id: 2504504, nome: "Condado", estado: 25},
	{id: 2504603, nome: "Conde", estado: 25},
	{id: 2504702, nome: "Congo", estado: 25},
	{id: 2504801, nome: "Coremas", estado: 25},
	{id: 2504850, nome: "Coxixola", estado: 25},
	{id: 2504900, nome: "Cruz do Espírito Santo", estado: 25},
	{id: 2505006, nome: "Cubati", estado: 25},
	{id: 2505105, nome: "Cuité", estado: 25},
	{id: 2505204, nome: "Cuitegi", estado: 25},
	{id: 2505238, nome: "Cuité de Mamanguape", estado: 25},
	{id: 2505279, nome: "Curral de Cima", estado: 25},
	{id: 2505303, nome: "Curral Velho", estado: 25},
	{id: 2505352, nome: "damião", estado: 25},
	{id: 2505402, nome: "desterro", estado: 25},
	{id: 2505501, nome: "Vista Serrana", estado: 25},
	{id: 2505600, nome: "Diamante", estado: 25},
	{id: 2505709, nome: "dona Inês", estado: 25},
	{id: 2505808, nome: "Duas Estradas", estado: 25},
	{id: 2505907, nome: "Emas", estado: 25},
	{id: 2506004, nome: "Esperança", estado: 25},
	{id: 2506103, nome: "Fagundes", estado: 25},
	{id: 2506202, nome: "Frei Martinho", estado: 25},
	{id: 2506251, nome: "Gado Bravo", estado: 25},
	{id: 2506301, nome: "Guarabira", estado: 25},
	{id: 2506400, nome: "Gurinhém", estado: 25},
	{id: 2506509, nome: "Gurjão", estado: 25},
	{id: 2506608, nome: "Ibiara", estado: 25},
	{id: 2506707, nome: "Imaculada", estado: 25},
	{id: 2506806, nome: "Ingá", estado: 25},
	{id: 2506905, nome: "Itabaiana", estado: 25},
	{id: 2507002, nome: "Itaporanga", estado: 25},
	{id: 2507101, nome: "Itapororoca", estado: 25},
	{id: 2507200, nome: "Itatuba", estado: 25},
	{id: 2507309, nome: "Jacaraú", estado: 25},
	{id: 2507408, nome: "Jericó", estado: 25},
	{id: 2507507, nome: "João Pessoa", estado: 25},
	{id: 2507606, nome: "Juarez Távora", estado: 25},
	{id: 2507705, nome: "Juazeirinho", estado: 25},
	{id: 2507804, nome: "Junco do Seridó", estado: 25},
	{id: 2507903, nome: "Juripiranga", estado: 25},
	{id: 2508000, nome: "Juru", estado: 25},
	{id: 2508109, nome: "Lagoa", estado: 25},
	{id: 2508208, nome: "Lagoa de dentro", estado: 25},
	{id: 2508307, nome: "Lagoa Seca", estado: 25},
	{id: 2508406, nome: "Lastro", estado: 25},
	{id: 2508505, nome: "Livramento", estado: 25},
	{id: 2508554, nome: "Logradouro", estado: 25},
	{id: 2508604, nome: "Lucena", estado: 25},
	{id: 2508703, nome: "Mãe D'água", estado: 25},
	{id: 2508802, nome: "Malta", estado: 25},
	{id: 2508901, nome: "Mamanguape", estado: 25},
	{id: 2509008, nome: "Manaíra", estado: 25},
	{id: 2509057, nome: "Marcação", estado: 25},
	{id: 2509107, nome: "Mari", estado: 25},
	{id: 2509156, nome: "Marizópolis", estado: 25},
	{id: 2509206, nome: "Massaranduba", estado: 25},
	{id: 2509305, nome: "Mataraca", estado: 25},
	{id: 2509339, nome: "Matinhas", estado: 25},
	{id: 2509370, nome: "Mato Grosso", estado: 25},
	{id: 2509396, nome: "Maturéia", estado: 25},
	{id: 2509404, nome: "Mogeiro", estado: 25},
	{id: 2509503, nome: "Montadas", estado: 25},
	{id: 2509602, nome: "Monte Horebe", estado: 25},
	{id: 2509701, nome: "Monteiro", estado: 25},
	{id: 2509800, nome: "Mulungu", estado: 25},
	{id: 2509909, nome: "Natuba", estado: 25},
	{id: 2510006, nome: "Nazarezinho", estado: 25},
	{id: 2510105, nome: "Nova Floresta", estado: 25},
	{id: 2510204, nome: "Nova Olinda", estado: 25},
	{id: 2510303, nome: "Nova Palmeira", estado: 25},
	{id: 2510402, nome: "Olho D'água", estado: 25},
	{id: 2510501, nome: "Olivedos", estado: 25},
	{id: 2510600, nome: "Ouro Velho", estado: 25},
	{id: 2510659, nome: "Parari", estado: 25},
	{id: 2510709, nome: "Passagem", estado: 25},
	{id: 2510808, nome: "Patos", estado: 25},
	{id: 2510907, nome: "Paulista", estado: 25},
	{id: 2511004, nome: "Pedra Branca", estado: 25},
	{id: 2511103, nome: "Pedra Lavrada", estado: 25},
	{id: 2511202, nome: "Pedras de Fogo", estado: 25},
	{id: 2511301, nome: "Piancó", estado: 25},
	{id: 2511400, nome: "Picuí", estado: 25},
	{id: 2511509, nome: "Pilar", estado: 25},
	{id: 2511608, nome: "Pilões", estado: 25},
	{id: 2511707, nome: "Pilõezinhos", estado: 25},
	{id: 2511806, nome: "Pirpirituba", estado: 25},
	{id: 2511905, nome: "Pitimbu", estado: 25},
	{id: 2512002, nome: "Pocinhos", estado: 25},
	{id: 2512036, nome: "Poço dantas", estado: 25},
	{id: 2512077, nome: "Poço de José de Moura", estado: 25},
	{id: 2512101, nome: "Pombal", estado: 25},
	{id: 2512200, nome: "Prata", estado: 25},
	{id: 2512309, nome: "Princesa Isabel", estado: 25},
	{id: 2512408, nome: "Puxinanã", estado: 25},
	{id: 2512507, nome: "Queimadas", estado: 25},
	{id: 2512606, nome: "Quixaba", estado: 25},
	{id: 2512705, nome: "Remígio", estado: 25},
	{id: 2512721, nome: "Pedro Régis", estado: 25},
	{id: 2512747, nome: "Riachão", estado: 25},
	{id: 2512754, nome: "Riachão do Bacamarte", estado: 25},
	{id: 2512762, nome: "Riachão do Poço", estado: 25},
	{id: 2512788, nome: "Riacho de Santo Antônio", estado: 25},
	{id: 2512804, nome: "Riacho dos Cavalos", estado: 25},
	{id: 2512903, nome: "Rio Tinto", estado: 25},
	{id: 2513000, nome: "Salgadinho", estado: 25},
	{id: 2513109, nome: "Salgado de São Félix", estado: 25},
	{id: 2513158, nome: "Santa Cecília", estado: 25},
	{id: 2513208, nome: "Santa Cruz", estado: 25},
	{id: 2513307, nome: "Santa Helena", estado: 25},
	{id: 2513356, nome: "Santa Inês", estado: 25},
	{id: 2513406, nome: "Santa Luzia", estado: 25},
	{id: 2513505, nome: "Santana de Mangueira", estado: 25},
	{id: 2513604, nome: "Santana dos Garrotes", estado: 25},
	{id: 2513653, nome: "Joca Claudino", estado: 25},
	{id: 2513703, nome: "Santa Rita", estado: 25},
	{id: 2513802, nome: "Santa Teresinha", estado: 25},
	{id: 2513851, nome: "Santo André", estado: 25},
	{id: 2513901, nome: "São Bento", estado: 25},
	{id: 2513927, nome: "São Bentinho", estado: 25},
	{id: 2513943, nome: "São domingos do Cariri", estado: 25},
	{id: 2513968, nome: "São domingos", estado: 25},
	{id: 2513984, nome: "São Francisco", estado: 25},
	{id: 2514008, nome: "São João do Cariri", estado: 25},
	{id: 2514107, nome: "São João do Tigre", estado: 25},
	{id: 2514206, nome: "São José da Lagoa Tapada", estado: 25},
	{id: 2514305, nome: "São José de Caiana", estado: 25},
	{id: 2514404, nome: "São José de Espinharas", estado: 25},
	{id: 2514453, nome: "São José dos Ramos", estado: 25},
	{id: 2514503, nome: "São José de Piranhas", estado: 25},
	{id: 2514552, nome: "São José de Princesa", estado: 25},
	{id: 2514602, nome: "São José do Bonfim", estado: 25},
	{id: 2514651, nome: "São José do Brejo do Cruz", estado: 25},
	{id: 2514701, nome: "São José do Sabugi", estado: 25},
	{id: 2514800, nome: "São José dos Cordeiros", estado: 25},
	{id: 2514909, nome: "São Mamede", estado: 25},
	{id: 2515005, nome: "São Miguel de Taipu", estado: 25},
	{id: 2515104, nome: "São Sebastião de Lagoa de Roça", estado: 25},
	{id: 2515203, nome: "São Sebastião do Umbuzeiro", estado: 25},
	{id: 2515302, nome: "Sapé", estado: 25},
	{id: 2515401, nome: "São Vicente do Seridó", estado: 25},
	{id: 2515500, nome: "Serra Branca", estado: 25},
	{id: 2515609, nome: "Serra da Raiz", estado: 25},
	{id: 2515708, nome: "Serra Grande", estado: 25},
	{id: 2515807, nome: "Serra Redonda", estado: 25},
	{id: 2515906, nome: "Serraria", estado: 25},
	{id: 2515930, nome: "Sertãozinho", estado: 25},
	{id: 2515971, nome: "Sobrado", estado: 25},
	{id: 2516003, nome: "Solânea", estado: 25},
	{id: 2516102, nome: "Soledade", estado: 25},
	{id: 2516151, nome: "Sossêgo", estado: 25},
	{id: 2516201, nome: "Sousa", estado: 25},
	{id: 2516300, nome: "Sumé", estado: 25},
	{id: 2516409, nome: "Tacima", estado: 25},
	{id: 2516508, nome: "Taperoá", estado: 25},
	{id: 2516607, nome: "Tavares", estado: 25},
	{id: 2516706, nome: "Teixeira", estado: 25},
	{id: 2516755, nome: "Tenório", estado: 25},
	{id: 2516805, nome: "Triunfo", estado: 25},
	{id: 2516904, nome: "Uiraúna", estado: 25},
	{id: 2517001, nome: "Umbuzeiro", estado: 25},
	{id: 2517100, nome: "Várzea", estado: 25},
	{id: 2517209, nome: "Vieirópolis", estado: 25},
	{id: 2517407, nome: "Zabelê", estado: 25},
	{id: 2600054, nome: "Abreu E Lima", estado: 26},
	{id: 2600104, nome: "Afogados da Ingazeira", estado: 26},
	{id: 2600203, nome: "Afrânio", estado: 26},
	{id: 2600302, nome: "Agrestina", estado: 26},
	{id: 2600401, nome: "Água Preta", estado: 26},
	{id: 2600500, nome: "Águas Belas", estado: 26},
	{id: 2600609, nome: "Alagoinha", estado: 26},
	{id: 2600708, nome: "Aliança", estado: 26},
	{id: 2600807, nome: "Altinho", estado: 26},
	{id: 2600906, nome: "Amaraji", estado: 26},
	{id: 2601003, nome: "Angelim", estado: 26},
	{id: 2601052, nome: "Araçoiaba", estado: 26},
	{id: 2601102, nome: "Araripina", estado: 26},
	{id: 2601201, nome: "Arcoverde", estado: 26},
	{id: 2601300, nome: "Barra de Guabiraba", estado: 26},
	{id: 2601409, nome: "Barreiros", estado: 26},
	{id: 2601508, nome: "Belém de Maria", estado: 26},
	{id: 2601607, nome: "Belém do São Francisco", estado: 26},
	{id: 2601706, nome: "Belo Jardim", estado: 26},
	{id: 2601805, nome: "Betânia", estado: 26},
	{id: 2601904, nome: "Bezerros", estado: 26},
	{id: 2602001, nome: "Bodocó", estado: 26},
	{id: 2602100, nome: "Bom Conselho", estado: 26},
	{id: 2602209, nome: "Bom Jardim", estado: 26},
	{id: 2602308, nome: "Bonito", estado: 26},
	{id: 2602407, nome: "Brejão", estado: 26},
	{id: 2602506, nome: "Brejinho", estado: 26},
	{id: 2602605, nome: "Brejo da Madre de deus", estado: 26},
	{id: 2602704, nome: "Buenos Aires", estado: 26},
	{id: 2602803, nome: "Buíque", estado: 26},
	{id: 2602902, nome: "Cabo de Santo Agostinho", estado: 26},
	{id: 2603009, nome: "Cabrobó", estado: 26},
	{id: 2603108, nome: "Cachoeirinha", estado: 26},
	{id: 2603207, nome: "Caetés", estado: 26},
	{id: 2603306, nome: "Calçado", estado: 26},
	{id: 2603405, nome: "Calumbi", estado: 26},
	{id: 2603454, nome: "Camaragibe", estado: 26},
	{id: 2603504, nome: "Camocim de São Félix", estado: 26},
	{id: 2603603, nome: "Camutanga", estado: 26},
	{id: 2603702, nome: "Canhotinho", estado: 26},
	{id: 2603801, nome: "Capoeiras", estado: 26},
	{id: 2603900, nome: "Carnaíba", estado: 26},
	{id: 2603926, nome: "Carnaubeira da Penha", estado: 26},
	{id: 2604007, nome: "Carpina", estado: 26},
	{id: 2604106, nome: "Caruaru", estado: 26},
	{id: 2604155, nome: "Casinhas", estado: 26},
	{id: 2604205, nome: "Catende", estado: 26},
	{id: 2604304, nome: "Cedro", estado: 26},
	{id: 2604403, nome: "Chã de Alegria", estado: 26},
	{id: 2604502, nome: "Chã Grande", estado: 26},
	{id: 2604601, nome: "Condado", estado: 26},
	{id: 2604700, nome: "Correntes", estado: 26},
	{id: 2604809, nome: "Cortês", estado: 26},
	{id: 2604908, nome: "Cumaru", estado: 26},
	{id: 2605004, nome: "Cupira", estado: 26},
	{id: 2605103, nome: "Custódia", estado: 26},
	{id: 2605152, nome: "dormentes", estado: 26},
	{id: 2605202, nome: "Escada", estado: 26},
	{id: 2605301, nome: "Exu", estado: 26},
	{id: 2605400, nome: "Feira Nova", estado: 26},
	{id: 2605459, nome: "Fernando de Noronha", estado: 26},
	{id: 2605509, nome: "Ferreiros", estado: 26},
	{id: 2605608, nome: "Flores", estado: 26},
	{id: 2605707, nome: "Floresta", estado: 26},
	{id: 2605806, nome: "Frei Miguelinho", estado: 26},
	{id: 2605905, nome: "Gameleira", estado: 26},
	{id: 2606002, nome: "Garanhuns", estado: 26},
	{id: 2606101, nome: "Glória do Goitá", estado: 26},
	{id: 2606200, nome: "Goiana", estado: 26},
	{id: 2606309, nome: "Granito", estado: 26},
	{id: 2606408, nome: "Gravatá", estado: 26},
	{id: 2606507, nome: "Iati", estado: 26},
	{id: 2606606, nome: "Ibimirim", estado: 26},
	{id: 2606705, nome: "Ibirajuba", estado: 26},
	{id: 2606804, nome: "Igarassu", estado: 26},
	{id: 2606903, nome: "Iguaracy", estado: 26},
	{id: 2607000, nome: "Inajá", estado: 26},
	{id: 2607109, nome: "Ingazeira", estado: 26},
	{id: 2607208, nome: "Ipojuca", estado: 26},
	{id: 2607307, nome: "Ipubi", estado: 26},
	{id: 2607406, nome: "Itacuruba", estado: 26},
	{id: 2607505, nome: "Itaíba", estado: 26},
	{id: 2607604, nome: "Ilha de Itamaracá", estado: 26},
	{id: 2607653, nome: "Itambé", estado: 26},
	{id: 2607703, nome: "Itapetim", estado: 26},
	{id: 2607752, nome: "Itapissuma", estado: 26},
	{id: 2607802, nome: "Itaquitinga", estado: 26},
	{id: 2607901, nome: "Jaboatão dos Guararapes", estado: 26},
	{id: 2607950, nome: "Jaqueira", estado: 26},
	{id: 2608008, nome: "Jataúba", estado: 26},
	{id: 2608057, nome: "Jatobá", estado: 26},
	{id: 2608107, nome: "João Alfredo", estado: 26},
	{id: 2608206, nome: "Joaquim Nabuco", estado: 26},
	{id: 2608255, nome: "Jucati", estado: 26},
	{id: 2608305, nome: "Jupi", estado: 26},
	{id: 2608404, nome: "Jurema", estado: 26},
	{id: 2608453, nome: "Lagoa do Carro", estado: 26},
	{id: 2608503, nome: "Lagoa de Itaenga", estado: 26},
	{id: 2608602, nome: "Lagoa do Ouro", estado: 26},
	{id: 2608701, nome: "Lagoa dos Gatos", estado: 26},
	{id: 2608750, nome: "Lagoa Grande", estado: 26},
	{id: 2608800, nome: "Lajedo", estado: 26},
	{id: 2608909, nome: "Limoeiro", estado: 26},
	{id: 2609006, nome: "Macaparana", estado: 26},
	{id: 2609105, nome: "Machados", estado: 26},
	{id: 2609154, nome: "Manari", estado: 26},
	{id: 2609204, nome: "Maraial", estado: 26},
	{id: 2609303, nome: "Mirandiba", estado: 26},
	{id: 2609402, nome: "Moreno", estado: 26},
	{id: 2609501, nome: "Nazaré da Mata", estado: 26},
	{id: 2609600, nome: "Olinda", estado: 26},
	{id: 2609709, nome: "Orobó", estado: 26},
	{id: 2609808, nome: "Orocó", estado: 26},
	{id: 2609907, nome: "Ouricuri", estado: 26},
	{id: 2610004, nome: "Palmares", estado: 26},
	{id: 2610103, nome: "Palmeirina", estado: 26},
	{id: 2610202, nome: "Panelas", estado: 26},
	{id: 2610301, nome: "Paranatama", estado: 26},
	{id: 2610400, nome: "Parnamirim", estado: 26},
	{id: 2610509, nome: "Passira", estado: 26},
	{id: 2610608, nome: "Paudalho", estado: 26},
	{id: 2610707, nome: "Paulista", estado: 26},
	{id: 2610806, nome: "Pedra", estado: 26},
	{id: 2610905, nome: "Pesqueira", estado: 26},
	{id: 2611002, nome: "Petrolândia", estado: 26},
	{id: 2611101, nome: "Petrolina", estado: 26},
	{id: 2611200, nome: "Poção", estado: 26},
	{id: 2611309, nome: "Pombos", estado: 26},
	{id: 2611408, nome: "Primavera", estado: 26},
	{id: 2611507, nome: "Quipapá", estado: 26},
	{id: 2611533, nome: "Quixaba", estado: 26},
	{id: 2611606, nome: "Recife", estado: 26},
	{id: 2611705, nome: "Riacho das Almas", estado: 26},
	{id: 2611804, nome: "Ribeirão", estado: 26},
	{id: 2611903, nome: "Rio Formoso", estado: 26},
	{id: 2612000, nome: "Sairé", estado: 26},
	{id: 2612109, nome: "Salgadinho", estado: 26},
	{id: 2612208, nome: "Salgueiro", estado: 26},
	{id: 2612307, nome: "Saloá", estado: 26},
	{id: 2612406, nome: "Sanharó", estado: 26},
	{id: 2612455, nome: "Santa Cruz", estado: 26},
	{id: 2612471, nome: "Santa Cruz da Baixa Verde", estado: 26},
	{id: 2612505, nome: "Santa Cruz do Capibaribe", estado: 26},
	{id: 2612554, nome: "Santa Filomena", estado: 26},
	{id: 2612604, nome: "Santa Maria da Boa Vista", estado: 26},
	{id: 2612703, nome: "Santa Maria do Cambucá", estado: 26},
	{id: 2612802, nome: "Santa Terezinha", estado: 26},
	{id: 2612901, nome: "São Benedito do Sul", estado: 26},
	{id: 2613008, nome: "São Bento do Una", estado: 26},
	{id: 2613107, nome: "São Caitano", estado: 26},
	{id: 2613206, nome: "São João", estado: 26},
	{id: 2613305, nome: "São Joaquim do Monte", estado: 26},
	{id: 2613404, nome: "São José da Coroa Grande", estado: 26},
	{id: 2613503, nome: "São José do Belmonte", estado: 26},
	{id: 2613602, nome: "São José do Egito", estado: 26},
	{id: 2613701, nome: "São Lourenço da Mata", estado: 26},
	{id: 2613800, nome: "São Vicente Ferrer", estado: 26},
	{id: 2613909, nome: "Serra Talhada", estado: 26},
	{id: 2614006, nome: "Serrita", estado: 26},
	{id: 2614105, nome: "Sertânia", estado: 26},
	{id: 2614204, nome: "Sirinhaém", estado: 26},
	{id: 2614303, nome: "Moreilândia", estado: 26},
	{id: 2614402, nome: "Solidão", estado: 26},
	{id: 2614501, nome: "Surubim", estado: 26},
	{id: 2614600, nome: "Tabira", estado: 26},
	{id: 2614709, nome: "Tacaimbó", estado: 26},
	{id: 2614808, nome: "Tacaratu", estado: 26},
	{id: 2614857, nome: "Tamandaré", estado: 26},
	{id: 2615003, nome: "Taquaritinga do Norte", estado: 26},
	{id: 2615102, nome: "Terezinha", estado: 26},
	{id: 2615201, nome: "Terra Nova", estado: 26},
	{id: 2615300, nome: "Timbaúba", estado: 26},
	{id: 2615409, nome: "Toritama", estado: 26},
	{id: 2615508, nome: "Tracunhaém", estado: 26},
	{id: 2615607, nome: "Trindade", estado: 26},
	{id: 2615706, nome: "Triunfo", estado: 26},
	{id: 2615805, nome: "Tupanatinga", estado: 26},
	{id: 2615904, nome: "Tuparetama", estado: 26},
	{id: 2616001, nome: "Venturosa", estado: 26},
	{id: 2616100, nome: "Verdejante", estado: 26},
	{id: 2616183, nome: "Vertente do Lério", estado: 26},
	{id: 2616209, nome: "Vertentes", estado: 26},
	{id: 2616308, nome: "Vicência", estado: 26},
	{id: 2616407, nome: "Vitória de Santo Antão", estado: 26},
	{id: 2616506, nome: "Xexéu", estado: 26},
	{id: 2700102, nome: "Água Branca", estado: 27},
	{id: 2700201, nome: "Anadia", estado: 27},
	{id: 2700300, nome: "Arapiraca", estado: 27},
	{id: 2700409, nome: "Atalaia", estado: 27},
	{id: 2700508, nome: "Barra de Santo Antônio", estado: 27},
	{id: 2700607, nome: "Barra de São Miguel", estado: 27},
	{id: 2700706, nome: "Batalha", estado: 27},
	{id: 2700805, nome: "Belém", estado: 27},
	{id: 2700904, nome: "Belo Monte", estado: 27},
	{id: 2701001, nome: "Boca da Mata", estado: 27},
	{id: 2701100, nome: "Branquinha", estado: 27},
	{id: 2701209, nome: "Cacimbinhas", estado: 27},
	{id: 2701308, nome: "Cajueiro", estado: 27},
	{id: 2701357, nome: "Campestre", estado: 27},
	{id: 2701407, nome: "Campo Alegre", estado: 27},
	{id: 2701506, nome: "Campo Grande", estado: 27},
	{id: 2701605, nome: "Canapi", estado: 27},
	{id: 2701704, nome: "Capela", estado: 27},
	{id: 2701803, nome: "Carneiros", estado: 27},
	{id: 2701902, nome: "Chã Preta", estado: 27},
	{id: 2702009, nome: "Coité do Nóia", estado: 27},
	{id: 2702108, nome: "Colônia Leopoldina", estado: 27},
	{id: 2702207, nome: "Coqueiro Seco", estado: 27},
	{id: 2702306, nome: "Coruripe", estado: 27},
	{id: 2702355, nome: "Craíbas", estado: 27},
	{id: 2702405, nome: "delmiro Gouveia", estado: 27},
	{id: 2702504, nome: "dois Riachos", estado: 27},
	{id: 2702553, nome: "Estrela de Alagoas", estado: 27},
	{id: 2702603, nome: "Feira Grande", estado: 27},
	{id: 2702702, nome: "Feliz deserto", estado: 27},
	{id: 2702801, nome: "Flexeiras", estado: 27},
	{id: 2702900, nome: "Girau do Ponciano", estado: 27},
	{id: 2703007, nome: "Ibateguara", estado: 27},
	{id: 2703106, nome: "Igaci", estado: 27},
	{id: 2703205, nome: "Igreja Nova", estado: 27},
	{id: 2703304, nome: "Inhapi", estado: 27},
	{id: 2703403, nome: "Jacaré dos Homens", estado: 27},
	{id: 2703502, nome: "Jacuípe", estado: 27},
	{id: 2703601, nome: "Japaratinga", estado: 27},
	{id: 2703700, nome: "Jaramataia", estado: 27},
	{id: 2703759, nome: "Jequiá da Praia", estado: 27},
	{id: 2703809, nome: "Joaquim Gomes", estado: 27},
	{id: 2703908, nome: "Jundiá", estado: 27},
	{id: 2704005, nome: "Junqueiro", estado: 27},
	{id: 2704104, nome: "Lagoa da Canoa", estado: 27},
	{id: 2704203, nome: "Limoeiro de Anadia", estado: 27},
	{id: 2704302, nome: "Maceió", estado: 27},
	{id: 2704401, nome: "Major Isidoro", estado: 27},
	{id: 2704500, nome: "Maragogi", estado: 27},
	{id: 2704609, nome: "Maravilha", estado: 27},
	{id: 2704708, nome: "Marechal deodoro", estado: 27},
	{id: 2704807, nome: "Maribondo", estado: 27},
	{id: 2704906, nome: "Mar Vermelho", estado: 27},
	{id: 2705002, nome: "Mata Grande", estado: 27},
	{id: 2705101, nome: "Matriz de Camaragibe", estado: 27},
	{id: 2705200, nome: "Messias", estado: 27},
	{id: 2705309, nome: "Minador do Negrão", estado: 27},
	{id: 2705408, nome: "Monteirópolis", estado: 27},
	{id: 2705507, nome: "Murici", estado: 27},
	{id: 2705606, nome: "Novo Lino", estado: 27},
	{id: 2705705, nome: "Olho D'água das Flores", estado: 27},
	{id: 2705804, nome: "Olho D'água do Casado", estado: 27},
	{id: 2705903, nome: "Olho D'água Grande", estado: 27},
	{id: 2706000, nome: "Olivença", estado: 27},
	{id: 2706109, nome: "Ouro Branco", estado: 27},
	{id: 2706208, nome: "Palestina", estado: 27},
	{id: 2706307, nome: "Palmeira dos Índios", estado: 27},
	{id: 2706406, nome: "Pão de Açúcar", estado: 27},
	{id: 2706422, nome: "Pariconha", estado: 27},
	{id: 2706448, nome: "Paripueira", estado: 27},
	{id: 2706505, nome: "Passo de Camaragibe", estado: 27},
	{id: 2706604, nome: "Paulo Jacinto", estado: 27},
	{id: 2706703, nome: "Penedo", estado: 27},
	{id: 2706802, nome: "Piaçabuçu", estado: 27},
	{id: 2706901, nome: "Pilar", estado: 27},
	{id: 2707008, nome: "Pindoba", estado: 27},
	{id: 2707107, nome: "Piranhas", estado: 27},
	{id: 2707206, nome: "Poço das Trincheiras", estado: 27},
	{id: 2707305, nome: "Porto Calvo", estado: 27},
	{id: 2707404, nome: "Porto de Pedras", estado: 27},
	{id: 2707503, nome: "Porto Real do Colégio", estado: 27},
	{id: 2707602, nome: "Quebrangulo", estado: 27},
	{id: 2707701, nome: "Rio Largo", estado: 27},
	{id: 2707800, nome: "Roteiro", estado: 27},
	{id: 2707909, nome: "Santa Luzia do Norte", estado: 27},
	{id: 2708006, nome: "Santana do Ipanema", estado: 27},
	{id: 2708105, nome: "Santana do Mundaú", estado: 27},
	{id: 2708204, nome: "São Brás", estado: 27},
	{id: 2708303, nome: "São José da Laje", estado: 27},
	{id: 2708402, nome: "São José da Tapera", estado: 27},
	{id: 2708501, nome: "São Luís do Quitunde", estado: 27},
	{id: 2708600, nome: "São Miguel dos Campos", estado: 27},
	{id: 2708709, nome: "São Miguel dos Milagres", estado: 27},
	{id: 2708808, nome: "São Sebastião", estado: 27},
	{id: 2708907, nome: "Satuba", estado: 27},
	{id: 2708956, nome: "Senador Rui Palmeira", estado: 27},
	{id: 2709004, nome: "Tanque D'arca", estado: 27},
	{id: 2709103, nome: "Taquarana", estado: 27},
	{id: 2709152, nome: "Teotônio Vilela", estado: 27},
	{id: 2709202, nome: "Traipu", estado: 27},
	{id: 2709301, nome: "União dos Palmares", estado: 27},
	{id: 2709400, nome: "Viçosa", estado: 27},
	{id: 2800100, nome: "Amparo de São Francisco", estado: 28},
	{id: 2800209, nome: "Aquidabã", estado: 28},
	{id: 2800308, nome: "Aracaju", estado: 28},
	{id: 2800407, nome: "Arauá", estado: 28},
	{id: 2800506, nome: "Areia Branca", estado: 28},
	{id: 2800605, nome: "Barra dos Coqueiros", estado: 28},
	{id: 2800670, nome: "Boquim", estado: 28},
	{id: 2800704, nome: "Brejo Grande", estado: 28},
	{id: 2801009, nome: "Campo do Brito", estado: 28},
	{id: 2801108, nome: "Canhoba", estado: 28},
	{id: 2801207, nome: "Canindé de São Francisco", estado: 28},
	{id: 2801306, nome: "Capela", estado: 28},
	{id: 2801405, nome: "Carira", estado: 28},
	{id: 2801504, nome: "Carmópolis", estado: 28},
	{id: 2801603, nome: "Cedro de São João", estado: 28},
	{id: 2801702, nome: "Cristinápolis", estado: 28},
	{id: 2801900, nome: "Cumbe", estado: 28},
	{id: 2802007, nome: "Divina Pastora", estado: 28},
	{id: 2802106, nome: "Estância", estado: 28},
	{id: 2802205, nome: "Feira Nova", estado: 28},
	{id: 2802304, nome: "Frei Paulo", estado: 28},
	{id: 2802403, nome: "Gararu", estado: 28},
	{id: 2802502, nome: "General Maynard", estado: 28},
	{id: 2802601, nome: "Gracho Cardoso", estado: 28},
	{id: 2802700, nome: "Ilha das Flores", estado: 28},
	{id: 2802809, nome: "Indiaroba", estado: 28},
	{id: 2802908, nome: "Itabaiana", estado: 28},
	{id: 2803005, nome: "Itabaianinha", estado: 28},
	{id: 2803104, nome: "Itabi", estado: 28},
	{id: 2803203, nome: "Itaporanga D'ajuda", estado: 28},
	{id: 2803302, nome: "Japaratuba", estado: 28},
	{id: 2803401, nome: "Japoatã", estado: 28},
	{id: 2803500, nome: "Lagarto", estado: 28},
	{id: 2803609, nome: "Laranjeiras", estado: 28},
	{id: 2803708, nome: "Macambira", estado: 28},
	{id: 2803807, nome: "Malhada dos Bois", estado: 28},
	{id: 2803906, nome: "Malhador", estado: 28},
	{id: 2804003, nome: "Maruim", estado: 28},
	{id: 2804102, nome: "Moita Bonita", estado: 28},
	{id: 2804201, nome: "Monte Alegre de Sergipe", estado: 28},
	{id: 2804300, nome: "Muribeca", estado: 28},
	{id: 2804409, nome: "Neópolis", estado: 28},
	{id: 2804458, nome: "Nossa Senhora Aparecida", estado: 28},
	{id: 2804508, nome: "Nossa Senhora da Glória", estado: 28},
	{id: 2804607, nome: "Nossa Senhora das dores", estado: 28},
	{id: 2804706, nome: "Nossa Senhora de Lourdes", estado: 28},
	{id: 2804805, nome: "Nossa Senhora do Socorro", estado: 28},
	{id: 2804904, nome: "Pacatuba", estado: 28},
	{id: 2805000, nome: "Pedra Mole", estado: 28},
	{id: 2805109, nome: "Pedrinhas", estado: 28},
	{id: 2805208, nome: "Pinhão", estado: 28},
	{id: 2805307, nome: "Pirambu", estado: 28},
	{id: 2805406, nome: "Poço Redondo", estado: 28},
	{id: 2805505, nome: "Poço Verde", estado: 28},
	{id: 2805604, nome: "Porto da Folha", estado: 28},
	{id: 2805703, nome: "Propriá", estado: 28},
	{id: 2805802, nome: "Riachão do dantas", estado: 28},
	{id: 2805901, nome: "Riachuelo", estado: 28},
	{id: 2806008, nome: "Ribeirópolis", estado: 28},
	{id: 2806107, nome: "Rosário do Catete", estado: 28},
	{id: 2806206, nome: "Salgado", estado: 28},
	{id: 2806305, nome: "Santa Luzia do Itanhy", estado: 28},
	{id: 2806404, nome: "Santana do São Francisco", estado: 28},
	{id: 2806503, nome: "Santa Rosa de Lima", estado: 28},
	{id: 2806602, nome: "Santo Amaro das Brotas", estado: 28},
	{id: 2806701, nome: "São Cristóvão", estado: 28},
	{id: 2806800, nome: "São domingos", estado: 28},
	{id: 2806909, nome: "São Francisco", estado: 28},
	{id: 2807006, nome: "São Miguel do Aleixo", estado: 28},
	{id: 2807105, nome: "Simão Dias", estado: 28},
	{id: 2807204, nome: "Siriri", estado: 28},
	{id: 2807303, nome: "Telha", estado: 28},
	{id: 2807402, nome: "Tobias Barreto", estado: 28},
	{id: 2807501, nome: "Tomar do Geru", estado: 28},
	{id: 2807600, nome: "Umbaúba", estado: 28},
	{id: 2900108, nome: "Abaíra", estado: 29},
	{id: 2900207, nome: "Abaré", estado: 29},
	{id: 2900306, nome: "Acajutiba", estado: 29},
	{id: 2900355, nome: "Adustina", estado: 29},
	{id: 2900405, nome: "Água Fria", estado: 29},
	{id: 2900504, nome: "Érico Cardoso", estado: 29},
	{id: 2900603, nome: "Aiquara", estado: 29},
	{id: 2900702, nome: "Alagoinhas", estado: 29},
	{id: 2900801, nome: "Alcobaça", estado: 29},
	{id: 2900900, nome: "Almadina", estado: 29},
	{id: 2901007, nome: "Amargosa", estado: 29},
	{id: 2901106, nome: "Amélia Rodrigues", estado: 29},
	{id: 2901155, nome: "América dourada", estado: 29},
	{id: 2901205, nome: "Anagé", estado: 29},
	{id: 2901304, nome: "Andaraí", estado: 29},
	{id: 2901353, nome: "Andorinha", estado: 29},
	{id: 2901403, nome: "Angical", estado: 29},
	{id: 2901502, nome: "Anguera", estado: 29},
	{id: 2901601, nome: "Antas", estado: 29},
	{id: 2901700, nome: "Antônio Cardoso", estado: 29},
	{id: 2901809, nome: "Antônio Gonçalves", estado: 29},
	{id: 2901908, nome: "Aporá", estado: 29},
	{id: 2901957, nome: "Apuarema", estado: 29},
	{id: 2902005, nome: "Aracatu", estado: 29},
	{id: 2902054, nome: "Araças", estado: 29},
	{id: 2902104, nome: "Araci", estado: 29},
	{id: 2902203, nome: "Aramari", estado: 29},
	{id: 2902252, nome: "Arataca", estado: 29},
	{id: 2902302, nome: "Aratuípe", estado: 29},
	{id: 2902401, nome: "Aurelino Leal", estado: 29},
	{id: 2902500, nome: "Baianópolis", estado: 29},
	{id: 2902609, nome: "Baixa Grande", estado: 29},
	{id: 2902658, nome: "Banzaê", estado: 29},
	{id: 2902708, nome: "Barra", estado: 29},
	{id: 2902807, nome: "Barra da Estiva", estado: 29},
	{id: 2902906, nome: "Barra do Choça", estado: 29},
	{id: 2903003, nome: "Barra do Mendes", estado: 29},
	{id: 2903102, nome: "Barra do Rocha", estado: 29},
	{id: 2903201, nome: "Barreiras", estado: 29},
	{id: 2903235, nome: "Barro Alto", estado: 29},
	{id: 2903276, nome: "Barrocas", estado: 29},
	{id: 2903300, nome: "Barro Preto", estado: 29},
	{id: 2903409, nome: "Belmonte", estado: 29},
	{id: 2903508, nome: "Belo Campo", estado: 29},
	{id: 2903607, nome: "Biritinga", estado: 29},
	{id: 2903706, nome: "Boa Nova", estado: 29},
	{id: 2903805, nome: "Boa Vista do Tupim", estado: 29},
	{id: 2903904, nome: "Bom Jesus da Lapa", estado: 29},
	{id: 2903953, nome: "Bom Jesus da Serra", estado: 29},
	{id: 2904001, nome: "Boninal", estado: 29},
	{id: 2904050, nome: "Bonito", estado: 29},
	{id: 2904100, nome: "Boquira", estado: 29},
	{id: 2904209, nome: "Botuporã", estado: 29},
	{id: 2904308, nome: "Brejões", estado: 29},
	{id: 2904407, nome: "Brejolândia", estado: 29},
	{id: 2904506, nome: "Brotas de Macaúbas", estado: 29},
	{id: 2904605, nome: "Brumado", estado: 29},
	{id: 2904704, nome: "Buerarema", estado: 29},
	{id: 2904753, nome: "Buritirama", estado: 29},
	{id: 2904803, nome: "Caatiba", estado: 29},
	{id: 2904852, nome: "Cabaceiras do Paraguaçu", estado: 29},
	{id: 2904902, nome: "Cachoeira", estado: 29},
	{id: 2905008, nome: "Caculé", estado: 29},
	{id: 2905107, nome: "Caém", estado: 29},
	{id: 2905156, nome: "Caetanos", estado: 29},
	{id: 2905206, nome: "Caetité", estado: 29},
	{id: 2905305, nome: "Cafarnaum", estado: 29},
	{id: 2905404, nome: "Cairu", estado: 29},
	{id: 2905503, nome: "Caldeirão Grande", estado: 29},
	{id: 2905602, nome: "Camacan", estado: 29},
	{id: 2905701, nome: "Camaçari", estado: 29},
	{id: 2905800, nome: "Camamu", estado: 29},
	{id: 2905909, nome: "Campo Alegre de Lourdes", estado: 29},
	{id: 2906006, nome: "Campo Formoso", estado: 29},
	{id: 2906105, nome: "Canápolis", estado: 29},
	{id: 2906204, nome: "Canarana", estado: 29},
	{id: 2906303, nome: "Canavieiras", estado: 29},
	{id: 2906402, nome: "Candeal", estado: 29},
	{id: 2906501, nome: "Candeias", estado: 29},
	{id: 2906600, nome: "Candiba", estado: 29},
	{id: 2906709, nome: "Cândido Sales", estado: 29},
	{id: 2906808, nome: "Cansanção", estado: 29},
	{id: 2906824, nome: "Canudos", estado: 29},
	{id: 2906857, nome: "Capela do Alto Alegre", estado: 29},
	{id: 2906873, nome: "Capim Grosso", estado: 29},
	{id: 2906899, nome: "Caraíbas", estado: 29},
	{id: 2906907, nome: "Caravelas", estado: 29},
	{id: 2907004, nome: "Cardeal da Silva", estado: 29},
	{id: 2907103, nome: "Carinhanha", estado: 29},
	{id: 2907202, nome: "Casa Nova", estado: 29},
	{id: 2907301, nome: "Castro Alves", estado: 29},
	{id: 2907400, nome: "Catolândia", estado: 29},
	{id: 2907509, nome: "Catu", estado: 29},
	{id: 2907558, nome: "Caturama", estado: 29},
	{id: 2907608, nome: "Central", estado: 29},
	{id: 2907707, nome: "Chorrochó", estado: 29},
	{id: 2907806, nome: "Cícero dantas", estado: 29},
	{id: 2907905, nome: "Cipó", estado: 29},
	{id: 2908002, nome: "Coaraci", estado: 29},
	{id: 2908101, nome: "Cocos", estado: 29},
	{id: 2908200, nome: "Conceição da Feira", estado: 29},
	{id: 2908309, nome: "Conceição do Almeida", estado: 29},
	{id: 2908408, nome: "Conceição do Coité", estado: 29},
	{id: 2908507, nome: "Conceição do Jacuípe", estado: 29},
	{id: 2908606, nome: "Conde", estado: 29},
	{id: 2908705, nome: "Condeúba", estado: 29},
	{id: 2908804, nome: "Contendas do Sincorá", estado: 29},
	{id: 2908903, nome: "Coração de Maria", estado: 29},
	{id: 2909000, nome: "Cordeiros", estado: 29},
	{id: 2909109, nome: "Coribe", estado: 29},
	{id: 2909208, nome: "Coronel João Sá", estado: 29},
	{id: 2909307, nome: "Correntina", estado: 29},
	{id: 2909406, nome: "Cotegipe", estado: 29},
	{id: 2909505, nome: "Cravolândia", estado: 29},
	{id: 2909604, nome: "Crisópolis", estado: 29},
	{id: 2909703, nome: "Cristópolis", estado: 29},
	{id: 2909802, nome: "Cruz das Almas", estado: 29},
	{id: 2909901, nome: "Curaçá", estado: 29},
	{id: 2910008, nome: "Dário Meira", estado: 29},
	{id: 2910057, nome: "Dias D'ávila", estado: 29},
	{id: 2910107, nome: "dom Basílio", estado: 29},
	{id: 2910206, nome: "dom Macedo Costa", estado: 29},
	{id: 2910305, nome: "Elísio Medrado", estado: 29},
	{id: 2910404, nome: "Encruzilhada", estado: 29},
	{id: 2910503, nome: "Entre Rios", estado: 29},
	{id: 2910602, nome: "Esplanada", estado: 29},
	{id: 2910701, nome: "Euclides da Cunha", estado: 29},
	{id: 2910727, nome: "Eunápolis", estado: 29},
	{id: 2910750, nome: "Fátima", estado: 29},
	{id: 2910776, nome: "Feira da Mata", estado: 29},
	{id: 2910800, nome: "Feira de Santana", estado: 29},
	{id: 2910859, nome: "Filadélfia", estado: 29},
	{id: 2910909, nome: "Firmino Alves", estado: 29},
	{id: 2911006, nome: "Floresta Azul", estado: 29},
	{id: 2911105, nome: "Formosa do Rio Preto", estado: 29},
	{id: 2911204, nome: "Gandu", estado: 29},
	{id: 2911253, nome: "Gavião", estado: 29},
	{id: 2911303, nome: "Gentio do Ouro", estado: 29},
	{id: 2911402, nome: "Glória", estado: 29},
	{id: 2911501, nome: "Gongogi", estado: 29},
	{id: 2911600, nome: "Governador Mangabeira", estado: 29},
	{id: 2911659, nome: "Guajeru", estado: 29},
	{id: 2911709, nome: "Guanambi", estado: 29},
	{id: 2911808, nome: "Guaratinga", estado: 29},
	{id: 2911857, nome: "Heliópolis", estado: 29},
	{id: 2911907, nome: "Iaçu", estado: 29},
	{id: 2912004, nome: "Ibiassucê", estado: 29},
	{id: 2912103, nome: "Ibicaraí", estado: 29},
	{id: 2912202, nome: "Ibicoara", estado: 29},
	{id: 2912301, nome: "Ibicuí", estado: 29},
	{id: 2912400, nome: "Ibipeba", estado: 29},
	{id: 2912509, nome: "Ibipitanga", estado: 29},
	{id: 2912608, nome: "Ibiquera", estado: 29},
	{id: 2912707, nome: "Ibirapitanga", estado: 29},
	{id: 2912806, nome: "Ibirapuã", estado: 29},
	{id: 2912905, nome: "Ibirataia", estado: 29},
	{id: 2913002, nome: "Ibitiara", estado: 29},
	{id: 2913101, nome: "Ibititá", estado: 29},
	{id: 2913200, nome: "Ibotirama", estado: 29},
	{id: 2913309, nome: "Ichu", estado: 29},
	{id: 2913408, nome: "Igaporã", estado: 29},
	{id: 2913457, nome: "Igrapiúna", estado: 29},
	{id: 2913507, nome: "Iguaí", estado: 29},
	{id: 2913606, nome: "Ilhéus", estado: 29},
	{id: 2913705, nome: "Inhambupe", estado: 29},
	{id: 2913804, nome: "Ipecaetá", estado: 29},
	{id: 2913903, nome: "Ipiaú", estado: 29},
	{id: 2914000, nome: "Ipirá", estado: 29},
	{id: 2914109, nome: "Ipupiara", estado: 29},
	{id: 2914208, nome: "Irajuba", estado: 29},
	{id: 2914307, nome: "Iramaia", estado: 29},
	{id: 2914406, nome: "Iraquara", estado: 29},
	{id: 2914505, nome: "Irará", estado: 29},
	{id: 2914604, nome: "Irecê", estado: 29},
	{id: 2914653, nome: "Itabela", estado: 29},
	{id: 2914703, nome: "Itaberaba", estado: 29},
	{id: 2914802, nome: "Itabuna", estado: 29},
	{id: 2914901, nome: "Itacaré", estado: 29},
	{id: 2915007, nome: "Itaeté", estado: 29},
	{id: 2915106, nome: "Itagi", estado: 29},
	{id: 2915205, nome: "Itagibá", estado: 29},
	{id: 2915304, nome: "Itagimirim", estado: 29},
	{id: 2915353, nome: "Itaguaçu da Bahia", estado: 29},
	{id: 2915403, nome: "Itaju do Colônia", estado: 29},
	{id: 2915502, nome: "Itajuípe", estado: 29},
	{id: 2915601, nome: "Itamaraju", estado: 29},
	{id: 2915700, nome: "Itamari", estado: 29},
	{id: 2915809, nome: "Itambé", estado: 29},
	{id: 2915908, nome: "Itanagra", estado: 29},
	{id: 2916005, nome: "Itanhém", estado: 29},
	{id: 2916104, nome: "Itaparica", estado: 29},
	{id: 2916203, nome: "Itapé", estado: 29},
	{id: 2916302, nome: "Itapebi", estado: 29},
	{id: 2916401, nome: "Itapetinga", estado: 29},
	{id: 2916500, nome: "Itapicuru", estado: 29},
	{id: 2916609, nome: "Itapitanga", estado: 29},
	{id: 2916708, nome: "Itaquara", estado: 29},
	{id: 2916807, nome: "Itarantim", estado: 29},
	{id: 2916856, nome: "Itatim", estado: 29},
	{id: 2916906, nome: "Itiruçu", estado: 29},
	{id: 2917003, nome: "Itiúba", estado: 29},
	{id: 2917102, nome: "Itororó", estado: 29},
	{id: 2917201, nome: "Ituaçu", estado: 29},
	{id: 2917300, nome: "Ituberá", estado: 29},
	{id: 2917334, nome: "Iuiú", estado: 29},
	{id: 2917359, nome: "Jaborandi", estado: 29},
	{id: 2917409, nome: "Jacaraci", estado: 29},
	{id: 2917508, nome: "Jacobina", estado: 29},
	{id: 2917607, nome: "Jaguaquara", estado: 29},
	{id: 2917706, nome: "Jaguarari", estado: 29},
	{id: 2917805, nome: "Jaguaripe", estado: 29},
	{id: 2917904, nome: "Jandaíra", estado: 29},
	{id: 2918001, nome: "Jequié", estado: 29},
	{id: 2918100, nome: "Jeremoabo", estado: 29},
	{id: 2918209, nome: "Jiquiriçá", estado: 29},
	{id: 2918308, nome: "Jitaúna", estado: 29},
	{id: 2918357, nome: "João dourado", estado: 29},
	{id: 2918407, nome: "Juazeiro", estado: 29},
	{id: 2918456, nome: "Jucuruçu", estado: 29},
	{id: 2918506, nome: "Jussara", estado: 29},
	{id: 2918555, nome: "Jussari", estado: 29},
	{id: 2918605, nome: "Jussiape", estado: 29},
	{id: 2918704, nome: "Lafaiete Coutinho", estado: 29},
	{id: 2918753, nome: "Lagoa Real", estado: 29},
	{id: 2918803, nome: "Laje", estado: 29},
	{id: 2918902, nome: "Lajedão", estado: 29},
	{id: 2919009, nome: "Lajedinho", estado: 29},
	{id: 2919058, nome: "Lajedo do Tabocal", estado: 29},
	{id: 2919108, nome: "Lamarão", estado: 29},
	{id: 2919157, nome: "Lapão", estado: 29},
	{id: 2919207, nome: "Lauro de Freitas", estado: 29},
	{id: 2919306, nome: "Lençóis", estado: 29},
	{id: 2919405, nome: "Licínio de Almeida", estado: 29},
	{id: 2919504, nome: "Livramento de Nossa Senhora", estado: 29},
	{id: 2919553, nome: "Luís Eduardo Magalhães", estado: 29},
	{id: 2919603, nome: "Macajuba", estado: 29},
	{id: 2919702, nome: "Macarani", estado: 29},
	{id: 2919801, nome: "Macaúbas", estado: 29},
	{id: 2919900, nome: "Macururé", estado: 29},
	{id: 2919926, nome: "Madre de deus", estado: 29},
	{id: 2919959, nome: "Maetinga", estado: 29},
	{id: 2920007, nome: "Maiquinique", estado: 29},
	{id: 2920106, nome: "Mairi", estado: 29},
	{id: 2920205, nome: "Malhada", estado: 29},
	{id: 2920304, nome: "Malhada de Pedras", estado: 29},
	{id: 2920403, nome: "Manoel Vitorino", estado: 29},
	{id: 2920452, nome: "Mansidão", estado: 29},
	{id: 2920502, nome: "Maracás", estado: 29},
	{id: 2920601, nome: "Maragogipe", estado: 29},
	{id: 2920700, nome: "Maraú", estado: 29},
	{id: 2920809, nome: "Marcionílio Souza", estado: 29},
	{id: 2920908, nome: "Mascote", estado: 29},
	{id: 2921005, nome: "Mata de São João", estado: 29},
	{id: 2921054, nome: "Matina", estado: 29},
	{id: 2921104, nome: "Medeiros Neto", estado: 29},
	{id: 2921203, nome: "Miguel Calmon", estado: 29},
	{id: 2921302, nome: "Milagres", estado: 29},
	{id: 2921401, nome: "Mirangaba", estado: 29},
	{id: 2921450, nome: "Mirante", estado: 29},
	{id: 2921500, nome: "Monte Santo", estado: 29},
	{id: 2921609, nome: "Morpará", estado: 29},
	{id: 2921708, nome: "Morro do Chapéu", estado: 29},
	{id: 2921807, nome: "Mortugaba", estado: 29},
	{id: 2921906, nome: "Mucugê", estado: 29},
	{id: 2922003, nome: "Mucuri", estado: 29},
	{id: 2922052, nome: "Mulungu do Morro", estado: 29},
	{id: 2922102, nome: "Mundo Novo", estado: 29},
	{id: 2922201, nome: "Muniz Ferreira", estado: 29},
	{id: 2922250, nome: "Muquém de São Francisco", estado: 29},
	{id: 2922300, nome: "Muritiba", estado: 29},
	{id: 2922409, nome: "Mutuípe", estado: 29},
	{id: 2922508, nome: "Nazaré", estado: 29},
	{id: 2922607, nome: "Nilo Peçanha", estado: 29},
	{id: 2922656, nome: "Nordestina", estado: 29},
	{id: 2922706, nome: "Nova Canaã", estado: 29},
	{id: 2922730, nome: "Nova Fátima", estado: 29},
	{id: 2922755, nome: "Nova Ibiá", estado: 29},
	{id: 2922805, nome: "Nova Itarana", estado: 29},
	{id: 2922854, nome: "Nova Redenção", estado: 29},
	{id: 2922904, nome: "Nova Soure", estado: 29},
	{id: 2923001, nome: "Nova Viçosa", estado: 29},
	{id: 2923035, nome: "Novo Horizonte", estado: 29},
	{id: 2923050, nome: "Novo Triunfo", estado: 29},
	{id: 2923100, nome: "Olindina", estado: 29},
	{id: 2923209, nome: "Oliveira dos Brejinhos", estado: 29},
	{id: 2923308, nome: "Ouriçangas", estado: 29},
	{id: 2923357, nome: "Ourolândia", estado: 29},
	{id: 2923407, nome: "Palmas de Monte Alto", estado: 29},
	{id: 2923506, nome: "Palmeiras", estado: 29},
	{id: 2923605, nome: "Paramirim", estado: 29},
	{id: 2923704, nome: "Paratinga", estado: 29},
	{id: 2923803, nome: "Paripiranga", estado: 29},
	{id: 2923902, nome: "Pau Brasil", estado: 29},
	{id: 2924009, nome: "Paulo Afonso", estado: 29},
	{id: 2924058, nome: "Pé de Serra", estado: 29},
	{id: 2924108, nome: "Pedrão", estado: 29},
	{id: 2924207, nome: "Pedro Alexandre", estado: 29},
	{id: 2924306, nome: "Piatã", estado: 29},
	{id: 2924405, nome: "Pilão Arcado", estado: 29},
	{id: 2924504, nome: "Pindaí", estado: 29},
	{id: 2924603, nome: "Pindobaçu", estado: 29},
	{id: 2924652, nome: "Pintadas", estado: 29},
	{id: 2924678, nome: "Piraí do Norte", estado: 29},
	{id: 2924702, nome: "Piripá", estado: 29},
	{id: 2924801, nome: "Piritiba", estado: 29},
	{id: 2924900, nome: "Planaltino", estado: 29},
	{id: 2925006, nome: "Planalto", estado: 29},
	{id: 2925105, nome: "Poções", estado: 29},
	{id: 2925204, nome: "Pojuca", estado: 29},
	{id: 2925253, nome: "Ponto Novo", estado: 29},
	{id: 2925303, nome: "Porto Seguro", estado: 29},
	{id: 2925402, nome: "Potiraguá", estado: 29},
	{id: 2925501, nome: "Prado", estado: 29},
	{id: 2925600, nome: "Presidente Dutra", estado: 29},
	{id: 2925709, nome: "Presidente Jânio Quadros", estado: 29},
	{id: 2925758, nome: "Presidente Tancredo Neves", estado: 29},
	{id: 2925808, nome: "Queimadas", estado: 29},
	{id: 2925907, nome: "Quijingue", estado: 29},
	{id: 2925931, nome: "Quixabeira", estado: 29},
	{id: 2925956, nome: "Rafael Jambeiro", estado: 29},
	{id: 2926004, nome: "Remanso", estado: 29},
	{id: 2926103, nome: "Retirolândia", estado: 29},
	{id: 2926202, nome: "Riachão das Neves", estado: 29},
	{id: 2926301, nome: "Riachão do Jacuípe", estado: 29},
	{id: 2926400, nome: "Riacho de Santana", estado: 29},
	{id: 2926509, nome: "Ribeira do Amparo", estado: 29},
	{id: 2926608, nome: "Ribeira do Pombal", estado: 29},
	{id: 2926657, nome: "Ribeirão do Largo", estado: 29},
	{id: 2926707, nome: "Rio de Contas", estado: 29},
	{id: 2926806, nome: "Rio do Antônio", estado: 29},
	{id: 2926905, nome: "Rio do Pires", estado: 29},
	{id: 2927002, nome: "Rio Real", estado: 29},
	{id: 2927101, nome: "Rodelas", estado: 29},
	{id: 2927200, nome: "Ruy Barbosa", estado: 29},
	{id: 2927309, nome: "Salinas da Margarida", estado: 29},
	{id: 2927408, nome: "Salvador", estado: 29},
	{id: 2927507, nome: "Santa Bárbara", estado: 29},
	{id: 2927606, nome: "Santa Brígida", estado: 29},
	{id: 2927705, nome: "Santa Cruz Cabrália", estado: 29},
	{id: 2927804, nome: "Santa Cruz da Vitória", estado: 29},
	{id: 2927903, nome: "Santa Inês", estado: 29},
	{id: 2928000, nome: "Santaluz", estado: 29},
	{id: 2928059, nome: "Santa Luzia", estado: 29},
	{id: 2928109, nome: "Santa Maria da Vitória", estado: 29},
	{id: 2928208, nome: "Santana", estado: 29},
	{id: 2928307, nome: "Santanópolis", estado: 29},
	{id: 2928406, nome: "Santa Rita de Cássia", estado: 29},
	{id: 2928505, nome: "Santa Teresinha", estado: 29},
	{id: 2928604, nome: "Santo Amaro", estado: 29},
	{id: 2928703, nome: "Santo Antônio de Jesus", estado: 29},
	{id: 2928802, nome: "Santo Estêvão", estado: 29},
	{id: 2928901, nome: "São desidério", estado: 29},
	{id: 2928950, nome: "São domingos", estado: 29},
	{id: 2929008, nome: "São Félix", estado: 29},
	{id: 2929057, nome: "São Félix do Coribe", estado: 29},
	{id: 2929107, nome: "São Felipe", estado: 29},
	{id: 2929206, nome: "São Francisco do Conde", estado: 29},
	{id: 2929255, nome: "São Gabriel", estado: 29},
	{id: 2929305, nome: "São Gonçalo dos Campos", estado: 29},
	{id: 2929354, nome: "São José da Vitória", estado: 29},
	{id: 2929370, nome: "São José do Jacuípe", estado: 29},
	{id: 2929404, nome: "São Miguel das Matas", estado: 29},
	{id: 2929503, nome: "São Sebastião do Passé", estado: 29},
	{id: 2929602, nome: "Sapeaçu", estado: 29},
	{id: 2929701, nome: "Sátiro Dias", estado: 29},
	{id: 2929750, nome: "Saubara", estado: 29},
	{id: 2929800, nome: "Saúde", estado: 29},
	{id: 2929909, nome: "Seabra", estado: 29},
	{id: 2930006, nome: "Sebastião Laranjeiras", estado: 29},
	{id: 2930105, nome: "Senhor do Bonfim", estado: 29},
	{id: 2930154, nome: "Serra do Ramalho", estado: 29},
	{id: 2930204, nome: "Sento Sé", estado: 29},
	{id: 2930303, nome: "Serra dourada", estado: 29},
	{id: 2930402, nome: "Serra Preta", estado: 29},
	{id: 2930501, nome: "Serrinha", estado: 29},
	{id: 2930600, nome: "Serrolândia", estado: 29},
	{id: 2930709, nome: "Simões Filho", estado: 29},
	{id: 2930758, nome: "Sítio do Mato", estado: 29},
	{id: 2930766, nome: "Sítio do Quinto", estado: 29},
	{id: 2930774, nome: "Sobradinho", estado: 29},
	{id: 2930808, nome: "Souto Soares", estado: 29},
	{id: 2930907, nome: "Tabocas do Brejo Velho", estado: 29},
	{id: 2931004, nome: "Tanhaçu", estado: 29},
	{id: 2931053, nome: "Tanque Novo", estado: 29},
	{id: 2931103, nome: "Tanquinho", estado: 29},
	{id: 2931202, nome: "Taperoá", estado: 29},
	{id: 2931301, nome: "Tapiramutá", estado: 29},
	{id: 2931350, nome: "Teixeira de Freitas", estado: 29},
	{id: 2931400, nome: "Teodoro Sampaio", estado: 29},
	{id: 2931509, nome: "Teofilândia", estado: 29},
	{id: 2931608, nome: "Teolândia", estado: 29},
	{id: 2931707, nome: "Terra Nova", estado: 29},
	{id: 2931806, nome: "Tremedal", estado: 29},
	{id: 2931905, nome: "Tucano", estado: 29},
	{id: 2932002, nome: "Uauá", estado: 29},
	{id: 2932101, nome: "Ubaíra", estado: 29},
	{id: 2932200, nome: "Ubaitaba", estado: 29},
	{id: 2932309, nome: "Ubatã", estado: 29},
	{id: 2932408, nome: "Uibaí", estado: 29},
	{id: 2932457, nome: "Umburanas", estado: 29},
	{id: 2932507, nome: "Una", estado: 29},
	{id: 2932606, nome: "Urandi", estado: 29},
	{id: 2932705, nome: "Uruçuca", estado: 29},
	{id: 2932804, nome: "Utinga", estado: 29},
	{id: 2932903, nome: "Valença", estado: 29},
	{id: 2933000, nome: "Valente", estado: 29},
	{id: 2933059, nome: "Várzea da Roça", estado: 29},
	{id: 2933109, nome: "Várzea do Poço", estado: 29},
	{id: 2933158, nome: "Várzea Nova", estado: 29},
	{id: 2933174, nome: "Varzedo", estado: 29},
	{id: 2933208, nome: "Vera Cruz", estado: 29},
	{id: 2933257, nome: "Vereda", estado: 29},
	{id: 2933307, nome: "Vitória da Conquista", estado: 29},
	{id: 2933406, nome: "Wagner", estado: 29},
	{id: 2933455, nome: "Wanderley", estado: 29},
	{id: 2933505, nome: "Wenceslau Guimarães", estado: 29},
	{id: 2933604, nome: "Xique-Xique", estado: 29},
	{id: 3100104, nome: "Abadia dos dourados", estado: 31},
	{id: 3100203, nome: "Abaeté", estado: 31},
	{id: 3100302, nome: "Abre Campo", estado: 31},
	{id: 3100401, nome: "Acaiaca", estado: 31},
	{id: 3100500, nome: "Açucena", estado: 31},
	{id: 3100609, nome: "Água Boa", estado: 31},
	{id: 3100708, nome: "Água Comprida", estado: 31},
	{id: 3100807, nome: "Aguanil", estado: 31},
	{id: 3100906, nome: "Águas Formosas", estado: 31},
	{id: 3101003, nome: "Águas Vermelhas", estado: 31},
	{id: 3101102, nome: "Aimorés", estado: 31},
	{id: 3101201, nome: "Aiuruoca", estado: 31},
	{id: 3101300, nome: "Alagoa", estado: 31},
	{id: 3101409, nome: "Albertina", estado: 31},
	{id: 3101508, nome: "Além Paraíba", estado: 31},
	{id: 3101607, nome: "Alfenas", estado: 31},
	{id: 3101631, nome: "Alfredo Vasconcelos", estado: 31},
	{id: 3101706, nome: "Almenara", estado: 31},
	{id: 3101805, nome: "Alpercata", estado: 31},
	{id: 3101904, nome: "Alpinópolis", estado: 31},
	{id: 3102001, nome: "Alterosa", estado: 31},
	{id: 3102050, nome: "Alto Caparaó", estado: 31},
	{id: 3102100, nome: "Alto Rio doce", estado: 31},
	{id: 3102209, nome: "Alvarenga", estado: 31},
	{id: 3102308, nome: "Alvinópolis", estado: 31},
	{id: 3102407, nome: "Alvorada de Minas", estado: 31},
	{id: 3102506, nome: "Amparo do Serra", estado: 31},
	{id: 3102605, nome: "Andradas", estado: 31},
	{id: 3102704, nome: "Cachoeira de Pajeú", estado: 31},
	{id: 3102803, nome: "Andrelândia", estado: 31},
	{id: 3102852, nome: "Angelândia", estado: 31},
	{id: 3102902, nome: "Antônio Carlos", estado: 31},
	{id: 3103009, nome: "Antônio Dias", estado: 31},
	{id: 3103108, nome: "Antônio Prado de Minas", estado: 31},
	{id: 3103207, nome: "Araçaí", estado: 31},
	{id: 3103306, nome: "Aracitaba", estado: 31},
	{id: 3103405, nome: "Araçuaí", estado: 31},
	{id: 3103504, nome: "Araguari", estado: 31},
	{id: 3103603, nome: "Arantina", estado: 31},
	{id: 3103702, nome: "Araponga", estado: 31},
	{id: 3103751, nome: "Araporã", estado: 31},
	{id: 3103801, nome: "Arapuá", estado: 31},
	{id: 3103900, nome: "Araújos", estado: 31},
	{id: 3104007, nome: "Araxá", estado: 31},
	{id: 3104106, nome: "Arceburgo", estado: 31},
	{id: 3104205, nome: "Arcos", estado: 31},
	{id: 3104304, nome: "Areado", estado: 31},
	{id: 3104403, nome: "Argirita", estado: 31},
	{id: 3104452, nome: "Aricanduva", estado: 31},
	{id: 3104502, nome: "Arinos", estado: 31},
	{id: 3104601, nome: "Astolfo Dutra", estado: 31},
	{id: 3104700, nome: "Ataléia", estado: 31},
	{id: 3104809, nome: "Augusto de Lima", estado: 31},
	{id: 3104908, nome: "Baependi", estado: 31},
	{id: 3105004, nome: "Baldim", estado: 31},
	{id: 3105103, nome: "Bambuí", estado: 31},
	{id: 3105202, nome: "Bandeira", estado: 31},
	{id: 3105301, nome: "Bandeira do Sul", estado: 31},
	{id: 3105400, nome: "Barão de Cocais", estado: 31},
	{id: 3105509, nome: "Barão de Monte Alto", estado: 31},
	{id: 3105608, nome: "Barbacena", estado: 31},
	{id: 3105707, nome: "Barra Longa", estado: 31},
	{id: 3105905, nome: "Barroso", estado: 31},
	{id: 3106002, nome: "Bela Vista de Minas", estado: 31},
	{id: 3106101, nome: "Belmiro Braga", estado: 31},
	{id: 3106200, nome: "Belo Horizonte", estado: 31},
	{id: 3106309, nome: "Belo Oriente", estado: 31},
	{id: 3106408, nome: "Belo Vale", estado: 31},
	{id: 3106507, nome: "Berilo", estado: 31},
	{id: 3106606, nome: "Bertópolis", estado: 31},
	{id: 3106655, nome: "Berizal", estado: 31},
	{id: 3106705, nome: "Betim", estado: 31},
	{id: 3106804, nome: "Bias Fortes", estado: 31},
	{id: 3106903, nome: "Bicas", estado: 31},
	{id: 3107000, nome: "Biquinhas", estado: 31},
	{id: 3107109, nome: "Boa Esperança", estado: 31},
	{id: 3107208, nome: "Bocaina de Minas", estado: 31},
	{id: 3107307, nome: "Bocaiúva", estado: 31},
	{id: 3107406, nome: "Bom despacho", estado: 31},
	{id: 3107505, nome: "Bom Jardim de Minas", estado: 31},
	{id: 3107604, nome: "Bom Jesus da Penha", estado: 31},
	{id: 3107703, nome: "Bom Jesus do Amparo", estado: 31},
	{id: 3107802, nome: "Bom Jesus do Galho", estado: 31},
	{id: 3107901, nome: "Bom Repouso", estado: 31},
	{id: 3108008, nome: "Bom Sucesso", estado: 31},
	{id: 3108107, nome: "Bonfim", estado: 31},
	{id: 3108206, nome: "Bonfinópolis de Minas", estado: 31},
	{id: 3108255, nome: "Bonito de Minas", estado: 31},
	{id: 3108305, nome: "Borda da Mata", estado: 31},
	{id: 3108404, nome: "Botelhos", estado: 31},
	{id: 3108503, nome: "Botumirim", estado: 31},
	{id: 3108552, nome: "Brasilândia de Minas", estado: 31},
	{id: 3108602, nome: "Brasília de Minas", estado: 31},
	{id: 3108701, nome: "Brás Pires", estado: 31},
	{id: 3108800, nome: "Braúnas", estado: 31},
	{id: 3108909, nome: "Brazópolis", estado: 31},
	{id: 3109006, nome: "Brumadinho", estado: 31},
	{id: 3109105, nome: "Bueno Brandão", estado: 31},
	{id: 3109204, nome: "Buenópolis", estado: 31},
	{id: 3109253, nome: "Bugre", estado: 31},
	{id: 3109303, nome: "Buritis", estado: 31},
	{id: 3109402, nome: "Buritizeiro", estado: 31},
	{id: 3109451, nome: "Cabeceira Grande", estado: 31},
	{id: 3109501, nome: "Cabo Verde", estado: 31},
	{id: 3109600, nome: "Cachoeira da Prata", estado: 31},
	{id: 3109709, nome: "Cachoeira de Minas", estado: 31},
	{id: 3109808, nome: "Cachoeira dourada", estado: 31},
	{id: 3109907, nome: "Caetanópolis", estado: 31},
	{id: 3110004, nome: "Caeté", estado: 31},
	{id: 3110103, nome: "Caiana", estado: 31},
	{id: 3110202, nome: "Cajuri", estado: 31},
	{id: 3110301, nome: "Caldas", estado: 31},
	{id: 3110400, nome: "Camacho", estado: 31},
	{id: 3110509, nome: "Camanducaia", estado: 31},
	{id: 3110608, nome: "Cambuí", estado: 31},
	{id: 3110707, nome: "Cambuquira", estado: 31},
	{id: 3110806, nome: "Campanário", estado: 31},
	{id: 3110905, nome: "Campanha", estado: 31},
	{id: 3111002, nome: "Campestre", estado: 31},
	{id: 3111101, nome: "Campina Verde", estado: 31},
	{id: 3111150, nome: "Campo Azul", estado: 31},
	{id: 3111200, nome: "Campo Belo", estado: 31},
	{id: 3111309, nome: "Campo do Meio", estado: 31},
	{id: 3111408, nome: "Campo Florido", estado: 31},
	{id: 3111507, nome: "Campos Altos", estado: 31},
	{id: 3111606, nome: "Campos Gerais", estado: 31},
	{id: 3111705, nome: "Canaã", estado: 31},
	{id: 3111804, nome: "Canápolis", estado: 31},
	{id: 3111903, nome: "Cana Verde", estado: 31},
	{id: 3112000, nome: "Candeias", estado: 31},
	{id: 3112059, nome: "Cantagalo", estado: 31},
	{id: 3112109, nome: "Caparaó", estado: 31},
	{id: 3112208, nome: "Capela Nova", estado: 31},
	{id: 3112307, nome: "Capelinha", estado: 31},
	{id: 3112406, nome: "Capetinga", estado: 31},
	{id: 3112505, nome: "Capim Branco", estado: 31},
	{id: 3112604, nome: "Capinópolis", estado: 31},
	{id: 3112653, nome: "Capitão Andrade", estado: 31},
	{id: 3112703, nome: "Capitão Enéas", estado: 31},
	{id: 3112802, nome: "Capitólio", estado: 31},
	{id: 3112901, nome: "Caputira", estado: 31},
	{id: 3113008, nome: "Caraí", estado: 31},
	{id: 3113107, nome: "Caranaíba", estado: 31},
	{id: 3113206, nome: "Carandaí", estado: 31},
	{id: 3113305, nome: "Carangola", estado: 31},
	{id: 3113404, nome: "Caratinga", estado: 31},
	{id: 3113503, nome: "Carbonita", estado: 31},
	{id: 3113602, nome: "Careaçu", estado: 31},
	{id: 3113701, nome: "Carlos Chagas", estado: 31},
	{id: 3113800, nome: "Carmésia", estado: 31},
	{id: 3113909, nome: "Carmo da Cachoeira", estado: 31},
	{id: 3114006, nome: "Carmo da Mata", estado: 31},
	{id: 3114105, nome: "Carmo de Minas", estado: 31},
	{id: 3114204, nome: "Carmo do Cajuru", estado: 31},
	{id: 3114303, nome: "Carmo do Paranaíba", estado: 31},
	{id: 3114402, nome: "Carmo do Rio Claro", estado: 31},
	{id: 3114501, nome: "Carmópolis de Minas", estado: 31},
	{id: 3114550, nome: "Carneirinho", estado: 31},
	{id: 3114600, nome: "Carrancas", estado: 31},
	{id: 3114709, nome: "Carvalhópolis", estado: 31},
	{id: 3114808, nome: "Carvalhos", estado: 31},
	{id: 3114907, nome: "Casa Grande", estado: 31},
	{id: 3115003, nome: "Cascalho Rico", estado: 31},
	{id: 3115102, nome: "Cássia", estado: 31},
	{id: 3115201, nome: "Conceição da Barra de Minas", estado: 31},
	{id: 3115300, nome: "Cataguases", estado: 31},
	{id: 3115359, nome: "Catas Altas", estado: 31},
	{id: 3115409, nome: "Catas Altas da Noruega", estado: 31},
	{id: 3115458, nome: "Catuji", estado: 31},
	{id: 3115474, nome: "Catuti", estado: 31},
	{id: 3115508, nome: "Caxambu", estado: 31},
	{id: 3115607, nome: "Cedro do Abaeté", estado: 31},
	{id: 3115706, nome: "Central de Minas", estado: 31},
	{id: 3115805, nome: "Centralina", estado: 31},
	{id: 3115904, nome: "Chácara", estado: 31},
	{id: 3116001, nome: "Chalé", estado: 31},
	{id: 3116100, nome: "Chapada do Norte", estado: 31},
	{id: 3116159, nome: "Chapada Gaúcha", estado: 31},
	{id: 3116209, nome: "Chiador", estado: 31},
	{id: 3116308, nome: "Cipotânea", estado: 31},
	{id: 3116407, nome: "Claraval", estado: 31},
	{id: 3116506, nome: "Claro dos Poções", estado: 31},
	{id: 3116605, nome: "Cláudio", estado: 31},
	{id: 3116704, nome: "Coimbra", estado: 31},
	{id: 3116803, nome: "Coluna", estado: 31},
	{id: 3116902, nome: "Comendador Gomes", estado: 31},
	{id: 3117009, nome: "Comercinho", estado: 31},
	{id: 3117108, nome: "Conceição da Aparecida", estado: 31},
	{id: 3117207, nome: "Conceição das Pedras", estado: 31},
	{id: 3117306, nome: "Conceição das Alagoas", estado: 31},
	{id: 3117405, nome: "Conceição de Ipanema", estado: 31},
	{id: 3117504, nome: "Conceição do Mato dentro", estado: 31},
	{id: 3117603, nome: "Conceição do Pará", estado: 31},
	{id: 3117702, nome: "Conceição do Rio Verde", estado: 31},
	{id: 3117801, nome: "Conceição dos Ouros", estado: 31},
	{id: 3117836, nome: "Cônego Marinho", estado: 31},
	{id: 3117876, nome: "Confins", estado: 31},
	{id: 3117900, nome: "Congonhal", estado: 31},
	{id: 3118007, nome: "Congonhas", estado: 31},
	{id: 3118106, nome: "Congonhas do Norte", estado: 31},
	{id: 3118205, nome: "Conquista", estado: 31},
	{id: 3118304, nome: "Conselheiro Lafaiete", estado: 31},
	{id: 3118403, nome: "Conselheiro Pena", estado: 31},
	{id: 3118502, nome: "Consolação", estado: 31},
	{id: 3118601, nome: "Contagem", estado: 31},
	{id: 3118700, nome: "Coqueiral", estado: 31},
	{id: 3118809, nome: "Coração de Jesus", estado: 31},
	{id: 3118908, nome: "Cordisburgo", estado: 31},
	{id: 3119005, nome: "Cordislândia", estado: 31},
	{id: 3119104, nome: "Corinto", estado: 31},
	{id: 3119203, nome: "Coroaci", estado: 31},
	{id: 3119302, nome: "Coromandel", estado: 31},
	{id: 3119401, nome: "Coronel Fabriciano", estado: 31},
	{id: 3119500, nome: "Coronel Murta", estado: 31},
	{id: 3119609, nome: "Coronel Pacheco", estado: 31},
	{id: 3119708, nome: "Coronel Xavier Chaves", estado: 31},
	{id: 3119807, nome: "Córrego danta", estado: 31},
	{id: 3119906, nome: "Córrego do Bom Jesus", estado: 31},
	{id: 3119955, nome: "Córrego Fundo", estado: 31},
	{id: 3120003, nome: "Córrego Novo", estado: 31},
	{id: 3120102, nome: "Couto de Magalhães de Minas", estado: 31},
	{id: 3120151, nome: "Crisólita", estado: 31},
	{id: 3120201, nome: "Cristais", estado: 31},
	{id: 3120300, nome: "Cristália", estado: 31},
	{id: 3120409, nome: "Cristiano Otoni", estado: 31},
	{id: 3120508, nome: "Cristina", estado: 31},
	{id: 3120607, nome: "Crucilândia", estado: 31},
	{id: 3120706, nome: "Cruzeiro da Fortaleza", estado: 31},
	{id: 3120805, nome: "Cruzília", estado: 31},
	{id: 3120839, nome: "Cuparaque", estado: 31},
	{id: 3120870, nome: "Curral de dentro", estado: 31},
	{id: 3120904, nome: "Curvelo", estado: 31},
	{id: 3121001, nome: "datas", estado: 31},
	{id: 3121100, nome: "delfim Moreira", estado: 31},
	{id: 3121209, nome: "delfinópolis", estado: 31},
	{id: 3121258, nome: "delta", estado: 31},
	{id: 3121308, nome: "descoberto", estado: 31},
	{id: 3121407, nome: "desterro de Entre Rios", estado: 31},
	{id: 3121506, nome: "desterro do Melo", estado: 31},
	{id: 3121605, nome: "Diamantina", estado: 31},
	{id: 3121704, nome: "Diogo de Vasconcelos", estado: 31},
	{id: 3121803, nome: "Dionísio", estado: 31},
	{id: 3121902, nome: "Divinésia", estado: 31},
	{id: 3122009, nome: "Divino", estado: 31},
	{id: 3122108, nome: "Divino das Laranjeiras", estado: 31},
	{id: 3122207, nome: "Divinolândia de Minas", estado: 31},
	{id: 3122306, nome: "Divinópolis", estado: 31},
	{id: 3122355, nome: "Divisa Alegre", estado: 31},
	{id: 3122405, nome: "Divisa Nova", estado: 31},
	{id: 3122454, nome: "Divisópolis", estado: 31},
	{id: 3122470, nome: "dom Bosco", estado: 31},
	{id: 3122504, nome: "dom Cavati", estado: 31},
	{id: 3122603, nome: "dom Joaquim", estado: 31},
	{id: 3122702, nome: "dom Silvério", estado: 31},
	{id: 3122801, nome: "dom Viçoso", estado: 31},
	{id: 3122900, nome: "dona Eusébia", estado: 31},
	{id: 3123007, nome: "dores de Campos", estado: 31},
	{id: 3123106, nome: "dores de Guanhães", estado: 31},
	{id: 3123205, nome: "dores do Indaiá", estado: 31},
	{id: 3123304, nome: "dores do Turvo", estado: 31},
	{id: 3123403, nome: "doresópolis", estado: 31},
	{id: 3123502, nome: "douradoquara", estado: 31},
	{id: 3123528, nome: "Durandé", estado: 31},
	{id: 3123601, nome: "Elói Mendes", estado: 31},
	{id: 3123700, nome: "Engenheiro Caldas", estado: 31},
	{id: 3123809, nome: "Engenheiro Navarro", estado: 31},
	{id: 3123858, nome: "Entre Folhas", estado: 31},
	{id: 3123908, nome: "Entre Rios de Minas", estado: 31},
	{id: 3124005, nome: "Ervália", estado: 31},
	{id: 3124104, nome: "Esmeraldas", estado: 31},
	{id: 3124203, nome: "Espera Feliz", estado: 31},
	{id: 3124302, nome: "Espinosa", estado: 31},
	{id: 3124401, nome: "Espírito Santo do dourado", estado: 31},
	{id: 3124500, nome: "Estiva", estado: 31},
	{id: 3124609, nome: "Estrela dalva", estado: 31},
	{id: 3124708, nome: "Estrela do Indaiá", estado: 31},
	{id: 3124807, nome: "Estrela do Sul", estado: 31},
	{id: 3124906, nome: "Eugenópolis", estado: 31},
	{id: 3125002, nome: "Ewbank da Câmara", estado: 31},
	{id: 3125101, nome: "Extrema", estado: 31},
	{id: 3125200, nome: "Fama", estado: 31},
	{id: 3125309, nome: "Faria Lemos", estado: 31},
	{id: 3125408, nome: "Felício dos Santos", estado: 31},
	{id: 3125507, nome: "São Gonçalo do Rio Preto", estado: 31},
	{id: 3125606, nome: "Felisburgo", estado: 31},
	{id: 3125705, nome: "Felixlândia", estado: 31},
	{id: 3125804, nome: "Fernandes Tourinho", estado: 31},
	{id: 3125903, nome: "Ferros", estado: 31},
	{id: 3125952, nome: "Fervedouro", estado: 31},
	{id: 3126000, nome: "Florestal", estado: 31},
	{id: 3126109, nome: "Formiga", estado: 31},
	{id: 3126208, nome: "Formoso", estado: 31},
	{id: 3126307, nome: "Fortaleza de Minas", estado: 31},
	{id: 3126406, nome: "Fortuna de Minas", estado: 31},
	{id: 3126505, nome: "Francisco Badaró", estado: 31},
	{id: 3126604, nome: "Francisco Dumont", estado: 31},
	{id: 3126703, nome: "Francisco Sá", estado: 31},
	{id: 3126752, nome: "Franciscópolis", estado: 31},
	{id: 3126802, nome: "Frei Gaspar", estado: 31},
	{id: 3126901, nome: "Frei Inocêncio", estado: 31},
	{id: 3126950, nome: "Frei Lagonegro", estado: 31},
	{id: 3127008, nome: "Fronteira", estado: 31},
	{id: 3127057, nome: "Fronteira dos Vales", estado: 31},
	{id: 3127073, nome: "Fruta de Leite", estado: 31},
	{id: 3127107, nome: "Frutal", estado: 31},
	{id: 3127206, nome: "Funilândia", estado: 31},
	{id: 3127305, nome: "Galiléia", estado: 31},
	{id: 3127339, nome: "Gameleiras", estado: 31},
	{id: 3127354, nome: "Glaucilândia", estado: 31},
	{id: 3127370, nome: "Goiabeira", estado: 31},
	{id: 3127388, nome: "Goianá", estado: 31},
	{id: 3127404, nome: "Gonçalves", estado: 31},
	{id: 3127503, nome: "Gonzaga", estado: 31},
	{id: 3127602, nome: "Gouveia", estado: 31},
	{id: 3127701, nome: "Governador Valadares", estado: 31},
	{id: 3127800, nome: "Grão Mogol", estado: 31},
	{id: 3127909, nome: "Grupiara", estado: 31},
	{id: 3128006, nome: "Guanhães", estado: 31},
	{id: 3128105, nome: "Guapé", estado: 31},
	{id: 3128204, nome: "Guaraciaba", estado: 31},
	{id: 3128253, nome: "Guaraciama", estado: 31},
	{id: 3128303, nome: "Guaranésia", estado: 31},
	{id: 3128402, nome: "Guarani", estado: 31},
	{id: 3128501, nome: "Guarará", estado: 31},
	{id: 3128600, nome: "Guarda-Mor", estado: 31},
	{id: 3128709, nome: "Guaxupé", estado: 31},
	{id: 3128808, nome: "Guidoval", estado: 31},
	{id: 3128907, nome: "Guimarânia", estado: 31},
	{id: 3129004, nome: "Guiricema", estado: 31},
	{id: 3129103, nome: "Gurinhatã", estado: 31},
	{id: 3129202, nome: "Heliodora", estado: 31},
	{id: 3129301, nome: "Iapu", estado: 31},
	{id: 3129400, nome: "Ibertioga", estado: 31},
	{id: 3129509, nome: "Ibiá", estado: 31},
	{id: 3129608, nome: "Ibiaí", estado: 31},
	{id: 3129657, nome: "Ibiracatu", estado: 31},
	{id: 3129707, nome: "Ibiraci", estado: 31},
	{id: 3129806, nome: "Ibirité", estado: 31},
	{id: 3129905, nome: "Ibitiúra de Minas", estado: 31},
	{id: 3130002, nome: "Ibituruna", estado: 31},
	{id: 3130051, nome: "Icaraí de Minas", estado: 31},
	{id: 3130101, nome: "Igarapé", estado: 31},
	{id: 3130200, nome: "Igaratinga", estado: 31},
	{id: 3130309, nome: "Iguatama", estado: 31},
	{id: 3130408, nome: "Ijaci", estado: 31},
	{id: 3130507, nome: "Ilicínea", estado: 31},
	{id: 3130556, nome: "Imbé de Minas", estado: 31},
	{id: 3130606, nome: "Inconfidentes", estado: 31},
	{id: 3130655, nome: "Indaiabira", estado: 31},
	{id: 3130705, nome: "Indianópolis", estado: 31},
	{id: 3130804, nome: "Ingaí", estado: 31},
	{id: 3130903, nome: "Inhapim", estado: 31},
	{id: 3131000, nome: "Inhaúma", estado: 31},
	{id: 3131109, nome: "Inimutaba", estado: 31},
	{id: 3131158, nome: "Ipaba", estado: 31},
	{id: 3131208, nome: "Ipanema", estado: 31},
	{id: 3131307, nome: "Ipatinga", estado: 31},
	{id: 3131406, nome: "Ipiaçu", estado: 31},
	{id: 3131505, nome: "Ipuiúna", estado: 31},
	{id: 3131604, nome: "Iraí de Minas", estado: 31},
	{id: 3131703, nome: "Itabira", estado: 31},
	{id: 3131802, nome: "Itabirinha", estado: 31},
	{id: 3131901, nome: "Itabirito", estado: 31},
	{id: 3132008, nome: "Itacambira", estado: 31},
	{id: 3132107, nome: "Itacarambi", estado: 31},
	{id: 3132206, nome: "Itaguara", estado: 31},
	{id: 3132305, nome: "Itaipé", estado: 31},
	{id: 3132404, nome: "Itajubá", estado: 31},
	{id: 3132503, nome: "Itamarandiba", estado: 31},
	{id: 3132602, nome: "Itamarati de Minas", estado: 31},
	{id: 3132701, nome: "Itambacuri", estado: 31},
	{id: 3132800, nome: "Itambé do Mato dentro", estado: 31},
	{id: 3132909, nome: "Itamogi", estado: 31},
	{id: 3133006, nome: "Itamonte", estado: 31},
	{id: 3133105, nome: "Itanhandu", estado: 31},
	{id: 3133204, nome: "Itanhomi", estado: 31},
	{id: 3133303, nome: "Itaobim", estado: 31},
	{id: 3133402, nome: "Itapagipe", estado: 31},
	{id: 3133501, nome: "Itapecerica", estado: 31},
	{id: 3133600, nome: "Itapeva", estado: 31},
	{id: 3133709, nome: "Itatiaiuçu", estado: 31},
	{id: 3133758, nome: "Itaú de Minas", estado: 31},
	{id: 3133808, nome: "Itaúna", estado: 31},
	{id: 3133907, nome: "Itaverava", estado: 31},
	{id: 3134004, nome: "Itinga", estado: 31},
	{id: 3134103, nome: "Itueta", estado: 31},
	{id: 3134202, nome: "Ituiutaba", estado: 31},
	{id: 3134301, nome: "Itumirim", estado: 31},
	{id: 3134400, nome: "Iturama", estado: 31},
	{id: 3134509, nome: "Itutinga", estado: 31},
	{id: 3134608, nome: "Jaboticatubas", estado: 31},
	{id: 3134707, nome: "Jacinto", estado: 31},
	{id: 3134806, nome: "Jacuí", estado: 31},
	{id: 3134905, nome: "Jacutinga", estado: 31},
	{id: 3135001, nome: "Jaguaraçu", estado: 31},
	{id: 3135050, nome: "Jaíba", estado: 31},
	{id: 3135076, nome: "Jampruca", estado: 31},
	{id: 3135100, nome: "Janaúba", estado: 31},
	{id: 3135209, nome: "Januária", estado: 31},
	{id: 3135308, nome: "Japaraíba", estado: 31},
	{id: 3135357, nome: "Japonvar", estado: 31},
	{id: 3135407, nome: "Jeceaba", estado: 31},
	{id: 3135456, nome: "Jenipapo de Minas", estado: 31},
	{id: 3135506, nome: "Jequeri", estado: 31},
	{id: 3135605, nome: "Jequitaí", estado: 31},
	{id: 3135704, nome: "Jequitibá", estado: 31},
	{id: 3135803, nome: "Jequitinhonha", estado: 31},
	{id: 3135902, nome: "Jesuânia", estado: 31},
	{id: 3136009, nome: "Joaíma", estado: 31},
	{id: 3136108, nome: "Joanésia", estado: 31},
	{id: 3136207, nome: "João Monlevade", estado: 31},
	{id: 3136306, nome: "João Pinheiro", estado: 31},
	{id: 3136405, nome: "Joaquim Felício", estado: 31},
	{id: 3136504, nome: "Jordânia", estado: 31},
	{id: 3136520, nome: "José Gonçalves de Minas", estado: 31},
	{id: 3136553, nome: "José Raydan", estado: 31},
	{id: 3136579, nome: "Josenópolis", estado: 31},
	{id: 3136603, nome: "Nova União", estado: 31},
	{id: 3136652, nome: "Juatuba", estado: 31},
	{id: 3136702, nome: "Juiz de Fora", estado: 31},
	{id: 3136801, nome: "Juramento", estado: 31},
	{id: 3136900, nome: "Juruaia", estado: 31},
	{id: 3136959, nome: "Juvenília", estado: 31},
	{id: 3137007, nome: "Ladainha", estado: 31},
	{id: 3137106, nome: "Lagamar", estado: 31},
	{id: 3137205, nome: "Lagoa da Prata", estado: 31},
	{id: 3137304, nome: "Lagoa dos Patos", estado: 31},
	{id: 3137403, nome: "Lagoa dourada", estado: 31},
	{id: 3137502, nome: "Lagoa Formosa", estado: 31},
	{id: 3137536, nome: "Lagoa Grande", estado: 31},
	{id: 3137601, nome: "Lagoa Santa", estado: 31},
	{id: 3137700, nome: "Lajinha", estado: 31},
	{id: 3137809, nome: "Lambari", estado: 31},
	{id: 3137908, nome: "Lamim", estado: 31},
	{id: 3138005, nome: "Laranjal", estado: 31},
	{id: 3138104, nome: "Lassance", estado: 31},
	{id: 3138203, nome: "Lavras", estado: 31},
	{id: 3138302, nome: "Leandro Ferreira", estado: 31},
	{id: 3138351, nome: "Leme do Prado", estado: 31},
	{id: 3138401, nome: "Leopoldina", estado: 31},
	{id: 3138500, nome: "Liberdade", estado: 31},
	{id: 3138609, nome: "Lima Duarte", estado: 31},
	{id: 3138625, nome: "Limeira do Oeste", estado: 31},
	{id: 3138658, nome: "Lontra", estado: 31},
	{id: 3138674, nome: "Luisburgo", estado: 31},
	{id: 3138682, nome: "Luislândia", estado: 31},
	{id: 3138708, nome: "Luminárias", estado: 31},
	{id: 3138807, nome: "Luz", estado: 31},
	{id: 3138906, nome: "Machacalis", estado: 31},
	{id: 3139003, nome: "Machado", estado: 31},
	{id: 3139102, nome: "Madre de deus de Minas", estado: 31},
	{id: 3139201, nome: "Malacacheta", estado: 31},
	{id: 3139250, nome: "Mamonas", estado: 31},
	{id: 3139300, nome: "Manga", estado: 31},
	{id: 3139409, nome: "Manhuaçu", estado: 31},
	{id: 3139508, nome: "Manhumirim", estado: 31},
	{id: 3139607, nome: "Mantena", estado: 31},
	{id: 3139706, nome: "Maravilhas", estado: 31},
	{id: 3139805, nome: "Mar de Espanha", estado: 31},
	{id: 3139904, nome: "Maria da Fé", estado: 31},
	{id: 3140001, nome: "Mariana", estado: 31},
	{id: 3140100, nome: "Marilac", estado: 31},
	{id: 3140159, nome: "Mário Campos", estado: 31},
	{id: 3140209, nome: "Maripá de Minas", estado: 31},
	{id: 3140308, nome: "Marliéria", estado: 31},
	{id: 3140407, nome: "Marmelópolis", estado: 31},
	{id: 3140506, nome: "Martinho Campos", estado: 31},
	{id: 3140530, nome: "Martins Soares", estado: 31},
	{id: 3140555, nome: "Mata Verde", estado: 31},
	{id: 3140605, nome: "Materlândia", estado: 31},
	{id: 3140704, nome: "Mateus Leme", estado: 31},
	{id: 3140803, nome: "Matias Barbosa", estado: 31},
	{id: 3140852, nome: "Matias Cardoso", estado: 31},
	{id: 3140902, nome: "Matipó", estado: 31},
	{id: 3141009, nome: "Mato Verde", estado: 31},
	{id: 3141108, nome: "Matozinhos", estado: 31},
	{id: 3141207, nome: "Matutina", estado: 31},
	{id: 3141306, nome: "Medeiros", estado: 31},
	{id: 3141405, nome: "Medina", estado: 31},
	{id: 3141504, nome: "Mendes Pimentel", estado: 31},
	{id: 3141603, nome: "Mercês", estado: 31},
	{id: 3141702, nome: "Mesquita", estado: 31},
	{id: 3141801, nome: "Minas Novas", estado: 31},
	{id: 3141900, nome: "Minduri", estado: 31},
	{id: 3142007, nome: "Mirabela", estado: 31},
	{id: 3142106, nome: "Miradouro", estado: 31},
	{id: 3142205, nome: "Miraí", estado: 31},
	{id: 3142254, nome: "Miravânia", estado: 31},
	{id: 3142304, nome: "Moeda", estado: 31},
	{id: 3142403, nome: "Moema", estado: 31},
	{id: 3142502, nome: "Monjolos", estado: 31},
	{id: 3142601, nome: "Monsenhor Paulo", estado: 31},
	{id: 3142700, nome: "Montalvânia", estado: 31},
	{id: 3142809, nome: "Monte Alegre de Minas", estado: 31},
	{id: 3142908, nome: "Monte Azul", estado: 31},
	{id: 3143005, nome: "Monte Belo", estado: 31},
	{id: 3143104, nome: "Monte Carmelo", estado: 31},
	{id: 3143153, nome: "Monte Formoso", estado: 31},
	{id: 3143203, nome: "Monte Santo de Minas", estado: 31},
	{id: 3143302, nome: "Montes Claros", estado: 31},
	{id: 3143401, nome: "Monte Sião", estado: 31},
	{id: 3143450, nome: "Montezuma", estado: 31},
	{id: 3143500, nome: "Morada Nova de Minas", estado: 31},
	{id: 3143609, nome: "Morro da Garça", estado: 31},
	{id: 3143708, nome: "Morro do Pilar", estado: 31},
	{id: 3143807, nome: "Munhoz", estado: 31},
	{id: 3143906, nome: "Muriaé", estado: 31},
	{id: 3144003, nome: "Mutum", estado: 31},
	{id: 3144102, nome: "Muzambinho", estado: 31},
	{id: 3144201, nome: "Nacip Raydan", estado: 31},
	{id: 3144300, nome: "Nanuque", estado: 31},
	{id: 3144359, nome: "Naque", estado: 31},
	{id: 3144375, nome: "Natalândia", estado: 31},
	{id: 3144409, nome: "Natércia", estado: 31},
	{id: 3144508, nome: "Nazareno", estado: 31},
	{id: 3144607, nome: "Nepomuceno", estado: 31},
	{id: 3144656, nome: "Ninheira", estado: 31},
	{id: 3144672, nome: "Nova Belém", estado: 31},
	{id: 3144706, nome: "Nova Era", estado: 31},
	{id: 3144805, nome: "Nova Lima", estado: 31},
	{id: 3144904, nome: "Nova Módica", estado: 31},
	{id: 3145000, nome: "Nova Ponte", estado: 31},
	{id: 3145059, nome: "Nova Porteirinha", estado: 31},
	{id: 3145109, nome: "Nova Resende", estado: 31},
	{id: 3145208, nome: "Nova Serrana", estado: 31},
	{id: 3145307, nome: "Novo Cruzeiro", estado: 31},
	{id: 3145356, nome: "Novo Oriente de Minas", estado: 31},
	{id: 3145372, nome: "Novorizonte", estado: 31},
	{id: 3145406, nome: "Olaria", estado: 31},
	{id: 3145455, nome: "Olhos-D'água", estado: 31},
	{id: 3145505, nome: "Olímpio Noronha", estado: 31},
	{id: 3145604, nome: "Oliveira", estado: 31},
	{id: 3145703, nome: "Oliveira Fortes", estado: 31},
	{id: 3145802, nome: "Onça de Pitangui", estado: 31},
	{id: 3145851, nome: "Oratórios", estado: 31},
	{id: 3145877, nome: "Orizânia", estado: 31},
	{id: 3145901, nome: "Ouro Branco", estado: 31},
	{id: 3146008, nome: "Ouro Fino", estado: 31},
	{id: 3146107, nome: "Ouro Preto", estado: 31},
	{id: 3146206, nome: "Ouro Verde de Minas", estado: 31},
	{id: 3146255, nome: "Padre Carvalho", estado: 31},
	{id: 3146305, nome: "Padre Paraíso", estado: 31},
	{id: 3146404, nome: "Paineiras", estado: 31},
	{id: 3146503, nome: "Pains", estado: 31},
	{id: 3146552, nome: "Pai Pedro", estado: 31},
	{id: 3146602, nome: "Paiva", estado: 31},
	{id: 3146701, nome: "Palma", estado: 31},
	{id: 3146750, nome: "Palmópolis", estado: 31},
	{id: 3146909, nome: "Papagaios", estado: 31},
	{id: 3147006, nome: "Paracatu", estado: 31},
	{id: 3147105, nome: "Pará de Minas", estado: 31},
	{id: 3147204, nome: "Paraguaçu", estado: 31},
	{id: 3147303, nome: "Paraisópolis", estado: 31},
	{id: 3147402, nome: "Paraopeba", estado: 31},
	{id: 3147501, nome: "Passabém", estado: 31},
	{id: 3147600, nome: "Passa Quatro", estado: 31},
	{id: 3147709, nome: "Passa Tempo", estado: 31},
	{id: 3147808, nome: "Passa-Vinte", estado: 31},
	{id: 3147907, nome: "Passos", estado: 31},
	{id: 3147956, nome: "Patis", estado: 31},
	{id: 3148004, nome: "Patos de Minas", estado: 31},
	{id: 3148103, nome: "Patrocínio", estado: 31},
	{id: 3148202, nome: "Patrocínio do Muriaé", estado: 31},
	{id: 3148301, nome: "Paula Cândido", estado: 31},
	{id: 3148400, nome: "Paulistas", estado: 31},
	{id: 3148509, nome: "Pavão", estado: 31},
	{id: 3148608, nome: "Peçanha", estado: 31},
	{id: 3148707, nome: "Pedra Azul", estado: 31},
	{id: 3148756, nome: "Pedra Bonita", estado: 31},
	{id: 3148806, nome: "Pedra do Anta", estado: 31},
	{id: 3148905, nome: "Pedra do Indaiá", estado: 31},
	{id: 3149002, nome: "Pedra dourada", estado: 31},
	{id: 3149101, nome: "Pedralva", estado: 31},
	{id: 3149150, nome: "Pedras de Maria da Cruz", estado: 31},
	{id: 3149200, nome: "Pedrinópolis", estado: 31},
	{id: 3149309, nome: "Pedro Leopoldo", estado: 31},
	{id: 3149408, nome: "Pedro Teixeira", estado: 31},
	{id: 3149507, nome: "Pequeri", estado: 31},
	{id: 3149606, nome: "Pequi", estado: 31},
	{id: 3149705, nome: "Perdigão", estado: 31},
	{id: 3149804, nome: "Perdizes", estado: 31},
	{id: 3149903, nome: "Perdões", estado: 31},
	{id: 3149952, nome: "Periquito", estado: 31},
	{id: 3150000, nome: "Pescador", estado: 31},
	{id: 3150109, nome: "Piau", estado: 31},
	{id: 3150158, nome: "Piedade de Caratinga", estado: 31},
	{id: 3150208, nome: "Piedade de Ponte Nova", estado: 31},
	{id: 3150307, nome: "Piedade do Rio Grande", estado: 31},
	{id: 3150406, nome: "Piedade dos Gerais", estado: 31},
	{id: 3150505, nome: "Pimenta", estado: 31},
	{id: 3150539, nome: "Pingo-D'água", estado: 31},
	{id: 3150570, nome: "Pintópolis", estado: 31},
	{id: 3150604, nome: "Piracema", estado: 31},
	{id: 3150703, nome: "Pirajuba", estado: 31},
	{id: 3150802, nome: "Piranga", estado: 31},
	{id: 3150901, nome: "Piranguçu", estado: 31},
	{id: 3151008, nome: "Piranguinho", estado: 31},
	{id: 3151107, nome: "Pirapetinga", estado: 31},
	{id: 3151206, nome: "Pirapora", estado: 31},
	{id: 3151305, nome: "Piraúba", estado: 31},
	{id: 3151404, nome: "Pitangui", estado: 31},
	{id: 3151503, nome: "Piumhi", estado: 31},
	{id: 3151602, nome: "Planura", estado: 31},
	{id: 3151701, nome: "Poço Fundo", estado: 31},
	{id: 3151800, nome: "Poços de Caldas", estado: 31},
	{id: 3151909, nome: "Pocrane", estado: 31},
	{id: 3152006, nome: "Pompéu", estado: 31},
	{id: 3152105, nome: "Ponte Nova", estado: 31},
	{id: 3152131, nome: "Ponto Chique", estado: 31},
	{id: 3152170, nome: "Ponto dos Volantes", estado: 31},
	{id: 3152204, nome: "Porteirinha", estado: 31},
	{id: 3152303, nome: "Porto Firme", estado: 31},
	{id: 3152402, nome: "Poté", estado: 31},
	{id: 3152501, nome: "Pouso Alegre", estado: 31},
	{id: 3152600, nome: "Pouso Alto", estado: 31},
	{id: 3152709, nome: "Prados", estado: 31},
	{id: 3152808, nome: "Prata", estado: 31},
	{id: 3152907, nome: "Pratápolis", estado: 31},
	{id: 3153004, nome: "Pratinha", estado: 31},
	{id: 3153103, nome: "Presidente Bernardes", estado: 31},
	{id: 3153202, nome: "Presidente Juscelino", estado: 31},
	{id: 3153301, nome: "Presidente Kubitschek", estado: 31},
	{id: 3153400, nome: "Presidente Olegário", estado: 31},
	{id: 3153509, nome: "Alto Jequitibá", estado: 31},
	{id: 3153608, nome: "Prudente de Morais", estado: 31},
	{id: 3153707, nome: "Quartel Geral", estado: 31},
	{id: 3153806, nome: "Queluzito", estado: 31},
	{id: 3153905, nome: "Raposos", estado: 31},
	{id: 3154002, nome: "Raul Soares", estado: 31},
	{id: 3154101, nome: "Recreio", estado: 31},
	{id: 3154150, nome: "Reduto", estado: 31},
	{id: 3154200, nome: "Resende Costa", estado: 31},
	{id: 3154309, nome: "Resplendor", estado: 31},
	{id: 3154408, nome: "Ressaquinha", estado: 31},
	{id: 3154457, nome: "Riachinho", estado: 31},
	{id: 3154507, nome: "Riacho dos Machados", estado: 31},
	{id: 3154606, nome: "Ribeirão das Neves", estado: 31},
	{id: 3154705, nome: "Ribeirão Vermelho", estado: 31},
	{id: 3154804, nome: "Rio Acima", estado: 31},
	{id: 3154903, nome: "Rio Casca", estado: 31},
	{id: 3155009, nome: "Rio doce", estado: 31},
	{id: 3155108, nome: "Rio do Prado", estado: 31},
	{id: 3155207, nome: "Rio Espera", estado: 31},
	{id: 3155306, nome: "Rio Manso", estado: 31},
	{id: 3155405, nome: "Rio Novo", estado: 31},
	{id: 3155504, nome: "Rio Paranaíba", estado: 31},
	{id: 3155603, nome: "Rio Pardo de Minas", estado: 31},
	{id: 3155702, nome: "Rio Piracicaba", estado: 31},
	{id: 3155801, nome: "Rio Pomba", estado: 31},
	{id: 3155900, nome: "Rio Preto", estado: 31},
	{id: 3156007, nome: "Rio Vermelho", estado: 31},
	{id: 3156106, nome: "Ritápolis", estado: 31},
	{id: 3156205, nome: "Rochedo de Minas", estado: 31},
	{id: 3156304, nome: "Rodeiro", estado: 31},
	{id: 3156403, nome: "Romaria", estado: 31},
	{id: 3156452, nome: "Rosário da Limeira", estado: 31},
	{id: 3156502, nome: "Rubelita", estado: 31},
	{id: 3156601, nome: "Rubim", estado: 31},
	{id: 3156700, nome: "Sabará", estado: 31},
	{id: 3156809, nome: "Sabinópolis", estado: 31},
	{id: 3156908, nome: "Sacramento", estado: 31},
	{id: 3157005, nome: "Salinas", estado: 31},
	{id: 3157104, nome: "Salto da Divisa", estado: 31},
	{id: 3157203, nome: "Santa Bárbara", estado: 31},
	{id: 3157252, nome: "Santa Bárbara do Leste", estado: 31},
	{id: 3157278, nome: "Santa Bárbara do Monte Verde", estado: 31},
	{id: 3157302, nome: "Santa Bárbara do Tugúrio", estado: 31},
	{id: 3157336, nome: "Santa Cruz de Minas", estado: 31},
	{id: 3157377, nome: "Santa Cruz de Salinas", estado: 31},
	{id: 3157401, nome: "Santa Cruz do Escalvado", estado: 31},
	{id: 3157500, nome: "Santa Efigênia de Minas", estado: 31},
	{id: 3157609, nome: "Santa Fé de Minas", estado: 31},
	{id: 3157658, nome: "Santa Helena de Minas", estado: 31},
	{id: 3157708, nome: "Santa Juliana", estado: 31},
	{id: 3157807, nome: "Santa Luzia", estado: 31},
	{id: 3157906, nome: "Santa Margarida", estado: 31},
	{id: 3158003, nome: "Santa Maria de Itabira", estado: 31},
	{id: 3158102, nome: "Santa Maria do Salto", estado: 31},
	{id: 3158201, nome: "Santa Maria do Suaçuí", estado: 31},
	{id: 3158300, nome: "Santana da Vargem", estado: 31},
	{id: 3158409, nome: "Santana de Cataguases", estado: 31},
	{id: 3158508, nome: "Santana de Pirapama", estado: 31},
	{id: 3158607, nome: "Santana do deserto", estado: 31},
	{id: 3158706, nome: "Santana do Garambéu", estado: 31},
	{id: 3158805, nome: "Santana do Jacaré", estado: 31},
	{id: 3158904, nome: "Santana do Manhuaçu", estado: 31},
	{id: 3158953, nome: "Santana do Paraíso", estado: 31},
	{id: 3159001, nome: "Santana do Riacho", estado: 31},
	{id: 3159100, nome: "Santana dos Montes", estado: 31},
	{id: 3159209, nome: "Santa Rita de Caldas", estado: 31},
	{id: 3159308, nome: "Santa Rita de Jacutinga", estado: 31},
	{id: 3159357, nome: "Santa Rita de Minas", estado: 31},
	{id: 3159407, nome: "Santa Rita de Ibitipoca", estado: 31},
	{id: 3159506, nome: "Santa Rita do Itueto", estado: 31},
	{id: 3159605, nome: "Santa Rita do Sapucaí", estado: 31},
	{id: 3159704, nome: "Santa Rosa da Serra", estado: 31},
	{id: 3159803, nome: "Santa Vitória", estado: 31},
	{id: 3159902, nome: "Santo Antônio do Amparo", estado: 31},
	{id: 3160009, nome: "Santo Antônio do Aventureiro", estado: 31},
	{id: 3160108, nome: "Santo Antônio do Grama", estado: 31},
	{id: 3160207, nome: "Santo Antônio do Itambé", estado: 31},
	{id: 3160306, nome: "Santo Antônio do Jacinto", estado: 31},
	{id: 3160405, nome: "Santo Antônio do Monte", estado: 31},
	{id: 3160454, nome: "Santo Antônio do Retiro", estado: 31},
	{id: 3160504, nome: "Santo Antônio do Rio Abaixo", estado: 31},
	{id: 3160603, nome: "Santo Hipólito", estado: 31},
	{id: 3160702, nome: "Santos Dumont", estado: 31},
	{id: 3160801, nome: "São Bento Abade", estado: 31},
	{id: 3160900, nome: "São Brás do Suaçuí", estado: 31},
	{id: 3160959, nome: "São domingos das dores", estado: 31},
	{id: 3161007, nome: "São domingos do Prata", estado: 31},
	{id: 3161056, nome: "São Félix de Minas", estado: 31},
	{id: 3161106, nome: "São Francisco", estado: 31},
	{id: 3161205, nome: "São Francisco de Paula", estado: 31},
	{id: 3161304, nome: "São Francisco de Sales", estado: 31},
	{id: 3161403, nome: "São Francisco do Glória", estado: 31},
	{id: 3161502, nome: "São Geraldo", estado: 31},
	{id: 3161601, nome: "São Geraldo da Piedade", estado: 31},
	{id: 3161650, nome: "São Geraldo do Baixio", estado: 31},
	{id: 3161700, nome: "São Gonçalo do Abaeté", estado: 31},
	{id: 3161809, nome: "São Gonçalo do Pará", estado: 31},
	{id: 3161908, nome: "São Gonçalo do Rio Abaixo", estado: 31},
	{id: 3162005, nome: "São Gonçalo do Sapucaí", estado: 31},
	{id: 3162104, nome: "São Gotardo", estado: 31},
	{id: 3162203, nome: "São João Batista do Glória", estado: 31},
	{id: 3162252, nome: "São João da Lagoa", estado: 31},
	{id: 3162302, nome: "São João da Mata", estado: 31},
	{id: 3162401, nome: "São João da Ponte", estado: 31},
	{id: 3162450, nome: "São João das Missões", estado: 31},
	{id: 3162500, nome: "São João del Rei", estado: 31},
	{id: 3162559, nome: "São João do Manhuaçu", estado: 31},
	{id: 3162575, nome: "São João do Manteninha", estado: 31},
	{id: 3162609, nome: "São João do Oriente", estado: 31},
	{id: 3162658, nome: "São João do Pacuí", estado: 31},
	{id: 3162708, nome: "São João do Paraíso", estado: 31},
	{id: 3162807, nome: "São João Evangelista", estado: 31},
	{id: 3162906, nome: "São João Nepomuceno", estado: 31},
	{id: 3162922, nome: "São Joaquim de Bicas", estado: 31},
	{id: 3162948, nome: "São José da Barra", estado: 31},
	{id: 3162955, nome: "São José da Lapa", estado: 31},
	{id: 3163003, nome: "São José da Safira", estado: 31},
	{id: 3163102, nome: "São José da Varginha", estado: 31},
	{id: 3163201, nome: "São José do Alegre", estado: 31},
	{id: 3163300, nome: "São José do Divino", estado: 31},
	{id: 3163409, nome: "São José do Goiabal", estado: 31},
	{id: 3163508, nome: "São José do Jacuri", estado: 31},
	{id: 3163607, nome: "São José do Mantimento", estado: 31},
	{id: 3163706, nome: "São Lourenço", estado: 31},
	{id: 3163805, nome: "São Miguel do Anta", estado: 31},
	{id: 3163904, nome: "São Pedro da União", estado: 31},
	{id: 3164001, nome: "São Pedro dos Ferros", estado: 31},
	{id: 3164100, nome: "São Pedro do Suaçuí", estado: 31},
	{id: 3164209, nome: "São Romão", estado: 31},
	{id: 3164308, nome: "São Roque de Minas", estado: 31},
	{id: 3164407, nome: "São Sebastião da Bela Vista", estado: 31},
	{id: 3164431, nome: "São Sebastião da Vargem Alegre", estado: 31},
	{id: 3164472, nome: "São Sebastião do Anta", estado: 31},
	{id: 3164506, nome: "São Sebastião do Maranhão", estado: 31},
	{id: 3164605, nome: "São Sebastião do Oeste", estado: 31},
	{id: 3164704, nome: "São Sebastião do Paraíso", estado: 31},
	{id: 3164803, nome: "São Sebastião do Rio Preto", estado: 31},
	{id: 3164902, nome: "São Sebastião do Rio Verde", estado: 31},
	{id: 3165008, nome: "São Tiago", estado: 31},
	{id: 3165107, nome: "São Tomás de Aquino", estado: 31},
	{id: 3165206, nome: "São Thomé das Letras", estado: 31},
	{id: 3165305, nome: "São Vicente de Minas", estado: 31},
	{id: 3165404, nome: "Sapucaí-Mirim", estado: 31},
	{id: 3165503, nome: "Sardoá", estado: 31},
	{id: 3165537, nome: "Sarzedo", estado: 31},
	{id: 3165552, nome: "Setubinha", estado: 31},
	{id: 3165560, nome: "Sem-Peixe", estado: 31},
	{id: 3165578, nome: "Senador Amaral", estado: 31},
	{id: 3165602, nome: "Senador Cortes", estado: 31},
	{id: 3165701, nome: "Senador Firmino", estado: 31},
	{id: 3165800, nome: "Senador José Bento", estado: 31},
	{id: 3165909, nome: "Senador Modestino Gonçalves", estado: 31},
	{id: 3166006, nome: "Senhora de Oliveira", estado: 31},
	{id: 3166105, nome: "Senhora do Porto", estado: 31},
	{id: 3166204, nome: "Senhora dos Remédios", estado: 31},
	{id: 3166303, nome: "Sericita", estado: 31},
	{id: 3166402, nome: "Seritinga", estado: 31},
	{id: 3166501, nome: "Serra Azul de Minas", estado: 31},
	{id: 3166600, nome: "Serra da Saudade", estado: 31},
	{id: 3166709, nome: "Serra dos Aimorés", estado: 31},
	{id: 3166808, nome: "Serra do Salitre", estado: 31},
	{id: 3166907, nome: "Serrania", estado: 31},
	{id: 3166956, nome: "Serranópolis de Minas", estado: 31},
	{id: 3167004, nome: "Serranos", estado: 31},
	{id: 3167103, nome: "Serro", estado: 31},
	{id: 3167202, nome: "Sete Lagoas", estado: 31},
	{id: 3167301, nome: "Silveirânia", estado: 31},
	{id: 3167400, nome: "Silvianópolis", estado: 31},
	{id: 3167509, nome: "Simão Pereira", estado: 31},
	{id: 3167608, nome: "Simonésia", estado: 31},
	{id: 3167707, nome: "Sobrália", estado: 31},
	{id: 3167806, nome: "Soledade de Minas", estado: 31},
	{id: 3167905, nome: "Tabuleiro", estado: 31},
	{id: 3168002, nome: "Taiobeiras", estado: 31},
	{id: 3168051, nome: "Taparuba", estado: 31},
	{id: 3168101, nome: "Tapira", estado: 31},
	{id: 3168200, nome: "Tapiraí", estado: 31},
	{id: 3168309, nome: "Taquaraçu de Minas", estado: 31},
	{id: 3168408, nome: "Tarumirim", estado: 31},
	{id: 3168507, nome: "Teixeiras", estado: 31},
	{id: 3168606, nome: "Teófilo Otoni", estado: 31},
	{id: 3168705, nome: "Timóteo", estado: 31},
	{id: 3168804, nome: "Tiradentes", estado: 31},
	{id: 3168903, nome: "Tiros", estado: 31},
	{id: 3169000, nome: "Tocantins", estado: 31},
	{id: 3169059, nome: "Tocos do Moji", estado: 31},
	{id: 3169109, nome: "Toledo", estado: 31},
	{id: 3169208, nome: "Tombos", estado: 31},
	{id: 3169307, nome: "Três Corações", estado: 31},
	{id: 3169356, nome: "Três Marias", estado: 31},
	{id: 3169406, nome: "Três Pontas", estado: 31},
	{id: 3169505, nome: "Tumiritinga", estado: 31},
	{id: 3169604, nome: "Tupaciguara", estado: 31},
	{id: 3169703, nome: "Turmalina", estado: 31},
	{id: 3169802, nome: "Turvolândia", estado: 31},
	{id: 3169901, nome: "Ubá", estado: 31},
	{id: 3170008, nome: "Ubaí", estado: 31},
	{id: 3170057, nome: "Ubaporanga", estado: 31},
	{id: 3170107, nome: "Uberaba", estado: 31},
	{id: 3170206, nome: "Uberlândia", estado: 31},
	{id: 3170305, nome: "Umburatiba", estado: 31},
	{id: 3170404, nome: "Unaí", estado: 31},
	{id: 3170438, nome: "União de Minas", estado: 31},
	{id: 3170479, nome: "Uruana de Minas", estado: 31},
	{id: 3170503, nome: "Urucânia", estado: 31},
	{id: 3170529, nome: "Urucuia", estado: 31},
	{id: 3170578, nome: "Vargem Alegre", estado: 31},
	{id: 3170602, nome: "Vargem Bonita", estado: 31},
	{id: 3170651, nome: "Vargem Grande do Rio Pardo", estado: 31},
	{id: 3170701, nome: "Varginha", estado: 31},
	{id: 3170750, nome: "Varjão de Minas", estado: 31},
	{id: 3170800, nome: "Várzea da Palma", estado: 31},
	{id: 3170909, nome: "Varzelândia", estado: 31},
	{id: 3171006, nome: "Vazante", estado: 31},
	{id: 3171030, nome: "Verdelândia", estado: 31},
	{id: 3171071, nome: "Veredinha", estado: 31},
	{id: 3171105, nome: "Veríssimo", estado: 31},
	{id: 3171154, nome: "Vermelho Novo", estado: 31},
	{id: 3171204, nome: "Vespasiano", estado: 31},
	{id: 3171303, nome: "Viçosa", estado: 31},
	{id: 3171402, nome: "Vieiras", estado: 31},
	{id: 3171501, nome: "Mathias Lobato", estado: 31},
	{id: 3171600, nome: "Virgem da Lapa", estado: 31},
	{id: 3171709, nome: "Virgínia", estado: 31},
	{id: 3171808, nome: "Virginópolis", estado: 31},
	{id: 3171907, nome: "Virgolândia", estado: 31},
	{id: 3172004, nome: "Visconde do Rio Branco", estado: 31},
	{id: 3172103, nome: "Volta Grande", estado: 31},
	{id: 3172202, nome: "Wenceslau Braz", estado: 31},
	{id: 3200102, nome: "Afonso Cláudio", estado: 32},
	{id: 3200136, nome: "Águia Branca", estado: 32},
	{id: 3200169, nome: "Água doce do Norte", estado: 32},
	{id: 3200201, nome: "Alegre", estado: 32},
	{id: 3200300, nome: "Alfredo Chaves", estado: 32},
	{id: 3200359, nome: "Alto Rio Novo", estado: 32},
	{id: 3200409, nome: "Anchieta", estado: 32},
	{id: 3200508, nome: "Apiacá", estado: 32},
	{id: 3200607, nome: "Aracruz", estado: 32},
	{id: 3200706, nome: "Atilio Vivacqua", estado: 32},
	{id: 3200805, nome: "Baixo Guandu", estado: 32},
	{id: 3200904, nome: "Barra de São Francisco", estado: 32},
	{id: 3201001, nome: "Boa Esperança", estado: 32},
	{id: 3201100, nome: "Bom Jesus do Norte", estado: 32},
	{id: 3201159, nome: "Brejetuba", estado: 32},
	{id: 3201209, nome: "Cachoeiro de Itapemirim", estado: 32},
	{id: 3201308, nome: "Cariacica", estado: 32},
	{id: 3201407, nome: "Castelo", estado: 32},
	{id: 3201506, nome: "Colatina", estado: 32},
	{id: 3201605, nome: "Conceição da Barra", estado: 32},
	{id: 3201704, nome: "Conceição do Castelo", estado: 32},
	{id: 3201803, nome: "Divino de São Lourenço", estado: 32},
	{id: 3201902, nome: "domingos Martins", estado: 32},
	{id: 3202009, nome: "dores do Rio Preto", estado: 32},
	{id: 3202108, nome: "Ecoporanga", estado: 32},
	{id: 3202207, nome: "Fundão", estado: 32},
	{id: 3202256, nome: "Governador Lindenberg", estado: 32},
	{id: 3202306, nome: "Guaçuí", estado: 32},
	{id: 3202405, nome: "Guarapari", estado: 32},
	{id: 3202454, nome: "Ibatiba", estado: 32},
	{id: 3202504, nome: "Ibiraçu", estado: 32},
	{id: 3202553, nome: "Ibitirama", estado: 32},
	{id: 3202603, nome: "Iconha", estado: 32},
	{id: 3202652, nome: "Irupi", estado: 32},
	{id: 3202702, nome: "Itaguaçu", estado: 32},
	{id: 3202801, nome: "Itapemirim", estado: 32},
	{id: 3202900, nome: "Itarana", estado: 32},
	{id: 3203007, nome: "Iúna", estado: 32},
	{id: 3203056, nome: "Jaguaré", estado: 32},
	{id: 3203106, nome: "Jerônimo Monteiro", estado: 32},
	{id: 3203130, nome: "João Neiva", estado: 32},
	{id: 3203163, nome: "Laranja da Terra", estado: 32},
	{id: 3203205, nome: "Linhares", estado: 32},
	{id: 3203304, nome: "Mantenópolis", estado: 32},
	{id: 3203320, nome: "Marataízes", estado: 32},
	{id: 3203346, nome: "Marechal Floriano", estado: 32},
	{id: 3203353, nome: "Marilândia", estado: 32},
	{id: 3203403, nome: "Mimoso do Sul", estado: 32},
	{id: 3203502, nome: "Montanha", estado: 32},
	{id: 3203601, nome: "Mucurici", estado: 32},
	{id: 3203700, nome: "Muniz Freire", estado: 32},
	{id: 3203809, nome: "Muqui", estado: 32},
	{id: 3203908, nome: "Nova Venécia", estado: 32},
	{id: 3204005, nome: "Pancas", estado: 32},
	{id: 3204054, nome: "Pedro Canário", estado: 32},
	{id: 3204104, nome: "Pinheiros", estado: 32},
	{id: 3204203, nome: "Piúma", estado: 32},
	{id: 3204252, nome: "Ponto Belo", estado: 32},
	{id: 3204302, nome: "Presidente Kennedy", estado: 32},
	{id: 3204351, nome: "Rio Bananal", estado: 32},
	{id: 3204401, nome: "Rio Novo do Sul", estado: 32},
	{id: 3204500, nome: "Santa Leopoldina", estado: 32},
	{id: 3204559, nome: "Santa Maria de Jetibá", estado: 32},
	{id: 3204609, nome: "Santa Teresa", estado: 32},
	{id: 3204658, nome: "São domingos do Norte", estado: 32},
	{id: 3204708, nome: "São Gabriel da Palha", estado: 32},
	{id: 3204807, nome: "São José do Calçado", estado: 32},
	{id: 3204906, nome: "São Mateus", estado: 32},
	{id: 3204955, nome: "São Roque do Canaã", estado: 32},
	{id: 3205002, nome: "Serra", estado: 32},
	{id: 3205010, nome: "Sooretama", estado: 32},
	{id: 3205036, nome: "Vargem Alta", estado: 32},
	{id: 3205069, nome: "Venda Nova do Imigrante", estado: 32},
	{id: 3205101, nome: "Viana", estado: 32},
	{id: 3205150, nome: "Vila Pavão", estado: 32},
	{id: 3205176, nome: "Vila Valério", estado: 32},
	{id: 3205200, nome: "Vila Velha", estado: 32},
	{id: 3205309, nome: "Vitória", estado: 32},
	{id: 3300100, nome: "Angra dos Reis", estado: 33},
	{id: 3300159, nome: "Aperibé", estado: 33},
	{id: 3300209, nome: "Araruama", estado: 33},
	{id: 3300225, nome: "Areal", estado: 33},
	{id: 3300233, nome: "Armação dos Búzios", estado: 33},
	{id: 3300258, nome: "Arraial do Cabo", estado: 33},
	{id: 3300308, nome: "Barra do Piraí", estado: 33},
	{id: 3300407, nome: "Barra Mansa", estado: 33},
	{id: 3300456, nome: "Belford Roxo", estado: 33},
	{id: 3300506, nome: "Bom Jardim", estado: 33},
	{id: 3300605, nome: "Bom Jesus do Itabapoana", estado: 33},
	{id: 3300704, nome: "Cabo Frio", estado: 33},
	{id: 3300803, nome: "Cachoeiras de Macacu", estado: 33},
	{id: 3300902, nome: "Cambuci", estado: 33},
	{id: 3300936, nome: "Carapebus", estado: 33},
	{id: 3300951, nome: "Comendador Levy Gasparian", estado: 33},
	{id: 3301009, nome: "Campos dos Goytacazes", estado: 33},
	{id: 3301108, nome: "Cantagalo", estado: 33},
	{id: 3301157, nome: "Cardoso Moreira", estado: 33},
	{id: 3301207, nome: "Carmo", estado: 33},
	{id: 3301306, nome: "Casimiro de Abreu", estado: 33},
	{id: 3301405, nome: "Conceição de Macabu", estado: 33},
	{id: 3301504, nome: "Cordeiro", estado: 33},
	{id: 3301603, nome: "Duas Barras", estado: 33},
	{id: 3301702, nome: "Duque de Caxias", estado: 33},
	{id: 3301801, nome: "Engenheiro Paulo de Frontin", estado: 33},
	{id: 3301850, nome: "Guapimirim", estado: 33},
	{id: 3301876, nome: "Iguaba Grande", estado: 33},
	{id: 3301900, nome: "Itaboraí", estado: 33},
	{id: 3302007, nome: "Itaguaí", estado: 33},
	{id: 3302056, nome: "Italva", estado: 33},
	{id: 3302106, nome: "Itaocara", estado: 33},
	{id: 3302205, nome: "Itaperuna", estado: 33},
	{id: 3302254, nome: "Itatiaia", estado: 33},
	{id: 3302270, nome: "Japeri", estado: 33},
	{id: 3302304, nome: "Laje do Muriaé", estado: 33},
	{id: 3302403, nome: "Macaé", estado: 33},
	{id: 3302452, nome: "Macuco", estado: 33},
	{id: 3302502, nome: "Magé", estado: 33},
	{id: 3302601, nome: "Mangaratiba", estado: 33},
	{id: 3302700, nome: "Maricá", estado: 33},
	{id: 3302809, nome: "Mendes", estado: 33},
	{id: 3302858, nome: "Mesquita", estado: 33},
	{id: 3302908, nome: "Miguel Pereira", estado: 33},
	{id: 3303005, nome: "Miracema", estado: 33},
	{id: 3303104, nome: "Natividade", estado: 33},
	{id: 3303203, nome: "Nilópolis", estado: 33},
	{id: 3303302, nome: "Niterói", estado: 33},
	{id: 3303401, nome: "Nova Friburgo", estado: 33},
	{id: 3303500, nome: "Nova Iguaçu", estado: 33},
	{id: 3303609, nome: "Paracambi", estado: 33},
	{id: 3303708, nome: "Paraíba do Sul", estado: 33},
	{id: 3303807, nome: "Paraty", estado: 33},
	{id: 3303856, nome: "Paty do Alferes", estado: 33},
	{id: 3303906, nome: "Petrópolis", estado: 33},
	{id: 3303955, nome: "Pinheiral", estado: 33},
	{id: 3304003, nome: "Piraí", estado: 33},
	{id: 3304102, nome: "Porciúncula", estado: 33},
	{id: 3304110, nome: "Porto Real", estado: 33},
	{id: 3304128, nome: "Quatis", estado: 33},
	{id: 3304144, nome: "Queimados", estado: 33},
	{id: 3304151, nome: "Quissamã", estado: 33},
	{id: 3304201, nome: "Resende", estado: 33},
	{id: 3304300, nome: "Rio Bonito", estado: 33},
	{id: 3304409, nome: "Rio Claro", estado: 33},
	{id: 3304508, nome: "Rio das Flores", estado: 33},
	{id: 3304524, nome: "Rio das Ostras", estado: 33},
	{id: 3304557, nome: "Rio de Janeiro", estado: 33},
	{id: 3304607, nome: "Santa Maria Madalena", estado: 33},
	{id: 3304706, nome: "Santo Antônio de Pádua", estado: 33},
	{id: 3304755, nome: "São Francisco de Itabapoana", estado: 33},
	{id: 3304805, nome: "São Fidélis", estado: 33},
	{id: 3304904, nome: "São Gonçalo", estado: 33},
	{id: 3305000, nome: "São João da Barra", estado: 33},
	{id: 3305109, nome: "São João de Meriti", estado: 33},
	{id: 3305133, nome: "São José de Ubá", estado: 33},
	{id: 3305158, nome: "São José do Vale do Rio Preto", estado: 33},
	{id: 3305208, nome: "São Pedro da Aldeia", estado: 33},
	{id: 3305307, nome: "São Sebastião do Alto", estado: 33},
	{id: 3305406, nome: "Sapucaia", estado: 33},
	{id: 3305505, nome: "Saquarema", estado: 33},
	{id: 3305554, nome: "Seropédica", estado: 33},
	{id: 3305604, nome: "Silva Jardim", estado: 33},
	{id: 3305703, nome: "Sumidouro", estado: 33},
	{id: 3305752, nome: "Tanguá", estado: 33},
	{id: 3305802, nome: "Teresópolis", estado: 33},
	{id: 3305901, nome: "Trajano de Moraes", estado: 33},
	{id: 3306008, nome: "Três Rios", estado: 33},
	{id: 3306107, nome: "Valença", estado: 33},
	{id: 3306156, nome: "Varre-Sai", estado: 33},
	{id: 3306206, nome: "Vassouras", estado: 33},
	{id: 3306305, nome: "Volta Redonda", estado: 33},
	{id: 3500105, nome: "Adamantina", estado: 35},
	{id: 3500204, nome: "Adolfo", estado: 35},
	{id: 3500303, nome: "Aguaí", estado: 35},
	{id: 3500402, nome: "Águas da Prata", estado: 35},
	{id: 3500501, nome: "Águas de Lindóia", estado: 35},
	{id: 3500550, nome: "Águas de Santa Bárbara", estado: 35},
	{id: 3500600, nome: "Águas de São Pedro", estado: 35},
	{id: 3500709, nome: "Agudos", estado: 35},
	{id: 3500758, nome: "Alambari", estado: 35},
	{id: 3500808, nome: "Alfredo Marcondes", estado: 35},
	{id: 3500907, nome: "Altair", estado: 35},
	{id: 3501004, nome: "Altinópolis", estado: 35},
	{id: 3501103, nome: "Alto Alegre", estado: 35},
	{id: 3501152, nome: "Alumínio", estado: 35},
	{id: 3501202, nome: "Álvares Florence", estado: 35},
	{id: 3501301, nome: "Álvares Machado", estado: 35},
	{id: 3501400, nome: "Álvaro de Carvalho", estado: 35},
	{id: 3501509, nome: "Alvinlândia", estado: 35},
	{id: 3501608, nome: "Americana", estado: 35},
	{id: 3501707, nome: "Américo Brasiliense", estado: 35},
	{id: 3501806, nome: "Américo de Campos", estado: 35},
	{id: 3501905, nome: "Amparo", estado: 35},
	{id: 3502002, nome: "Analândia", estado: 35},
	{id: 3502101, nome: "Andradina", estado: 35},
	{id: 3502200, nome: "Angatuba", estado: 35},
	{id: 3502309, nome: "Anhembi", estado: 35},
	{id: 3502408, nome: "Anhumas", estado: 35},
	{id: 3502507, nome: "Aparecida", estado: 35},
	{id: 3502606, nome: "Aparecida D'oeste", estado: 35},
	{id: 3502705, nome: "Apiaí", estado: 35},
	{id: 3502754, nome: "Araçariguama", estado: 35},
	{id: 3502804, nome: "Araçatuba", estado: 35},
	{id: 3502903, nome: "Araçoiaba da Serra", estado: 35},
	{id: 3503000, nome: "Aramina", estado: 35},
	{id: 3503109, nome: "Arandu", estado: 35},
	{id: 3503158, nome: "Arapeí", estado: 35},
	{id: 3503208, nome: "Araraquara", estado: 35},
	{id: 3503307, nome: "Araras", estado: 35},
	{id: 3503356, nome: "Arco-Íris", estado: 35},
	{id: 3503406, nome: "Arealva", estado: 35},
	{id: 3503505, nome: "Areias", estado: 35},
	{id: 3503604, nome: "Areiópolis", estado: 35},
	{id: 3503703, nome: "Ariranha", estado: 35},
	{id: 3503802, nome: "Artur Nogueira", estado: 35},
	{id: 3503901, nome: "Arujá", estado: 35},
	{id: 3503950, nome: "Aspásia", estado: 35},
	{id: 3504008, nome: "Assis", estado: 35},
	{id: 3504107, nome: "Atibaia", estado: 35},
	{id: 3504206, nome: "Auriflama", estado: 35},
	{id: 3504305, nome: "Avaí", estado: 35},
	{id: 3504404, nome: "Avanhandava", estado: 35},
	{id: 3504503, nome: "Avaré", estado: 35},
	{id: 3504602, nome: "Bady Bassitt", estado: 35},
	{id: 3504701, nome: "Balbinos", estado: 35},
	{id: 3504800, nome: "Bálsamo", estado: 35},
	{id: 3504909, nome: "Bananal", estado: 35},
	{id: 3505005, nome: "Barão de Antonina", estado: 35},
	{id: 3505104, nome: "Barbosa", estado: 35},
	{id: 3505203, nome: "Bariri", estado: 35},
	{id: 3505302, nome: "Barra Bonita", estado: 35},
	{id: 3505351, nome: "Barra do Chapéu", estado: 35},
	{id: 3505401, nome: "Barra do Turvo", estado: 35},
	{id: 3505500, nome: "Barretos", estado: 35},
	{id: 3505609, nome: "Barrinha", estado: 35},
	{id: 3505708, nome: "Barueri", estado: 35},
	{id: 3505807, nome: "Bastos", estado: 35},
	{id: 3505906, nome: "Batatais", estado: 35},
	{id: 3506003, nome: "Bauru", estado: 35},
	{id: 3506102, nome: "Bebedouro", estado: 35},
	{id: 3506201, nome: "Bento de Abreu", estado: 35},
	{id: 3506300, nome: "Bernardino de Campos", estado: 35},
	{id: 3506359, nome: "Bertioga", estado: 35},
	{id: 3506409, nome: "Bilac", estado: 35},
	{id: 3506508, nome: "Birigui", estado: 35},
	{id: 3506607, nome: "Biritiba-Mirim", estado: 35},
	{id: 3506706, nome: "Boa Esperança do Sul", estado: 35},
	{id: 3506805, nome: "Bocaina", estado: 35},
	{id: 3506904, nome: "Bofete", estado: 35},
	{id: 3507001, nome: "Boituva", estado: 35},
	{id: 3507100, nome: "Bom Jesus dos Perdões", estado: 35},
	{id: 3507159, nome: "Bom Sucesso de Itararé", estado: 35},
	{id: 3507209, nome: "Borá", estado: 35},
	{id: 3507308, nome: "Boracéia", estado: 35},
	{id: 3507407, nome: "Borborema", estado: 35},
	{id: 3507456, nome: "Borebi", estado: 35},
	{id: 3507506, nome: "Botucatu", estado: 35},
	{id: 3507605, nome: "Bragança Paulista", estado: 35},
	{id: 3507704, nome: "Braúna", estado: 35},
	{id: 3507753, nome: "Brejo Alegre", estado: 35},
	{id: 3507803, nome: "Brodowski", estado: 35},
	{id: 3507902, nome: "Brotas", estado: 35},
	{id: 3508009, nome: "Buri", estado: 35},
	{id: 3508108, nome: "Buritama", estado: 35},
	{id: 3508207, nome: "Buritizal", estado: 35},
	{id: 3508306, nome: "Cabrália Paulista", estado: 35},
	{id: 3508405, nome: "Cabreúva", estado: 35},
	{id: 3508504, nome: "Caçapava", estado: 35},
	{id: 3508603, nome: "Cachoeira Paulista", estado: 35},
	{id: 3508702, nome: "Caconde", estado: 35},
	{id: 3508801, nome: "Cafelândia", estado: 35},
	{id: 3508900, nome: "Caiabu", estado: 35},
	{id: 3509007, nome: "Caieiras", estado: 35},
	{id: 3509106, nome: "Caiuá", estado: 35},
	{id: 3509205, nome: "Cajamar", estado: 35},
	{id: 3509254, nome: "Cajati", estado: 35},
	{id: 3509304, nome: "Cajobi", estado: 35},
	{id: 3509403, nome: "Cajuru", estado: 35},
	{id: 3509452, nome: "Campina do Monte Alegre", estado: 35},
	{id: 3509502, nome: "Campinas", estado: 35},
	{id: 3509601, nome: "Campo Limpo Paulista", estado: 35},
	{id: 3509700, nome: "Campos do Jordão", estado: 35},
	{id: 3509809, nome: "Campos Novos Paulista", estado: 35},
	{id: 3509908, nome: "Cananéia", estado: 35},
	{id: 3509957, nome: "Canas", estado: 35},
	{id: 3510005, nome: "Cândido Mota", estado: 35},
	{id: 3510104, nome: "Cândido Rodrigues", estado: 35},
	{id: 3510153, nome: "Canitar", estado: 35},
	{id: 3510203, nome: "Capão Bonito", estado: 35},
	{id: 3510302, nome: "Capela do Alto", estado: 35},
	{id: 3510401, nome: "Capivari", estado: 35},
	{id: 3510500, nome: "Caraguatatuba", estado: 35},
	{id: 3510609, nome: "Carapicuíba", estado: 35},
	{id: 3510708, nome: "Cardoso", estado: 35},
	{id: 3510807, nome: "Casa Branca", estado: 35},
	{id: 3510906, nome: "Cássia dos Coqueiros", estado: 35},
	{id: 3511003, nome: "Castilho", estado: 35},
	{id: 3511102, nome: "Catanduva", estado: 35},
	{id: 3511201, nome: "Catiguá", estado: 35},
	{id: 3511300, nome: "Cedral", estado: 35},
	{id: 3511409, nome: "Cerqueira César", estado: 35},
	{id: 3511508, nome: "Cerquilho", estado: 35},
	{id: 3511607, nome: "Cesário Lange", estado: 35},
	{id: 3511706, nome: "Charqueada", estado: 35},
	{id: 3511904, nome: "Clementina", estado: 35},
	{id: 3512001, nome: "Colina", estado: 35},
	{id: 3512100, nome: "Colômbia", estado: 35},
	{id: 3512209, nome: "Conchal", estado: 35},
	{id: 3512308, nome: "Conchas", estado: 35},
	{id: 3512407, nome: "Cordeirópolis", estado: 35},
	{id: 3512506, nome: "Coroados", estado: 35},
	{id: 3512605, nome: "Coronel Macedo", estado: 35},
	{id: 3512704, nome: "Corumbataí", estado: 35},
	{id: 3512803, nome: "Cosmópolis", estado: 35},
	{id: 3512902, nome: "Cosmorama", estado: 35},
	{id: 3513009, nome: "Cotia", estado: 35},
	{id: 3513108, nome: "Cravinhos", estado: 35},
	{id: 3513207, nome: "Cristais Paulista", estado: 35},
	{id: 3513306, nome: "Cruzália", estado: 35},
	{id: 3513405, nome: "Cruzeiro", estado: 35},
	{id: 3513504, nome: "Cubatão", estado: 35},
	{id: 3513603, nome: "Cunha", estado: 35},
	{id: 3513702, nome: "descalvado", estado: 35},
	{id: 3513801, nome: "Diadema", estado: 35},
	{id: 3513850, nome: "Dirce Reis", estado: 35},
	{id: 3513900, nome: "Divinolândia", estado: 35},
	{id: 3514007, nome: "dobrada", estado: 35},
	{id: 3514106, nome: "dois Córregos", estado: 35},
	{id: 3514205, nome: "dolcinópolis", estado: 35},
	{id: 3514304, nome: "dourado", estado: 35},
	{id: 3514403, nome: "Dracena", estado: 35},
	{id: 3514502, nome: "Duartina", estado: 35},
	{id: 3514601, nome: "Dumont", estado: 35},
	{id: 3514700, nome: "Echaporã", estado: 35},
	{id: 3514809, nome: "Eldorado", estado: 35},
	{id: 3514908, nome: "Elias Fausto", estado: 35},
	{id: 3514924, nome: "Elisiário", estado: 35},
	{id: 3514957, nome: "Embaúba", estado: 35},
	{id: 3515004, nome: "Embu das Artes", estado: 35},
	{id: 3515103, nome: "Embu-Guaçu", estado: 35},
	{id: 3515129, nome: "Emilianópolis", estado: 35},
	{id: 3515152, nome: "Engenheiro Coelho", estado: 35},
	{id: 3515186, nome: "Espírito Santo do Pinhal", estado: 35},
	{id: 3515194, nome: "Espírito Santo do Turvo", estado: 35},
	{id: 3515202, nome: "Estrela D'oeste", estado: 35},
	{id: 3515301, nome: "Estrela do Norte", estado: 35},
	{id: 3515350, nome: "Euclides da Cunha Paulista", estado: 35},
	{id: 3515400, nome: "Fartura", estado: 35},
	{id: 3515509, nome: "Fernandópolis", estado: 35},
	{id: 3515608, nome: "Fernando Prestes", estado: 35},
	{id: 3515657, nome: "Fernão", estado: 35},
	{id: 3515707, nome: "Ferraz de Vasconcelos", estado: 35},
	{id: 3515806, nome: "Flora Rica", estado: 35},
	{id: 3515905, nome: "Floreal", estado: 35},
	{id: 3516002, nome: "Flórida Paulista", estado: 35},
	{id: 3516101, nome: "Florínia", estado: 35},
	{id: 3516200, nome: "Franca", estado: 35},
	{id: 3516309, nome: "Francisco Morato", estado: 35},
	{id: 3516408, nome: "Franco da Rocha", estado: 35},
	{id: 3516507, nome: "Gabriel Monteiro", estado: 35},
	{id: 3516606, nome: "Gália", estado: 35},
	{id: 3516705, nome: "Garça", estado: 35},
	{id: 3516804, nome: "Gastão Vidigal", estado: 35},
	{id: 3516853, nome: "Gavião Peixoto", estado: 35},
	{id: 3516903, nome: "General Salgado", estado: 35},
	{id: 3517000, nome: "Getulina", estado: 35},
	{id: 3517109, nome: "Glicério", estado: 35},
	{id: 3517208, nome: "Guaiçara", estado: 35},
	{id: 3517307, nome: "Guaimbê", estado: 35},
	{id: 3517406, nome: "Guaíra", estado: 35},
	{id: 3517505, nome: "Guapiaçu", estado: 35},
	{id: 3517604, nome: "Guapiara", estado: 35},
	{id: 3517703, nome: "Guará", estado: 35},
	{id: 3517802, nome: "Guaraçaí", estado: 35},
	{id: 3517901, nome: "Guaraci", estado: 35},
	{id: 3518008, nome: "Guarani D'oeste", estado: 35},
	{id: 3518107, nome: "Guarantã", estado: 35},
	{id: 3518206, nome: "Guararapes", estado: 35},
	{id: 3518305, nome: "Guararema", estado: 35},
	{id: 3518404, nome: "Guaratinguetá", estado: 35},
	{id: 3518503, nome: "Guareí", estado: 35},
	{id: 3518602, nome: "Guariba", estado: 35},
	{id: 3518701, nome: "Guarujá", estado: 35},
	{id: 3518800, nome: "Guarulhos", estado: 35},
	{id: 3518859, nome: "Guatapará", estado: 35},
	{id: 3518909, nome: "Guzolândia", estado: 35},
	{id: 3519006, nome: "Herculândia", estado: 35},
	{id: 3519055, nome: "Holambra", estado: 35},
	{id: 3519071, nome: "Hortolândia", estado: 35},
	{id: 3519105, nome: "Iacanga", estado: 35},
	{id: 3519204, nome: "Iacri", estado: 35},
	{id: 3519253, nome: "Iaras", estado: 35},
	{id: 3519303, nome: "Ibaté", estado: 35},
	{id: 3519402, nome: "Ibirá", estado: 35},
	{id: 3519501, nome: "Ibirarema", estado: 35},
	{id: 3519600, nome: "Ibitinga", estado: 35},
	{id: 3519709, nome: "Ibiúna", estado: 35},
	{id: 3519808, nome: "Icém", estado: 35},
	{id: 3519907, nome: "Iepê", estado: 35},
	{id: 3520004, nome: "Igaraçu do Tietê", estado: 35},
	{id: 3520103, nome: "Igarapava", estado: 35},
	{id: 3520202, nome: "Igaratá", estado: 35},
	{id: 3520301, nome: "Iguape", estado: 35},
	{id: 3520400, nome: "Ilhabela", estado: 35},
	{id: 3520426, nome: "Ilha Comprida", estado: 35},
	{id: 3520442, nome: "Ilha Solteira", estado: 35},
	{id: 3520509, nome: "Indaiatuba", estado: 35},
	{id: 3520608, nome: "Indiana", estado: 35},
	{id: 3520707, nome: "Indiaporã", estado: 35},
	{id: 3520806, nome: "Inúbia Paulista", estado: 35},
	{id: 3520905, nome: "Ipaussu", estado: 35},
	{id: 3521002, nome: "Iperó", estado: 35},
	{id: 3521101, nome: "Ipeúna", estado: 35},
	{id: 3521150, nome: "Ipiguá", estado: 35},
	{id: 3521200, nome: "Iporanga", estado: 35},
	{id: 3521309, nome: "Ipuã", estado: 35},
	{id: 3521408, nome: "Iracemápolis", estado: 35},
	{id: 3521507, nome: "Irapuã", estado: 35},
	{id: 3521606, nome: "Irapuru", estado: 35},
	{id: 3521705, nome: "Itaberá", estado: 35},
	{id: 3521804, nome: "Itaí", estado: 35},
	{id: 3521903, nome: "Itajobi", estado: 35},
	{id: 3522000, nome: "Itaju", estado: 35},
	{id: 3522109, nome: "Itanhaém", estado: 35},
	{id: 3522158, nome: "Itaóca", estado: 35},
	{id: 3522208, nome: "Itapecerica da Serra", estado: 35},
	{id: 3522307, nome: "Itapetininga", estado: 35},
	{id: 3522406, nome: "Itapeva", estado: 35},
	{id: 3522505, nome: "Itapevi", estado: 35},
	{id: 3522604, nome: "Itapira", estado: 35},
	{id: 3522653, nome: "Itapirapuã Paulista", estado: 35},
	{id: 3522703, nome: "Itápolis", estado: 35},
	{id: 3522802, nome: "Itaporanga", estado: 35},
	{id: 3522901, nome: "Itapuí", estado: 35},
	{id: 3523008, nome: "Itapura", estado: 35},
	{id: 3523107, nome: "Itaquaquecetuba", estado: 35},
	{id: 3523206, nome: "Itararé", estado: 35},
	{id: 3523305, nome: "Itariri", estado: 35},
	{id: 3523404, nome: "Itatiba", estado: 35},
	{id: 3523503, nome: "Itatinga", estado: 35},
	{id: 3523602, nome: "Itirapina", estado: 35},
	{id: 3523701, nome: "Itirapuã", estado: 35},
	{id: 3523800, nome: "Itobi", estado: 35},
	{id: 3523909, nome: "Itu", estado: 35},
	{id: 3524006, nome: "Itupeva", estado: 35},
	{id: 3524105, nome: "Ituverava", estado: 35},
	{id: 3524204, nome: "Jaborandi", estado: 35},
	{id: 3524303, nome: "Jaboticabal", estado: 35},
	{id: 3524402, nome: "Jacareí", estado: 35},
	{id: 3524501, nome: "Jaci", estado: 35},
	{id: 3524600, nome: "Jacupiranga", estado: 35},
	{id: 3524709, nome: "Jaguariúna", estado: 35},
	{id: 3524808, nome: "Jales", estado: 35},
	{id: 3524907, nome: "Jambeiro", estado: 35},
	{id: 3525003, nome: "Jandira", estado: 35},
	{id: 3525102, nome: "Jardinópolis", estado: 35},
	{id: 3525201, nome: "Jarinu", estado: 35},
	{id: 3525300, nome: "Jaú", estado: 35},
	{id: 3525409, nome: "Jeriquara", estado: 35},
	{id: 3525508, nome: "Joanópolis", estado: 35},
	{id: 3525607, nome: "João Ramalho", estado: 35},
	{id: 3525706, nome: "José Bonifácio", estado: 35},
	{id: 3525805, nome: "Júlio Mesquita", estado: 35},
	{id: 3525854, nome: "Jumirim", estado: 35},
	{id: 3525904, nome: "Jundiaí", estado: 35},
	{id: 3526001, nome: "Junqueirópolis", estado: 35},
	{id: 3526100, nome: "Juquiá", estado: 35},
	{id: 3526209, nome: "Juquitiba", estado: 35},
	{id: 3526308, nome: "Lagoinha", estado: 35},
	{id: 3526407, nome: "Laranjal Paulista", estado: 35},
	{id: 3526506, nome: "Lavínia", estado: 35},
	{id: 3526605, nome: "Lavrinhas", estado: 35},
	{id: 3526704, nome: "Leme", estado: 35},
	{id: 3526803, nome: "Lençóis Paulista", estado: 35},
	{id: 3526902, nome: "Limeira", estado: 35},
	{id: 3527009, nome: "Lindóia", estado: 35},
	{id: 3527108, nome: "Lins", estado: 35},
	{id: 3527207, nome: "Lorena", estado: 35},
	{id: 3527256, nome: "Lourdes", estado: 35},
	{id: 3527306, nome: "Louveira", estado: 35},
	{id: 3527405, nome: "Lucélia", estado: 35},
	{id: 3527504, nome: "Lucianópolis", estado: 35},
	{id: 3527603, nome: "Luís Antônio", estado: 35},
	{id: 3527702, nome: "Luiziânia", estado: 35},
	{id: 3527801, nome: "Lupércio", estado: 35},
	{id: 3527900, nome: "Lutécia", estado: 35},
	{id: 3528007, nome: "Macatuba", estado: 35},
	{id: 3528106, nome: "Macaubal", estado: 35},
	{id: 3528205, nome: "Macedônia", estado: 35},
	{id: 3528304, nome: "Magda", estado: 35},
	{id: 3528403, nome: "Mairinque", estado: 35},
	{id: 3528502, nome: "Mairiporã", estado: 35},
	{id: 3528601, nome: "Manduri", estado: 35},
	{id: 3528700, nome: "Marabá Paulista", estado: 35},
	{id: 3528809, nome: "Maracaí", estado: 35},
	{id: 3528858, nome: "Marapoama", estado: 35},
	{id: 3528908, nome: "Mariápolis", estado: 35},
	{id: 3529005, nome: "Marília", estado: 35},
	{id: 3529104, nome: "Marinópolis", estado: 35},
	{id: 3529203, nome: "Martinópolis", estado: 35},
	{id: 3529302, nome: "Matão", estado: 35},
	{id: 3529401, nome: "Mauá", estado: 35},
	{id: 3529500, nome: "Mendonça", estado: 35},
	{id: 3529609, nome: "Meridiano", estado: 35},
	{id: 3529658, nome: "Mesópolis", estado: 35},
	{id: 3529708, nome: "Miguelópolis", estado: 35},
	{id: 3529807, nome: "Mineiros do Tietê", estado: 35},
	{id: 3529906, nome: "Miracatu", estado: 35},
	{id: 3530003, nome: "Mira Estrela", estado: 35},
	{id: 3530102, nome: "Mirandópolis", estado: 35},
	{id: 3530201, nome: "Mirante do Paranapanema", estado: 35},
	{id: 3530300, nome: "Mirassol", estado: 35},
	{id: 3530409, nome: "Mirassolândia", estado: 35},
	{id: 3530508, nome: "Mococa", estado: 35},
	{id: 3530607, nome: "Mogi das Cruzes", estado: 35},
	{id: 3530706, nome: "Mogi Guaçu", estado: 35},
	{id: 3530805, nome: "Mogi Mirim", estado: 35},
	{id: 3530904, nome: "Mombuca", estado: 35},
	{id: 3531001, nome: "Monções", estado: 35},
	{id: 3531100, nome: "Mongaguá", estado: 35},
	{id: 3531209, nome: "Monte Alegre do Sul", estado: 35},
	{id: 3531308, nome: "Monte Alto", estado: 35},
	{id: 3531407, nome: "Monte Aprazível", estado: 35},
	{id: 3531506, nome: "Monte Azul Paulista", estado: 35},
	{id: 3531605, nome: "Monte Castelo", estado: 35},
	{id: 3531704, nome: "Monteiro Lobato", estado: 35},
	{id: 3531803, nome: "Monte Mor", estado: 35},
	{id: 3531902, nome: "Morro Agudo", estado: 35},
	{id: 3532009, nome: "Morungaba", estado: 35},
	{id: 3532058, nome: "Motuca", estado: 35},
	{id: 3532108, nome: "Murutinga do Sul", estado: 35},
	{id: 3532157, nome: "Nantes", estado: 35},
	{id: 3532207, nome: "Narandiba", estado: 35},
	{id: 3532306, nome: "Natividade da Serra", estado: 35},
	{id: 3532405, nome: "Nazaré Paulista", estado: 35},
	{id: 3532504, nome: "Neves Paulista", estado: 35},
	{id: 3532603, nome: "Nhandeara", estado: 35},
	{id: 3532702, nome: "Nipoã", estado: 35},
	{id: 3532801, nome: "Nova Aliança", estado: 35},
	{id: 3532827, nome: "Nova Campina", estado: 35},
	{id: 3532843, nome: "Nova Canaã Paulista", estado: 35},
	{id: 3532868, nome: "Nova Castilho", estado: 35},
	{id: 3532900, nome: "Nova Europa", estado: 35},
	{id: 3533007, nome: "Nova Granada", estado: 35},
	{id: 3533106, nome: "Nova Guataporanga", estado: 35},
	{id: 3533205, nome: "Nova Independência", estado: 35},
	{id: 3533254, nome: "Novais", estado: 35},
	{id: 3533304, nome: "Nova Luzitânia", estado: 35},
	{id: 3533403, nome: "Nova Odessa", estado: 35},
	{id: 3533502, nome: "Novo Horizonte", estado: 35},
	{id: 3533601, nome: "Nuporanga", estado: 35},
	{id: 3533700, nome: "Ocauçu", estado: 35},
	{id: 3533809, nome: "Óleo", estado: 35},
	{id: 3533908, nome: "Olímpia", estado: 35},
	{id: 3534005, nome: "Onda Verde", estado: 35},
	{id: 3534104, nome: "Oriente", estado: 35},
	{id: 3534203, nome: "Orindiúva", estado: 35},
	{id: 3534302, nome: "Orlândia", estado: 35},
	{id: 3534401, nome: "Osasco", estado: 35},
	{id: 3534500, nome: "Oscar Bressane", estado: 35},
	{id: 3534609, nome: "Osvaldo Cruz", estado: 35},
	{id: 3534708, nome: "Ourinhos", estado: 35},
	{id: 3534757, nome: "Ouroeste", estado: 35},
	{id: 3534807, nome: "Ouro Verde", estado: 35},
	{id: 3534906, nome: "Pacaembu", estado: 35},
	{id: 3535002, nome: "Palestina", estado: 35},
	{id: 3535101, nome: "Palmares Paulista", estado: 35},
	{id: 3535200, nome: "Palmeira D'oeste", estado: 35},
	{id: 3535309, nome: "Palmital", estado: 35},
	{id: 3535408, nome: "Panorama", estado: 35},
	{id: 3535507, nome: "Paraguaçu Paulista", estado: 35},
	{id: 3535606, nome: "Paraibuna", estado: 35},
	{id: 3535705, nome: "Paraíso", estado: 35},
	{id: 3535804, nome: "Paranapanema", estado: 35},
	{id: 3535903, nome: "Paranapuã", estado: 35},
	{id: 3536000, nome: "Parapuã", estado: 35},
	{id: 3536109, nome: "Pardinho", estado: 35},
	{id: 3536208, nome: "Pariquera-Açu", estado: 35},
	{id: 3536257, nome: "Parisi", estado: 35},
	{id: 3536307, nome: "Patrocínio Paulista", estado: 35},
	{id: 3536406, nome: "Paulicéia", estado: 35},
	{id: 3536505, nome: "Paulínia", estado: 35},
	{id: 3536570, nome: "Paulistânia", estado: 35},
	{id: 3536604, nome: "Paulo de Faria", estado: 35},
	{id: 3536703, nome: "Pederneiras", estado: 35},
	{id: 3536802, nome: "Pedra Bela", estado: 35},
	{id: 3536901, nome: "Pedranópolis", estado: 35},
	{id: 3537008, nome: "Pedregulho", estado: 35},
	{id: 3537107, nome: "Pedreira", estado: 35},
	{id: 3537156, nome: "Pedrinhas Paulista", estado: 35},
	{id: 3537206, nome: "Pedro de Toledo", estado: 35},
	{id: 3537305, nome: "Penápolis", estado: 35},
	{id: 3537404, nome: "Pereira Barreto", estado: 35},
	{id: 3537503, nome: "Pereiras", estado: 35},
	{id: 3537602, nome: "Peruíbe", estado: 35},
	{id: 3537701, nome: "Piacatu", estado: 35},
	{id: 3537800, nome: "Piedade", estado: 35},
	{id: 3537909, nome: "Pilar do Sul", estado: 35},
	{id: 3538006, nome: "Pindamonhangaba", estado: 35},
	{id: 3538105, nome: "Pindorama", estado: 35},
	{id: 3538204, nome: "Pinhalzinho", estado: 35},
	{id: 3538303, nome: "Piquerobi", estado: 35},
	{id: 3538501, nome: "Piquete", estado: 35},
	{id: 3538600, nome: "Piracaia", estado: 35},
	{id: 3538709, nome: "Piracicaba", estado: 35},
	{id: 3538808, nome: "Piraju", estado: 35},
	{id: 3538907, nome: "Pirajuí", estado: 35},
	{id: 3539004, nome: "Pirangi", estado: 35},
	{id: 3539103, nome: "Pirapora do Bom Jesus", estado: 35},
	{id: 3539202, nome: "Pirapozinho", estado: 35},
	{id: 3539301, nome: "Pirassununga", estado: 35},
	{id: 3539400, nome: "Piratininga", estado: 35},
	{id: 3539509, nome: "Pitangueiras", estado: 35},
	{id: 3539608, nome: "Planalto", estado: 35},
	{id: 3539707, nome: "Platina", estado: 35},
	{id: 3539806, nome: "Poá", estado: 35},
	{id: 3539905, nome: "Poloni", estado: 35},
	{id: 3540002, nome: "Pompéia", estado: 35},
	{id: 3540101, nome: "Pongaí", estado: 35},
	{id: 3540200, nome: "Pontal", estado: 35},
	{id: 3540259, nome: "Pontalinda", estado: 35},
	{id: 3540309, nome: "Pontes Gestal", estado: 35},
	{id: 3540408, nome: "Populina", estado: 35},
	{id: 3540507, nome: "Porangaba", estado: 35},
	{id: 3540606, nome: "Porto Feliz", estado: 35},
	{id: 3540705, nome: "Porto Ferreira", estado: 35},
	{id: 3540754, nome: "Potim", estado: 35},
	{id: 3540804, nome: "Potirendaba", estado: 35},
	{id: 3540853, nome: "Pracinha", estado: 35},
	{id: 3540903, nome: "Pradópolis", estado: 35},
	{id: 3541000, nome: "Praia Grande", estado: 35},
	{id: 3541059, nome: "Pratânia", estado: 35},
	{id: 3541109, nome: "Presidente Alves", estado: 35},
	{id: 3541208, nome: "Presidente Bernardes", estado: 35},
	{id: 3541307, nome: "Presidente Epitácio", estado: 35},
	{id: 3541406, nome: "Presidente Prudente", estado: 35},
	{id: 3541505, nome: "Presidente Venceslau", estado: 35},
	{id: 3541604, nome: "Promissão", estado: 35},
	{id: 3541653, nome: "Quadra", estado: 35},
	{id: 3541703, nome: "Quatá", estado: 35},
	{id: 3541802, nome: "Queiroz", estado: 35},
	{id: 3541901, nome: "Queluz", estado: 35},
	{id: 3542008, nome: "Quintana", estado: 35},
	{id: 3542107, nome: "Rafard", estado: 35},
	{id: 3542206, nome: "Rancharia", estado: 35},
	{id: 3542305, nome: "Redenção da Serra", estado: 35},
	{id: 3542404, nome: "Regente Feijó", estado: 35},
	{id: 3542503, nome: "Reginópolis", estado: 35},
	{id: 3542602, nome: "Registro", estado: 35},
	{id: 3542701, nome: "Restinga", estado: 35},
	{id: 3542800, nome: "Ribeira", estado: 35},
	{id: 3542909, nome: "Ribeirão Bonito", estado: 35},
	{id: 3543006, nome: "Ribeirão Branco", estado: 35},
	{id: 3543105, nome: "Ribeirão Corrente", estado: 35},
	{id: 3543204, nome: "Ribeirão do Sul", estado: 35},
	{id: 3543238, nome: "Ribeirão dos Índios", estado: 35},
	{id: 3543253, nome: "Ribeirão Grande", estado: 35},
	{id: 3543303, nome: "Ribeirão Pires", estado: 35},
	{id: 3543402, nome: "Ribeirão Preto", estado: 35},
	{id: 3543501, nome: "Riversul", estado: 35},
	{id: 3543600, nome: "Rifaina", estado: 35},
	{id: 3543709, nome: "Rincão", estado: 35},
	{id: 3543808, nome: "Rinópolis", estado: 35},
	{id: 3543907, nome: "Rio Claro", estado: 35},
	{id: 3544004, nome: "Rio das Pedras", estado: 35},
	{id: 3544103, nome: "Rio Grande da Serra", estado: 35},
	{id: 3544202, nome: "Riolândia", estado: 35},
	{id: 3544251, nome: "Rosana", estado: 35},
	{id: 3544301, nome: "Roseira", estado: 35},
	{id: 3544400, nome: "Rubiácea", estado: 35},
	{id: 3544509, nome: "Rubinéia", estado: 35},
	{id: 3544608, nome: "Sabino", estado: 35},
	{id: 3544707, nome: "Sagres", estado: 35},
	{id: 3544806, nome: "Sales", estado: 35},
	{id: 3544905, nome: "Sales Oliveira", estado: 35},
	{id: 3545001, nome: "Salesópolis", estado: 35},
	{id: 3545100, nome: "Salmourão", estado: 35},
	{id: 3545159, nome: "Saltinho", estado: 35},
	{id: 3545209, nome: "Salto", estado: 35},
	{id: 3545308, nome: "Salto de Pirapora", estado: 35},
	{id: 3545407, nome: "Salto Grande", estado: 35},
	{id: 3545506, nome: "Sandovalina", estado: 35},
	{id: 3545605, nome: "Santa Adélia", estado: 35},
	{id: 3545704, nome: "Santa Albertina", estado: 35},
	{id: 3545803, nome: "Santa Bárbara D'oeste", estado: 35},
	{id: 3546009, nome: "Santa Branca", estado: 35},
	{id: 3546108, nome: "Santa Clara D'oeste", estado: 35},
	{id: 3546207, nome: "Santa Cruz da Conceição", estado: 35},
	{id: 3546256, nome: "Santa Cruz da Esperança", estado: 35},
	{id: 3546306, nome: "Santa Cruz das Palmeiras", estado: 35},
	{id: 3546405, nome: "Santa Cruz do Rio Pardo", estado: 35},
	{id: 3546504, nome: "Santa Ernestina", estado: 35},
	{id: 3546603, nome: "Santa Fé do Sul", estado: 35},
	{id: 3546702, nome: "Santa Gertrudes", estado: 35},
	{id: 3546801, nome: "Santa Isabel", estado: 35},
	{id: 3546900, nome: "Santa Lúcia", estado: 35},
	{id: 3547007, nome: "Santa Maria da Serra", estado: 35},
	{id: 3547106, nome: "Santa Mercedes", estado: 35},
	{id: 3547205, nome: "Santana da Ponte Pensa", estado: 35},
	{id: 3547304, nome: "Santana de Parnaíba", estado: 35},
	{id: 3547403, nome: "Santa Rita D'oeste", estado: 35},
	{id: 3547502, nome: "Santa Rita do Passa Quatro", estado: 35},
	{id: 3547601, nome: "Santa Rosa de Viterbo", estado: 35},
	{id: 3547650, nome: "Santa Salete", estado: 35},
	{id: 3547700, nome: "Santo Anastácio", estado: 35},
	{id: 3547809, nome: "Santo André", estado: 35},
	{id: 3547908, nome: "Santo Antônio da Alegria", estado: 35},
	{id: 3548005, nome: "Santo Antônio de Posse", estado: 35},
	{id: 3548054, nome: "Santo Antônio do Aracanguá", estado: 35},
	{id: 3548104, nome: "Santo Antônio do Jardim", estado: 35},
	{id: 3548203, nome: "Santo Antônio do Pinhal", estado: 35},
	{id: 3548302, nome: "Santo Expedito", estado: 35},
	{id: 3548401, nome: "Santópolis do Aguapeí", estado: 35},
	{id: 3548500, nome: "Santos", estado: 35},
	{id: 3548609, nome: "São Bento do Sapucaí", estado: 35},
	{id: 3548708, nome: "São Bernardo do Campo", estado: 35},
	{id: 3548807, nome: "São Caetano do Sul", estado: 35},
	{id: 3548906, nome: "São Carlos", estado: 35},
	{id: 3549003, nome: "São Francisco", estado: 35},
	{id: 3549102, nome: "São João da Boa Vista", estado: 35},
	{id: 3549201, nome: "São João das Duas Pontes", estado: 35},
	{id: 3549250, nome: "São João de Iracema", estado: 35},
	{id: 3549300, nome: "São João do Pau D'alho", estado: 35},
	{id: 3549409, nome: "São Joaquim da Barra", estado: 35},
	{id: 3549508, nome: "São José da Bela Vista", estado: 35},
	{id: 3549607, nome: "São José do Barreiro", estado: 35},
	{id: 3549706, nome: "São José do Rio Pardo", estado: 35},
	{id: 3549805, nome: "São José do Rio Preto", estado: 35},
	{id: 3549904, nome: "São José dos Campos", estado: 35},
	{id: 3549953, nome: "São Lourenço da Serra", estado: 35},
	{id: 3550001, nome: "São Luís do Paraitinga", estado: 35},
	{id: 3550100, nome: "São Manuel", estado: 35},
	{id: 3550209, nome: "São Miguel Arcanjo", estado: 35},
	{id: 3550308, nome: "São Paulo", estado: 35},
	{id: 3550407, nome: "São Pedro", estado: 35},
	{id: 3550506, nome: "São Pedro do Turvo", estado: 35},
	{id: 3550605, nome: "São Roque", estado: 35},
	{id: 3550704, nome: "São Sebastião", estado: 35},
	{id: 3550803, nome: "São Sebastião da Grama", estado: 35},
	{id: 3550902, nome: "São Simão", estado: 35},
	{id: 3551009, nome: "São Vicente", estado: 35},
	{id: 3551108, nome: "Sarapuí", estado: 35},
	{id: 3551207, nome: "Sarutaiá", estado: 35},
	{id: 3551306, nome: "Sebastianópolis do Sul", estado: 35},
	{id: 3551405, nome: "Serra Azul", estado: 35},
	{id: 3551504, nome: "Serrana", estado: 35},
	{id: 3551603, nome: "Serra Negra", estado: 35},
	{id: 3551702, nome: "Sertãozinho", estado: 35},
	{id: 3551801, nome: "Sete Barras", estado: 35},
	{id: 3551900, nome: "Severínia", estado: 35},
	{id: 3552007, nome: "Silveiras", estado: 35},
	{id: 3552106, nome: "Socorro", estado: 35},
	{id: 3552205, nome: "Sorocaba", estado: 35},
	{id: 3552304, nome: "Sud Mennucci", estado: 35},
	{id: 3552403, nome: "Sumaré", estado: 35},
	{id: 3552502, nome: "Suzano", estado: 35},
	{id: 3552551, nome: "Suzanápolis", estado: 35},
	{id: 3552601, nome: "Tabapuã", estado: 35},
	{id: 3552700, nome: "Tabatinga", estado: 35},
	{id: 3552809, nome: "Taboão da Serra", estado: 35},
	{id: 3552908, nome: "Taciba", estado: 35},
	{id: 3553005, nome: "Taguaí", estado: 35},
	{id: 3553104, nome: "Taiaçu", estado: 35},
	{id: 3553203, nome: "Taiúva", estado: 35},
	{id: 3553302, nome: "Tambaú", estado: 35},
	{id: 3553401, nome: "Tanabi", estado: 35},
	{id: 3553500, nome: "Tapiraí", estado: 35},
	{id: 3553609, nome: "Tapiratiba", estado: 35},
	{id: 3553658, nome: "Taquaral", estado: 35},
	{id: 3553708, nome: "Taquaritinga", estado: 35},
	{id: 3553807, nome: "Taquarituba", estado: 35},
	{id: 3553856, nome: "Taquarivaí", estado: 35},
	{id: 3553906, nome: "Tarabai", estado: 35},
	{id: 3553955, nome: "Tarumã", estado: 35},
	{id: 3554003, nome: "Tatuí", estado: 35},
	{id: 3554102, nome: "Taubaté", estado: 35},
	{id: 3554201, nome: "Tejupá", estado: 35},
	{id: 3554300, nome: "Teodoro Sampaio", estado: 35},
	{id: 3554409, nome: "Terra Roxa", estado: 35},
	{id: 3554508, nome: "Tietê", estado: 35},
	{id: 3554607, nome: "Timburi", estado: 35},
	{id: 3554656, nome: "Torre de Pedra", estado: 35},
	{id: 3554706, nome: "Torrinha", estado: 35},
	{id: 3554755, nome: "Trabiju", estado: 35},
	{id: 3554805, nome: "Tremembé", estado: 35},
	{id: 3554904, nome: "Três Fronteiras", estado: 35},
	{id: 3554953, nome: "Tuiuti", estado: 35},
	{id: 3555000, nome: "Tupã", estado: 35},
	{id: 3555109, nome: "Tupi Paulista", estado: 35},
	{id: 3555208, nome: "Turiúba", estado: 35},
	{id: 3555307, nome: "Turmalina", estado: 35},
	{id: 3555356, nome: "Ubarana", estado: 35},
	{id: 3555406, nome: "Ubatuba", estado: 35},
	{id: 3555505, nome: "Ubirajara", estado: 35},
	{id: 3555604, nome: "Uchoa", estado: 35},
	{id: 3555703, nome: "União Paulista", estado: 35},
	{id: 3555802, nome: "Urânia", estado: 35},
	{id: 3555901, nome: "Uru", estado: 35},
	{id: 3556008, nome: "Urupês", estado: 35},
	{id: 3556107, nome: "Valentim Gentil", estado: 35},
	{id: 3556206, nome: "Valinhos", estado: 35},
	{id: 3556305, nome: "Valparaíso", estado: 35},
	{id: 3556354, nome: "Vargem", estado: 35},
	{id: 3556404, nome: "Vargem Grande do Sul", estado: 35},
	{id: 3556453, nome: "Vargem Grande Paulista", estado: 35},
	{id: 3556503, nome: "Várzea Paulista", estado: 35},
	{id: 3556602, nome: "Vera Cruz", estado: 35},
	{id: 3556701, nome: "Vinhedo", estado: 35},
	{id: 3556800, nome: "Viradouro", estado: 35},
	{id: 3556909, nome: "Vista Alegre do Alto", estado: 35},
	{id: 3556958, nome: "Vitória Brasil", estado: 35},
	{id: 3557006, nome: "Votorantim", estado: 35},
	{id: 3557105, nome: "Votuporanga", estado: 35},
	{id: 3557154, nome: "Zacarias", estado: 35},
	{id: 3557204, nome: "Chavantes", estado: 35},
	{id: 3557303, nome: "Estiva Gerbi", estado: 35},
	{id: 4100103, nome: "Abatiá", estado: 41},
	{id: 4100202, nome: "Adrianópolis", estado: 41},
	{id: 4100301, nome: "Agudos do Sul", estado: 41},
	{id: 4100400, nome: "Almirante Tamandaré", estado: 41},
	{id: 4100459, nome: "Altamira do Paraná", estado: 41},
	{id: 4100509, nome: "Altônia", estado: 41},
	{id: 4100608, nome: "Alto Paraná", estado: 41},
	{id: 4100707, nome: "Alto Piquiri", estado: 41},
	{id: 4100806, nome: "Alvorada do Sul", estado: 41},
	{id: 4100905, nome: "Amaporã", estado: 41},
	{id: 4101002, nome: "Ampére", estado: 41},
	{id: 4101051, nome: "Anahy", estado: 41},
	{id: 4101101, nome: "Andirá", estado: 41},
	{id: 4101150, nome: "Ângulo", estado: 41},
	{id: 4101200, nome: "Antonina", estado: 41},
	{id: 4101309, nome: "Antônio Olinto", estado: 41},
	{id: 4101408, nome: "Apucarana", estado: 41},
	{id: 4101507, nome: "Arapongas", estado: 41},
	{id: 4101606, nome: "Arapoti", estado: 41},
	{id: 4101655, nome: "Arapuã", estado: 41},
	{id: 4101705, nome: "Araruna", estado: 41},
	{id: 4101804, nome: "Araucária", estado: 41},
	{id: 4101853, nome: "Ariranha do Ivaí", estado: 41},
	{id: 4101903, nome: "Assaí", estado: 41},
	{id: 4102000, nome: "Assis Chateaubriand", estado: 41},
	{id: 4102109, nome: "Astorga", estado: 41},
	{id: 4102208, nome: "Atalaia", estado: 41},
	{id: 4102307, nome: "Balsa Nova", estado: 41},
	{id: 4102406, nome: "Bandeirantes", estado: 41},
	{id: 4102505, nome: "Barbosa Ferraz", estado: 41},
	{id: 4102604, nome: "Barracão", estado: 41},
	{id: 4102703, nome: "Barra do Jacaré", estado: 41},
	{id: 4102752, nome: "Bela Vista da Caroba", estado: 41},
	{id: 4102802, nome: "Bela Vista do Paraíso", estado: 41},
	{id: 4102901, nome: "Bituruna", estado: 41},
	{id: 4103008, nome: "Boa Esperança", estado: 41},
	{id: 4103024, nome: "Boa Esperança do Iguaçu", estado: 41},
	{id: 4103040, nome: "Boa Ventura de São Roque", estado: 41},
	{id: 4103057, nome: "Boa Vista da Aparecida", estado: 41},
	{id: 4103107, nome: "Bocaiúva do Sul", estado: 41},
	{id: 4103156, nome: "Bom Jesus do Sul", estado: 41},
	{id: 4103206, nome: "Bom Sucesso", estado: 41},
	{id: 4103222, nome: "Bom Sucesso do Sul", estado: 41},
	{id: 4103305, nome: "Borrazópolis", estado: 41},
	{id: 4103354, nome: "Braganey", estado: 41},
	{id: 4103370, nome: "Brasilândia do Sul", estado: 41},
	{id: 4103404, nome: "Cafeara", estado: 41},
	{id: 4103453, nome: "Cafelândia", estado: 41},
	{id: 4103479, nome: "Cafezal do Sul", estado: 41},
	{id: 4103503, nome: "Califórnia", estado: 41},
	{id: 4103602, nome: "Cambará", estado: 41},
	{id: 4103701, nome: "Cambé", estado: 41},
	{id: 4103800, nome: "Cambira", estado: 41},
	{id: 4103909, nome: "Campina da Lagoa", estado: 41},
	{id: 4103958, nome: "Campina do Simão", estado: 41},
	{id: 4104006, nome: "Campina Grande do Sul", estado: 41},
	{id: 4104055, nome: "Campo Bonito", estado: 41},
	{id: 4104105, nome: "Campo do Tenente", estado: 41},
	{id: 4104204, nome: "Campo Largo", estado: 41},
	{id: 4104253, nome: "Campo Magro", estado: 41},
	{id: 4104303, nome: "Campo Mourão", estado: 41},
	{id: 4104402, nome: "Cândido de Abreu", estado: 41},
	{id: 4104428, nome: "Candói", estado: 41},
	{id: 4104451, nome: "Cantagalo", estado: 41},
	{id: 4104501, nome: "Capanema", estado: 41},
	{id: 4104600, nome: "Capitão Leônidas Marques", estado: 41},
	{id: 4104659, nome: "Carambeí", estado: 41},
	{id: 4104709, nome: "Carlópolis", estado: 41},
	{id: 4104808, nome: "Cascavel", estado: 41},
	{id: 4104907, nome: "Castro", estado: 41},
	{id: 4105003, nome: "Catanduvas", estado: 41},
	{id: 4105102, nome: "Centenário do Sul", estado: 41},
	{id: 4105201, nome: "Cerro Azul", estado: 41},
	{id: 4105300, nome: "Céu Azul", estado: 41},
	{id: 4105409, nome: "Chopinzinho", estado: 41},
	{id: 4105508, nome: "Cianorte", estado: 41},
	{id: 4105607, nome: "Cidade Gaúcha", estado: 41},
	{id: 4105706, nome: "Clevelândia", estado: 41},
	{id: 4105805, nome: "Colombo", estado: 41},
	{id: 4105904, nome: "Colorado", estado: 41},
	{id: 4106001, nome: "Congonhinhas", estado: 41},
	{id: 4106100, nome: "Conselheiro Mairinck", estado: 41},
	{id: 4106209, nome: "Contenda", estado: 41},
	{id: 4106308, nome: "Corbélia", estado: 41},
	{id: 4106407, nome: "Cornélio Procópio", estado: 41},
	{id: 4106456, nome: "Coronel domingos Soares", estado: 41},
	{id: 4106506, nome: "Coronel Vivida", estado: 41},
	{id: 4106555, nome: "Corumbataí do Sul", estado: 41},
	{id: 4106571, nome: "Cruzeiro do Iguaçu", estado: 41},
	{id: 4106605, nome: "Cruzeiro do Oeste", estado: 41},
	{id: 4106704, nome: "Cruzeiro do Sul", estado: 41},
	{id: 4106803, nome: "Cruz Machado", estado: 41},
	{id: 4106852, nome: "Cruzmaltina", estado: 41},
	{id: 4106902, nome: "Curitiba", estado: 41},
	{id: 4107009, nome: "Curiúva", estado: 41},
	{id: 4107108, nome: "Diamante do Norte", estado: 41},
	{id: 4107124, nome: "Diamante do Sul", estado: 41},
	{id: 4107157, nome: "Diamante D'oeste", estado: 41},
	{id: 4107207, nome: "dois Vizinhos", estado: 41},
	{id: 4107256, nome: "douradina", estado: 41},
	{id: 4107306, nome: "doutor Camargo", estado: 41},
	{id: 4107405, nome: "Enéas Marques", estado: 41},
	{id: 4107504, nome: "Engenheiro Beltrão", estado: 41},
	{id: 4107520, nome: "Esperança Nova", estado: 41},
	{id: 4107538, nome: "Entre Rios do Oeste", estado: 41},
	{id: 4107546, nome: "Espigão Alto do Iguaçu", estado: 41},
	{id: 4107553, nome: "Farol", estado: 41},
	{id: 4107603, nome: "Faxinal", estado: 41},
	{id: 4107652, nome: "Fazenda Rio Grande", estado: 41},
	{id: 4107702, nome: "Fênix", estado: 41},
	{id: 4107736, nome: "Fernandes Pinheiro", estado: 41},
	{id: 4107751, nome: "Figueira", estado: 41},
	{id: 4107801, nome: "Floraí", estado: 41},
	{id: 4107850, nome: "Flor da Serra do Sul", estado: 41},
	{id: 4107900, nome: "Floresta", estado: 41},
	{id: 4108007, nome: "Florestópolis", estado: 41},
	{id: 4108106, nome: "Flórida", estado: 41},
	{id: 4108205, nome: "Formosa do Oeste", estado: 41},
	{id: 4108304, nome: "Foz do Iguaçu", estado: 41},
	{id: 4108320, nome: "Francisco Alves", estado: 41},
	{id: 4108403, nome: "Francisco Beltrão", estado: 41},
	{id: 4108452, nome: "Foz do Jordão", estado: 41},
	{id: 4108502, nome: "General Carneiro", estado: 41},
	{id: 4108551, nome: "Godoy Moreira", estado: 41},
	{id: 4108601, nome: "Goioerê", estado: 41},
	{id: 4108650, nome: "Goioxim", estado: 41},
	{id: 4108700, nome: "Grandes Rios", estado: 41},
	{id: 4108809, nome: "Guaíra", estado: 41},
	{id: 4108908, nome: "Guairaçá", estado: 41},
	{id: 4108957, nome: "Guamiranga", estado: 41},
	{id: 4109005, nome: "Guapirama", estado: 41},
	{id: 4109104, nome: "Guaporema", estado: 41},
	{id: 4109203, nome: "Guaraci", estado: 41},
	{id: 4109302, nome: "Guaraniaçu", estado: 41},
	{id: 4109401, nome: "Guarapuava", estado: 41},
	{id: 4109500, nome: "Guaraqueçaba", estado: 41},
	{id: 4109609, nome: "Guaratuba", estado: 41},
	{id: 4109658, nome: "Honório Serpa", estado: 41},
	{id: 4109708, nome: "Ibaiti", estado: 41},
	{id: 4109757, nome: "Ibema", estado: 41},
	{id: 4109807, nome: "Ibiporã", estado: 41},
	{id: 4109906, nome: "Icaraíma", estado: 41},
	{id: 4110003, nome: "Iguaraçu", estado: 41},
	{id: 4110052, nome: "Iguatu", estado: 41},
	{id: 4110078, nome: "Imbaú", estado: 41},
	{id: 4110102, nome: "Imbituva", estado: 41},
	{id: 4110201, nome: "Inácio Martins", estado: 41},
	{id: 4110300, nome: "Inajá", estado: 41},
	{id: 4110409, nome: "Indianópolis", estado: 41},
	{id: 4110508, nome: "Ipiranga", estado: 41},
	{id: 4110607, nome: "Iporã", estado: 41},
	{id: 4110656, nome: "Iracema do Oeste", estado: 41},
	{id: 4110706, nome: "Irati", estado: 41},
	{id: 4110805, nome: "Iretama", estado: 41},
	{id: 4110904, nome: "Itaguajé", estado: 41},
	{id: 4110953, nome: "Itaipulândia", estado: 41},
	{id: 4111001, nome: "Itambaracá", estado: 41},
	{id: 4111100, nome: "Itambé", estado: 41},
	{id: 4111209, nome: "Itapejara D'oeste", estado: 41},
	{id: 4111258, nome: "Itaperuçu", estado: 41},
	{id: 4111308, nome: "Itaúna do Sul", estado: 41},
	{id: 4111407, nome: "Ivaí", estado: 41},
	{id: 4111506, nome: "Ivaiporã", estado: 41},
	{id: 4111555, nome: "Ivaté", estado: 41},
	{id: 4111605, nome: "Ivatuba", estado: 41},
	{id: 4111704, nome: "Jaboti", estado: 41},
	{id: 4111803, nome: "Jacarezinho", estado: 41},
	{id: 4111902, nome: "Jaguapitã", estado: 41},
	{id: 4112009, nome: "Jaguariaíva", estado: 41},
	{id: 4112108, nome: "Jandaia do Sul", estado: 41},
	{id: 4112207, nome: "Janiópolis", estado: 41},
	{id: 4112306, nome: "Japira", estado: 41},
	{id: 4112405, nome: "Japurá", estado: 41},
	{id: 4112504, nome: "Jardim Alegre", estado: 41},
	{id: 4112603, nome: "Jardim Olinda", estado: 41},
	{id: 4112702, nome: "Jataizinho", estado: 41},
	{id: 4112751, nome: "Jesuítas", estado: 41},
	{id: 4112801, nome: "Joaquim Távora", estado: 41},
	{id: 4112900, nome: "Jundiaí do Sul", estado: 41},
	{id: 4112959, nome: "Juranda", estado: 41},
	{id: 4113007, nome: "Jussara", estado: 41},
	{id: 4113106, nome: "Kaloré", estado: 41},
	{id: 4113205, nome: "Lapa", estado: 41},
	{id: 4113254, nome: "Laranjal", estado: 41},
	{id: 4113304, nome: "Laranjeiras do Sul", estado: 41},
	{id: 4113403, nome: "Leópolis", estado: 41},
	{id: 4113429, nome: "Lidianópolis", estado: 41},
	{id: 4113452, nome: "Lindoeste", estado: 41},
	{id: 4113502, nome: "Loanda", estado: 41},
	{id: 4113601, nome: "Lobato", estado: 41},
	{id: 4113700, nome: "Londrina", estado: 41},
	{id: 4113734, nome: "Luiziana", estado: 41},
	{id: 4113759, nome: "Lunardelli", estado: 41},
	{id: 4113809, nome: "Lupionópolis", estado: 41},
	{id: 4113908, nome: "Mallet", estado: 41},
	{id: 4114005, nome: "Mamborê", estado: 41},
	{id: 4114104, nome: "Mandaguaçu", estado: 41},
	{id: 4114203, nome: "Mandaguari", estado: 41},
	{id: 4114302, nome: "Mandirituba", estado: 41},
	{id: 4114351, nome: "Manfrinópolis", estado: 41},
	{id: 4114401, nome: "Mangueirinha", estado: 41},
	{id: 4114500, nome: "Manoel Ribas", estado: 41},
	{id: 4114609, nome: "Marechal Cândido Rondon", estado: 41},
	{id: 4114708, nome: "Maria Helena", estado: 41},
	{id: 4114807, nome: "Marialva", estado: 41},
	{id: 4114906, nome: "Marilândia do Sul", estado: 41},
	{id: 4115002, nome: "Marilena", estado: 41},
	{id: 4115101, nome: "Mariluz", estado: 41},
	{id: 4115200, nome: "Maringá", estado: 41},
	{id: 4115309, nome: "Mariópolis", estado: 41},
	{id: 4115358, nome: "Maripá", estado: 41},
	{id: 4115408, nome: "Marmeleiro", estado: 41},
	{id: 4115457, nome: "Marquinho", estado: 41},
	{id: 4115507, nome: "Marumbi", estado: 41},
	{id: 4115606, nome: "Matelândia", estado: 41},
	{id: 4115705, nome: "Matinhos", estado: 41},
	{id: 4115739, nome: "Mato Rico", estado: 41},
	{id: 4115754, nome: "Mauá da Serra", estado: 41},
	{id: 4115804, nome: "Medianeira", estado: 41},
	{id: 4115853, nome: "Mercedes", estado: 41},
	{id: 4115903, nome: "Mirador", estado: 41},
	{id: 4116000, nome: "Miraselva", estado: 41},
	{id: 4116059, nome: "Missal", estado: 41},
	{id: 4116109, nome: "Moreira Sales", estado: 41},
	{id: 4116208, nome: "Morretes", estado: 41},
	{id: 4116307, nome: "Munhoz de Melo", estado: 41},
	{id: 4116406, nome: "Nossa Senhora das Graças", estado: 41},
	{id: 4116505, nome: "Nova Aliança do Ivaí", estado: 41},
	{id: 4116604, nome: "Nova América da Colina", estado: 41},
	{id: 4116703, nome: "Nova Aurora", estado: 41},
	{id: 4116802, nome: "Nova Cantu", estado: 41},
	{id: 4116901, nome: "Nova Esperança", estado: 41},
	{id: 4116950, nome: "Nova Esperança do Sudoeste", estado: 41},
	{id: 4117008, nome: "Nova Fátima", estado: 41},
	{id: 4117057, nome: "Nova Laranjeiras", estado: 41},
	{id: 4117107, nome: "Nova Londrina", estado: 41},
	{id: 4117206, nome: "Nova Olímpia", estado: 41},
	{id: 4117214, nome: "Nova Santa Bárbara", estado: 41},
	{id: 4117222, nome: "Nova Santa Rosa", estado: 41},
	{id: 4117255, nome: "Nova Prata do Iguaçu", estado: 41},
	{id: 4117271, nome: "Nova Tebas", estado: 41},
	{id: 4117297, nome: "Novo Itacolomi", estado: 41},
	{id: 4117305, nome: "Ortigueira", estado: 41},
	{id: 4117404, nome: "Ourizona", estado: 41},
	{id: 4117453, nome: "Ouro Verde do Oeste", estado: 41},
	{id: 4117503, nome: "Paiçandu", estado: 41},
	{id: 4117602, nome: "Palmas", estado: 41},
	{id: 4117701, nome: "Palmeira", estado: 41},
	{id: 4117800, nome: "Palmital", estado: 41},
	{id: 4117909, nome: "Palotina", estado: 41},
	{id: 4118006, nome: "Paraíso do Norte", estado: 41},
	{id: 4118105, nome: "Paranacity", estado: 41},
	{id: 4118204, nome: "Paranaguá", estado: 41},
	{id: 4118303, nome: "Paranapoema", estado: 41},
	{id: 4118402, nome: "Paranavaí", estado: 41},
	{id: 4118451, nome: "Pato Bragado", estado: 41},
	{id: 4118501, nome: "Pato Branco", estado: 41},
	{id: 4118600, nome: "Paula Freitas", estado: 41},
	{id: 4118709, nome: "Paulo Frontin", estado: 41},
	{id: 4118808, nome: "Peabiru", estado: 41},
	{id: 4118857, nome: "Perobal", estado: 41},
	{id: 4118907, nome: "Pérola", estado: 41},
	{id: 4119004, nome: "Pérola D'oeste", estado: 41},
	{id: 4119103, nome: "Piên", estado: 41},
	{id: 4119152, nome: "Pinhais", estado: 41},
	{id: 4119202, nome: "Pinhalão", estado: 41},
	{id: 4119251, nome: "Pinhal de São Bento", estado: 41},
	{id: 4119301, nome: "Pinhão", estado: 41},
	{id: 4119400, nome: "Piraí do Sul", estado: 41},
	{id: 4119509, nome: "Piraquara", estado: 41},
	{id: 4119608, nome: "Pitanga", estado: 41},
	{id: 4119657, nome: "Pitangueiras", estado: 41},
	{id: 4119707, nome: "Planaltina do Paraná", estado: 41},
	{id: 4119806, nome: "Planalto", estado: 41},
	{id: 4119905, nome: "Ponta Grossa", estado: 41},
	{id: 4119954, nome: "Pontal do Paraná", estado: 41},
	{id: 4120002, nome: "Porecatu", estado: 41},
	{id: 4120101, nome: "Porto Amazonas", estado: 41},
	{id: 4120150, nome: "Porto Barreiro", estado: 41},
	{id: 4120200, nome: "Porto Rico", estado: 41},
	{id: 4120309, nome: "Porto Vitória", estado: 41},
	{id: 4120333, nome: "Prado Ferreira", estado: 41},
	{id: 4120358, nome: "Pranchita", estado: 41},
	{id: 4120408, nome: "Presidente Castelo Branco", estado: 41},
	{id: 4120507, nome: "Primeiro de Maio", estado: 41},
	{id: 4120606, nome: "Prudentópolis", estado: 41},
	{id: 4120655, nome: "Quarto Centenário", estado: 41},
	{id: 4120705, nome: "Quatiguá", estado: 41},
	{id: 4120804, nome: "Quatro Barras", estado: 41},
	{id: 4120853, nome: "Quatro Pontes", estado: 41},
	{id: 4120903, nome: "Quedas do Iguaçu", estado: 41},
	{id: 4121000, nome: "Querência do Norte", estado: 41},
	{id: 4121109, nome: "Quinta do Sol", estado: 41},
	{id: 4121208, nome: "Quitandinha", estado: 41},
	{id: 4121257, nome: "Ramilândia", estado: 41},
	{id: 4121307, nome: "Rancho Alegre", estado: 41},
	{id: 4121356, nome: "Rancho Alegre D'oeste", estado: 41},
	{id: 4121406, nome: "Realeza", estado: 41},
	{id: 4121505, nome: "Rebouças", estado: 41},
	{id: 4121604, nome: "Renascença", estado: 41},
	{id: 4121703, nome: "Reserva", estado: 41},
	{id: 4121752, nome: "Reserva do Iguaçu", estado: 41},
	{id: 4121802, nome: "Ribeirão Claro", estado: 41},
	{id: 4121901, nome: "Ribeirão do Pinhal", estado: 41},
	{id: 4122008, nome: "Rio Azul", estado: 41},
	{id: 4122107, nome: "Rio Bom", estado: 41},
	{id: 4122156, nome: "Rio Bonito do Iguaçu", estado: 41},
	{id: 4122172, nome: "Rio Branco do Ivaí", estado: 41},
	{id: 4122206, nome: "Rio Branco do Sul", estado: 41},
	{id: 4122305, nome: "Rio Negro", estado: 41},
	{id: 4122404, nome: "Rolândia", estado: 41},
	{id: 4122503, nome: "Roncador", estado: 41},
	{id: 4122602, nome: "Rondon", estado: 41},
	{id: 4122651, nome: "Rosário do Ivaí", estado: 41},
	{id: 4122701, nome: "Sabáudia", estado: 41},
	{id: 4122800, nome: "Salgado Filho", estado: 41},
	{id: 4122909, nome: "Salto do Itararé", estado: 41},
	{id: 4123006, nome: "Salto do Lontra", estado: 41},
	{id: 4123105, nome: "Santa Amélia", estado: 41},
	{id: 4123204, nome: "Santa Cecília do Pavão", estado: 41},
	{id: 4123303, nome: "Santa Cruz de Monte Castelo", estado: 41},
	{id: 4123402, nome: "Santa Fé", estado: 41},
	{id: 4123501, nome: "Santa Helena", estado: 41},
	{id: 4123600, nome: "Santa Inês", estado: 41},
	{id: 4123709, nome: "Santa Isabel do Ivaí", estado: 41},
	{id: 4123808, nome: "Santa Izabel do Oeste", estado: 41},
	{id: 4123824, nome: "Santa Lúcia", estado: 41},
	{id: 4123857, nome: "Santa Maria do Oeste", estado: 41},
	{id: 4123907, nome: "Santa Mariana", estado: 41},
	{id: 4123956, nome: "Santa Mônica", estado: 41},
	{id: 4124004, nome: "Santana do Itararé", estado: 41},
	{id: 4124020, nome: "Santa Tereza do Oeste", estado: 41},
	{id: 4124053, nome: "Santa Terezinha de Itaipu", estado: 41},
	{id: 4124103, nome: "Santo Antônio da Platina", estado: 41},
	{id: 4124202, nome: "Santo Antônio do Caiuá", estado: 41},
	{id: 4124301, nome: "Santo Antônio do Paraíso", estado: 41},
	{id: 4124400, nome: "Santo Antônio do Sudoeste", estado: 41},
	{id: 4124509, nome: "Santo Inácio", estado: 41},
	{id: 4124608, nome: "São Carlos do Ivaí", estado: 41},
	{id: 4124707, nome: "São Jerônimo da Serra", estado: 41},
	{id: 4124806, nome: "São João", estado: 41},
	{id: 4124905, nome: "São João do Caiuá", estado: 41},
	{id: 4125001, nome: "São João do Ivaí", estado: 41},
	{id: 4125100, nome: "São João do Triunfo", estado: 41},
	{id: 4125209, nome: "São Jorge D'oeste", estado: 41},
	{id: 4125308, nome: "São Jorge do Ivaí", estado: 41},
	{id: 4125357, nome: "São Jorge do Patrocínio", estado: 41},
	{id: 4125407, nome: "São José da Boa Vista", estado: 41},
	{id: 4125456, nome: "São José das Palmeiras", estado: 41},
	{id: 4125506, nome: "São José dos Pinhais", estado: 41},
	{id: 4125555, nome: "São Manoel do Paraná", estado: 41},
	{id: 4125605, nome: "São Mateus do Sul", estado: 41},
	{id: 4125704, nome: "São Miguel do Iguaçu", estado: 41},
	{id: 4125753, nome: "São Pedro do Iguaçu", estado: 41},
	{id: 4125803, nome: "São Pedro do Ivaí", estado: 41},
	{id: 4125902, nome: "São Pedro do Paraná", estado: 41},
	{id: 4126009, nome: "São Sebastião da Amoreira", estado: 41},
	{id: 4126108, nome: "São Tomé", estado: 41},
	{id: 4126207, nome: "Sapopema", estado: 41},
	{id: 4126256, nome: "Sarandi", estado: 41},
	{id: 4126272, nome: "Saudade do Iguaçu", estado: 41},
	{id: 4126306, nome: "Sengés", estado: 41},
	{id: 4126355, nome: "Serranópolis do Iguaçu", estado: 41},
	{id: 4126405, nome: "Sertaneja", estado: 41},
	{id: 4126504, nome: "Sertanópolis", estado: 41},
	{id: 4126603, nome: "Siqueira Campos", estado: 41},
	{id: 4126652, nome: "Sulina", estado: 41},
	{id: 4126678, nome: "Tamarana", estado: 41},
	{id: 4126702, nome: "Tamboara", estado: 41},
	{id: 4126801, nome: "Tapejara", estado: 41},
	{id: 4126900, nome: "Tapira", estado: 41},
	{id: 4127007, nome: "Teixeira Soares", estado: 41},
	{id: 4127106, nome: "Telêmaco Borba", estado: 41},
	{id: 4127205, nome: "Terra Boa", estado: 41},
	{id: 4127304, nome: "Terra Rica", estado: 41},
	{id: 4127403, nome: "Terra Roxa", estado: 41},
	{id: 4127502, nome: "Tibagi", estado: 41},
	{id: 4127601, nome: "Tijucas do Sul", estado: 41},
	{id: 4127700, nome: "Toledo", estado: 41},
	{id: 4127809, nome: "Tomazina", estado: 41},
	{id: 4127858, nome: "Três Barras do Paraná", estado: 41},
	{id: 4127882, nome: "Tunas do Paraná", estado: 41},
	{id: 4127908, nome: "Tuneiras do Oeste", estado: 41},
	{id: 4127957, nome: "Tupãssi", estado: 41},
	{id: 4127965, nome: "Turvo", estado: 41},
	{id: 4128005, nome: "Ubiratã", estado: 41},
	{id: 4128104, nome: "Umuarama", estado: 41},
	{id: 4128203, nome: "União da Vitória", estado: 41},
	{id: 4128302, nome: "Uniflor", estado: 41},
	{id: 4128401, nome: "Uraí", estado: 41},
	{id: 4128500, nome: "Wenceslau Braz", estado: 41},
	{id: 4128534, nome: "Ventania", estado: 41},
	{id: 4128559, nome: "Vera Cruz do Oeste", estado: 41},
	{id: 4128609, nome: "Verê", estado: 41},
	{id: 4128625, nome: "Alto Paraíso", estado: 41},
	{id: 4128633, nome: "doutor Ulysses", estado: 41},
	{id: 4128658, nome: "Virmond", estado: 41},
	{id: 4128708, nome: "Vitorino", estado: 41},
	{id: 4128807, nome: "Xambrê", estado: 41},
	{id: 4200051, nome: "Abdon Batista", estado: 42},
	{id: 4200101, nome: "Abelardo Luz", estado: 42},
	{id: 4200200, nome: "Agrolândia", estado: 42},
	{id: 4200309, nome: "Agronômica", estado: 42},
	{id: 4200408, nome: "Água doce", estado: 42},
	{id: 4200507, nome: "Águas de Chapecó", estado: 42},
	{id: 4200556, nome: "Águas Frias", estado: 42},
	{id: 4200606, nome: "Águas Mornas", estado: 42},
	{id: 4200705, nome: "Alfredo Wagner", estado: 42},
	{id: 4200754, nome: "Alto Bela Vista", estado: 42},
	{id: 4200804, nome: "Anchieta", estado: 42},
	{id: 4200903, nome: "Angelina", estado: 42},
	{id: 4201000, nome: "Anita Garibaldi", estado: 42},
	{id: 4201109, nome: "Anitápolis", estado: 42},
	{id: 4201208, nome: "Antônio Carlos", estado: 42},
	{id: 4201257, nome: "Apiúna", estado: 42},
	{id: 4201273, nome: "Arabutã", estado: 42},
	{id: 4201307, nome: "Araquari", estado: 42},
	{id: 4201406, nome: "Araranguá", estado: 42},
	{id: 4201505, nome: "Armazém", estado: 42},
	{id: 4201604, nome: "Arroio Trinta", estado: 42},
	{id: 4201653, nome: "Arvoredo", estado: 42},
	{id: 4201703, nome: "Ascurra", estado: 42},
	{id: 4201802, nome: "Atalanta", estado: 42},
	{id: 4201901, nome: "Aurora", estado: 42},
	{id: 4201950, nome: "Balneário Arroio do Silva", estado: 42},
	{id: 4202008, nome: "Balneário Camboriú", estado: 42},
	{id: 4202057, nome: "Balneário Barra do Sul", estado: 42},
	{id: 4202073, nome: "Balneário Gaivota", estado: 42},
	{id: 4202081, nome: "Bandeirante", estado: 42},
	{id: 4202099, nome: "Barra Bonita", estado: 42},
	{id: 4202107, nome: "Barra Velha", estado: 42},
	{id: 4202131, nome: "Bela Vista do Toldo", estado: 42},
	{id: 4202156, nome: "Belmonte", estado: 42},
	{id: 4202206, nome: "Benedito Novo", estado: 42},
	{id: 4202305, nome: "Biguaçu", estado: 42},
	{id: 4202404, nome: "Blumenau", estado: 42},
	{id: 4202438, nome: "Bocaina do Sul", estado: 42},
	{id: 4202453, nome: "Bombinhas", estado: 42},
	{id: 4202503, nome: "Bom Jardim da Serra", estado: 42},
	{id: 4202537, nome: "Bom Jesus", estado: 42},
	{id: 4202578, nome: "Bom Jesus do Oeste", estado: 42},
	{id: 4202602, nome: "Bom Retiro", estado: 42},
	{id: 4202701, nome: "Botuverá", estado: 42},
	{id: 4202800, nome: "Braço do Norte", estado: 42},
	{id: 4202859, nome: "Braço do Trombudo", estado: 42},
	{id: 4202875, nome: "Brunópolis", estado: 42},
	{id: 4202909, nome: "Brusque", estado: 42},
	{id: 4203006, nome: "Caçador", estado: 42},
	{id: 4203105, nome: "Caibi", estado: 42},
	{id: 4203154, nome: "Calmon", estado: 42},
	{id: 4203204, nome: "Camboriú", estado: 42},
	{id: 4203253, nome: "Capão Alto", estado: 42},
	{id: 4203303, nome: "Campo Alegre", estado: 42},
	{id: 4203402, nome: "Campo Belo do Sul", estado: 42},
	{id: 4203501, nome: "Campo Erê", estado: 42},
	{id: 4203600, nome: "Campos Novos", estado: 42},
	{id: 4203709, nome: "Canelinha", estado: 42},
	{id: 4203808, nome: "Canoinhas", estado: 42},
	{id: 4203907, nome: "Capinzal", estado: 42},
	{id: 4203956, nome: "Capivari de Baixo", estado: 42},
	{id: 4204004, nome: "Catanduvas", estado: 42},
	{id: 4204103, nome: "Caxambu do Sul", estado: 42},
	{id: 4204152, nome: "Celso Ramos", estado: 42},
	{id: 4204178, nome: "Cerro Negro", estado: 42},
	{id: 4204194, nome: "Chapadão do Lageado", estado: 42},
	{id: 4204202, nome: "Chapecó", estado: 42},
	{id: 4204251, nome: "Cocal do Sul", estado: 42},
	{id: 4204301, nome: "Concórdia", estado: 42},
	{id: 4204350, nome: "Cordilheira Alta", estado: 42},
	{id: 4204400, nome: "Coronel Freitas", estado: 42},
	{id: 4204459, nome: "Coronel Martins", estado: 42},
	{id: 4204509, nome: "Corupá", estado: 42},
	{id: 4204558, nome: "Correia Pinto", estado: 42},
	{id: 4204608, nome: "Criciúma", estado: 42},
	{id: 4204707, nome: "Cunha Porã", estado: 42},
	{id: 4204756, nome: "Cunhataí", estado: 42},
	{id: 4204806, nome: "Curitibanos", estado: 42},
	{id: 4204905, nome: "descanso", estado: 42},
	{id: 4205001, nome: "Dionísio Cerqueira", estado: 42},
	{id: 4205100, nome: "dona Emma", estado: 42},
	{id: 4205159, nome: "doutor Pedrinho", estado: 42},
	{id: 4205175, nome: "Entre Rios", estado: 42},
	{id: 4205191, nome: "Ermo", estado: 42},
	{id: 4205209, nome: "Erval Velho", estado: 42},
	{id: 4205308, nome: "Faxinal dos Guedes", estado: 42},
	{id: 4205357, nome: "Flor do Sertão", estado: 42},
	{id: 4205407, nome: "Florianópolis", estado: 42},
	{id: 4205431, nome: "Formosa do Sul", estado: 42},
	{id: 4205456, nome: "Forquilhinha", estado: 42},
	{id: 4205506, nome: "Fraiburgo", estado: 42},
	{id: 4205555, nome: "Frei Rogério", estado: 42},
	{id: 4205605, nome: "Galvão", estado: 42},
	{id: 4205704, nome: "Garopaba", estado: 42},
	{id: 4205803, nome: "Garuva", estado: 42},
	{id: 4205902, nome: "Gaspar", estado: 42},
	{id: 4206009, nome: "Governador Celso Ramos", estado: 42},
	{id: 4206108, nome: "Grão Pará", estado: 42},
	{id: 4206207, nome: "Gravatal", estado: 42},
	{id: 4206306, nome: "Guabiruba", estado: 42},
	{id: 4206405, nome: "Guaraciaba", estado: 42},
	{id: 4206504, nome: "Guaramirim", estado: 42},
	{id: 4206603, nome: "Guarujá do Sul", estado: 42},
	{id: 4206652, nome: "Guatambú", estado: 42},
	{id: 4206702, nome: "Herval D'oeste", estado: 42},
	{id: 4206751, nome: "Ibiam", estado: 42},
	{id: 4206801, nome: "Ibicaré", estado: 42},
	{id: 4206900, nome: "Ibirama", estado: 42},
	{id: 4207007, nome: "Içara", estado: 42},
	{id: 4207106, nome: "Ilhota", estado: 42},
	{id: 4207205, nome: "Imaruí", estado: 42},
	{id: 4207304, nome: "Imbituba", estado: 42},
	{id: 4207403, nome: "Imbuia", estado: 42},
	{id: 4207502, nome: "Indaial", estado: 42},
	{id: 4207577, nome: "Iomerê", estado: 42},
	{id: 4207601, nome: "Ipira", estado: 42},
	{id: 4207650, nome: "Iporã do Oeste", estado: 42},
	{id: 4207684, nome: "Ipuaçu", estado: 42},
	{id: 4207700, nome: "Ipumirim", estado: 42},
	{id: 4207759, nome: "Iraceminha", estado: 42},
	{id: 4207809, nome: "Irani", estado: 42},
	{id: 4207858, nome: "Irati", estado: 42},
	{id: 4207908, nome: "Irineópolis", estado: 42},
	{id: 4208005, nome: "Itá", estado: 42},
	{id: 4208104, nome: "Itaiópolis", estado: 42},
	{id: 4208203, nome: "Itajaí", estado: 42},
	{id: 4208302, nome: "Itapema", estado: 42},
	{id: 4208401, nome: "Itapiranga", estado: 42},
	{id: 4208450, nome: "Itapoá", estado: 42},
	{id: 4208500, nome: "Ituporanga", estado: 42},
	{id: 4208609, nome: "Jaborá", estado: 42},
	{id: 4208708, nome: "Jacinto Machado", estado: 42},
	{id: 4208807, nome: "Jaguaruna", estado: 42},
	{id: 4208906, nome: "Jaraguá do Sul", estado: 42},
	{id: 4208955, nome: "Jardinópolis", estado: 42},
	{id: 4209003, nome: "Joaçaba", estado: 42},
	{id: 4209102, nome: "Joinville", estado: 42},
	{id: 4209151, nome: "José Boiteux", estado: 42},
	{id: 4209177, nome: "Jupiá", estado: 42},
	{id: 4209201, nome: "Lacerdópolis", estado: 42},
	{id: 4209300, nome: "Lages", estado: 42},
	{id: 4209409, nome: "Laguna", estado: 42},
	{id: 4209458, nome: "Lajeado Grande", estado: 42},
	{id: 4209508, nome: "Laurentino", estado: 42},
	{id: 4209607, nome: "Lauro Muller", estado: 42},
	{id: 4209706, nome: "Lebon Régis", estado: 42},
	{id: 4209805, nome: "Leoberto Leal", estado: 42},
	{id: 4209854, nome: "Lindóia do Sul", estado: 42},
	{id: 4209904, nome: "Lontras", estado: 42},
	{id: 4210001, nome: "Luiz Alves", estado: 42},
	{id: 4210035, nome: "Luzerna", estado: 42},
	{id: 4210050, nome: "Macieira", estado: 42},
	{id: 4210100, nome: "Mafra", estado: 42},
	{id: 4210209, nome: "Major Gercino", estado: 42},
	{id: 4210308, nome: "Major Vieira", estado: 42},
	{id: 4210407, nome: "Maracajá", estado: 42},
	{id: 4210506, nome: "Maravilha", estado: 42},
	{id: 4210555, nome: "Marema", estado: 42},
	{id: 4210605, nome: "Massaranduba", estado: 42},
	{id: 4210704, nome: "Matos Costa", estado: 42},
	{id: 4210803, nome: "Meleiro", estado: 42},
	{id: 4210852, nome: "Mirim doce", estado: 42},
	{id: 4210902, nome: "Modelo", estado: 42},
	{id: 4211009, nome: "Mondaí", estado: 42},
	{id: 4211058, nome: "Monte Carlo", estado: 42},
	{id: 4211108, nome: "Monte Castelo", estado: 42},
	{id: 4211207, nome: "Morro da Fumaça", estado: 42},
	{id: 4211256, nome: "Morro Grande", estado: 42},
	{id: 4211306, nome: "Navegantes", estado: 42},
	{id: 4211405, nome: "Nova Erechim", estado: 42},
	{id: 4211454, nome: "Nova Itaberaba", estado: 42},
	{id: 4211504, nome: "Nova Trento", estado: 42},
	{id: 4211603, nome: "Nova Veneza", estado: 42},
	{id: 4211652, nome: "Novo Horizonte", estado: 42},
	{id: 4211702, nome: "Orleans", estado: 42},
	{id: 4211751, nome: "Otacílio Costa", estado: 42},
	{id: 4211801, nome: "Ouro", estado: 42},
	{id: 4211850, nome: "Ouro Verde", estado: 42},
	{id: 4211876, nome: "Paial", estado: 42},
	{id: 4211892, nome: "Painel", estado: 42},
	{id: 4211900, nome: "Palhoça", estado: 42},
	{id: 4212007, nome: "Palma Sola", estado: 42},
	{id: 4212056, nome: "Palmeira", estado: 42},
	{id: 4212106, nome: "Palmitos", estado: 42},
	{id: 4212205, nome: "Papanduva", estado: 42},
	{id: 4212239, nome: "Paraíso", estado: 42},
	{id: 4212254, nome: "Passo de Torres", estado: 42},
	{id: 4212270, nome: "Passos Maia", estado: 42},
	{id: 4212304, nome: "Paulo Lopes", estado: 42},
	{id: 4212403, nome: "Pedras Grandes", estado: 42},
	{id: 4212502, nome: "Penha", estado: 42},
	{id: 4212601, nome: "Peritiba", estado: 42},
	{id: 4212650, nome: "Pescaria Brava", estado: 42},
	{id: 4212700, nome: "Petrolândia", estado: 42},
	{id: 4212809, nome: "Balneário Piçarras", estado: 42},
	{id: 4212908, nome: "Pinhalzinho", estado: 42},
	{id: 4213005, nome: "Pinheiro Preto", estado: 42},
	{id: 4213104, nome: "Piratuba", estado: 42},
	{id: 4213153, nome: "Planalto Alegre", estado: 42},
	{id: 4213203, nome: "Pomerode", estado: 42},
	{id: 4213302, nome: "Ponte Alta", estado: 42},
	{id: 4213351, nome: "Ponte Alta do Norte", estado: 42},
	{id: 4213401, nome: "Ponte Serrada", estado: 42},
	{id: 4213500, nome: "Porto Belo", estado: 42},
	{id: 4213609, nome: "Porto União", estado: 42},
	{id: 4213708, nome: "Pouso Redondo", estado: 42},
	{id: 4213807, nome: "Praia Grande", estado: 42},
	{id: 4213906, nome: "Presidente Castello Branco", estado: 42},
	{id: 4214003, nome: "Presidente Getúlio", estado: 42},
	{id: 4214102, nome: "Presidente Nereu", estado: 42},
	{id: 4214151, nome: "Princesa", estado: 42},
	{id: 4214201, nome: "Quilombo", estado: 42},
	{id: 4214300, nome: "Rancho Queimado", estado: 42},
	{id: 4214409, nome: "Rio das Antas", estado: 42},
	{id: 4214508, nome: "Rio do Campo", estado: 42},
	{id: 4214607, nome: "Rio do Oeste", estado: 42},
	{id: 4214706, nome: "Rio dos Cedros", estado: 42},
	{id: 4214805, nome: "Rio do Sul", estado: 42},
	{id: 4214904, nome: "Rio Fortuna", estado: 42},
	{id: 4215000, nome: "Rio Negrinho", estado: 42},
	{id: 4215059, nome: "Rio Rufino", estado: 42},
	{id: 4215075, nome: "Riqueza", estado: 42},
	{id: 4215109, nome: "Rodeio", estado: 42},
	{id: 4215208, nome: "Romelândia", estado: 42},
	{id: 4215307, nome: "Salete", estado: 42},
	{id: 4215356, nome: "Saltinho", estado: 42},
	{id: 4215406, nome: "Salto Veloso", estado: 42},
	{id: 4215455, nome: "Sangão", estado: 42},
	{id: 4215505, nome: "Santa Cecília", estado: 42},
	{id: 4215554, nome: "Santa Helena", estado: 42},
	{id: 4215604, nome: "Santa Rosa de Lima", estado: 42},
	{id: 4215653, nome: "Santa Rosa do Sul", estado: 42},
	{id: 4215679, nome: "Santa Terezinha", estado: 42},
	{id: 4215687, nome: "Santa Terezinha do Progresso", estado: 42},
	{id: 4215695, nome: "Santiago do Sul", estado: 42},
	{id: 4215703, nome: "Santo Amaro da Imperatriz", estado: 42},
	{id: 4215752, nome: "São Bernardino", estado: 42},
	{id: 4215802, nome: "São Bento do Sul", estado: 42},
	{id: 4215901, nome: "São Bonifácio", estado: 42},
	{id: 4216008, nome: "São Carlos", estado: 42},
	{id: 4216057, nome: "São Cristovão do Sul", estado: 42},
	{id: 4216107, nome: "São domingos", estado: 42},
	{id: 4216206, nome: "São Francisco do Sul", estado: 42},
	{id: 4216255, nome: "São João do Oeste", estado: 42},
	{id: 4216305, nome: "São João Batista", estado: 42},
	{id: 4216354, nome: "São João do Itaperiú", estado: 42},
	{id: 4216404, nome: "São João do Sul", estado: 42},
	{id: 4216503, nome: "São Joaquim", estado: 42},
	{id: 4216602, nome: "São José", estado: 42},
	{id: 4216701, nome: "São José do Cedro", estado: 42},
	{id: 4216800, nome: "São José do Cerrito", estado: 42},
	{id: 4216909, nome: "São Lourenço do Oeste", estado: 42},
	{id: 4217006, nome: "São Ludgero", estado: 42},
	{id: 4217105, nome: "São Martinho", estado: 42},
	{id: 4217154, nome: "São Miguel da Boa Vista", estado: 42},
	{id: 4217204, nome: "São Miguel do Oeste", estado: 42},
	{id: 4217253, nome: "São Pedro de Alcântara", estado: 42},
	{id: 4217303, nome: "Saudades", estado: 42},
	{id: 4217402, nome: "Schroeder", estado: 42},
	{id: 4217501, nome: "Seara", estado: 42},
	{id: 4217550, nome: "Serra Alta", estado: 42},
	{id: 4217600, nome: "Siderópolis", estado: 42},
	{id: 4217709, nome: "Sombrio", estado: 42},
	{id: 4217758, nome: "Sul Brasil", estado: 42},
	{id: 4217808, nome: "Taió", estado: 42},
	{id: 4217907, nome: "Tangará", estado: 42},
	{id: 4217956, nome: "Tigrinhos", estado: 42},
	{id: 4218004, nome: "Tijucas", estado: 42},
	{id: 4218103, nome: "Timbé do Sul", estado: 42},
	{id: 4218202, nome: "Timbó", estado: 42},
	{id: 4218251, nome: "Timbó Grande", estado: 42},
	{id: 4218301, nome: "Três Barras", estado: 42},
	{id: 4218350, nome: "Treviso", estado: 42},
	{id: 4218400, nome: "Treze de Maio", estado: 42},
	{id: 4218509, nome: "Treze Tílias", estado: 42},
	{id: 4218608, nome: "Trombudo Central", estado: 42},
	{id: 4218707, nome: "Tubarão", estado: 42},
	{id: 4218756, nome: "Tunápolis", estado: 42},
	{id: 4218806, nome: "Turvo", estado: 42},
	{id: 4218855, nome: "União do Oeste", estado: 42},
	{id: 4218905, nome: "Urubici", estado: 42},
	{id: 4218954, nome: "Urupema", estado: 42},
	{id: 4219002, nome: "Urussanga", estado: 42},
	{id: 4219101, nome: "Vargeão", estado: 42},
	{id: 4219150, nome: "Vargem", estado: 42},
	{id: 4219176, nome: "Vargem Bonita", estado: 42},
	{id: 4219200, nome: "Vidal Ramos", estado: 42},
	{id: 4219309, nome: "Videira", estado: 42},
	{id: 4219358, nome: "Vitor Meireles", estado: 42},
	{id: 4219408, nome: "Witmarsum", estado: 42},
	{id: 4219507, nome: "Xanxerê", estado: 42},
	{id: 4219606, nome: "Xavantina", estado: 42},
	{id: 4219705, nome: "Xaxim", estado: 42},
	{id: 4219853, nome: "Zortéa", estado: 42},
	{id: 4220000, nome: "Balneário Rincão", estado: 42},
	{id: 4300034, nome: "Aceguá", estado: 43},
	{id: 4300059, nome: "Água Santa", estado: 43},
	{id: 4300109, nome: "Agudo", estado: 43},
	{id: 4300208, nome: "Ajuricaba", estado: 43},
	{id: 4300307, nome: "Alecrim", estado: 43},
	{id: 4300406, nome: "Alegrete", estado: 43},
	{id: 4300455, nome: "Alegria", estado: 43},
	{id: 4300471, nome: "Almirante Tamandaré do Sul", estado: 43},
	{id: 4300505, nome: "Alpestre", estado: 43},
	{id: 4300554, nome: "Alto Alegre", estado: 43},
	{id: 4300570, nome: "Alto Feliz", estado: 43},
	{id: 4300604, nome: "Alvorada", estado: 43},
	{id: 4300638, nome: "Amaral Ferrador", estado: 43},
	{id: 4300646, nome: "Ametista do Sul", estado: 43},
	{id: 4300661, nome: "André da Rocha", estado: 43},
	{id: 4300703, nome: "Anta Gorda", estado: 43},
	{id: 4300802, nome: "Antônio Prado", estado: 43},
	{id: 4300851, nome: "Arambaré", estado: 43},
	{id: 4300877, nome: "Araricá", estado: 43},
	{id: 4300901, nome: "Aratiba", estado: 43},
	{id: 4301008, nome: "Arroio do Meio", estado: 43},
	{id: 4301057, nome: "Arroio do Sal", estado: 43},
	{id: 4301073, nome: "Arroio do Padre", estado: 43},
	{id: 4301107, nome: "Arroio dos Ratos", estado: 43},
	{id: 4301206, nome: "Arroio do Tigre", estado: 43},
	{id: 4301305, nome: "Arroio Grande", estado: 43},
	{id: 4301404, nome: "Arvorezinha", estado: 43},
	{id: 4301503, nome: "Augusto Pestana", estado: 43},
	{id: 4301552, nome: "Áurea", estado: 43},
	{id: 4301602, nome: "Bagé", estado: 43},
	{id: 4301636, nome: "Balneário Pinhal", estado: 43},
	{id: 4301651, nome: "Barão", estado: 43},
	{id: 4301701, nome: "Barão de Cotegipe", estado: 43},
	{id: 4301750, nome: "Barão do Triunfo", estado: 43},
	{id: 4301800, nome: "Barracão", estado: 43},
	{id: 4301859, nome: "Barra do Guarita", estado: 43},
	{id: 4301875, nome: "Barra do Quaraí", estado: 43},
	{id: 4301909, nome: "Barra do Ribeiro", estado: 43},
	{id: 4301925, nome: "Barra do Rio Azul", estado: 43},
	{id: 4301958, nome: "Barra Funda", estado: 43},
	{id: 4302006, nome: "Barros Cassal", estado: 43},
	{id: 4302055, nome: "Benjamin Constant do Sul", estado: 43},
	{id: 4302105, nome: "Bento Gonçalves", estado: 43},
	{id: 4302154, nome: "Boa Vista das Missões", estado: 43},
	{id: 4302204, nome: "Boa Vista do Buricá", estado: 43},
	{id: 4302220, nome: "Boa Vista do Cadeado", estado: 43},
	{id: 4302238, nome: "Boa Vista do Incra", estado: 43},
	{id: 4302253, nome: "Boa Vista do Sul", estado: 43},
	{id: 4302303, nome: "Bom Jesus", estado: 43},
	{id: 4302352, nome: "Bom Princípio", estado: 43},
	{id: 4302378, nome: "Bom Progresso", estado: 43},
	{id: 4302402, nome: "Bom Retiro do Sul", estado: 43},
	{id: 4302451, nome: "Boqueirão do Leão", estado: 43},
	{id: 4302501, nome: "Bossoroca", estado: 43},
	{id: 4302584, nome: "Bozano", estado: 43},
	{id: 4302600, nome: "Braga", estado: 43},
	{id: 4302659, nome: "Brochier", estado: 43},
	{id: 4302709, nome: "Butiá", estado: 43},
	{id: 4302808, nome: "Caçapava do Sul", estado: 43},
	{id: 4302907, nome: "Cacequi", estado: 43},
	{id: 4303004, nome: "Cachoeira do Sul", estado: 43},
	{id: 4303103, nome: "Cachoeirinha", estado: 43},
	{id: 4303202, nome: "Cacique doble", estado: 43},
	{id: 4303301, nome: "Caibaté", estado: 43},
	{id: 4303400, nome: "Caiçara", estado: 43},
	{id: 4303509, nome: "Camaquã", estado: 43},
	{id: 4303558, nome: "Camargo", estado: 43},
	{id: 4303608, nome: "Cambará do Sul", estado: 43},
	{id: 4303673, nome: "Campestre da Serra", estado: 43},
	{id: 4303707, nome: "Campina das Missões", estado: 43},
	{id: 4303806, nome: "Campinas do Sul", estado: 43},
	{id: 4303905, nome: "Campo Bom", estado: 43},
	{id: 4304002, nome: "Campo Novo", estado: 43},
	{id: 4304101, nome: "Campos Borges", estado: 43},
	{id: 4304200, nome: "Candelária", estado: 43},
	{id: 4304309, nome: "Cândido Godói", estado: 43},
	{id: 4304358, nome: "Candiota", estado: 43},
	{id: 4304408, nome: "Canela", estado: 43},
	{id: 4304507, nome: "Canguçu", estado: 43},
	{id: 4304606, nome: "Canoas", estado: 43},
	{id: 4304614, nome: "Canudos do Vale", estado: 43},
	{id: 4304622, nome: "Capão Bonito do Sul", estado: 43},
	{id: 4304630, nome: "Capão da Canoa", estado: 43},
	{id: 4304655, nome: "Capão do Cipó", estado: 43},
	{id: 4304663, nome: "Capão do Leão", estado: 43},
	{id: 4304671, nome: "Capivari do Sul", estado: 43},
	{id: 4304689, nome: "Capela de Santana", estado: 43},
	{id: 4304697, nome: "Capitão", estado: 43},
	{id: 4304705, nome: "Carazinho", estado: 43},
	{id: 4304713, nome: "Caraá", estado: 43},
	{id: 4304804, nome: "Carlos Barbosa", estado: 43},
	{id: 4304853, nome: "Carlos Gomes", estado: 43},
	{id: 4304903, nome: "Casca", estado: 43},
	{id: 4304952, nome: "Caseiros", estado: 43},
	{id: 4305009, nome: "Catuípe", estado: 43},
	{id: 4305108, nome: "Caxias do Sul", estado: 43},
	{id: 4305116, nome: "Centenário", estado: 43},
	{id: 4305124, nome: "Cerrito", estado: 43},
	{id: 4305132, nome: "Cerro Branco", estado: 43},
	{id: 4305157, nome: "Cerro Grande", estado: 43},
	{id: 4305173, nome: "Cerro Grande do Sul", estado: 43},
	{id: 4305207, nome: "Cerro Largo", estado: 43},
	{id: 4305306, nome: "Chapada", estado: 43},
	{id: 4305355, nome: "Charqueadas", estado: 43},
	{id: 4305371, nome: "Charrua", estado: 43},
	{id: 4305405, nome: "Chiapetta", estado: 43},
	{id: 4305439, nome: "Chuí", estado: 43},
	{id: 4305447, nome: "Chuvisca", estado: 43},
	{id: 4305454, nome: "Cidreira", estado: 43},
	{id: 4305504, nome: "Ciríaco", estado: 43},
	{id: 4305587, nome: "Colinas", estado: 43},
	{id: 4305603, nome: "Colorado", estado: 43},
	{id: 4305702, nome: "Condor", estado: 43},
	{id: 4305801, nome: "Constantina", estado: 43},
	{id: 4305835, nome: "Coqueiro Baixo", estado: 43},
	{id: 4305850, nome: "Coqueiros do Sul", estado: 43},
	{id: 4305871, nome: "Coronel Barros", estado: 43},
	{id: 4305900, nome: "Coronel Bicaco", estado: 43},
	{id: 4305934, nome: "Coronel Pilar", estado: 43},
	{id: 4305959, nome: "Cotiporã", estado: 43},
	{id: 4305975, nome: "Coxilha", estado: 43},
	{id: 4306007, nome: "Crissiumal", estado: 43},
	{id: 4306056, nome: "Cristal", estado: 43},
	{id: 4306072, nome: "Cristal do Sul", estado: 43},
	{id: 4306106, nome: "Cruz Alta", estado: 43},
	{id: 4306130, nome: "Cruzaltense", estado: 43},
	{id: 4306205, nome: "Cruzeiro do Sul", estado: 43},
	{id: 4306304, nome: "david Canabarro", estado: 43},
	{id: 4306320, nome: "derrubadas", estado: 43},
	{id: 4306353, nome: "dezesseis de Novembro", estado: 43},
	{id: 4306379, nome: "Dilermando de Aguiar", estado: 43},
	{id: 4306403, nome: "dois Irmãos", estado: 43},
	{id: 4306429, nome: "dois Irmãos das Missões", estado: 43},
	{id: 4306452, nome: "dois Lajeados", estado: 43},
	{id: 4306502, nome: "dom Feliciano", estado: 43},
	{id: 4306551, nome: "dom Pedro de Alcântara", estado: 43},
	{id: 4306601, nome: "dom Pedrito", estado: 43},
	{id: 4306700, nome: "dona Francisca", estado: 43},
	{id: 4306734, nome: "doutor Maurício Cardoso", estado: 43},
	{id: 4306759, nome: "doutor Ricardo", estado: 43},
	{id: 4306767, nome: "Eldorado do Sul", estado: 43},
	{id: 4306809, nome: "Encantado", estado: 43},
	{id: 4306908, nome: "Encruzilhada do Sul", estado: 43},
	{id: 4306924, nome: "Engenho Velho", estado: 43},
	{id: 4306932, nome: "Entre-Ijuís", estado: 43},
	{id: 4306957, nome: "Entre Rios do Sul", estado: 43},
	{id: 4306973, nome: "Erebango", estado: 43},
	{id: 4307005, nome: "Erechim", estado: 43},
	{id: 4307054, nome: "Ernestina", estado: 43},
	{id: 4307104, nome: "Herval", estado: 43},
	{id: 4307203, nome: "Erval Grande", estado: 43},
	{id: 4307302, nome: "Erval Seco", estado: 43},
	{id: 4307401, nome: "Esmeralda", estado: 43},
	{id: 4307450, nome: "Esperança do Sul", estado: 43},
	{id: 4307500, nome: "Espumoso", estado: 43},
	{id: 4307559, nome: "Estação", estado: 43},
	{id: 4307609, nome: "Estância Velha", estado: 43},
	{id: 4307708, nome: "Esteio", estado: 43},
	{id: 4307807, nome: "Estrela", estado: 43},
	{id: 4307815, nome: "Estrela Velha", estado: 43},
	{id: 4307831, nome: "Eugênio de Castro", estado: 43},
	{id: 4307864, nome: "Fagundes Varela", estado: 43},
	{id: 4307906, nome: "Farroupilha", estado: 43},
	{id: 4308003, nome: "Faxinal do Soturno", estado: 43},
	{id: 4308052, nome: "Faxinalzinho", estado: 43},
	{id: 4308078, nome: "Fazenda Vilanova", estado: 43},
	{id: 4308102, nome: "Feliz", estado: 43},
	{id: 4308201, nome: "Flores da Cunha", estado: 43},
	{id: 4308250, nome: "Floriano Peixoto", estado: 43},
	{id: 4308300, nome: "Fontoura Xavier", estado: 43},
	{id: 4308409, nome: "Formigueiro", estado: 43},
	{id: 4308433, nome: "Forquetinha", estado: 43},
	{id: 4308458, nome: "Fortaleza dos Valos", estado: 43},
	{id: 4308508, nome: "Frederico Westphalen", estado: 43},
	{id: 4308607, nome: "Garibaldi", estado: 43},
	{id: 4308656, nome: "Garruchos", estado: 43},
	{id: 4308706, nome: "Gaurama", estado: 43},
	{id: 4308805, nome: "General Câmara", estado: 43},
	{id: 4308854, nome: "Gentil", estado: 43},
	{id: 4308904, nome: "Getúlio Vargas", estado: 43},
	{id: 4309001, nome: "Giruá", estado: 43},
	{id: 4309050, nome: "Glorinha", estado: 43},
	{id: 4309100, nome: "Gramado", estado: 43},
	{id: 4309126, nome: "Gramado dos Loureiros", estado: 43},
	{id: 4309159, nome: "Gramado Xavier", estado: 43},
	{id: 4309209, nome: "Gravataí", estado: 43},
	{id: 4309258, nome: "Guabiju", estado: 43},
	{id: 4309308, nome: "Guaíba", estado: 43},
	{id: 4309407, nome: "Guaporé", estado: 43},
	{id: 4309506, nome: "Guarani das Missões", estado: 43},
	{id: 4309555, nome: "Harmonia", estado: 43},
	{id: 4309571, nome: "Herveiras", estado: 43},
	{id: 4309605, nome: "Horizontina", estado: 43},
	{id: 4309654, nome: "Hulha Negra", estado: 43},
	{id: 4309704, nome: "Humaitá", estado: 43},
	{id: 4309753, nome: "Ibarama", estado: 43},
	{id: 4309803, nome: "Ibiaçá", estado: 43},
	{id: 4309902, nome: "Ibiraiaras", estado: 43},
	{id: 4309951, nome: "Ibirapuitã", estado: 43},
	{id: 4310009, nome: "Ibirubá", estado: 43},
	{id: 4310108, nome: "Igrejinha", estado: 43},
	{id: 4310207, nome: "Ijuí", estado: 43},
	{id: 4310306, nome: "Ilópolis", estado: 43},
	{id: 4310330, nome: "Imbé", estado: 43},
	{id: 4310363, nome: "Imigrante", estado: 43},
	{id: 4310405, nome: "Independência", estado: 43},
	{id: 4310413, nome: "Inhacorá", estado: 43},
	{id: 4310439, nome: "Ipê", estado: 43},
	{id: 4310462, nome: "Ipiranga do Sul", estado: 43},
	{id: 4310504, nome: "Iraí", estado: 43},
	{id: 4310538, nome: "Itaara", estado: 43},
	{id: 4310553, nome: "Itacurubi", estado: 43},
	{id: 4310579, nome: "Itapuca", estado: 43},
	{id: 4310603, nome: "Itaqui", estado: 43},
	{id: 4310652, nome: "Itati", estado: 43},
	{id: 4310702, nome: "Itatiba do Sul", estado: 43},
	{id: 4310751, nome: "Ivorá", estado: 43},
	{id: 4310801, nome: "Ivoti", estado: 43},
	{id: 4310850, nome: "Jaboticaba", estado: 43},
	{id: 4310876, nome: "Jacuizinho", estado: 43},
	{id: 4310900, nome: "Jacutinga", estado: 43},
	{id: 4311007, nome: "Jaguarão", estado: 43},
	{id: 4311106, nome: "Jaguari", estado: 43},
	{id: 4311122, nome: "Jaquirana", estado: 43},
	{id: 4311130, nome: "Jari", estado: 43},
	{id: 4311155, nome: "Jóia", estado: 43},
	{id: 4311205, nome: "Júlio de Castilhos", estado: 43},
	{id: 4311239, nome: "Lagoa Bonita do Sul", estado: 43},
	{id: 4311254, nome: "Lagoão", estado: 43},
	{id: 4311270, nome: "Lagoa dos Três Cantos", estado: 43},
	{id: 4311304, nome: "Lagoa Vermelha", estado: 43},
	{id: 4311403, nome: "Lajeado", estado: 43},
	{id: 4311429, nome: "Lajeado do Bugre", estado: 43},
	{id: 4311502, nome: "Lavras do Sul", estado: 43},
	{id: 4311601, nome: "Liberato Salzano", estado: 43},
	{id: 4311627, nome: "Lindolfo Collor", estado: 43},
	{id: 4311643, nome: "Linha Nova", estado: 43},
	{id: 4311700, nome: "Machadinho", estado: 43},
	{id: 4311718, nome: "Maçambará", estado: 43},
	{id: 4311734, nome: "Mampituba", estado: 43},
	{id: 4311759, nome: "Manoel Viana", estado: 43},
	{id: 4311775, nome: "Maquiné", estado: 43},
	{id: 4311791, nome: "Maratá", estado: 43},
	{id: 4311809, nome: "Marau", estado: 43},
	{id: 4311908, nome: "Marcelino Ramos", estado: 43},
	{id: 4311981, nome: "Mariana Pimentel", estado: 43},
	{id: 4312005, nome: "Mariano Moro", estado: 43},
	{id: 4312054, nome: "Marques de Souza", estado: 43},
	{id: 4312104, nome: "Mata", estado: 43},
	{id: 4312138, nome: "Mato Castelhano", estado: 43},
	{id: 4312153, nome: "Mato Leitão", estado: 43},
	{id: 4312179, nome: "Mato Queimado", estado: 43},
	{id: 4312203, nome: "Maximiliano de Almeida", estado: 43},
	{id: 4312252, nome: "Minas do Leão", estado: 43},
	{id: 4312302, nome: "Miraguaí", estado: 43},
	{id: 4312351, nome: "Montauri", estado: 43},
	{id: 4312377, nome: "Monte Alegre dos Campos", estado: 43},
	{id: 4312385, nome: "Monte Belo do Sul", estado: 43},
	{id: 4312401, nome: "Montenegro", estado: 43},
	{id: 4312427, nome: "Mormaço", estado: 43},
	{id: 4312443, nome: "Morrinhos do Sul", estado: 43},
	{id: 4312450, nome: "Morro Redondo", estado: 43},
	{id: 4312476, nome: "Morro Reuter", estado: 43},
	{id: 4312500, nome: "Mostardas", estado: 43},
	{id: 4312609, nome: "Muçum", estado: 43},
	{id: 4312617, nome: "Muitos Capões", estado: 43},
	{id: 4312625, nome: "Muliterno", estado: 43},
	{id: 4312658, nome: "Não-Me-Toque", estado: 43},
	{id: 4312674, nome: "Nicolau Vergueiro", estado: 43},
	{id: 4312708, nome: "Nonoai", estado: 43},
	{id: 4312757, nome: "Nova Alvorada", estado: 43},
	{id: 4312807, nome: "Nova Araçá", estado: 43},
	{id: 4312906, nome: "Nova Bassano", estado: 43},
	{id: 4312955, nome: "Nova Boa Vista", estado: 43},
	{id: 4313003, nome: "Nova Bréscia", estado: 43},
	{id: 4313011, nome: "Nova Candelária", estado: 43},
	{id: 4313037, nome: "Nova Esperança do Sul", estado: 43},
	{id: 4313060, nome: "Nova Hartz", estado: 43},
	{id: 4313086, nome: "Nova Pádua", estado: 43},
	{id: 4313102, nome: "Nova Palma", estado: 43},
	{id: 4313201, nome: "Nova Petrópolis", estado: 43},
	{id: 4313300, nome: "Nova Prata", estado: 43},
	{id: 4313334, nome: "Nova Ramada", estado: 43},
	{id: 4313359, nome: "Nova Roma do Sul", estado: 43},
	{id: 4313375, nome: "Nova Santa Rita", estado: 43},
	{id: 4313391, nome: "Novo Cabrais", estado: 43},
	{id: 4313409, nome: "Novo Hamburgo", estado: 43},
	{id: 4313425, nome: "Novo Machado", estado: 43},
	{id: 4313441, nome: "Novo Tiradentes", estado: 43},
	{id: 4313466, nome: "Novo Xingu", estado: 43},
	{id: 4313490, nome: "Novo Barreiro", estado: 43},
	{id: 4313508, nome: "Osório", estado: 43},
	{id: 4313607, nome: "Paim Filho", estado: 43},
	{id: 4313656, nome: "Palmares do Sul", estado: 43},
	{id: 4313706, nome: "Palmeira das Missões", estado: 43},
	{id: 4313805, nome: "Palmitinho", estado: 43},
	{id: 4313904, nome: "Panambi", estado: 43},
	{id: 4313953, nome: "Pantano Grande", estado: 43},
	{id: 4314001, nome: "Paraí", estado: 43},
	{id: 4314027, nome: "Paraíso do Sul", estado: 43},
	{id: 4314035, nome: "Pareci Novo", estado: 43},
	{id: 4314050, nome: "Parobé", estado: 43},
	{id: 4314068, nome: "Passa Sete", estado: 43},
	{id: 4314076, nome: "Passo do Sobrado", estado: 43},
	{id: 4314100, nome: "Passo Fundo", estado: 43},
	{id: 4314134, nome: "Paulo Bento", estado: 43},
	{id: 4314159, nome: "Paverama", estado: 43},
	{id: 4314175, nome: "Pedras Altas", estado: 43},
	{id: 4314209, nome: "Pedro Osório", estado: 43},
	{id: 4314308, nome: "Pejuçara", estado: 43},
	{id: 4314407, nome: "Pelotas", estado: 43},
	{id: 4314423, nome: "Picada Café", estado: 43},
	{id: 4314456, nome: "Pinhal", estado: 43},
	{id: 4314464, nome: "Pinhal da Serra", estado: 43},
	{id: 4314472, nome: "Pinhal Grande", estado: 43},
	{id: 4314498, nome: "Pinheirinho do Vale", estado: 43},
	{id: 4314506, nome: "Pinheiro Machado", estado: 43},
	{id: 4314548, nome: "Pinto Bandeira", estado: 43},
	{id: 4314555, nome: "Pirapó", estado: 43},
	{id: 4314605, nome: "Piratini", estado: 43},
	{id: 4314704, nome: "Planalto", estado: 43},
	{id: 4314753, nome: "Poço das Antas", estado: 43},
	{id: 4314779, nome: "Pontão", estado: 43},
	{id: 4314787, nome: "Ponte Preta", estado: 43},
	{id: 4314803, nome: "Portão", estado: 43},
	{id: 4314902, nome: "Porto Alegre", estado: 43},
	{id: 4315008, nome: "Porto Lucena", estado: 43},
	{id: 4315057, nome: "Porto Mauá", estado: 43},
	{id: 4315073, nome: "Porto Vera Cruz", estado: 43},
	{id: 4315107, nome: "Porto Xavier", estado: 43},
	{id: 4315131, nome: "Pouso Novo", estado: 43},
	{id: 4315149, nome: "Presidente Lucena", estado: 43},
	{id: 4315156, nome: "Progresso", estado: 43},
	{id: 4315172, nome: "Protásio Alves", estado: 43},
	{id: 4315206, nome: "Putinga", estado: 43},
	{id: 4315305, nome: "Quaraí", estado: 43},
	{id: 4315313, nome: "Quatro Irmãos", estado: 43},
	{id: 4315321, nome: "Quevedos", estado: 43},
	{id: 4315354, nome: "Quinze de Novembro", estado: 43},
	{id: 4315404, nome: "Redentora", estado: 43},
	{id: 4315453, nome: "Relvado", estado: 43},
	{id: 4315503, nome: "Restinga Seca", estado: 43},
	{id: 4315552, nome: "Rio dos Índios", estado: 43},
	{id: 4315602, nome: "Rio Grande", estado: 43},
	{id: 4315701, nome: "Rio Pardo", estado: 43},
	{id: 4315750, nome: "Riozinho", estado: 43},
	{id: 4315800, nome: "Roca Sales", estado: 43},
	{id: 4315909, nome: "Rodeio Bonito", estado: 43},
	{id: 4315958, nome: "Rolador", estado: 43},
	{id: 4316006, nome: "Rolante", estado: 43},
	{id: 4316105, nome: "Ronda Alta", estado: 43},
	{id: 4316204, nome: "Rondinha", estado: 43},
	{id: 4316303, nome: "Roque Gonzales", estado: 43},
	{id: 4316402, nome: "Rosário do Sul", estado: 43},
	{id: 4316428, nome: "Sagrada Família", estado: 43},
	{id: 4316436, nome: "Saldanha Marinho", estado: 43},
	{id: 4316451, nome: "Salto do Jacuí", estado: 43},
	{id: 4316477, nome: "Salvador das Missões", estado: 43},
	{id: 4316501, nome: "Salvador do Sul", estado: 43},
	{id: 4316600, nome: "Sananduva", estado: 43},
	{id: 4316709, nome: "Santa Bárbara do Sul", estado: 43},
	{id: 4316733, nome: "Santa Cecília do Sul", estado: 43},
	{id: 4316758, nome: "Santa Clara do Sul", estado: 43},
	{id: 4316808, nome: "Santa Cruz do Sul", estado: 43},
	{id: 4316907, nome: "Santa Maria", estado: 43},
	{id: 4316956, nome: "Santa Maria do Herval", estado: 43},
	{id: 4316972, nome: "Santa Margarida do Sul", estado: 43},
	{id: 4317004, nome: "Santana da Boa Vista", estado: 43},
	{id: 4317103, nome: "Sant'ana do Livramento", estado: 43},
	{id: 4317202, nome: "Santa Rosa", estado: 43},
	{id: 4317251, nome: "Santa Tereza", estado: 43},
	{id: 4317301, nome: "Santa Vitória do Palmar", estado: 43},
	{id: 4317400, nome: "Santiago", estado: 43},
	{id: 4317509, nome: "Santo Ângelo", estado: 43},
	{id: 4317558, nome: "Santo Antônio do Palma", estado: 43},
	{id: 4317608, nome: "Santo Antônio da Patrulha", estado: 43},
	{id: 4317707, nome: "Santo Antônio das Missões", estado: 43},
	{id: 4317756, nome: "Santo Antônio do Planalto", estado: 43},
	{id: 4317806, nome: "Santo Augusto", estado: 43},
	{id: 4317905, nome: "Santo Cristo", estado: 43},
	{id: 4317954, nome: "Santo Expedito do Sul", estado: 43},
	{id: 4318002, nome: "São Borja", estado: 43},
	{id: 4318051, nome: "São domingos do Sul", estado: 43},
	{id: 4318101, nome: "São Francisco de Assis", estado: 43},
	{id: 4318200, nome: "São Francisco de Paula", estado: 43},
	{id: 4318309, nome: "São Gabriel", estado: 43},
	{id: 4318408, nome: "São Jerônimo", estado: 43},
	{id: 4318424, nome: "São João da Urtiga", estado: 43},
	{id: 4318432, nome: "São João do Polêsine", estado: 43},
	{id: 4318440, nome: "São Jorge", estado: 43},
	{id: 4318457, nome: "São José das Missões", estado: 43},
	{id: 4318465, nome: "São José do Herval", estado: 43},
	{id: 4318481, nome: "São José do Hortêncio", estado: 43},
	{id: 4318499, nome: "São José do Inhacorá", estado: 43},
	{id: 4318507, nome: "São José do Norte", estado: 43},
	{id: 4318606, nome: "São José do Ouro", estado: 43},
	{id: 4318614, nome: "São José do Sul", estado: 43},
	{id: 4318622, nome: "São José dos Ausentes", estado: 43},
	{id: 4318705, nome: "São Leopoldo", estado: 43},
	{id: 4318804, nome: "São Lourenço do Sul", estado: 43},
	{id: 4318903, nome: "São Luiz Gonzaga", estado: 43},
	{id: 4319000, nome: "São Marcos", estado: 43},
	{id: 4319109, nome: "São Martinho", estado: 43},
	{id: 4319125, nome: "São Martinho da Serra", estado: 43},
	{id: 4319158, nome: "São Miguel das Missões", estado: 43},
	{id: 4319208, nome: "São Nicolau", estado: 43},
	{id: 4319307, nome: "São Paulo das Missões", estado: 43},
	{id: 4319356, nome: "São Pedro da Serra", estado: 43},
	{id: 4319364, nome: "São Pedro das Missões", estado: 43},
	{id: 4319372, nome: "São Pedro do Butiá", estado: 43},
	{id: 4319406, nome: "São Pedro do Sul", estado: 43},
	{id: 4319505, nome: "São Sebastião do Caí", estado: 43},
	{id: 4319604, nome: "São Sepé", estado: 43},
	{id: 4319703, nome: "São Valentim", estado: 43},
	{id: 4319711, nome: "São Valentim do Sul", estado: 43},
	{id: 4319737, nome: "São Valério do Sul", estado: 43},
	{id: 4319752, nome: "São Vendelino", estado: 43},
	{id: 4319802, nome: "São Vicente do Sul", estado: 43},
	{id: 4319901, nome: "Sapiranga", estado: 43},
	{id: 4320008, nome: "Sapucaia do Sul", estado: 43},
	{id: 4320107, nome: "Sarandi", estado: 43},
	{id: 4320206, nome: "Seberi", estado: 43},
	{id: 4320230, nome: "Sede Nova", estado: 43},
	{id: 4320263, nome: "Segredo", estado: 43},
	{id: 4320305, nome: "Selbach", estado: 43},
	{id: 4320321, nome: "Senador Salgado Filho", estado: 43},
	{id: 4320354, nome: "Sentinela do Sul", estado: 43},
	{id: 4320404, nome: "Serafina Corrêa", estado: 43},
	{id: 4320453, nome: "Sério", estado: 43},
	{id: 4320503, nome: "Sertão", estado: 43},
	{id: 4320552, nome: "Sertão Santana", estado: 43},
	{id: 4320578, nome: "Sete de Setembro", estado: 43},
	{id: 4320602, nome: "Severiano de Almeida", estado: 43},
	{id: 4320651, nome: "Silveira Martins", estado: 43},
	{id: 4320677, nome: "Sinimbu", estado: 43},
	{id: 4320701, nome: "Sobradinho", estado: 43},
	{id: 4320800, nome: "Soledade", estado: 43},
	{id: 4320859, nome: "Tabaí", estado: 43},
	{id: 4320909, nome: "Tapejara", estado: 43},
	{id: 4321006, nome: "Tapera", estado: 43},
	{id: 4321105, nome: "Tapes", estado: 43},
	{id: 4321204, nome: "Taquara", estado: 43},
	{id: 4321303, nome: "Taquari", estado: 43},
	{id: 4321329, nome: "Taquaruçu do Sul", estado: 43},
	{id: 4321352, nome: "Tavares", estado: 43},
	{id: 4321402, nome: "Tenente Portela", estado: 43},
	{id: 4321436, nome: "Terra de Areia", estado: 43},
	{id: 4321451, nome: "Teutônia", estado: 43},
	{id: 4321469, nome: "Tio Hugo", estado: 43},
	{id: 4321477, nome: "Tiradentes do Sul", estado: 43},
	{id: 4321493, nome: "Toropi", estado: 43},
	{id: 4321501, nome: "Torres", estado: 43},
	{id: 4321600, nome: "Tramandaí", estado: 43},
	{id: 4321626, nome: "Travesseiro", estado: 43},
	{id: 4321634, nome: "Três Arroios", estado: 43},
	{id: 4321667, nome: "Três Cachoeiras", estado: 43},
	{id: 4321709, nome: "Três Coroas", estado: 43},
	{id: 4321808, nome: "Três de Maio", estado: 43},
	{id: 4321832, nome: "Três Forquilhas", estado: 43},
	{id: 4321857, nome: "Três Palmeiras", estado: 43},
	{id: 4321907, nome: "Três Passos", estado: 43},
	{id: 4321956, nome: "Trindade do Sul", estado: 43},
	{id: 4322004, nome: "Triunfo", estado: 43},
	{id: 4322103, nome: "Tucunduva", estado: 43},
	{id: 4322152, nome: "Tunas", estado: 43},
	{id: 4322186, nome: "Tupanci do Sul", estado: 43},
	{id: 4322202, nome: "Tupanciretã", estado: 43},
	{id: 4322251, nome: "Tupandi", estado: 43},
	{id: 4322301, nome: "Tuparendi", estado: 43},
	{id: 4322327, nome: "Turuçu", estado: 43},
	{id: 4322343, nome: "Ubiretama", estado: 43},
	{id: 4322350, nome: "União da Serra", estado: 43},
	{id: 4322376, nome: "Unistalda", estado: 43},
	{id: 4322400, nome: "Uruguaiana", estado: 43},
	{id: 4322509, nome: "Vacaria", estado: 43},
	{id: 4322525, nome: "Vale Verde", estado: 43},
	{id: 4322533, nome: "Vale do Sol", estado: 43},
	{id: 4322541, nome: "Vale Real", estado: 43},
	{id: 4322558, nome: "Vanini", estado: 43},
	{id: 4322608, nome: "Venâncio Aires", estado: 43},
	{id: 4322707, nome: "Vera Cruz", estado: 43},
	{id: 4322806, nome: "Veranópolis", estado: 43},
	{id: 4322855, nome: "Vespasiano Correa", estado: 43},
	{id: 4322905, nome: "Viadutos", estado: 43},
	{id: 4323002, nome: "Viamão", estado: 43},
	{id: 4323101, nome: "Vicente Dutra", estado: 43},
	{id: 4323200, nome: "Victor Graeff", estado: 43},
	{id: 4323309, nome: "Vila Flores", estado: 43},
	{id: 4323358, nome: "Vila Lângaro", estado: 43},
	{id: 4323408, nome: "Vila Maria", estado: 43},
	{id: 4323457, nome: "Vila Nova do Sul", estado: 43},
	{id: 4323507, nome: "Vista Alegre", estado: 43},
	{id: 4323606, nome: "Vista Alegre do Prata", estado: 43},
	{id: 4323705, nome: "Vista Gaúcha", estado: 43},
	{id: 4323754, nome: "Vitória das Missões", estado: 43},
	{id: 4323770, nome: "Westfalia", estado: 43},
	{id: 4323804, nome: "Xangri-Lá", estado: 43},
	{id: 5000203, nome: "Água Clara", estado: 50},
	{id: 5000252, nome: "Alcinópolis", estado: 50},
	{id: 5000609, nome: "Amambai", estado: 50},
	{id: 5000708, nome: "Anastácio", estado: 50},
	{id: 5000807, nome: "Anaurilândia", estado: 50},
	{id: 5000856, nome: "Angélica", estado: 50},
	{id: 5000906, nome: "Antônio João", estado: 50},
	{id: 5001003, nome: "Aparecida do Taboado", estado: 50},
	{id: 5001102, nome: "Aquidauana", estado: 50},
	{id: 5001243, nome: "Aral Moreira", estado: 50},
	{id: 5001508, nome: "Bandeirantes", estado: 50},
	{id: 5001904, nome: "Bataguassu", estado: 50},
	{id: 5002001, nome: "Batayporã", estado: 50},
	{id: 5002100, nome: "Bela Vista", estado: 50},
	{id: 5002159, nome: "Bodoquena", estado: 50},
	{id: 5002209, nome: "Bonito", estado: 50},
	{id: 5002308, nome: "Brasilândia", estado: 50},
	{id: 5002407, nome: "Caarapó", estado: 50},
	{id: 5002605, nome: "Camapuã", estado: 50},
	{id: 5002704, nome: "Campo Grande", estado: 50},
	{id: 5002803, nome: "Caracol", estado: 50},
	{id: 5002902, nome: "Cassilândia", estado: 50},
	{id: 5002951, nome: "Chapadão do Sul", estado: 50},
	{id: 5003108, nome: "Corguinho", estado: 50},
	{id: 5003157, nome: "Coronel Sapucaia", estado: 50},
	{id: 5003207, nome: "Corumbá", estado: 50},
	{id: 5003256, nome: "Costa Rica", estado: 50},
	{id: 5003306, nome: "Coxim", estado: 50},
	{id: 5003454, nome: "deodápolis", estado: 50},
	{id: 5003488, nome: "dois Irmãos do Buriti", estado: 50},
	{id: 5003504, nome: "douradina", estado: 50},
	{id: 5003702, nome: "dourados", estado: 50},
	{id: 5003751, nome: "Eldorado", estado: 50},
	{id: 5003801, nome: "Fátima do Sul", estado: 50},
	{id: 5003900, nome: "Figueirão", estado: 50},
	{id: 5004007, nome: "Glória de dourados", estado: 50},
	{id: 5004106, nome: "Guia Lopes da Laguna", estado: 50},
	{id: 5004304, nome: "Iguatemi", estado: 50},
	{id: 5004403, nome: "Inocência", estado: 50},
	{id: 5004502, nome: "Itaporã", estado: 50},
	{id: 5004601, nome: "Itaquiraí", estado: 50},
	{id: 5004700, nome: "Ivinhema", estado: 50},
	{id: 5004809, nome: "Japorã", estado: 50},
	{id: 5004908, nome: "Jaraguari", estado: 50},
	{id: 5005004, nome: "Jardim", estado: 50},
	{id: 5005103, nome: "Jateí", estado: 50},
	{id: 5005152, nome: "Juti", estado: 50},
	{id: 5005202, nome: "Ladário", estado: 50},
	{id: 5005251, nome: "Laguna Carapã", estado: 50},
	{id: 5005400, nome: "Maracaju", estado: 50},
	{id: 5005608, nome: "Miranda", estado: 50},
	{id: 5005681, nome: "Mundo Novo", estado: 50},
	{id: 5005707, nome: "Naviraí", estado: 50},
	{id: 5005806, nome: "Nioaque", estado: 50},
	{id: 5006002, nome: "Nova Alvorada do Sul", estado: 50},
	{id: 5006200, nome: "Nova Andradina", estado: 50},
	{id: 5006259, nome: "Novo Horizonte do Sul", estado: 50},
	{id: 5006275, nome: "Paraíso das Águas", estado: 50},
	{id: 5006309, nome: "Paranaíba", estado: 50},
	{id: 5006358, nome: "Paranhos", estado: 50},
	{id: 5006408, nome: "Pedro Gomes", estado: 50},
	{id: 5006606, nome: "Ponta Porã", estado: 50},
	{id: 5006903, nome: "Porto Murtinho", estado: 50},
	{id: 5007109, nome: "Ribas do Rio Pardo", estado: 50},
	{id: 5007208, nome: "Rio Brilhante", estado: 50},
	{id: 5007307, nome: "Rio Negro", estado: 50},
	{id: 5007406, nome: "Rio Verde de Mato Grosso", estado: 50},
	{id: 5007505, nome: "Rochedo", estado: 50},
	{id: 5007554, nome: "Santa Rita do Pardo", estado: 50},
	{id: 5007695, nome: "São Gabriel do Oeste", estado: 50},
	{id: 5007703, nome: "Sete Quedas", estado: 50},
	{id: 5007802, nome: "Selvíria", estado: 50},
	{id: 5007901, nome: "Sidrolândia", estado: 50},
	{id: 5007935, nome: "Sonora", estado: 50},
	{id: 5007950, nome: "Tacuru", estado: 50},
	{id: 5007976, nome: "Taquarussu", estado: 50},
	{id: 5008008, nome: "Terenos", estado: 50},
	{id: 5008305, nome: "Três Lagoas", estado: 50},
	{id: 5008404, nome: "Vicentina", estado: 50},
	{id: 5100102, nome: "Acorizal", estado: 51},
	{id: 5100201, nome: "Água Boa", estado: 51},
	{id: 5100250, nome: "Alta Floresta", estado: 51},
	{id: 5100300, nome: "Alto Araguaia", estado: 51},
	{id: 5100359, nome: "Alto Boa Vista", estado: 51},
	{id: 5100409, nome: "Alto Garças", estado: 51},
	{id: 5100508, nome: "Alto Paraguai", estado: 51},
	{id: 5100607, nome: "Alto Taquari", estado: 51},
	{id: 5100805, nome: "Apiacás", estado: 51},
	{id: 5101001, nome: "Araguaiana", estado: 51},
	{id: 5101209, nome: "Araguainha", estado: 51},
	{id: 5101258, nome: "Araputanga", estado: 51},
	{id: 5101308, nome: "Arenápolis", estado: 51},
	{id: 5101407, nome: "Aripuanã", estado: 51},
	{id: 5101605, nome: "Barão de Melgaço", estado: 51},
	{id: 5101704, nome: "Barra do Bugres", estado: 51},
	{id: 5101803, nome: "Barra do Garças", estado: 51},
	{id: 5101852, nome: "Bom Jesus do Araguaia", estado: 51},
	{id: 5101902, nome: "Brasnorte", estado: 51},
	{id: 5102504, nome: "Cáceres", estado: 51},
	{id: 5102603, nome: "Campinápolis", estado: 51},
	{id: 5102637, nome: "Campo Novo do Parecis", estado: 51},
	{id: 5102678, nome: "Campo Verde", estado: 51},
	{id: 5102686, nome: "Campos de Júlio", estado: 51},
	{id: 5102694, nome: "Canabrava do Norte", estado: 51},
	{id: 5102702, nome: "Canarana", estado: 51},
	{id: 5102793, nome: "Carlinda", estado: 51},
	{id: 5102850, nome: "Castanheira", estado: 51},
	{id: 5103007, nome: "Chapada dos Guimarães", estado: 51},
	{id: 5103056, nome: "Cláudia", estado: 51},
	{id: 5103106, nome: "Cocalinho", estado: 51},
	{id: 5103205, nome: "Colíder", estado: 51},
	{id: 5103254, nome: "Colniza", estado: 51},
	{id: 5103304, nome: "Comodoro", estado: 51},
	{id: 5103353, nome: "Confresa", estado: 51},
	{id: 5103361, nome: "Conquista D'oeste", estado: 51},
	{id: 5103379, nome: "Cotriguaçu", estado: 51},
	{id: 5103403, nome: "Cuiabá", estado: 51},
	{id: 5103437, nome: "Curvelândia", estado: 51},
	{id: 5103452, nome: "denise", estado: 51},
	{id: 5103502, nome: "Diamantino", estado: 51},
	{id: 5103601, nome: "dom Aquino", estado: 51},
	{id: 5103700, nome: "Feliz Natal", estado: 51},
	{id: 5103809, nome: "Figueirópolis D'oeste", estado: 51},
	{id: 5103858, nome: "Gaúcha do Norte", estado: 51},
	{id: 5103908, nome: "General Carneiro", estado: 51},
	{id: 5103957, nome: "Glória D'oeste", estado: 51},
	{id: 5104104, nome: "Guarantã do Norte", estado: 51},
	{id: 5104203, nome: "Guiratinga", estado: 51},
	{id: 5104500, nome: "Indiavaí", estado: 51},
	{id: 5104526, nome: "Ipiranga do Norte", estado: 51},
	{id: 5104542, nome: "Itanhangá", estado: 51},
	{id: 5104559, nome: "Itaúba", estado: 51},
	{id: 5104609, nome: "Itiquira", estado: 51},
	{id: 5104807, nome: "Jaciara", estado: 51},
	{id: 5104906, nome: "Jangada", estado: 51},
	{id: 5105002, nome: "Jauru", estado: 51},
	{id: 5105101, nome: "Juara", estado: 51},
	{id: 5105150, nome: "Juína", estado: 51},
	{id: 5105176, nome: "Juruena", estado: 51},
	{id: 5105200, nome: "Juscimeira", estado: 51},
	{id: 5105234, nome: "Lambari D'oeste", estado: 51},
	{id: 5105259, nome: "Lucas do Rio Verde", estado: 51},
	{id: 5105309, nome: "Luciara", estado: 51},
	{id: 5105507, nome: "Vila Bela da Santíssima Trindade", estado: 51},
	{id: 5105580, nome: "Marcelândia", estado: 51},
	{id: 5105606, nome: "Matupá", estado: 51},
	{id: 5105622, nome: "Mirassol D'oeste", estado: 51},
	{id: 5105903, nome: "Nobres", estado: 51},
	{id: 5106000, nome: "Nortelândia", estado: 51},
	{id: 5106109, nome: "Nossa Senhora do Livramento", estado: 51},
	{id: 5106158, nome: "Nova Bandeirantes", estado: 51},
	{id: 5106174, nome: "Nova Nazaré", estado: 51},
	{id: 5106182, nome: "Nova Lacerda", estado: 51},
	{id: 5106190, nome: "Nova Santa Helena", estado: 51},
	{id: 5106208, nome: "Nova Brasilândia", estado: 51},
	{id: 5106216, nome: "Nova Canaã do Norte", estado: 51},
	{id: 5106224, nome: "Nova Mutum", estado: 51},
	{id: 5106232, nome: "Nova Olímpia", estado: 51},
	{id: 5106240, nome: "Nova Ubiratã", estado: 51},
	{id: 5106257, nome: "Nova Xavantina", estado: 51},
	{id: 5106265, nome: "Novo Mundo", estado: 51},
	{id: 5106273, nome: "Novo Horizonte do Norte", estado: 51},
	{id: 5106281, nome: "Novo São Joaquim", estado: 51},
	{id: 5106299, nome: "Paranaíta", estado: 51},
	{id: 5106307, nome: "Paranatinga", estado: 51},
	{id: 5106315, nome: "Novo Santo Antônio", estado: 51},
	{id: 5106372, nome: "Pedra Preta", estado: 51},
	{id: 5106422, nome: "Peixoto de Azevedo", estado: 51},
	{id: 5106455, nome: "Planalto da Serra", estado: 51},
	{id: 5106505, nome: "Poconé", estado: 51},
	{id: 5106653, nome: "Pontal do Araguaia", estado: 51},
	{id: 5106703, nome: "Ponte Branca", estado: 51},
	{id: 5106752, nome: "Pontes E Lacerda", estado: 51},
	{id: 5106778, nome: "Porto Alegre do Norte", estado: 51},
	{id: 5106802, nome: "Porto dos Gaúchos", estado: 51},
	{id: 5106828, nome: "Porto Esperidião", estado: 51},
	{id: 5106851, nome: "Porto Estrela", estado: 51},
	{id: 5107008, nome: "Poxoréu", estado: 51},
	{id: 5107040, nome: "Primavera do Leste", estado: 51},
	{id: 5107065, nome: "Querência", estado: 51},
	{id: 5107107, nome: "São José dos Quatro Marcos", estado: 51},
	{id: 5107156, nome: "Reserva do Cabaçal", estado: 51},
	{id: 5107180, nome: "Ribeirão Cascalheira", estado: 51},
	{id: 5107198, nome: "Ribeirãozinho", estado: 51},
	{id: 5107206, nome: "Rio Branco", estado: 51},
	{id: 5107248, nome: "Santa Carmem", estado: 51},
	{id: 5107263, nome: "Santo Afonso", estado: 51},
	{id: 5107297, nome: "São José do Povo", estado: 51},
	{id: 5107305, nome: "São José do Rio Claro", estado: 51},
	{id: 5107354, nome: "São José do Xingu", estado: 51},
	{id: 5107404, nome: "São Pedro da Cipa", estado: 51},
	{id: 5107578, nome: "Rondolândia", estado: 51},
	{id: 5107602, nome: "Rondonópolis", estado: 51},
	{id: 5107701, nome: "Rosário Oeste", estado: 51},
	{id: 5107743, nome: "Santa Cruz do Xingu", estado: 51},
	{id: 5107750, nome: "Salto do Céu", estado: 51},
	{id: 5107768, nome: "Santa Rita do Trivelato", estado: 51},
	{id: 5107776, nome: "Santa Terezinha", estado: 51},
	{id: 5107792, nome: "Santo Antônio do Leste", estado: 51},
	{id: 5107800, nome: "Santo Antônio do Leverger", estado: 51},
	{id: 5107859, nome: "São Félix do Araguaia", estado: 51},
	{id: 5107875, nome: "Sapezal", estado: 51},
	{id: 5107883, nome: "Serra Nova dourada", estado: 51},
	{id: 5107909, nome: "Sinop", estado: 51},
	{id: 5107925, nome: "Sorriso", estado: 51},
	{id: 5107941, nome: "Tabaporã", estado: 51},
	{id: 5107958, nome: "Tangará da Serra", estado: 51},
	{id: 5108006, nome: "Tapurah", estado: 51},
	{id: 5108055, nome: "Terra Nova do Norte", estado: 51},
	{id: 5108105, nome: "Tesouro", estado: 51},
	{id: 5108204, nome: "Torixoréu", estado: 51},
	{id: 5108303, nome: "União do Sul", estado: 51},
	{id: 5108352, nome: "Vale de São domingos", estado: 51},
	{id: 5108402, nome: "Várzea Grande", estado: 51},
	{id: 5108501, nome: "Vera", estado: 51},
	{id: 5108600, nome: "Vila Rica", estado: 51},
	{id: 5108808, nome: "Nova Guarita", estado: 51},
	{id: 5108857, nome: "Nova Marilândia", estado: 51},
	{id: 5108907, nome: "Nova Maringá", estado: 51},
	{id: 5108956, nome: "Nova Monte Verde", estado: 51},
	{id: 5200050, nome: "Abadia de Goiás", estado: 52},
	{id: 5200100, nome: "Abadiânia", estado: 52},
	{id: 5200134, nome: "Acreúna", estado: 52},
	{id: 5200159, nome: "Adelândia", estado: 52},
	{id: 5200175, nome: "Água Fria de Goiás", estado: 52},
	{id: 5200209, nome: "Água Limpa", estado: 52},
	{id: 5200258, nome: "Águas Lindas de Goiás", estado: 52},
	{id: 5200308, nome: "Alexânia", estado: 52},
	{id: 5200506, nome: "Aloândia", estado: 52},
	{id: 5200555, nome: "Alto Horizonte", estado: 52},
	{id: 5200605, nome: "Alto Paraíso de Goiás", estado: 52},
	{id: 5200803, nome: "Alvorada do Norte", estado: 52},
	{id: 5200829, nome: "Amaralina", estado: 52},
	{id: 5200852, nome: "Americano do Brasil", estado: 52},
	{id: 5200902, nome: "Amorinópolis", estado: 52},
	{id: 5201108, nome: "Anápolis", estado: 52},
	{id: 5201207, nome: "Anhanguera", estado: 52},
	{id: 5201306, nome: "Anicuns", estado: 52},
	{id: 5201405, nome: "Aparecida de Goiânia", estado: 52},
	{id: 5201454, nome: "Aparecida do Rio doce", estado: 52},
	{id: 5201504, nome: "Aporé", estado: 52},
	{id: 5201603, nome: "Araçu", estado: 52},
	{id: 5201702, nome: "Aragarças", estado: 52},
	{id: 5201801, nome: "Aragoiânia", estado: 52},
	{id: 5202155, nome: "Araguapaz", estado: 52},
	{id: 5202353, nome: "Arenópolis", estado: 52},
	{id: 5202502, nome: "Aruanã", estado: 52},
	{id: 5202601, nome: "Aurilândia", estado: 52},
	{id: 5202809, nome: "Avelinópolis", estado: 52},
	{id: 5203104, nome: "Baliza", estado: 52},
	{id: 5203203, nome: "Barro Alto", estado: 52},
	{id: 5203302, nome: "Bela Vista de Goiás", estado: 52},
	{id: 5203401, nome: "Bom Jardim de Goiás", estado: 52},
	{id: 5203500, nome: "Bom Jesus de Goiás", estado: 52},
	{id: 5203559, nome: "Bonfinópolis", estado: 52},
	{id: 5203575, nome: "Bonópolis", estado: 52},
	{id: 5203609, nome: "Brazabrantes", estado: 52},
	{id: 5203807, nome: "Britânia", estado: 52},
	{id: 5203906, nome: "Buriti Alegre", estado: 52},
	{id: 5203939, nome: "Buriti de Goiás", estado: 52},
	{id: 5203962, nome: "Buritinópolis", estado: 52},
	{id: 5204003, nome: "Cabeceiras", estado: 52},
	{id: 5204102, nome: "Cachoeira Alta", estado: 52},
	{id: 5204201, nome: "Cachoeira de Goiás", estado: 52},
	{id: 5204250, nome: "Cachoeira dourada", estado: 52},
	{id: 5204300, nome: "Caçu", estado: 52},
	{id: 5204409, nome: "Caiapônia", estado: 52},
	{id: 5204508, nome: "Caldas Novas", estado: 52},
	{id: 5204557, nome: "Caldazinha", estado: 52},
	{id: 5204607, nome: "Campestre de Goiás", estado: 52},
	{id: 5204656, nome: "Campinaçu", estado: 52},
	{id: 5204706, nome: "Campinorte", estado: 52},
	{id: 5204805, nome: "Campo Alegre de Goiás", estado: 52},
	{id: 5204854, nome: "Campo Limpo de Goiás", estado: 52},
	{id: 5204904, nome: "Campos Belos", estado: 52},
	{id: 5204953, nome: "Campos Verdes", estado: 52},
	{id: 5205000, nome: "Carmo do Rio Verde", estado: 52},
	{id: 5205059, nome: "Castelândia", estado: 52},
	{id: 5205109, nome: "Catalão", estado: 52},
	{id: 5205208, nome: "Caturaí", estado: 52},
	{id: 5205307, nome: "Cavalcante", estado: 52},
	{id: 5205406, nome: "Ceres", estado: 52},
	{id: 5205455, nome: "Cezarina", estado: 52},
	{id: 5205471, nome: "Chapadão do Céu", estado: 52},
	{id: 5205497, nome: "Cidade Ocidental", estado: 52},
	{id: 5205513, nome: "Cocalzinho de Goiás", estado: 52},
	{id: 5205521, nome: "Colinas do Sul", estado: 52},
	{id: 5205703, nome: "Córrego do Ouro", estado: 52},
	{id: 5205802, nome: "Corumbá de Goiás", estado: 52},
	{id: 5205901, nome: "Corumbaíba", estado: 52},
	{id: 5206206, nome: "Cristalina", estado: 52},
	{id: 5206305, nome: "Cristianópolis", estado: 52},
	{id: 5206404, nome: "Crixás", estado: 52},
	{id: 5206503, nome: "Cromínia", estado: 52},
	{id: 5206602, nome: "Cumari", estado: 52},
	{id: 5206701, nome: "damianópolis", estado: 52},
	{id: 5206800, nome: "damolândia", estado: 52},
	{id: 5206909, nome: "davinópolis", estado: 52},
	{id: 5207105, nome: "Diorama", estado: 52},
	{id: 5207253, nome: "doverlândia", estado: 52},
	{id: 5207352, nome: "Edealina", estado: 52},
	{id: 5207402, nome: "Edéia", estado: 52},
	{id: 5207501, nome: "Estrela do Norte", estado: 52},
	{id: 5207535, nome: "Faina", estado: 52},
	{id: 5207600, nome: "Fazenda Nova", estado: 52},
	{id: 5207808, nome: "Firminópolis", estado: 52},
	{id: 5207907, nome: "Flores de Goiás", estado: 52},
	{id: 5208004, nome: "Formosa", estado: 52},
	{id: 5208103, nome: "Formoso", estado: 52},
	{id: 5208152, nome: "Gameleira de Goiás", estado: 52},
	{id: 5208301, nome: "Divinópolis de Goiás", estado: 52},
	{id: 5208400, nome: "Goianápolis", estado: 52},
	{id: 5208509, nome: "Goiandira", estado: 52},
	{id: 5208608, nome: "Goianésia", estado: 52},
	{id: 5208707, nome: "Goiânia", estado: 52},
	{id: 5208806, nome: "Goianira", estado: 52},
	{id: 5208905, nome: "Goiás", estado: 52},
	{id: 5209101, nome: "Goiatuba", estado: 52},
	{id: 5209150, nome: "Gouvelândia", estado: 52},
	{id: 5209200, nome: "Guapó", estado: 52},
	{id: 5209291, nome: "Guaraíta", estado: 52},
	{id: 5209408, nome: "Guarani de Goiás", estado: 52},
	{id: 5209457, nome: "Guarinos", estado: 52},
	{id: 5209606, nome: "Heitoraí", estado: 52},
	{id: 5209705, nome: "Hidrolândia", estado: 52},
	{id: 5209804, nome: "Hidrolina", estado: 52},
	{id: 5209903, nome: "Iaciara", estado: 52},
	{id: 5209937, nome: "Inaciolândia", estado: 52},
	{id: 5209952, nome: "Indiara", estado: 52},
	{id: 5210000, nome: "Inhumas", estado: 52},
	{id: 5210109, nome: "Ipameri", estado: 52},
	{id: 5210158, nome: "Ipiranga de Goiás", estado: 52},
	{id: 5210208, nome: "Iporá", estado: 52},
	{id: 5210307, nome: "Israelândia", estado: 52},
	{id: 5210406, nome: "Itaberaí", estado: 52},
	{id: 5210562, nome: "Itaguari", estado: 52},
	{id: 5210604, nome: "Itaguaru", estado: 52},
	{id: 5210802, nome: "Itajá", estado: 52},
	{id: 5210901, nome: "Itapaci", estado: 52},
	{id: 5211008, nome: "Itapirapuã", estado: 52},
	{id: 5211206, nome: "Itapuranga", estado: 52},
	{id: 5211305, nome: "Itarumã", estado: 52},
	{id: 5211404, nome: "Itauçu", estado: 52},
	{id: 5211503, nome: "Itumbiara", estado: 52},
	{id: 5211602, nome: "Ivolândia", estado: 52},
	{id: 5211701, nome: "Jandaia", estado: 52},
	{id: 5211800, nome: "Jaraguá", estado: 52},
	{id: 5211909, nome: "Jataí", estado: 52},
	{id: 5212006, nome: "Jaupaci", estado: 52},
	{id: 5212055, nome: "Jesúpolis", estado: 52},
	{id: 5212105, nome: "Joviânia", estado: 52},
	{id: 5212204, nome: "Jussara", estado: 52},
	{id: 5212253, nome: "Lagoa Santa", estado: 52},
	{id: 5212303, nome: "Leopoldo de Bulhões", estado: 52},
	{id: 5212501, nome: "Luziânia", estado: 52},
	{id: 5212600, nome: "Mairipotaba", estado: 52},
	{id: 5212709, nome: "Mambaí", estado: 52},
	{id: 5212808, nome: "Mara Rosa", estado: 52},
	{id: 5212907, nome: "Marzagão", estado: 52},
	{id: 5212956, nome: "Matrinchã", estado: 52},
	{id: 5213004, nome: "Maurilândia", estado: 52},
	{id: 5213053, nome: "Mimoso de Goiás", estado: 52},
	{id: 5213087, nome: "Minaçu", estado: 52},
	{id: 5213103, nome: "Mineiros", estado: 52},
	{id: 5213400, nome: "Moiporá", estado: 52},
	{id: 5213509, nome: "Monte Alegre de Goiás", estado: 52},
	{id: 5213707, nome: "Montes Claros de Goiás", estado: 52},
	{id: 5213756, nome: "Montividiu", estado: 52},
	{id: 5213772, nome: "Montividiu do Norte", estado: 52},
	{id: 5213806, nome: "Morrinhos", estado: 52},
	{id: 5213855, nome: "Morro Agudo de Goiás", estado: 52},
	{id: 5213905, nome: "Mossâmedes", estado: 52},
	{id: 5214002, nome: "Mozarlândia", estado: 52},
	{id: 5214051, nome: "Mundo Novo", estado: 52},
	{id: 5214101, nome: "Mutunópolis", estado: 52},
	{id: 5214408, nome: "Nazário", estado: 52},
	{id: 5214507, nome: "Nerópolis", estado: 52},
	{id: 5214606, nome: "Niquelândia", estado: 52},
	{id: 5214705, nome: "Nova América", estado: 52},
	{id: 5214804, nome: "Nova Aurora", estado: 52},
	{id: 5214838, nome: "Nova Crixás", estado: 52},
	{id: 5214861, nome: "Nova Glória", estado: 52},
	{id: 5214879, nome: "Nova Iguaçu de Goiás", estado: 52},
	{id: 5214903, nome: "Nova Roma", estado: 52},
	{id: 5215009, nome: "Nova Veneza", estado: 52},
	{id: 5215207, nome: "Novo Brasil", estado: 52},
	{id: 5215231, nome: "Novo Gama", estado: 52},
	{id: 5215256, nome: "Novo Planalto", estado: 52},
	{id: 5215306, nome: "Orizona", estado: 52},
	{id: 5215405, nome: "Ouro Verde de Goiás", estado: 52},
	{id: 5215504, nome: "Ouvidor", estado: 52},
	{id: 5215603, nome: "Padre Bernardo", estado: 52},
	{id: 5215652, nome: "Palestina de Goiás", estado: 52},
	{id: 5215702, nome: "Palmeiras de Goiás", estado: 52},
	{id: 5215801, nome: "Palmelo", estado: 52},
	{id: 5215900, nome: "Palminópolis", estado: 52},
	{id: 5216007, nome: "Panamá", estado: 52},
	{id: 5216304, nome: "Paranaiguara", estado: 52},
	{id: 5216403, nome: "Paraúna", estado: 52},
	{id: 5216452, nome: "Perolândia", estado: 52},
	{id: 5216809, nome: "Petrolina de Goiás", estado: 52},
	{id: 5216908, nome: "Pilar de Goiás", estado: 52},
	{id: 5217104, nome: "Piracanjuba", estado: 52},
	{id: 5217203, nome: "Piranhas", estado: 52},
	{id: 5217302, nome: "Pirenópolis", estado: 52},
	{id: 5217401, nome: "Pires do Rio", estado: 52},
	{id: 5217609, nome: "Planaltina", estado: 52},
	{id: 5217708, nome: "Pontalina", estado: 52},
	{id: 5218003, nome: "Porangatu", estado: 52},
	{id: 5218052, nome: "Porteirão", estado: 52},
	{id: 5218102, nome: "Portelândia", estado: 52},
	{id: 5218300, nome: "Posse", estado: 52},
	{id: 5218391, nome: "Professor Jamil", estado: 52},
	{id: 5218508, nome: "Quirinópolis", estado: 52},
	{id: 5218607, nome: "Rialma", estado: 52},
	{id: 5218706, nome: "Rianápolis", estado: 52},
	{id: 5218789, nome: "Rio Quente", estado: 52},
	{id: 5218805, nome: "Rio Verde", estado: 52},
	{id: 5218904, nome: "Rubiataba", estado: 52},
	{id: 5219001, nome: "Sanclerlândia", estado: 52},
	{id: 5219100, nome: "Santa Bárbara de Goiás", estado: 52},
	{id: 5219209, nome: "Santa Cruz de Goiás", estado: 52},
	{id: 5219258, nome: "Santa Fé de Goiás", estado: 52},
	{id: 5219308, nome: "Santa Helena de Goiás", estado: 52},
	{id: 5219357, nome: "Santa Isabel", estado: 52},
	{id: 5219407, nome: "Santa Rita do Araguaia", estado: 52},
	{id: 5219456, nome: "Santa Rita do Novo destino", estado: 52},
	{id: 5219506, nome: "Santa Rosa de Goiás", estado: 52},
	{id: 5219605, nome: "Santa Tereza de Goiás", estado: 52},
	{id: 5219704, nome: "Santa Terezinha de Goiás", estado: 52},
	{id: 5219712, nome: "Santo Antônio da Barra", estado: 52},
	{id: 5219738, nome: "Santo Antônio de Goiás", estado: 52},
	{id: 5219753, nome: "Santo Antônio do descoberto", estado: 52},
	{id: 5219803, nome: "São domingos", estado: 52},
	{id: 5219902, nome: "São Francisco de Goiás", estado: 52},
	{id: 5220009, nome: "São João D'aliança", estado: 52},
	{id: 5220058, nome: "São João da Paraúna", estado: 52},
	{id: 5220108, nome: "São Luís de Montes Belos", estado: 52},
	{id: 5220157, nome: "São Luíz do Norte", estado: 52},
	{id: 5220207, nome: "São Miguel do Araguaia", estado: 52},
	{id: 5220264, nome: "São Miguel do Passa Quatro", estado: 52},
	{id: 5220280, nome: "São Patrício", estado: 52},
	{id: 5220405, nome: "São Simão", estado: 52},
	{id: 5220454, nome: "Senador Canedo", estado: 52},
	{id: 5220504, nome: "Serranópolis", estado: 52},
	{id: 5220603, nome: "Silvânia", estado: 52},
	{id: 5220686, nome: "Simolândia", estado: 52},
	{id: 5220702, nome: "Sítio D'abadia", estado: 52},
	{id: 5221007, nome: "Taquaral de Goiás", estado: 52},
	{id: 5221080, nome: "Teresina de Goiás", estado: 52},
	{id: 5221197, nome: "Terezópolis de Goiás", estado: 52},
	{id: 5221304, nome: "Três Ranchos", estado: 52},
	{id: 5221403, nome: "Trindade", estado: 52},
	{id: 5221452, nome: "Trombas", estado: 52},
	{id: 5221502, nome: "Turvânia", estado: 52},
	{id: 5221551, nome: "Turvelândia", estado: 52},
	{id: 5221577, nome: "Uirapuru", estado: 52},
	{id: 5221601, nome: "Uruaçu", estado: 52},
	{id: 5221700, nome: "Uruana", estado: 52},
	{id: 5221809, nome: "Urutaí", estado: 52},
	{id: 5221858, nome: "Valparaíso de Goiás", estado: 52},
	{id: 5221908, nome: "Varjão", estado: 52},
	{id: 5222005, nome: "Vianópolis", estado: 52},
	{id: 5222054, nome: "Vicentinópolis", estado: 52},
	{id: 5222203, nome: "Vila Boa", estado: 52},
	{id: 5222302, nome: "Vila Propício", estado: 52},
	{id: 5300108, nome: "Brasília", estado: 53}
  ];
  
  
  

export const listaAlergias  = [
    { id: 1, nome: 'Glutem' },
    { id: 2, nome: 'Crostácios' },
    { id: 3, nome: 'Lacteos' },
    { id: 4, nome: 'Amendoins' },
    { id: 5, nome: 'Pescado' },
    { id: 6, nome: 'Frutos Secos' },
    { id: 7, nome: 'Ovos' },
    { id: 8, nome: 'Moluscúlos' },
    { id: 9, nome: 'Mostarda' },
    { id: 10, nome: 'Sementes de Sésamo' },
    { id: 11, nome: 'Dioxido Enxofre e Sulfitos' },
];
