import { useKeycloak } from "keycloak-react-web";
import Config from "../prescrito/Config";
import { useSendGet } from "../Server";

export const useLocalCacheUpdate = function(lkey: string) {
  const key = lkey + useKeycloak().keycloak.tokenParsed?.upn;
	return (select:Function, data:any) => {
		let lists = null;
		if((lists = localStorage.getItem(key)) != null){
		  let list = JSON.parse(lists).data.filter(select)
		  localStorage.setItem(key, JSON.stringify(
			{lastUpdate: Date.now(), data: [...list, data]}));
		}
	}
}

export const useLocalCache = function(lkey: string, url: string, setData: Function) {
  const key = lkey + useKeycloak().keycloak.tokenParsed?.upn;
  
	const sendGet = useSendGet();
	var get = false;
	return () => {
		let local: any = localStorage.getItem(key);
		const shouldUpdate = Config.get().shouldUpdate;

		if (local == null || shouldUpdate(key, JSON.parse(local).lastUpdate)) {
			if (get) return;
			get = true;

			sendGet(url,
				(response: any) => {
					if (response.data[0].statusCode) {
						setData([]);
						return;
					}

					setData(response.data);
					localStorage.setItem(key, JSON.stringify(
						{ lastUpdate: Date.now(), data: response.data }));

					get = false;
				});
		}
		else {
			let itens: any = JSON.parse(local).data;
			setData(itens ? itens : []);
		}
	}
}