import Form from 'react-bootstrap/Form';
import { Button, Col, Container, Row, } from 'react-bootstrap';
import MenuCelular from './MenuCelular';
import MenuPrincipal from './MenuPrincipal';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSendGet, useSendPost } from '../Server';
import InputMask from 'react-input-mask';

import 'react-phone-number-input/style.css'
import PhoneInputWithCountrySelect from 'react-phone-number-input';
import { vazio } from '../Validacao';
import { useLocalCache } from '../componentes/LocalCache';
import { verificaCpf, verificaEmail, verificaTelefone } from '../componentes/Validacao';
import { useAlerta } from '../componentes/Alerta';
import { listaCidades, listaEstados } from '../componentes/DadosComuns';
import Pesquisa from '../componentes/Pesquisa';

function PrescricaoVerificacao() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [paciente, setPaciente] = useState<any>({});
  const [dados, setDados] = useState<any>({ prescricao: id });

  const [listaCidadesFiltro, setListaCidadesFiltro] = useState<any[]>([]);

  const sendPost = useSendPost();
  const sendGet = useSendGet();


  const [pacientes, setPacientes] = useState([]);
  const pacientesDados = useLocalCache("pacientes", "paciente/medico", setPacientes);

  const [MensagemAlerta, setAlerta] = useAlerta();

  useEffect(() => {
    pacientesDados();
  }, []);

  useEffect(() => {
    const d = { prescricao: id };
    sendGet("prescricao/verificacao/" + id,
      (response: any) => {
        const r = response.data;

        r.pacienteObj.cidadeObj = { id: 1, nome: r.pacienteObj.cidade };
        setPaciente(r.pacienteObj);
        setDados(r);
      });
  }, []);

  function sendDados() {
    let p = {... paciente};
    p.cidade = p.cidadeObj?.nome;

    const d = { prescricao: id, paciente: p };
    sendPost("prescricao/verificada", d,
      (response: any) => {
        navigate("/prescricao-finalizada/" + id, { state: response.data });
      });
    return false;
  }

  useEffect(() => {
	let cidades = listaCidades.filter((c) => c.estado === parseInt(paciente.estado));
	setListaCidadesFiltro(cidades)	
  }, [paciente.estado]);

  
  return <>
    <MenuPrincipal />
    <Container>
        <Row className='mt-3'>
          <Col>
            <h2>Dados do Paciente</h2>
          </Col>
        </Row>
        <MensagemAlerta />
        <Row className='mt-3'>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>Nome</Form.Label>
              <Form.Control id="nome" name='nome' type="input" placeholder="Digite o nome do seu paciente"  autoComplete="no"
                value={paciente.nome} onChange={e => setPaciente({ ...paciente, nome: e.target.value })} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>CPF</Form.Label>
              <InputMask className="form-control" mask="999.999.999-99" value={paciente.cpf} onChange={e => setPaciente({ ...paciente, cpf: e.target.value })} autoComplete="no"
                type="input" placeholder="Digite o cpf do seu paciente" aria-describedby="cpfdescription" id="cpf"
                onBlur={e => verificaCpf(e.target.value, paciente.paciente, pacientes, setAlerta)}/>

              {vazio(paciente.cpf) ?
                <Form.Text id="cpfdescription" muted>
                  Esta informação pode ser útil no futuro.
                </Form.Text> : null}
            </Form.Group>
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>Telefone</Form.Label>

              <PhoneInputWithCountrySelect onChange={e => setPaciente({ ...paciente, telefone: e })} defaultCountry='BR'  autoComplete="no"
                value={paciente.telefone} placeholder="Digite o telefone" initialValueFormat='national' aria-describedby="telefone-description"
                onBlur={e => verificaTelefone(e, paciente.paciente, pacientes, setAlerta)} />

              {vazio(paciente.telefone) ?
                <Form.Text id="telefone-description" muted>
                  Esta informação pode ser útil no futuro.
                </Form.Text> : null}

            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>E-mail</Form.Label>
              <Form.Control id="email" type="input" placeholder="Digite o email do seu paciente"  autoComplete="no"
                value={paciente.email} onChange={e => setPaciente({ ...paciente, email: e.target.value })}
                onBlur={e => verificaEmail(e.target.value, paciente.paciente, pacientes, setAlerta)} />
            </Form.Group>
          </Col>
        </Row>

        <Row className='mt-3'>
          <Form.Group className="mb-3" >
            <Form.Label>Endereço</Form.Label>
            <Form.Control id="endereco" type="input" placeholder="Digite o endereço do seu paciente"  autoComplete="no"
              value={paciente.endereco} onChange={e =>  setPaciente({ ...paciente, endereco: e.target.value }) } />
          </Form.Group>
        </Row>
        <Row className='mt-3'>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>Estado</Form.Label>
              <Form.Select id="estado" aria-label="Selecione a raça do seu paciente"  autoComplete="no"
                onChange={e => setPaciente({... paciente, estado: e.target.value })}>
                <option>Selecione</option>
                {listaEstados.map((e) => <option selected={e.id === parseInt(paciente.estado)} value={e.id}>{e.nome}</option>)}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>Cidade</Form.Label>
              <Pesquisa id="cidade" selecao={paciente.cidadeObj} setSelecao={(s: any) =>setPaciente({ ...paciente, cidadeObj: s })} itens={listaCidadesFiltro} placeholder='Selecione a cidade' />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-3" >
          <Col sm={{ span: 6 }}>
            <Button type='submit' variant='primary' onClick={() => sendDados()}>Avançar</Button>
          </Col>
        </Row>
    </Container >
    <MenuCelular />

  </>
}
export default PrescricaoVerificacao;
