import axios from "axios";
import Config from "./prescrito/Config";


export function useSendPut() {

    return (path:string, data:any, onResponse:Function, onError:Function = (e:any)=>null) => {
      const url = Config.get().url(path);
      axios
        .put(url, data)
        .then((response) => {
          onResponse.call(globalThis, response);
        })
        .catch((error:any) => {
          onError.call(globalThis, error);
        });
    }
}

export function useSendGetImage() {
    return (path:string, onResponse:Function, onError:Function = (e:any)=>null) => {
      const url = Config.get().url(path);

      axios
        .get(url, {
			responseType: "document"
		})
        .then((response:any) => {
          onResponse.call(globalThis , response);
        })
        .catch((error:any) => {
          onError.call(globalThis, error);
        });
    }
}
export function useSendGetFile() {
    return (path:string, onResponse:Function, onError:Function = (e:any)=>null) => {
      const url = Config.get().url(path);

      axios
        .get(url, {
			responseType: "blob"
		})
        .then((response:any) => {
          onResponse.call(globalThis , response);
        })
        .catch((error:any) => {
          onError.call(globalThis, error);
        });
    }
}

export function useSendPostFile() {
    return (path:string, data:any, onResponse:Function, onError:Function = (e:any)=>null) => {
      const url = Config.get().url(path);

      axios
        .post(url, data, {
          responseType: "blob"
        })
        .then((response) => {
          onResponse.call(globalThis, response);
        }).catch((error:any) => {
          onError.call(globalThis, error);
        });
    }
}

export function useSendGet() {
    return (path:string, onResponse:Function, onError:Function = (e:any)=>null) => {
      const url = Config.get().url(path);

      axios
        .get(url)
        .then((response:any) => {
          onResponse.call(globalThis , response);
        })
        .catch((error:any) => {
          onError.call(globalThis, error);
        });
    }
}

export function useSendPost() {
    return (path:string, data:any, onResponse:Function, onError:Function = (e:any)=>null) => {
      const url = Config.get().url(path);

      axios
        .post(url, data)
        .then((response) => {
          onResponse.call(globalThis, response);
        })
        .catch((error:any) => {
          onError.call(globalThis, error);
        });
    }
}
