import Form from 'react-bootstrap/Form';
import { Button, Col, Container, Row } from 'react-bootstrap';
import MenuPrincipal from './MenuPrincipal';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSendPostFile } from '../NoAuthServer';
import MenuLogo from './MenuLogo';
import { useAlerta } from '../componentes/Alerta';
import { vazioProps } from '../Validacao';

function PrescricaoDownload() {
  const { id } = useParams();

  const [dados, setDados] = useState<any>({ prescricao: id });

  const sendPostFile = useSendPostFile();


  const [MensagemAlerta, setAlerta] = useAlerta();

  function downloadPrescricao() {
    let msg = null;

     let props = vazioProps(dados, ['codigoDownload'])
     if (msg == null && props.length > 0)
       msg = { type: 'danger', message: 'Informe os dados da prescricao:  ' + props.join(', ') };

     if (msg != null) {
       setAlerta(msg)
       return;
     }


    const d = { ...dados, id: id }
    sendPostFile("prescricao/acesso", d, (response: any) => {
      if (!response.data.size) {
        setAlerta({ type: 'danger', message: "Codigo invalido" });
        return;
      }
      const href = URL.createObjectURL(
        new Blob([response.data], { type: 'application/pdf' }));

      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'prescricao' + id + '.pdf');
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  }
  return <>
    <MenuLogo logoLink="/" />
    <Container>
      <Row className="mt-5">
        <Col>
          <h2>Arquivo prescricão </h2>
        </Col>
      </Row>
      <Row>
        <MensagemAlerta />
        <Form.Label>Informe o codigo para download da sua prescrição</Form.Label>
        <Col sm={{ span: 6 }}>
          <Form.Control id="codigoDownload" type="input" placeholder="Digite o codigo de download"
            value={dados.codigoDownload} onChange={e => setDados({ ...dados, codigoDownload: e.target.value })} onKeyPress={e => e.key === 'Enter' && downloadPrescricao()} />
        </Col>
        <Col sm={{ span: 6 }}>
          <Button type='button' variant='primary' onClick={() => downloadPrescricao()}>Fazer download</Button>
        </Col>
      </Row>
    </Container>
  </>
}
export default PrescricaoDownload;
