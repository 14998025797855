import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Aplicacao from './prescrito/Aplicacao';
import Login from './prescrito/Login';
import AppIndex from './AppIndex';
import Dashboard from './prescrito/Dashboard';
import Pesquisa from './prescrito/Pesquisa';
import Pacientes from './prescrito/Pacientes';
import Prescricao from './prescrito/Prescricao';
import PacienteEditor from './prescrito/PacienteEditor';
import MedicamentoLista from './prescrito/MedicamentoLista';
import PrescricaoConfiguracao from './prescrito/PrescricaoConfiguracao';
import PrivateRoute from './PrivateRoute';
import Logout from './prescrito/Logout';
import PrescricaoGerar from './prescrito/PrescricaoGerar';
import Home from './Home';
import MedicoEditor from './prescrito/MedicoEditor';
import SecretariaEditor from './prescrito/SecretariaEditor';
import PrescricaoFinalizada from './prescrito/PrescricaoFinalizada';
import SinaisVitaisEditor from './prescrito/SinaisVitaisEditor';
import EstabelecimentoEditor from './prescrito/EstabelecimentoEditor';
import PrescricaoVerificacao from './prescrito/PrescricaoVerificacao';
import PrescricaoVisualizacao from './prescrito/PrescricaoVisualizacao';
import Estabelecimentos from './prescrito/Estabelecimentos';
import MedicoCadastroInicial from './prescrito/MedicoCadastroInicial';
import PrescricaoAcesso from './prescrito/PrescricaoAcesso';
import PrescricaoDownload from './prescrito/PrescricaoDownload';

function AppRouter() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AppIndex />} />
          <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/aplicacao" element={<PrivateRoute><Aplicacao/></PrivateRoute>} />
          <Route path="/pesquisa" element={<PrivateRoute><Pesquisa /></PrivateRoute>} />
          <Route path="/pacientes" element={<PrivateRoute><Pacientes /></PrivateRoute>} />
          <Route path="/paciente-editor" element={<PrivateRoute><PacienteEditor /></PrivateRoute>} />
          <Route path="/paciente-editor/:id" element={<PrivateRoute><PacienteEditor /></PrivateRoute>} />
          <Route path="/prescricao" element={<PrivateRoute><Prescricao /></PrivateRoute>} />
          <Route path="/home" element={<PrivateRoute><Home/></PrivateRoute>} />
          <Route path="/medicamento-lista" element={<PrivateRoute><MedicamentoLista /></PrivateRoute>} />
          <Route path="/prescricao-configuracao" element={<PrivateRoute><PrescricaoConfiguracao /></PrivateRoute>} />
          <Route path="/gerar-prescricao/:id" element={<PrivateRoute><PrescricaoGerar/></PrivateRoute>} />
          <Route path="/medico-editor" element={<PrivateRoute><MedicoEditor/></PrivateRoute>} />
          <Route path="/secretaria-editor" element={<PrivateRoute><SecretariaEditor/></PrivateRoute>} />
          <Route path="/prescricao-finalizada/:id" element={<PrivateRoute><PrescricaoFinalizada/></PrivateRoute>} />
          <Route path="/download-prescricao/:id" element={<PrescricaoDownload/>} />
          <Route path="/acesso" element={<PrescricaoAcesso/>} />
          <Route path="/sinais-vitais" element={<PrivateRoute><SinaisVitaisEditor/></PrivateRoute>} />
          <Route path="/estabelecimento" element={<PrivateRoute><EstabelecimentoEditor/></PrivateRoute>} />
          <Route path="/estabelecimento/:id" element={<PrivateRoute><EstabelecimentoEditor/></PrivateRoute>} />
          <Route path="/estabelecimento-lista" element={<PrivateRoute><Estabelecimentos/></PrivateRoute>} />
          <Route path="/prescricao-verificacao/:id" element={<PrivateRoute><PrescricaoVerificacao/></PrivateRoute>} />
          <Route path="/prescricao-visualizar/:id" element={<PrivateRoute><PrescricaoVisualizacao/></PrivateRoute>} />
          <Route path="/medico-inicial" element={<PrivateRoute><MedicoCadastroInicial/></PrivateRoute>} />
        </Routes>
      </BrowserRouter>
  );
}
export default AppRouter;
