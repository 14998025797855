import Form from 'react-bootstrap/Form';
import { Button, ButtonGroup, Card, Col, Container, Dropdown, ListGroup, Nav, Row, Stack, Table } from 'react-bootstrap';
import MenuCelular from './MenuCelular';
import MenuPrincipal from './MenuPrincipal';
import { useEffect, useState } from 'react';
import Pesquisa from '../componentes/Pesquisa';
import { useKeycloak } from 'keycloak-react-web';
import { redirect, useNavigate, useParams } from 'react-router-dom';
import { useSendGet, useSendPost, useSendPut } from '../Server';
import { vazioProps } from '../Validacao';
import { useAlerta } from '../componentes/Alerta';
import { listaEstados, listaSexo } from '../componentes/DadosComuns';

function MedicoEditor() {
  const {id} = useParams();
  const keycloak = useKeycloak();
 
  const navigate = useNavigate();
  
  const userData = keycloak.keycloak.idTokenParsed;

  const [dados, setDados] = useState<any>({nome: userData?.given_name + ' ' + userData?.family_name, email: userData?.email});

  const [selected, setSelected] = useState("dados");
  
  const [MensagemAlerta, setAlerta] = useAlerta();

  const sendPostF = useSendPost();
  const sendPut = useSendPut();

  function sendDados(){
    let update = id ? sendPut: sendPostF;

    
    let msg = null;
    let props = vazioProps(dados, ['nome', 'cpf', 'email', 'telefone', 'numrCrm', 'estadoCrm']);
    if (msg == null && props.length > 0)
      msg = { type: 'warning', message: 'Informe dados do paciente: ' + props.join(', ') };

    if (msg != null) {
      setAlerta(msg)
      return;
    }

    let d = {...dados};
    const path = "medico";
    update(
      id ? path+"/" + id: path, d,  
      (response:any) => {
        console.log('data', response.data);
        navigate("/home")
    });
  }

  return <>
    <MenuPrincipal />
    <Container>
      <Row>
        <Col>
          <h1>Dados Médico</h1>
        </Col>
      </Row>
      <MensagemAlerta />
      <Form>
        <Row>
          <Form.Group className="mb-3" >
            <Form.Label>Nome *</Form.Label>
            <Form.Control id="nome" type="input" placeholder="Digite seu nome" autoComplete="no"
              value={dados.nome} onChange={e => setDados({ ...dados, nome: e.target.value })} />
          </Form.Group>
        </Row>
        <Form.Group className="mb-3" >
          <Form.Label>Sexo *</Form.Label>
          <Form.Select aria-label="Selecione o sexo do seu paciente"
            id="sexo" onChange={e => setDados({ ...dados, sexo: e.target.value })} >
            <option>Selecione</option>
            {listaSexo.map((i) => <option selected={dados.sexo == i.id} value={i.id}>{i.nome}</option>)}
          </Form.Select>
        </Form.Group>
        <Row>
          <Form.Group className="mb-3" >
            <Form.Label>E-mail *</Form.Label>
            <Form.Control id="email" type="input" placeholder="Digite o seu e-mail" autoComplete="no"
              value={dados.email} onChange={e => setDados({ ...dados, email: e.target.value })} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group className="mb-3" >
            <Form.Label>CPF *</Form.Label>
            <Form.Control id="cpf" type="input" placeholder="Digite apenas os números do CPF" autoComplete="no"
              value={dados.cpf} onChange={e => setDados({ ...dados, cpf: e.target.value })} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group className="mb-3" >
            <Form.Label>Número CRM</Form.Label>
            <Form.Control id="numrCrm" type="input" placeholder="Digite o seu CRM" autoComplete="no"
              value={dados.numrCrm} onChange={e => setDados({ ...dados, numrCrm: e.target.value })} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group className="mb-3" >
            <Form.Label>Estado CRM *</Form.Label>
            <Form.Select id="estadoCrm" aria-label="Selecione o estado do seu CRM"
              onChange={e => setDados({ ...dados, estadoCrm: e.target.value })}>
              <option>Selecione</option>
              {listaEstados.map((e) => <option selected={e.id === dados.estadoCrm} value={e.id}>{e.nome}</option>)}
            </Form.Select>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group className="mb-3" >
            <Form.Label>Telefone *</Form.Label>
            <Form.Control id="telefone" type="input" placeholder="Digite somente numeros do seu Telefone" autoComplete="no"
              value={dados.telefone} onChange={e => setDados({ ...dados, telefone: e.target.value })} />
          </Form.Group>
        </Row>
        <Row className="justify-content-end">
          <Col sm={{ span: 4 }}>
            <Stack gap={2} direction="horizontal">
              <Form.Group className="mt-3" >
                <Button type='button' variant='secondary'>Cancelar</Button>
              </Form.Group>
              <Form.Group className="mt-3" >{ 
                  id ? <Button type='button' variant='primary' onClick={() => sendDados()}>Atualizar</Button> :
                    <Button type='button' variant='primary' onClick={() => sendDados()}>Incluir</Button> 
                }
              </Form.Group>
            </Stack>
          </Col>
        </Row>
      </Form>
    </Container>
    <MenuCelular />
  </>
}
export default MedicoEditor;
