import Form from 'react-bootstrap/Form';
import { Button, ButtonGroup, Card, Col, Container, Dropdown, ListGroup, Nav, Row, Stack, Table } from 'react-bootstrap';
import MenuCelular from './MenuCelular';
import MenuPrincipal from './MenuPrincipal';
import { useEffect, useState } from 'react';
import { useKeycloak } from 'keycloak-react-web';
import { useNavigate, useParams } from 'react-router-dom';
import { useSendPost, useSendPut } from '../Server';
import { vazioProps } from '../Validacao';
import { useAlerta } from '../componentes/Alerta';
import { listaEstados, listaSexo } from '../componentes/DadosComuns';
import MenuLogo from './MenuLogo';

function MedicoCadastroInicial() {
  const { id } = useParams();
  const keycloak = useKeycloak();
  const userData = keycloak.keycloak.idTokenParsed;

  const navigate = useNavigate();
  const [dados, setDados] = useState<any>({ nome: userData?.given_name + ' ' + userData?.family_name, email: userData?.email });
  const [estabelecimento, setEstabelecimento] = useState<any>({});
  const [outros, setOutros] = useState<any>({});

  const sendPostF = useSendPost();
  const sendPut = useSendPut();

  const [MensagemAlerta, setAlerta] = useAlerta();
  let send = false;
  
  function sendDados() {
    if(send) return;
    send = true;
   
    let msg = null; 

    let props = vazioProps(dados, ['nome', 'cpf', 'email', 'telefone', 'numrCrm', 'estadoCrm']);
    if (msg == null && props.length > 0)
      msg = { type: 'warning', message: 'Informe dados do paciente: ' + props.join(', ') };

    if (msg != null) {
      setAlerta(msg)
      return;
    }
    
    let d = { medico: { ...dados }, estabelecimento: { ...estabelecimento }, ...outros};

    const path = "medico/create-inicial";
    sendPostF(path, d,
      (response: any) => {
        console.log('data', response.data);
        navigate("/home")
        send = false;
      }, 
    () => {
      send = false;
    });
  }

  return <>
    <MenuLogo />
    <Container>
      <Row className='mt-5'>
        <Col>
          <h3>Médico</h3>
          <p>Informe seus dados</p>
        </Col>
      </Row>
      <MensagemAlerta />
      <Form  autoComplete="no">
        <Row>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>Nome *</Form.Label>
              <Form.Control id="nome" type="input" placeholder="Digite seu nome" autoComplete="no"
                value={dados.nome} onChange={e => setDados({ ...dados, nome: e.target.value })} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>CPF *</Form.Label>
              <Form.Control id="cpf" type="input" placeholder="Digite apenas os números do CPF" autoComplete="no"
                value={dados.cpf} onChange={e => setDados({ ...dados, cpf: e.target.value })} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>Sexo *</Form.Label>
              <Form.Select aria-label="Selecione o sexo do seu paciente"
                id="sexo" onChange={e => setDados({ ...dados, sexo: e.target.value })} >
                <option>Selecione</option>
                {listaSexo.map((i) => <option selected={dados.sexo == i.id} value={i.id}>{i.nome}</option>)}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>E-mail *</Form.Label>
              <Form.Control id="email" type="input" placeholder="Digite o seu e-mail" autoComplete="no"
                value={dados.email} onChange={e => setDados({ ...dados, email: e.target.value })} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>Telefone *</Form.Label>
              <Form.Control id="telefone" type="input" placeholder="Digite somente numeros do seu Telefone" autoComplete="no"
                value={dados.telefone} onChange={e => setDados({ ...dados, telefone: e.target.value })} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>Número CRM *</Form.Label>
              <Form.Control id="numrCrm" type="input" placeholder="Digite o seu CRM" autoComplete="no"
                value={dados.numrCrm} onChange={e => setDados({ ...dados, numrCrm: e.target.value })} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>Estado CRM *</Form.Label>
              <Form.Select id="estadoCrm" aria-label="Selecione o estado do seu CRM"
                onChange={e => setDados({ ...dados, estadoCrm: e.target.value })}>
                <option>Selecione</option>
                {listaEstados.map((e) => <option selected={e.id === dados.estadoCrm} value={e.id}>{e.nome}</option>)}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
      </Form>
      <Row className='mt-5'>
        <Col>
          <h3>Estabelecimento</h3>
        </Col>
      </Row>
      <Form className="mt-3">
        <Row>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>Nome</Form.Label>
              <Form.Control id="nome" type="input" placeholder="Digite o nome do estabelecimento" autoComplete="no"
                value={estabelecimento.nome} onChange={e => setEstabelecimento({ ...estabelecimento, nome: e.target.value })} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>Telefone</Form.Label>
              <Form.Control id="telefone" type="input" placeholder="Digite apenas os números do telefone" autoComplete="no"
                value={estabelecimento.telefone} onChange={e => setEstabelecimento({ ...estabelecimento, telefone: e.target.value })} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>E-mail</Form.Label>
              <Form.Control id="email" type="input" placeholder="Digite o e-mail do estabelecimento" autoComplete="no"
                value={estabelecimento.email} onChange={e => setEstabelecimento({ ...estabelecimento, email: e.target.value })} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>Endereço</Form.Label>
              <Form.Control id="endereco" type="input" placeholder="Digite o endereço do estabelecimento" autoComplete="no"
                value={estabelecimento.endereco} onChange={e => setEstabelecimento({ ...estabelecimento, endereco: e.target.value })} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>Cidade</Form.Label>
              <Form.Control id="endereco" type="input" placeholder="Informe a cidade do estabelecimento" autoComplete="no"
                value={estabelecimento.cidade} onChange={e => setEstabelecimento({ ...estabelecimento, cidade: e.target.value })} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>Estado</Form.Label>
              <Form.Control id="endereco" type="input" placeholder="Informe o estado onde o estabelecimento esta localizado" autoComplete="no"
                value={estabelecimento.estado} onChange={e => setEstabelecimento({ ...estabelecimento, estado: e.target.value })} />
            </Form.Group>
          </Col>
        </Row>
        <Row className='mt-5'>
          <Col>
            <h3>Modelo prescrição</h3>
            <p>Dados para o modelo de prescrição</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>Título</Form.Label>
              <Form.Control id="titulo" type="input" placeholder="Informe o estado onde o estabelecimento esta localizado" autoComplete="no"
                value={outros.titulo} onChange={e => setOutros({ ...outros, titulo: e.target.value })} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>Subtítulo</Form.Label>
              <Form.Control id="subtitulo" type="input" placeholder="Informe o estado onde o estabelecimento esta localizado" autoComplete="no"
                value={outros.subtitulo} onChange={e => setOutros({ ...outros, subtitulo: e.target.value })} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>Rodapé</Form.Label>
              <Form.Control id="rodape" type="input" placeholder="Informe o estado onde o estabelecimento esta localizado" autoComplete="no"
                value={outros.rodape} onChange={e => setOutros({ ...outros, rodape: e.target.value })} />
            </Form.Group>
          </Col>
        </Row>

        <Row className="justify-content-end mb-3">
          <Col sm={{ span: 1 }}>
            <Form.Group className="mt-3">{
              id ? <Button type='button' variant='primary' onClick={() => sendDados()}>Atualizar</Button> :
                <Button type='button' variant='primary' onClick={() => sendDados()}>Incluir</Button>
            }
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </Container>
  </>
}
export default MedicoCadastroInicial;
